import React from "react";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";

const StyledButton = styled.button`
  ${breakpoint("xs")`
    width: 120px;
  `};
  ${breakpoint("sm")`
    width: 202px;
  `};
  height: 40px;
  color: ${({ theme }) => theme.basicTextColor};
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.disabledBtnBg : theme.activeBgColor};
  border: none;
  border-radius: 10px;

  &:hover {
    background: ${({ theme, disabled }) =>
      disabled ? `${theme.disabledBtnBg}bf` : theme.activeBgColor};
  }
`;

const Finalize = (props) => <StyledButton {...props} />;

export default Finalize;
