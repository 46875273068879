import axiosInstance from "./index";

export const getVideoRequest = (id) =>
  axiosInstance.get(`/video/${id}/share`).then((response) => response?.data);

export const getVideoKey = (id, params = {}) =>
  axiosInstance
    .get(`/video/${id}/share/key`, params)
    .then((response) => response?.data);

export const deleteDiveVideo = (videoId) =>
  axiosInstance
    .post(`/video/${videoId}/remove`)
    .then((response) => response.data);

export const setFeaturedDiveVideoRequest = ({ videoId, featured }) =>
  axiosInstance
    .post(`/video/${videoId}/featured/${featured}`)
    .then((response) => response.data);

export const reportVideoRequest = ({ id, ...params }) =>
  axiosInstance
    .post(`/video/${id}/report`, params)
    .then((response) => response.data);

export const restoreVideoRequest = (id) =>
  axiosInstance
    .get(`/video/${id}/delete/report/flag`)
    .then((response) => response.data);
