import React from "react";
import styled, { keyframes } from "styled-components";

const moveForever = keyframes`
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
`;

const WavesWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  transform: rotate(180deg);

  .waves {
    width: 100%;
    height: ${({ isMobile }) => (isMobile ? 100 : 150)}px;
    margin-bottom: -5px;
  }

  .parallax > use {
    fill: rgb(228 239 254 / 50%);
    animation: ${moveForever} 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
  }
  .parallax > use:nth-child(1) {
    animation-delay: -2s;
    animation-duration: 7s;
  }
  .parallax > use:nth-child(2) {
    animation-delay: -3s;
    animation-duration: 10s;
  }
  .parallax > use:nth-child(3) {
    animation-delay: -4s;
    animation-duration: 13s;
  }

  @media print {
    display: none;
  }
`;

const BackgroundWaves = ({ isMobile = false }) => (
  <WavesWrapper isMobile={isMobile}>
    <svg
      className="waves"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 24 150 28"
      preserveAspectRatio="none"
      shapeRendering="auto"
    >
      <defs>
        <path
          id="gentle-wave"
          d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
        />
      </defs>
      <g className="parallax">
        <use xlinkHref="#gentle-wave" x="48" y="0" />
        <use xlinkHref="#gentle-wave" x="48" y="3" />
        <use xlinkHref="#gentle-wave" x="48" y="5" />
      </g>
    </svg>
  </WavesWrapper>
);

export default BackgroundWaves;
