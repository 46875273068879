import { toast } from "react-toastify";

import { EVENT_STATUS, WS_STATUSES } from "constants/status";
import { WEBSOCKET_MSG_TYPES } from "constants/types";
import { CURRENT_DIVER_TIMEOUT } from "constants/leadershipModes";

import { createRoundQueue, getRoundDivers } from "helpers/diverInfoList";
import { getIsDiverHasCode } from "helpers/diveCode";
import { getEventName } from "helpers/event";
import {
  updateWebsocketData_2,
  setHeaderForCurrentRound_2,
  setRoundQueue_2,
  updateWsCurrentRound,
} from "store/actions/leadershipScreens";
import {
  updateEventLeadershipRound,
  setNextDiverRound,
  setFocusedDiverRound,
} from "store/actions/leadershipRound";
import {
  setCurrentDiverFocus,
  setDiverFocusedAction,
  clearLeadershipInfo,
  setCurrentRoundScreenSlidedAction,
} from "store/actions/diverInfo";
import { createWebsocket } from "API/websockets";

const { CLOSED, CANCELLED, NEW } = EVENT_STATUS;

const UPDATE_DIVERS_TIMEOUT = 1;

export const sendWSData = ({ ws: currentWS, data, resetWS = () => {} }) => {
  if (currentWS) {
    const sendWS = (ws) => ws.send(JSON.stringify(data));
    const onReconnect = (newWS) => {
      sendWS(newWS);
      resetWS(newWS);
    };

    if (currentWS.readyState === WS_STATUSES.OPEN) sendWS(currentWS);
    if (currentWS.readyState === WS_STATUSES.CLOSED)
      currentWS.reconnect({
        onReconnect: ({ ws: newWS }) => onReconnect(newWS),
      });
  } else {
    toast.error("Something went wrong. Please reload page");
  }
};

export const getCurrentDive = ({ currentDiveId, dives = [] }) => {
  if (currentDiveId) return dives.find(({ id }) => id === currentDiveId);

  return dives.find(({ current }) => current);
};

export const getCurrentEvent = (events = []) =>
  events.find(({ currentDiveId }) => currentDiveId) ||
  events.find(({ currentDiveId, info }) =>
    info.find(({ dives }) => getCurrentDive({ currentDiveId, dives })),
  ) ||
  events[0] ||
  {};

export const getCurrentDiverInfo = (event) => {
  const { currentDiveId, info = [] } = event;

  const resultInfo = info
    .sort(({ roundOrder: a }, { roundOrder: b }) => a - b)
    .map((props, index) => ({ ...props, roundOrder: index + 1 }));

  let currentDiveInfo;

  if (currentDiveId) {
    currentDiveInfo =
      resultInfo?.find(({ dives }) =>
        dives.some(({ id }) => id === currentDiveId),
      ) || {};
  }

  if (!currentDiveInfo) {
    currentDiveInfo =
      resultInfo?.find(({ dives }) => dives.some(({ current }) => current)) ||
      {};
  }

  return currentDiveInfo;
};

const getFocusedDiverRound = (events = []) => {
  const currentEvent = getCurrentEvent(events);
  const { roundNumber, totalRounds } = currentEvent;
  const allEventsInfo = events.flatMap(({ info }) => info);

  const divers = allEventsInfo
    .filter(getIsDiverHasCode(roundNumber))
    .sort(({ roundOrder: a }, { roundOrder: b }) => a - b);
  const isLastAward =
    roundNumber === totalRounds && allEventsInfo.every(({ jumped }) => jumped);
  const futureDiver = divers.find(({ jumped }) => !jumped) || divers[0];

  if (!futureDiver) return;

  if (isLastAward) return futureDiver?.numberOfPastDives || 0;

  return (futureDiver?.numberOfPastDives || 0) + 1;
};

export const wsOnMessage = ({ event, eventId, dispatch }) => {
  const message = JSON.parse(event.data);
  const { events = [], type } = message;

  const eventsIds = events.flatMap(({ eventId }) => eventId);

  dispatch(updateWebsocketData_2(message));
  dispatch(updateEventLeadershipRound(message));

  if (
    type === WEBSOCKET_MSG_TYPES.FOCUS_CHANGED &&
    eventsIds.includes(+eventId)
  ) {
    const diverFocused = events.find(({ currentDiveId, info }) =>
      info.find(({ dives }) => getCurrentDive({ currentDiveId, dives })),
    );

    dispatch(setCurrentDiverFocus(message));
    diverFocused && dispatch(setDiverFocusedAction(diverFocused));
    dispatch(setCurrentRoundScreenSlidedAction(false));

    return;
  }

  const focusedDiverRound = getFocusedDiverRound(events);
  focusedDiverRound && dispatch(setFocusedDiverRound(focusedDiverRound));

  if (type === WEBSOCKET_MSG_TYPES.EVENT_STARTED) {
    dispatch(setNextDiverRound(message));

    return;
  }
};

export const createRoundWebsocket =
  ({ eventId, dispatch }) =>
  () => {
    const onMessage = (event) => wsOnMessage({ event, eventId, dispatch });
    const ws =
      eventId && createWebsocket({ path: `event/${eventId}/round`, onMessage });

    return () => {
      ws?.close(1000, "Close component");
      dispatch(clearLeadershipInfo());
    };
  };

export const updateWebsocket =
  ({
    dispatch,
    wsNewMessage,
    wsDiverOnScreen = [],
    showAwardsDelay,
    hideDelay,
    currentRound,
  }) =>
  () => {
    const { events = [], type } = wsNewMessage;
    const currentEvent = getCurrentEvent(events);

    const allEventsInfo = events
      ?.sort(({ eventOrder: a }, { eventOrder: b }) => a - b)
      ?.flatMap(({ info }) =>
        info.sort(({ roundOrder: a }, { roundOrder: b }) => a - b),
      )
      ?.map((props, index) => ({ ...props, roundOrder: index + 1 }));
    const {
      dives: newDiverDives,
      user,
      combinedUser,
      place: placeFromNewWsMessage,
      totalAward,
      roundOrder: diverCurrent,
      warnings: warningsFromNewWsMessage,
      exhibition,
      prequalified,
    } = getCurrentDiverInfo(currentEvent);

    const userFromNewWsMessage = user ? [user] : combinedUser?.users;

    const updatedEventName = [CLOSED, CANCELLED, NEW].includes(
      currentEvent?.eventStatus,
    )
      ? events
          .flatMap((event) =>
            getEventName({
              ...event,
              title: event.title || event.eventName,
              type: event.type || event.eventType,
            }),
          )
          .join("\n")
      : getEventName({
          ...(currentEvent || {}),
          title: currentEvent?.eventName,
          type: currentEvent?.eventType,
        });

    dispatch(
      setRoundQueue_2({
        roundQueue: createRoundQueue(
          allEventsInfo,
          currentEvent?.currentDiveId,
        ),
        roundDivers: getRoundDivers(allEventsInfo),
      }),
    );

    const newDiverCurrentDive = getCurrentDive({
      currentDiveId: currentEvent.currentDiveId,
      dives: newDiverDives,
    });
    const newHeaderInfo = {
      round: {
        current: currentEvent?.roundNumber,
        total: currentEvent?.totalRounds,
      },
      diver: {
        current: diverCurrent + (currentEvent?.diversShift || 0),
        total: allEventsInfo.length,
      },
      place: +totalAward ? placeFromNewWsMessage : "-",
    };

    const newDiveOnScreen = {
      id: newDiverCurrentDive?.id,
      averageJudge: currentEvent.averageJudge,
      averageTotal: currentEvent.averageTotal,
      personalBest: currentEvent.personalBest,
      eventId: currentEvent?.eventId,
      eventName: updatedEventName,
      optional: newDiverCurrentDive?.optional,
      diveName: newDiverCurrentDive?.diveDifficulty?.dive?.name,
      position: newDiverCurrentDive?.diveDifficulty?.divePosition,
      code: newDiverCurrentDive?.diveDifficulty?.code,
      dd: newDiverCurrentDive?.diveDifficulty?.difficulty,
      customDifficulty: newDiverCurrentDive?.diveDifficulty?.customDifficulty,
      height: newDiverCurrentDive?.diveDifficulty?.diveHeight?.meters,
    };

    if (type === WEBSOCKET_MSG_TYPES.FOCUS_CHANGED) {
      const oldDiverId = wsDiverOnScreen[0]?.id;
      const { dives: newDives } =
        allEventsInfo.find(
          ({ user, combinedUser }) =>
            user?.id === oldDiverId || combinedUser?.users[0].id === oldDiverId,
        ) || {};
      const updatedOldDive =
        newDives?.find(({ number }) => number === currentRound) || {};

      dispatch(
        updateWsCurrentRound({
          currentTotal: updatedOldDive.currentRoundTotal,
          diveTotal: updatedOldDive.roundAward,
          awards: updatedOldDive.awards,
        }),
      );

      const updateData = () => {
        dispatch(
          updateWsCurrentRound({
            currentTotal: newDiverCurrentDive?.currentRoundTotal,
            diveTotal: newDiverCurrentDive?.roundAward,
            awards: [],
            diverOnScreen: userFromNewWsMessage,
            warnings: warningsFromNewWsMessage,
            exhibition,
            prequalified,
            diveOnScreen: newDiveOnScreen,
          }),
        );
        dispatch(setHeaderForCurrentRound_2(newHeaderInfo));
      };
      if (hideDelay) {
        updateData();
      } else {
        clearTimeout(showAwardsDelay.current);
        showAwardsDelay.current = setTimeout(
          () => {
            updateData();
          },
          updatedOldDive.awards?.length
            ? CURRENT_DIVER_TIMEOUT
            : UPDATE_DIVERS_TIMEOUT,
        );
      }
    }

    if (
      !type ||
      type === WEBSOCKET_MSG_TYPES.EVENT_CLOSED ||
      type === WEBSOCKET_MSG_TYPES.EVENT_CANCELLED
    ) {
      dispatch(
        updateWsCurrentRound({
          currentTotal: newDiverCurrentDive?.currentRoundTotal || "",
          diveTotal: newDiverCurrentDive?.roundAward || "",
          diverOnScreen: userFromNewWsMessage,
          warnings: warningsFromNewWsMessage,
          exhibition,
          prequalified,
          diveOnScreen: newDiveOnScreen,
        }),
      );
      dispatch(setHeaderForCurrentRound_2(newHeaderInfo));
    }
  };
