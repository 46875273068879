import { EVENT_STATUS } from "constants/status";

const { CLOSED, CANCELLED, NEW, IN_PROGRESS } = EVENT_STATUS;

export const roundsListResponseHandler = ({
  rounds,
  judgePanel: judges,
  boardName,
  eventColour,
  splitted,
  governingBody,
}) => {
  rounds
    .sort(
      (
        { number: a, subRounds: subRoundsA },
        { number: b, subRounds: subRoundsB },
      ) => (a || subRoundsA.number) - (b || subRoundsB.number),
    )
    .unshift("");
  delete rounds[0];

  return {
    governingBody,
    judges,
    rounds,
    splitBoard: {
      boardName,
      eventColour,
      splitted,
    },
  };
};

export const getFiltredDivers = ({
  divers = [],
  eventStatus = IN_PROGRESS,
  isOpenedSlider = true,
}) => {
  const diverUpNextIndex = divers.findIndex(({ isUpNext }) => isUpNext);
  const diverLiveDiveIndex = divers.findIndex(({ isLive }) => isLive);

  const diverFilterCallback = (index, isOpened) => {
    if (isOpened) return true;

    if (diverUpNextIndex >= 0) return diverUpNextIndex === index;
    if (diverLiveDiveIndex >= 0) return diverLiveDiveIndex === index;

    return !index;
  };

  let filtredDivers = divers.filter((_, index) =>
    diverFilterCallback(index, isOpenedSlider),
  );

  if (
    !filtredDivers.length &&
    divers.length &&
    [CLOSED, CANCELLED, NEW].includes(eventStatus)
  ) {
    filtredDivers = divers.slice(0, 1);
  }

  return filtredDivers;
};
