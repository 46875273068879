import {
  actionFulfilled,
  SET_CURRENT_DIVE_HEADER,
  UPDATE_LEADERSHIP_SOCKET,
  CLEAR_LEADERSHIP,
  SET_ROUND_QUEUE,
  GET_MEET_ADMINS,
  GET_DIVE_LIST_VIDEOS,
  SET_LEADER_SCREEN_EVENTS,
  ADD_LEADER_SCREEN_EVENTS,
  REMOVE_DIVE_VIDEO,
  UPDATE_WS_CURRENT_ROUND,
  GET_MEET_PERMISSIONS,
  REPORT_VIDEO,
  GET_DIVE_SHEET_VIDEOS,
} from "store/actionTypes";

const defaultState = {
  websocketNewMessage: {},
  roundQueue: [],
  roundDivers: [],
  diveList: { diveVideos: {} },
  currentRound: {},
  leaderboard: {},
  headerInfo: {
    round: { current: "_", total: "_" },
    diver: { current: "_", total: "_" },
    place: "_",
  },
  leaderScreenEvents: [],
  meetPermissions: [],
};

const leadershipScreensReducer = (state = defaultState, { type, payload }) => {
  switch (type) {
    case UPDATE_LEADERSHIP_SOCKET:
      return {
        ...state,
        websocketNewMessage: payload,
      };
    case actionFulfilled(GET_DIVE_LIST_VIDEOS):
    case actionFulfilled(GET_DIVE_SHEET_VIDEOS):
      return {
        ...state,
        diveList: {
          ...state.diveList,
          diveVideos: { ...state.diveList.diveVideos, ...payload },
        },
      };
    case actionFulfilled(REPORT_VIDEO): {
      const { diveVideos } = state.diveList;

      return {
        ...state,
        diveList: {
          ...state.diveList,
          diveVideos: Object.keys(diveVideos).reduce(
            (acc, cur) => ({
              ...acc,
              [cur]: diveVideos[cur].filter(({ id }) => id !== payload),
            }),
            {},
          ),
        },
      };
    }
    case actionFulfilled(REMOVE_DIVE_VIDEO):
      const { id, diveId } = payload;
      const { diveVideos } = state.diveList;

      return {
        ...state,
        diveList: {
          ...state.diveList,
          diveVideos: {
            ...diveVideos,
            [diveId]: diveVideos[diveId].filter((video) => video.id !== id),
          },
        },
      };
    case actionFulfilled(GET_MEET_ADMINS):
      return {
        ...state,
        meetAdmins: payload,
      };
    case actionFulfilled(GET_MEET_PERMISSIONS):
      return {
        ...state,
        meetPermissions: payload,
      };
    case UPDATE_WS_CURRENT_ROUND:
      return {
        ...state,
        currentRound: {
          ...state.currentRound,
          ...payload,
        },
      };
    case SET_CURRENT_DIVE_HEADER:
      return {
        ...state,
        headerInfo: payload,
      };
    case SET_ROUND_QUEUE:
      return {
        ...state,
        ...payload,
      };
    case CLEAR_LEADERSHIP:
      return defaultState;

    case SET_LEADER_SCREEN_EVENTS:
      return {
        ...state,
        leaderScreenEvents: payload,
      };
    case ADD_LEADER_SCREEN_EVENTS:
      const newEvents = payload.filter(
        (event) =>
          !state.leaderScreenEvents.some(
            ({ mainEventId }) => mainEventId === event.mainEventId,
          ),
      );

      const leaderScreenEvents = state.leaderScreenEvents.map((event) => {
        const newEventData = payload.find(
          ({ mainEventId }) => mainEventId === event.mainEventId,
        );

        if (newEventData) return newEventData;

        return event;
      });

      return {
        ...state,
        leaderScreenEvents: [...leaderScreenEvents, ...newEvents],
      };
    default:
      return state;
  }
};

export default leadershipScreensReducer;
