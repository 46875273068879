export const INDOOR_THEME = "INDOOR_THEME";
export const OUTDOOR_THEME = "OUTDOOR_THEME";

export const darkTheme = {
  navbarBackgroundColor: "#dbdbdb",
  navbarBackColor: "#21232300",
  basicBackGroundColor: "#ffffff",
  basicBgOnHover: "#b0dcf5",
  basicBgRgb: "255, 255, 255",
  basicBlue: "#0497f1",
  lighterBlue: "#00A3FF",
  darkBasicBgBlue: "#2B4DA7",
  basicBgRevert: "#000000",
  basicBgRevertRgb: "0, 0, 0",
  infoCardBackgroundColor: "#3f4342",
  accentBackgroundColor: "#ffffff",
  focusBackgroundColor: "rgba(245, 196, 21, 0.1)",
  borderColor: "#e0e7ff",
  grayBlueBackground: "#E9EEF8",
  borderGreyColor: "#b5b5b5",
  borderLightGreyColor: "#d3d3d3",
  focusBorderColor: "rgba(46, 91, 255, 0.08)",
  errorColor: "#ED4337",
  accentTextColor: "#222222",
  red: "#ff0000",
  mainBackgroundRgb: "33, 35, 35",
  mainBackground: "rgba(33, 35, 35, 0.086)",
  activeBackground: "#000240",
  lightBgColor: "#F3F6FC",
  activeBgColor: "#03d951",
  darkActiveBgColor: "#00b141",
  inactiveLightGreyBorder: "#dddddd",
  shadow: "4px 4px 14px -5px #000000",

  semiBlue: "#ECF3FF",
  greenButtonColor: "#03D94F",
  darkPinkButtonColor: "#D02C75",
  modalBlueBtnColor: "#0094f4",
  orange: "#F08803",
  pink: "#CF2C75",
  basicTextColor: "#ffffff",
  grayTextColor: "#000000",
  grayButtonHover: "#e4e2e2",
  grayBorder: "#363333",
  lightGreyBorder: "#dddddd",
  highGray: "#bcbcbc",
  semiGray: "#77839A",
  fieldBackground: "#F9F9F9",
  listBackColor: "#f6f6f6",
  unimportantText: "#adadb0",
  lightBorderColor: "#ebe9e9",
  linkWhiteColor: "#ffffff",
  progressBarFill: "#4487d5",
  progressBarEmpty: "#e5f0fa",
  disabledBtnBg: "#b8b8b8",
};

export const lightTheme = {
  navbarBackgroundColor: "#dbdbdb",
  navbarBackColor: "#21232300",
  basicBackGroundColor: "#ffffff",
  basicBgOnHover: "#b0dcf5",
  basicBgRgb: "255, 255, 255",
  basicBlue: "#0043be",
  lighterBlue: "#00A3FF",
  darkBasicBgBlue: "#2B4DA7",
  basicBgRevert: "#000000",
  basicBgRevertRgb: "0, 0, 0",
  infoCardBackgroundColor: "#3f4342",
  accentBackgroundColor: "#fff",
  focusBackgroundColor: "rgba(245, 196, 21, 0.1)",
  borderColor: "#e0e7ff",
  grayBlueBackground: "#E9EEF8",
  borderGreyColor: "#b5b5b5",
  borderLightGreyColor: "#d3d3d3",
  focusBorderColor: "rgba(46, 91, 255, 0.08)",
  errorColor: "#ED4337",
  accentTextColor: "#000000",
  red: "#ff0000",
  mainBackgroundRgb: "33, 35, 35",
  mainBackground: "rgba(33, 35, 35, 0.086)",
  activeBackground: "#000240",
  lightBgColor: "#a9a9a9",
  activeBgColor: "#00b141",
  darkActiveBgColor: "#00b141",
  inactiveLightGreyBorder: "#dddddd",
  shadow: "4px 4px 14px -5px #000000",

  semiBlue: "#f4fcff",
  greenButtonColor: "#03D94F",
  darkPinkButtonColor: "#D02C75",
  modalBlueBtnColor: "#006fff",
  orange: "#F08803",
  pink: "#a6004a",
  basicTextColor: "#ffffff",
  grayTextColor: "#000000",
  grayButtonHover: "#979797",
  grayBorder: "#363333",
  lightGreyBorder: "#dddddd",
  highGray: "#000000",
  semiGray: "#000000",
  fieldBackground: "#e4e4e4",
  listBackColor: "#f6f6f6",
  unimportantText: "#adadb0",
  lightBorderColor: "#ebe9e9",
  linkWhiteColor: "#ffffff",
  progressBarFill: "#4487d5",
  progressBarEmpty: "#e5f0fa",
  disabledBtnBg: "#838383",
};
