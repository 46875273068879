import {
  actionFulfilled,
  GET_ALL_USERS,
  SEARCH_ALL_USERS,
  CLEAR_FOLLOWED_USERS,
  CLEAR_USER_FOLLOWERS,
  FOLLOW_DIVER,
  GET_FOLLOWED_USERS,
  GET_USER_FOLLOWERS,
  SEARCH_FOLLOWED_USERS,
  SEARCH_USER_FOLLOWERS,
  GET_USER_INFO_FOLLOWED,
  CLEAR_USER_INFO_FOLLOWED,
  REMOVE_FOLLOWER,
  FOLLOW_USER,
} from "store/actionTypes";

const DEFAULT_CURRENT_PAGE = 0;

const defaultState = {
  all: {
    list: [],
    currentPage: DEFAULT_CURRENT_PAGE,
  },
  followed: {
    list: [],
    currentPage: DEFAULT_CURRENT_PAGE,
  },
  followers: {
    list: [],
    currentPage: DEFAULT_CURRENT_PAGE,
  },
  info: {
    followed: 0,
    followers: 0,
    previewUsers: [],
  },
};

const sortUsers = (a, b) =>
  `${a.firstName.trim()} ${a.lastName.trim()}`.localeCompare(
    `${b.firstName.trim()} ${b.lastName.trim()}`,
  );

const followReducer = (state = defaultState, { type, payload }) => {
  switch (type) {
    case actionFulfilled(GET_ALL_USERS): {
      const { usersDto, currentPage } = payload;

      return {
        ...state,
        all: {
          currentPage,
          list: [...(!!currentPage ? state.all.list : []), ...usersDto],
        },
      };
    }
    case actionFulfilled(FOLLOW_USER): {
      const { follow, user } = payload;

      const previewUsers = (function () {
        if (!follow)
          return state.info.previewUsers.filter(({ id }) => id !== user?.id);
        if (state.info.previewUsers.length < 2)
          return [...state.info.previewUsers, user];

        return state.info.previewUsers;
      })();

      return {
        ...state,
        info: {
          ...state.info,
          followers: follow
            ? state.info.followers + 1
            : state.info.followers - 1,
          previewUsers,
        },
      };
    }
    case actionFulfilled(SEARCH_ALL_USERS): {
      const { usersDto, currentPage } = payload;

      return {
        ...state,
        all: {
          currentPage,
          list: usersDto.sort(sortUsers),
        },
      };
    }
    case actionFulfilled(FOLLOW_DIVER): {
      const { diverId, followed, listKey } = payload;

      if (!listKey) return state;

      if (!followed)
        return {
          ...state,
          [listKey]: {
            ...state[listKey],
            list: state[listKey].list.filter((diver) => diver.id !== diverId),
          },
        };

      return {
        ...state,
        [listKey]: {
          ...state[listKey],
          list: state[listKey].list.map((diver) =>
            diver.id === diverId ? { ...diver, followed } : diver,
          ),
        },
      };
    }
    case actionFulfilled(REMOVE_FOLLOWER): {
      return {
        ...state,
        followers: {
          ...state.followers,
          list: state.followers.list.filter(({ id }) => id !== payload),
        },
      };
    }
    case actionFulfilled(GET_USER_INFO_FOLLOWED): {
      const { followers, followed, usersDto } = payload;

      return {
        ...state,
        info: {
          followers,
          followed,
          previewUsers: usersDto,
        },
      };
    }
    case actionFulfilled(GET_FOLLOWED_USERS): {
      const { currentPage, usersDto } = payload;

      return {
        ...state,
        followed: {
          currentPage,
          list: [...(!!currentPage ? state.followed.list : []), ...usersDto],
        },
      };
    }
    case actionFulfilled(GET_USER_FOLLOWERS): {
      const { currentPage, usersDto } = payload;

      return {
        ...state,
        followers: {
          currentPage,
          list: [...(!!currentPage ? state.followers.list : []), ...usersDto],
        },
      };
    }
    case actionFulfilled(SEARCH_FOLLOWED_USERS): {
      const { currentPage, usersDto } = payload;

      return {
        ...state,
        followed: {
          currentPage,
          list: usersDto.sort(sortUsers),
        },
      };
    }
    case actionFulfilled(SEARCH_USER_FOLLOWERS): {
      const { currentPage, usersDto } = payload;

      return {
        ...state,
        followers: {
          currentPage,
          list: usersDto.sort(sortUsers),
        },
      };
    }
    case CLEAR_USER_INFO_FOLLOWED:
      return {
        ...state,
        info: defaultState.info,
      };
    case CLEAR_FOLLOWED_USERS:
      return {
        ...state,
        followed: defaultState.followed,
      };
    case CLEAR_USER_FOLLOWERS:
      return {
        ...state,
        followers: defaultState.followers,
      };
    default:
      return state;
  }
};

export default followReducer;
