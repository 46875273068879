import {
  actionFulfilled,
  ADD_LEADERBOARD_VIEW_EVENTS,
  SET_LEADERBOARD_VIEW_EVENTS,
  CLEAR_LEADERBOARD_VIEW,
  GET_EVENT_LEADERBOARD_VIEW,
  SET_PAGE_SIZE,
  GO_TO_NEXT_PAGE,
} from "store/actionTypes";

const defaultState = {
  mainEvent: {},
  events: [],
  pageSize: 1,
  currentPage: 1,
  currentPageEventId: 0,
};

const leaderboardViewReducer = (state = defaultState, { type, payload }) => {
  switch (type) {
    case actionFulfilled(GET_EVENT_LEADERBOARD_VIEW): {
      return {
        ...state,
        mainEvent: payload,
      };
    }
    case SET_LEADERBOARD_VIEW_EVENTS:
      return {
        ...state,
        events: payload,
      };
    case ADD_LEADERBOARD_VIEW_EVENTS:
      const newEvents = payload.filter(
        (event) =>
          !state.events.some(
            ({ mainEventId }) => mainEventId === event.mainEventId,
          ),
      );

      const events = state.events.map((event) => {
        const newEventData = payload.find(
          ({ mainEventId }) => mainEventId === event.mainEventId,
        );

        if (newEventData) return newEventData;

        return event;
      });

      return {
        ...state,
        events: [...events, ...newEvents],
      };
    case SET_PAGE_SIZE:
      return {
        ...state,
        pageSize: payload,
      };
    case GO_TO_NEXT_PAGE: {
      const { currentPage, currentPageEventId, events, pageSize } = state;
      const eventsWithCurrentSheets = events.filter(({ sheets }) =>
        sheets.some(({ current }) => current),
      );

      const newCurrentPageEventId =
        eventsWithCurrentSheets.length === 1
          ? eventsWithCurrentSheets[0].eventId
          : 0;

      const newCurrentPage = (function () {
        if (currentPageEventId !== newCurrentPageEventId)
          // if an active event is new return initial page number
          return defaultState.currentPage;

        const newCurrentPageNumber = currentPage + 1;

        if (!!newCurrentPageEventId) {
          // if an active event is only one
          const { sheets } = eventsWithCurrentSheets[0];
          const eventPagesAmount = Math.ceil(sheets.length / pageSize);

          if (newCurrentPageNumber > eventPagesAmount)
            return defaultState.currentPage; // if a new page is out of active event return initial page number

          return newCurrentPageNumber; // go to next page
        }

        // if there is no specific event
        const sumOfPages = events.reduce((acc, { sheets }) => {
          const eventPagesAmount = Math.ceil(sheets.length / pageSize);

          return acc + eventPagesAmount;
        }, 0);

        return newCurrentPageNumber > sumOfPages
          ? defaultState.currentPage
          : newCurrentPageNumber;
      })();

      return {
        ...state,
        currentPage: newCurrentPage,
        currentPageEventId: newCurrentPageEventId,
      };
    }

    case CLEAR_LEADERBOARD_VIEW:
      return defaultState;
    default:
      return state;
  }
};

export default leaderboardViewReducer;
