import React, { useEffect, useState } from "react";
import PullToRefresh from "react-simple-pull-to-refresh";

const RefreshWrapper = ({ children }) => {
  const [isRefresh, setIsRefresh] = useState(false);

  const onRefreshPage = () => {
    return new Promise((res) => {
      setTimeout(() => {
        res(
          (() => {
            setIsRefresh(true);

            window.location.reload(true);
          })(),
        );
      }, 1000);
    });
  };

  useEffect(() => {
    if (isRefresh) setIsRefresh(false);
  }, [isRefresh]);

  return (
    <PullToRefresh
      pullDownThreshold={50}
      onRefresh={onRefreshPage}
      className="refresh-container"
    >
      {isRefresh ? null : children}
    </PullToRefresh>
  );
};

export default RefreshWrapper;
