import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { BsShare, BsDot } from "react-icons/bs";
import { toast } from "react-toastify";

import circles from "assets/images/circles.svg";
import waves from "assets/images/waves.svg";

import { ROUTE } from "constants/routing";
import {
  getYesNoModalDefaultState,
  MEET_STEPS,
  GOVERNING_BODIES,
  TICKET_CANVAS_TYPES,
} from "constants/types";
import { modalStateForAdministrationClick } from "constants/settings";

import { postDeleteMeetRequest } from "API/meets";
import { isMobile } from "helpers/user";
import { deleteMeetAction } from "store/actions/meets";
import { getGoverningBodyLogoBase64 } from "helpers/meets";
import { currencySymbolSelector } from "store/selectors/general";
import { shareModalIdSelector } from "store/selectors/video";

import ButtonDropdown from "Components/ButtonDropdown";
import TicketCanvas from "Components/TicketCanvas";
import ShareModal from "Components/ShareModal";
import ConfirmationModal from "Components/ConfirmationModal";

import BestScores from "./BestScores";

const StyledContainer = styled.div`
  margin: ${({ withMargin }) => (withMargin ? "10px 20px" : "0")};
  position: relative;
  cursor: pointer;
`;
const StyledPlaceholder = styled.div`
  position: relative;
  width: 100%;
  min-height: ${({ hiddenCard }) => (hiddenCard ? 235 : 189)}px;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 20px;
  background: center bottom / 102% no-repeat url(${waves}),
    center -3px / 102% no-repeat url(${circles});
  overflow: hidden;
`;
const StyledTitleGroup = styled.h4`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
`;
const StyledMeetType = styled.span`
  color: #ffffff;
  font-size: 14px;
  text-transform: uppercase;
`;
const StyledButtonPlaceholder = styled.div`
  align-items: center;
  display: flex;

  .ml-10 {
    margin-left: 10px;
  }
`;
const StyledTitle = styled.span`
  color: #ffffff;
  font-size: 20px;
  padding-bottom: 5px;
  ${breakpoint("md")`
    font-size: 20px;
  `};
  font-weight: 600;
`;
const StyledDate = styled.p`
  color: #ffffff;
  font-size: 14px;
`;
const StyledLocation = styled.p`
  color: #ffec00;
  font-size: 14px;
  padding-top: 10px;
`;
const StyledDivers = styled.span`
  color: #ffffff;
  font-size: 14px;
  text-align: right;
`;
const StyledNumber = styled.span`
  color: #ffec00;
  font-size: 14px;
`;
const StyledNameStatus = styled.p`
  color: ${({ theme }) => theme.basicTextColor};
  font-size: 14px;
`;
const GoverningIconWrapper = styled.div`
  position: relative;
  font-size: 0;

  img {
    position: relative;
    width: ${({ governingAbbreviation }) => {
      switch (governingAbbreviation) {
        case GOVERNING_BODIES.MCDL:
          return "75px";
        default:
          return "40px";
      }
    }};
  }

  img:first-child {
    position: absolute;

    ${({ governingAbbreviation, theme }) =>
      governingAbbreviation === GOVERNING_BODIES.FINA
        ? `
        filter: brightness(0) invert(1) blur(13px);
        background-color: ${theme.basicBackGroundColor};`
        : "filter: brightness(0) invert(1) blur(3px);"}
  }
`;
const IconLink = styled.a`
  cursor: pointer;
  margin-left: 10px;
  color: ${({ theme }) => theme.basicTextColor};
`;
const CurrencyIcon = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 26px;
  min-width: 26px;
  padding: 0 6px;
  font-size: 18px;
  font-weight: 500;
  color: ${({ theme }) => theme.basicBackGroundColor};
  border: 2px solid ${({ theme }) => theme.basicBackGroundColor};
  border-radius: 13px;
  box-sizing: border-box;
`;
const TicketContent = styled.div`
  display: flex;
  gap: 5px;
`;
const TicketContentMain = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: end;
`;
const TicketContentSidebar = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: end;
  gap: 5px;
`;

const Ticket = ({
  id,
  type,
  name,
  date,
  location,
  numberOfDivers,
  isAdmin,
  isOwner,
  isEnded,
  scoreMap,
  withMargin = true,
  currency,
  isOpen,
  governingAbbreviation,
  hiddenCard,
  renderTicketFooter = () => {},
  style,
  isCoachInMeet,
  status,
  registered,
  paid,
  isOncePaid,
  isSheetPaid,
  isSheetExists,
  isSpectatorExists,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const currencySymbol = useSelector(currencySymbolSelector(currency));
  const shareModalId = useSelector(shareModalIdSelector);

  const [modalComponent, setModalComponent] = useState(null);

  const defaultEditMeetStep = isOpen
    ? MEET_STEPS.MEETING_FORM_EDIT
    : MEET_STEPS.INVITE_TEAMS;

  const dropdownItems = [
    {
      id: 1,
      route: ROUTE.EDIT_MEET(id, defaultEditMeetStep),
      text: t("Edit meet"),
      hidden: !isAdmin,
    },
    {
      id: 2,
      route: "#",
      text: t("Meet administration"),
      callback: () => {
        if (isMobile) {
          setModalComponent(
            <ConfirmationModal
              {...{
                ...modalStateForAdministrationClick(id, t, history),
                onBackdropClick: () => setModalComponent(null),
              }}
            />,
          );
        } else {
          history.push(ROUTE.MEETS_EVENTS(id));
        }
      },
      hidden: !isAdmin,
    },
    {
      id: 3,
      route: "#",
      text: t("Delete meet"),
      callback: () =>
        setModalComponent(
          <ConfirmationModal
            {...{
              ...getYesNoModalDefaultState(t),
              message: t("Are you sure you want to delete this meet"),
              onLeftBtnClick: (e) => {
                e.stopPropagation();
                postDeleteMeetRequest(id).then(() => {
                  toast.success(t("Successfully deleted"));
                  dispatch(deleteMeetAction(id));
                });
              },
              onBackdropClick: () => setModalComponent(null),
            }}
          />,
        ),
      hidden: !isOwner,
    },
  ];

  const isItems = dropdownItems.some(({ hidden }) => !hidden);

  const onTicketClick = () => {
    history.push(ROUTE.MEET_SHORT(id));
  };

  const onClickShareLink = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { onShare } = await import("helpers/video");

    onShare({
      routeUrl: ROUTE.MEET_SHORT(id),
      id,
      text: name,
    });
  };

  const gbLogoSrc = `${getGoverningBodyLogoBase64(governingAbbreviation)}`;

  useEffect(() => {
    const isOpen = id ? shareModalId === id : false;

    if (!isOpen) {
      setModalComponent(null);

      return;
    }

    setModalComponent(<ShareModal />);
  }, [id, shareModalId]);

  return (
    <>
      {modalComponent}
      <StyledContainer
        id={id}
        data-test={isEnded ? "blackCard" : "blueCard"}
        withMargin={withMargin}
        style={style}
      >
        <TicketCanvas
          type={
            isEnded ? TICKET_CANVAS_TYPES.ENDED_MEET : TICKET_CANVAS_TYPES.MEET
          }
          noGradient
          onClick={onTicketClick}
        >
          <StyledPlaceholder hiddenCard={hiddenCard}>
            <StyledTitleGroup>
              <StyledMeetType>{`${type} ${t("meet")}`}</StyledMeetType>
              <StyledButtonPlaceholder>
                {!!currencySymbol && (
                  <CurrencyIcon>{currencySymbol}</CurrencyIcon>
                )}
                {isEnded && (
                  <StyledMeetType className="ml-10">
                    {t("COMPLETED")}
                  </StyledMeetType>
                )}
                {!hiddenCard && (
                  <>
                    <IconLink onClick={onClickShareLink}>
                      <BsShare fontSize={20} />
                    </IconLink>
                    {isItems && (
                      <ButtonDropdown
                        items={dropdownItems}
                        buttonIcon={
                          <>
                            <BsDot />
                            <BsDot />
                          </>
                        }
                        buttonStyleType="ticketDots"
                        dataTest="ticketButton"
                        className="ml-10"
                      />
                    )}
                  </>
                )}
              </StyledButtonPlaceholder>
            </StyledTitleGroup>
            <TicketContent>
              <TicketContentMain>
                <StyledTitle>{name?.substr(0, 60)}</StyledTitle>
                <StyledDate>{date}</StyledDate>
                {!!location?.aquaticCenter || !!location?.poolName ? (
                  <StyledLocation>
                    {location?.aquaticCenter || ""}
                    {!!location?.aquaticCenter && !!location?.poolName && (
                      <br />
                    )}
                    {location?.poolName || ""}
                  </StyledLocation>
                ) : null}
              </TicketContentMain>
              <TicketContentSidebar>
                {isOpen && <StyledNameStatus>{t("OPEN")}</StyledNameStatus>}
                <GoverningIconWrapper
                  governingAbbreviation={governingAbbreviation}
                >
                  <img src={gbLogoSrc} alt={governingAbbreviation} />
                  <img src={gbLogoSrc} alt="" />
                </GoverningIconWrapper>
                {!hiddenCard && (
                  <>
                    {!isEnded && (
                      <StyledDivers>
                        (<StyledNumber>{numberOfDivers}</StyledNumber>)
                        {t(" Divers")}
                      </StyledDivers>
                    )}
                    {isEnded && !!scoreMap && (
                      <BestScores scoreMap={scoreMap} />
                    )}
                  </>
                )}
              </TicketContentSidebar>
            </TicketContent>
          </StyledPlaceholder>
        </TicketCanvas>
        {renderTicketFooter({
          id,
          isCoachInMeet,
          status,
          registered,
          paid,
          isOncePaid,
          isSheetPaid,
          isSheetExists,
          isSpectatorExists,
        })}
      </StyledContainer>
    </>
  );
};

export default Ticket;
