export const ENVS = {
  LOCAL: {
    DOMAIN: "diving-dev.scoresandmore.live",
    GCP_BUCKET: "diving-dev-scoresandmore-live",
    ATTENDIZE_URL: "diving-dev-tickets.scoresandmore.live",
    SENTRY_DSN_LINK:
      "https://87576ee6dfda4161a6e7d1b8783ec12a@o1095599.ingest.sentry.io/6475149",
    SHOW_SENTRY: false,
    IS_LOGGER: false,
  },
  DEV: {
    DOMAIN: "diving-dev.scoresandmore.live",
    GCP_BUCKET: "diving-dev-scoresandmore-live",
    ATTENDIZE_URL: "diving-dev-tickets.scoresandmore.live",
    SENTRY_DSN_LINK:
      "https://87576ee6dfda4161a6e7d1b8783ec12a@o1095599.ingest.sentry.io/6475149",
    SHOW_SENTRY: true,
    IS_LOGGER: true,
  },
  QA: {
    DOMAIN: "diving-qa.scoresandmore.live",
    GCP_BUCKET: "diving-qa-scoresandmore-live",
    ATTENDIZE_URL: "diving-qa-tickets.scoresandmore.live",
    SENTRY_DSN_LINK:
      "https://8f2cf3a983d44dee9da194f665c91af0@o1095599.ingest.sentry.io/6475162",
    SHOW_SENTRY: true,
    IS_LOGGER: true,
  },
  PROD: {
    DOMAIN: "diving.scoresandmore.live",
    GCP_BUCKET: "diving-scoresandmore-live",
    ATTENDIZE_URL: "diving-tickets.scoresandmore.live",
    SENTRY_DSN_LINK:
      "https://79befee0572e425ca5d928c9156e9a39@o1095599.ingest.sentry.io/6475163",
    SHOW_SENTRY: true,
    IS_LOGGER: false,
  },
};

export const GCP_URL = "https://storage.googleapis.com";
export const IS_ENV_DEVELOPMENT = process.env.NODE_ENV === "development";
const currentGcpBucket = ENVS[process.env.REACT_APP_ENV].GCP_BUCKET;
export const GCP_BUCKET_URL = `${GCP_URL}/storage/v1/b/${currentGcpBucket}/o`;
export const GCP_BUCKET_URL_MEETS = `${GCP_URL}/${currentGcpBucket}/meetsPreview/`;
export const GCP_BUCKET_URL_AVATARS = `${GCP_URL}/${currentGcpBucket}/logo_images/`;
export const GCP_BUCKET_URL_FLAGS = `${GCP_URL}/${ENVS.PROD.GCP_BUCKET}/countryFlags/`;
export const GCP_BUCKET_URL_GB = `${GCP_URL}/${ENVS.PROD.GCP_BUCKET}/governingBodies/`;
export const GCP_BUCKET_URL_IMAGES = `${GCP_URL}/${ENVS.PROD.GCP_BUCKET}/ui_images/`;
export const GCP_BUCKET_UPLOAD_URL = (folder = "docs") =>
  `${GCP_URL}/upload/storage/v1/b/${currentGcpBucket}/o?uploadType=media&name=${folder}/`;

export const SITE_ENVS = {
  PROD: "http://diveliveapp.com",
  DEV: "https://diving-dev.scoresandmore.live",
};

export const baseURL = `${window.location.origin}/restapi/`;
export const SOCKET_URL = `wss://${
  IS_ENV_DEVELOPMENT
    ? ENVS[process.env.REACT_APP_ENV].DOMAIN
    : window.location.host
}/restapi/socket/`;

export const ATT_TOKEN = "qwer1234";
