import { JM_AUTH_KEY } from "constants/storageKeys";

import axiosInstance from "./index";

export const getJudgesByMeet = (meetId) =>
  axiosInstance
    .get(`/meet/${meetId}/judges`)
    .then((response) => response?.data);

export const getJudgesByPageRequest = (meetId, page = 0, size = 10) =>
  axiosInstance
    .get(`user/coaches/all/${meetId}/${page}/${size}`)
    .then((response) => response?.data);

export const getCreatedJudgesRequest = (meetId) =>
  axiosInstance
    .get(`meet/${meetId}/judges/created`)
    .then((response) => response?.data);

export const addJudgesInMeet = (meetId, judgeData) =>
  axiosInstance
    .post(`/meet/${meetId}/judges/add`, judgeData)
    .then((response) => response?.data);

export const assignJudgePanelEventRequest = ({ eventId, data }) =>
  axiosInstance
    .post(`/event/${eventId}/judgepanel`, data)
    .then((response) => response?.data);

export const getRefreshToken = (token) =>
  axiosInstance
    .get(`/judgemode/token/${token}/refresh`)
    .then((response) => response?.data);

export const assignEventRequest = ({ eventId, data }) =>
  axiosInstance
    .post(`/event/${eventId}/assign`, data)
    .then((response) => response?.data);

export const getAllJudgesRequest = () =>
  axiosInstance.get(`user/judges/all`).then((response) => response?.data);

export const switchJudgePositionRequest = ({ id, token, data }) =>
  axiosInstance
    .post(`event/${id}/assign/token/${token}`, data)
    .then((response) => response?.data);

export const switchDiverPositionRequest = ({ id, token, data }) =>
  axiosInstance
    .post(`event/${id}/rounds/many/reorder/token/${token}`, data)

    .then((response) => response?.data);

export const assignEventRefereeRequest = ({ eventId, ...data }) =>
  axiosInstance
    .post(`event/${eventId}/referee/assign`, data)

    .then((response) => response?.data);

export const getEventJudgesTokensRequest = ({ meetId, data, config = {} }) =>
  axiosInstance
    .post(`meet/${meetId}/event/judges/tokens`, data, config)
    .then((response) => response?.data);

export const postJudgemodeAward = (data) =>
  axiosInstance
    .post("judgemode/award", data, { authKey: JM_AUTH_KEY })
    .then((response) => response?.data);

export const postJudgemodeFocus = (data) =>
  axiosInstance
    .post("judgemode/focus", data, { authKey: JM_AUTH_KEY })
    .then((response) => response?.data);

export const getJudgemodeEventInfoRequest = (eventId, config = {}) =>
  axiosInstance
    .get(`judgemode/${eventId}/info`, { ...config, authKey: JM_AUTH_KEY })
    .then((response) => response?.data);

export const postJudgemodeClear = (data) =>
  axiosInstance
    .post("judgemode/clear", data, { authKey: JM_AUTH_KEY })
    .then((response) => response?.data);

export const getJudgemodeDiveUpdatesResponse = ({ diveId, onError }) =>
  axiosInstance
    .get(`judgemode/${diveId}/updates`, {
      withSpinner: false,
      onError,
      authKey: JM_AUTH_KEY,
    })
    .then((response) => response?.data);
