import React, { Component } from "react";

import NavigationLayout from "Components/NavigationLayout";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  render() {
    if (this.state.hasError) {
      return (
        <NavigationLayout withHardRefresh>
          {this.props.fallback}
        </NavigationLayout>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
