import React from "react";
import styled, { keyframes } from "styled-components";

const moveToLeft = keyframes`
  0% {
    stroke-dashoffset: 0;
    transform: translate3d(0, 0, 0);
  }
  100% {
    stroke-dashoffset: -133;
    transform: translate3d(-90px, 0, 0);
  }
`;

const moveToRight = keyframes`
  0% {
    stroke-dashoffset: -133;
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    stroke-dashoffset: 0;
    transform: translate3d(0, 0, 0);
  }
`;

const StyledSpinner = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 6000;
  width: 100vw;
  height: 100vh;
  background-color: #e1e1eba1;
  display: flex;
  align-items: center;
  justify-content: center;

  .parallax > use {
    stroke-dasharray: 0 16 101 16;
    stroke: ${({ theme }) => theme.basicBlue};
  }
  .parallax > use:nth-child(1) {
    animation: ${moveToLeft} 2400ms linear infinite;
  }
  .parallax > use:nth-child(2) {
    animation: ${moveToRight} 2400ms linear infinite;
  }
  .parallax > use:nth-child(3) {
    animation: ${moveToLeft} 2400ms linear infinite;
  }
`;

const Spinner = () => (
  <StyledSpinner id="spinner">
    <svg
      className="waves"
      width="80px"
      height="70px"
      version="1.1"
      viewBox="5 0 80 70"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path
          id="wave"
          fill="none"
          strokeWidth="4"
          strokeLinecap="round"
          d="M 0 17.5 c 7.684299348848887 0 7.172012725592294 -15 15 -15 s 7.172012725592294 15 15 15 s 7.172012725592294 -15 15 -15 s 7.172012725592294 15 15 15 s 7.172012725592294 -15 15 -15 s 7.172012725592294 15 15 15 s 7.172012725592294 -15 15 -15 s 7.172012725592294 15 15 15 s 7.172012725592294 -15 15 -15 s 7.172012725592294 15 15 15 s 7.172012725592294 -15 15 -15 s 7.172012725592294 15 15 15 s 7.172012725592294 -15 15 -15 s 7.172012725592294 15 15 15 s 7.172012725592294 -15 15 -15"
        ></path>
      </defs>
      <g className="parallax">
        <use xlinkHref="#wave" x="0" y="0" />
        <use xlinkHref="#wave" x="0" y="25" />
        <use xlinkHref="#wave" x="0" y="50" />
      </g>
    </svg>
  </StyledSpinner>
);

export default Spinner;
