import {
  actionFulfilled,
  GET_NEXT_FEED_PAGE,
  REMOVE_VIDEO_FROM_USER,
  REPORT_VIDEO,
} from "store/actionTypes";

const initState = {
  currentPage: 1,
  hasMore: true,
};

const userReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case actionFulfilled(GET_NEXT_FEED_PAGE):
      const { userVideos = [], feed = [], pageNumber } = payload || {};

      return {
        ...state,
        currentPage: pageNumber,
        userVideos:
          pageNumber === initState.currentPage
            ? userVideos
            : [...state.userVideos, ...userVideos],
        feed:
          pageNumber === initState.currentPage
            ? feed
            : [...state.feed, ...feed],
        hasMore: !!feed.length,
      };
    case actionFulfilled(REPORT_VIDEO):
    case actionFulfilled(REMOVE_VIDEO_FROM_USER): {
      const { userVideos = [], feed = [] } = state;

      return {
        ...state,
        userVideos: userVideos.filter(({ id }) => id !== payload),
        feed: feed.filter(({ teamVideos }) => teamVideos?.id !== payload),
      };
    }

    default:
      return state;
  }
};

export default userReducer;
