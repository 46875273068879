import axios from "axios";

import { ATT_TOKEN, baseURL, ENVS } from "constants/env";
import {
  CLIENT_APP_VERSION,
  APP_NEW_VERSION,
  AUTH_KEY,
} from "constants/storageKeys";

import {
  errorCallback,
  getLocalStorage,
  setLocalStorage,
  showSpinner,
  hideSpinner,
} from "helpers/general";

const checkAppVersion = (response) => {
  const appVersionFormResponse = response.headers["x-version"];

  if (!appVersionFormResponse) return;

  const currentAppVersion = getLocalStorage(CLIENT_APP_VERSION);

  if (currentAppVersion === null) {
    setLocalStorage({ key: CLIENT_APP_VERSION, value: appVersionFormResponse });
  } else if (
    currentAppVersion &&
    currentAppVersion !== appVersionFormResponse
  ) {
    setLocalStorage({ key: CLIENT_APP_VERSION, value: appVersionFormResponse });

    const isAppNewVersion = getLocalStorage(APP_NEW_VERSION);
    !isAppNewVersion &&
      setLocalStorage({ key: APP_NEW_VERSION, value: appVersionFormResponse });
  }
};

const axiosInstance = axios.create({
  baseURL,
  headers: {
    Accept: "application/json;pagingspec=1.0, application/xml;pagingspec=1.0",
    Authorization: localStorage.getItem(AUTH_KEY)
      ? `Bearer ${localStorage.getItem(AUTH_KEY)}`
      : "",
  },
});

const onFulfilled = (response) => {
  const { url, noAppVerification = false } = response.config;

  hideSpinner({ path: url });
  !noAppVerification && checkAppVersion(response);

  return response;
};

const onRejected = (error) => {
  const { url, onError } = error.config;

  hideSpinner({ path: url });
  onError ? onError(error) : errorCallback(error);

  return Promise.reject(error);
};

axiosInstance.interceptors.request.use((config) => {
  const { withSpinner = true, url } = config;

  if (withSpinner) showSpinner({ path: url });

  return config;
});

axiosInstance.interceptors.response.use(onFulfilled, onRejected);

export const HTTP_ATT = axios.create({
  baseURL: `https://${ENVS[process.env.REACT_APP_ENV].ATTENDIZE_URL}`,
});

HTTP_ATT.interceptors.request.use((config) => {
  const { withSpinner = true, url } = config;

  if (withSpinner) showSpinner({ path: url });

  return {
    ...config,
    headers: {
      ...config.headers,
      "x-dive": ATT_TOKEN,
    },
  };
});

HTTP_ATT.interceptors.response.use(onFulfilled, onRejected);

export default axiosInstance;
