import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import breakpoint from "styled-components-breakpoint";
import { useHistory } from "react-router";

import { ROUTE } from "constants/routing";

import { getFormattedDate, getDateRange } from "helpers/time";

import AvatarWithDefaultImage from "Components/AvatarWithDefaultImage";
import Ticket from "Components/Ticket";

import { TYPES } from "../constants";
import Divider from "./Divider";
import { ROLES } from "constants/types";

const StyledMeetItemHolder = styled.div`
  margin: 5px 20px;
`;
const StyledAuthorHolder = styled.div`
  display: flex;
  margin-bottom: 20px;
`;
const StyledFullname = styled.div`
  margin-bottom: 3px;
  font-size: 16px;
  color: #222222;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 1;
  cursor: ${({ onClick }) => (onClick ? "pointer" : "default")};
`;
const StyledUserDataHolder = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: -moz-available;
  width: -webkit-fill-available;
  width: fill-available;
  font-size: 14px;
  ${breakpoint("sm")`width: 290px`}

  & .name {
    color: #222222;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 15px;
  }

  & .additional {
    color: #9b9b9b;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 1.2;

    &.teamName {
      color: #0597f1;
    }

    &.notFirst {
      margin-top: 10px;
    }
  }
`;
const StyledNoteHolder = styled.span`
  display: block;
  margin-bottom: 3px;
  font-weight: 600;
  font-size: 16px;
`;

const MeetFeedItem = ({ meetData }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const { name: teamName } = meetData?.teamMeet?.team || {};
  const {
    type,
    name,
    startDate,
    endDate,
    location,
    meetDiversCount,
    id,
    isAdmin,
    isOwner,
    scoreMap,
    owner,
    finishedAt,
    governingBody,
    open,
    currency,
  } = meetData?.teamMeet?.meet || {};
  const { roles } = meetData?.teamMeet;
  const { firstName, lastName, cloudAlias, id: userId } = owner || {};

  const postDateInfo = `${t("Created a new meet at")} ${getFormattedDate({
    date: meetData.date,
    dateFormat: "hh:mm aa",
    useOffset: true,
  })} ${t("on")} ${getFormattedDate({
    date: meetData.date,
    dateFormat: "MMM. d, y",
    useOffset: true,
  })}`;
  const meetDates =
    startDate && endDate ? getDateRange(startDate, endDate) : "";

  const isEnded = meetData.type === TYPES.MEET_ENDED;
  const isDiver = roles.some((role) => ROLES.DIVER.includes(role));
  const isCoach = roles.some((role) =>
    [ROLES.HEAD_COACH, ROLES.COACH, ROLES.SWIM_COACH].includes(role),
  );
  const isTeamAdmin = roles.includes(ROLES.TEAM_ADMIN);

  let noteText = "";
  if (isEnded) {
    noteText = `${t("Meet was finished at")} ${getFormattedDate({
      date: finishedAt,
      dateFormat: "hh:mm aa",
      useOffset: true,
    })} ${t("on")} ${getFormattedDate({
      date: finishedAt,
      dateFormat: "MMM. d, yyyy",
      useOffset: true,
    })}`;
  } else {
    noteText = isDiver
      ? t("You’ve been invited to participate!")
      : isCoach || isTeamAdmin
      ? t("You've been invited to the meet")
      : t("You can follow the meet");
  }

  const onUserClick = (userId) => {
    return userId ? () => history.push(ROUTE.PROFILE(userId)) : "";
  };

  return (
    <>
      <StyledMeetItemHolder>
        {!isEnded && (
          <StyledAuthorHolder>
            <AvatarWithDefaultImage
              src={cloudAlias}
              isSmall={false}
              onAvatarClick={onUserClick(userId)}
            />
            <StyledUserDataHolder>
              <StyledFullname
                onClick={onUserClick(userId)}
              >{`${firstName} ${lastName}`}</StyledFullname>
              {teamName && (
                <span className="additional teamName">{teamName}</span>
              )}
              <span className="additional notFirst">{postDateInfo}</span>
            </StyledUserDataHolder>
          </StyledAuthorHolder>
        )}
        <StyledNoteHolder>{noteText}</StyledNoteHolder>

        <Ticket
          id={id}
          type={type}
          name={name || ""}
          date={meetDates}
          location={location || ""}
          numberOfDivers={meetDiversCount}
          isAdmin={isAdmin}
          isOwner={isOwner}
          scoreMap={scoreMap}
          withMargin={false}
          isEnded={isEnded}
          isOpen={open}
          currency={currency}
          governingAbbreviation={governingBody}
        />
      </StyledMeetItemHolder>
      <Divider />
    </>
  );
};

export default MeetFeedItem;
