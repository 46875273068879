import React, { useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";

import { SEARCH_LENGTH_MIN, IS_IOS } from "constants/settings";

import { selectedTeamSelector, userTeamsSelector } from "store/selectors/user";
import {
  setUserTeamId,
  getUserTeams,
  searchUserTeams,
} from "store/actions/teams";

import InfiniteScrollContainer from "Components/InfiniteScrollContainer";
import Loader from "Components/Loader";
import FormikInput from "Components/FormikInputs/FormikInput";

import TeamAvatar from "./TeamAvatar";

const TeamWrap = styled.div`
  display: inline-flex;
  font-size: 16px;
  ${({ isClickable }) => isClickable && "cursor: pointer;"}

  .name {
    margin-top: 3px;
  }

  svg {
    flex-shrink: 0;
    margin-top: 4px;
    margin-left: 5px;
  }
`;

const TeamsPopupWrap = styled.div`
  position: absolute;
  top: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: ${({ dataLength }) => dataLength * 35}px;
  max-height: ${IS_IOS ? 150 : 180}px;
  background-color: ${({ theme }) => theme.basicBackGroundColor};
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0px 2px 4px 0px rgb(0 0 0 / 33%);

  ${TeamWrap} {
    padding: 5px 10px;
    width: 100%;
    box-sizing: border-box;

    &:hover,
    &.selected {
      background-color: ${({ theme }) => theme.basicBgOnHover};
    }
  }
`;

const StyledNoResults = styled.div`
  text-align: center;
  padding-top: 30px;
  font-size: 24px;
  opacity: 0.5;
`;

const SearchTeam = ({ setIsOpen, value, setValue }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const scrollContainerRef = useRef();

  const {
    list = [],
    currentPage = 0,
    hasMore,
  } = useSelector(userTeamsSelector);
  const selectedTeam = useSelector(selectedTeamSelector);

  const { id: selectedTeamId } = selectedTeam;

  const teamOptions = list.map(({ id, cloudAlias, name }) => ({
    id,
    iconBefore: <TeamAvatar avatar={cloudAlias} />,
    label: name,
  }));

  const onTeamChange = (id) => () => {
    !!id && dispatch(setUserTeamId(id));
    setIsOpen(false);
    setValue("");
  };

  const onChangeSearch = (e) => setValue(e.target.value);

  const getNextPage = () => {
    value?.length >= SEARCH_LENGTH_MIN
      ? dispatch(
          searchUserTeams({
            key: value,
            page: currentPage + 1,
          }),
        )
      : dispatch(getUserTeams({ page: currentPage + 1 }));
  };

  return (
    <Formik initialValues={{ searchTeam: value }} onSubmit={() => {}}>
      {() => (
        <Form>
          <FormikInput
            name="searchTeam"
            style={{ marginBottom: 0 }}
            onChange={onChangeSearch}
          />
          <TeamsPopupWrap dataLength={teamOptions.length}>
            <InfiniteScrollContainer
              wrapperRef={scrollContainerRef}
              dataLength={teamOptions.length}
              next={getNextPage}
              hasMore={hasMore}
              loader={<Loader />}
              scrollableTarget="scrollableTeamsWrap"
              noResults={<StyledNoResults>{t("No results")}</StyledNoResults>}
            >
              <div>
                {teamOptions.map(({ id, label, iconBefore }) => (
                  <TeamWrap
                    key={id}
                    className={`${id === selectedTeamId ? " selected" : ""}`}
                    isClickable
                    onClick={onTeamChange(id)}
                  >
                    {iconBefore}
                    <span className="name">{label}</span>
                  </TeamWrap>
                ))}
              </div>
            </InfiniteScrollContainer>
          </TeamsPopupWrap>
        </Form>
      )}
    </Formik>
  );
};

export default SearchTeam;
