import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import { ROUTE } from "constants/routing";

import AvatarWithDefaultImage from "Components/AvatarWithDefaultImage";
import ButtonDropdown from "Components/ButtonDropdown";

const DiversTopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledSynchroWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 5px;
  box-sizing: border-box;

  ${({ isSynchro }) =>
    isSynchro
      ? `
    padding: 8px;
    border: 2px solid #dde8fb;
    border-radius: 24px;
  `
      : ""}
`;

const DiversList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const StyledTeamName = styled.p`
  position: absolute;
  right: 32px;
  top: -9px;
  background: #fff;
  padding: 0 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
`;

const StyledUserWrapper = styled.div`
  display: flex;
  align-items: center;

  &,
  & > div,
  & > p {
    cursor: pointer;
  }
`;

const UserDescription = styled.p`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 1.2;
  color: #aab4c5;

  .name {
    margin-bottom: 3px;
    color: ${({ theme }) => theme.accentTextColor};
    font-weight: 600;
    font-size: 16px;
  }

  .userDescription {
    margin-top: 10px;
  }
`;

const DiversTopAvatars = ({
  users = [],
  isSmall = false,
  teamName,
  description,
  dropdownItems,
}) => {
  const history = useHistory();

  const isSynchro = users.length > 1;

  const onItemClick = (id) => () => {
    if (!id) return;

    history.push(ROUTE.PROFILE(id));
  };

  return (
    <DiversTopWrapper>
      <StyledSynchroWrapper isSynchro={isSynchro}>
        <StyledTeamName>{teamName}</StyledTeamName>
        <DiversList>
          {users?.map(
            ({
              cloudAlias,
              firstName,
              lastName,
              team,
              teamName: userTeamName,
              id,
              description,
            }) => (
              <StyledUserWrapper key={id} onClick={onItemClick(id)}>
                <AvatarWithDefaultImage
                  src={cloudAlias}
                  isSmall={isSmall}
                  onAvatarClick={onItemClick(id)}
                />
                <UserDescription>
                  <span className="name">{`${firstName} ${lastName}`}</span>
                  <span className="userTeamName">
                    {team?.name || userTeamName}
                  </span>
                  {description && (
                    <span className="userDescription">{description}</span>
                  )}
                </UserDescription>
              </StyledUserWrapper>
            ),
          )}
        </DiversList>

        {dropdownItems && <ButtonDropdown items={dropdownItems} />}
      </StyledSynchroWrapper>
      {description && (
        <UserDescription className="description" style={{ marginTop: 10 }}>
          {description}
        </UserDescription>
      )}
    </DiversTopWrapper>
  );
};

export default DiversTopAvatars;
