import React from "react";
import styled from "styled-components";

const DiverLabelWrap = styled.span`
  display: inline-block;
  padding: 2px 5px 1px;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  color: ${({ theme }) => theme.basicTextColor};
  background-color: ${({ theme }) => theme.errorColor};
  border-radius: 10px;
`;

const DiverLabel = ({ style = {}, className = "", children }) => (
  <DiverLabelWrap className={className} style={style}>
    {children}
  </DiverLabelWrap>
);

export default DiverLabel;
