import { useState } from "react";

import { ACTIVE_LOADINGS } from "constants/storageKeys";

import useLocalStorage from "hooks/useLocalStorage";
import { getSessionStorage } from "helpers/general";

const useSpinner = (initialValue = false) => {
  const [isSpinner, setIsSpinner] = useState(initialValue);

  useLocalStorage(() => {
    const activeLoadings = getSessionStorage(ACTIVE_LOADINGS) || {};

    setIsSpinner(Object.values(activeLoadings).some((action) => !!action));
  });

  return [isSpinner, setIsSpinner];
};

export default useSpinner;
