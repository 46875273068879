export const currenciesSelector = (state) => state.general.currencies;

export const currencyByCodeSelector = (selectedCode) => (state) =>
  state.general.currencies.find(({ code }) => code === selectedCode) || {};

export const currencySymbolSelector = (selectedCode) => (state) => {
  const { symbol_left, symbol_right } =
    currencyByCodeSelector(selectedCode)(state);

  return symbol_left || symbol_right;
};
