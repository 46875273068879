import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { getFormattedDate } from "helpers/time";

import DiversTopAvatars from "Components/DiversTopAvatars";

const StyledHeaderHolder = styled.div`
  margin-bottom: ${({ isSynchro }) => (isSynchro ? 12 : 5)}px;
`;

const HeaderForItem = ({ uploadedAt, combinedUserInfo, user }) => {
  const { t } = useTranslation();

  const isSynchro = !!combinedUserInfo;
  const users = combinedUserInfo
    ? combinedUserInfo.users
    : [
        {
          ...user,
          description: `${t("Posted at")} ${getFormattedDate({
            date: uploadedAt,
            dateFormat: "hh:mm aa",
            useOffset: true,
          })} ${t("on")} ${getFormattedDate({
            date: uploadedAt,
            dateFormat: "MMM. d, y",
            useOffset: true,
          })}`,
        },
      ];

  return (
    <>
      <StyledHeaderHolder isSynchro={isSynchro}>
        <DiversTopAvatars
          users={users}
          isSmall={isSynchro}
          teamName={combinedUserInfo?.teamName}
        />
      </StyledHeaderHolder>
    </>
  );
};

export default HeaderForItem;
