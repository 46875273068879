import React from "react";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import engTranslation from "assets/lang/en-translation.json";
import rusTranslation from "assets/lang/ru-translation.json";
import arbTranslation from "assets/lang/ru-translation.json";

const resources = {
  en: {
    translation: engTranslation,
  },
  ru: {
    translation: rusTranslation,
  },
  ar: {
    translation: arbTranslation,
  },
};

export default function GlobalLocalisationProvider(props) {
  i18n.use(initReactI18next).init({
    resources,
    lng: "en",
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

  return <>{props.children}</>;
}
