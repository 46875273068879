import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Formik, Form } from "formik";
import { toast } from "react-toastify";
import * as yup from "yup";
import styled from "styled-components";

import { ERR_MSG } from "constants/errors";

import { helpRequest } from "API/user";
import { userDetailsSelector } from "store/selectors/user";
import { emailNormalizer } from "helpers/general";

import FormikInput from "Components/FormikInputs/FormikInput";

const FormDescription = styled.p`
  margin-bottom: 15px;
  color: ${({ theme }) => theme.unimportantText};
  line-height: 1.3;
  text-align: center;
`;

const HelpForm = ({ formRef, onBackCloseClick }) => {
  const { t } = useTranslation();

  const { email: currentUSerEmail } = useSelector(userDetailsSelector);

  const { TEXT_EXCEED_SIZE, MAX_HELP_BODY_LENGTH, MAX_HELP_SBJ_LENGTH } =
    ERR_MSG(t);

  const onFormSubmit = (values) =>
    helpRequest({
      ...values,
      userEmail: emailNormalizer(values.userEmail),
    }).then(() => {
      onBackCloseClick();
      toast.success(t("Help request has been sent successfully"));
    });

  return (
    <Formik
      innerRef={formRef}
      initialValues={{
        userEmail: currentUSerEmail,
        subject: "",
        body: "",
      }}
      validationSchema={() =>
        yup.lazy((values) =>
          yup.object().shape({
            subject: yup
              .string()
              .max(
                MAX_HELP_SBJ_LENGTH,
                TEXT_EXCEED_SIZE(MAX_HELP_SBJ_LENGTH, values?.subject?.length),
              ),
            body: yup
              .string()
              .max(
                MAX_HELP_BODY_LENGTH,
                TEXT_EXCEED_SIZE(MAX_HELP_BODY_LENGTH, values?.body?.length),
              ),
          }),
        )
      }
      onSubmit={onFormSubmit}
    >
      {({ values }) => (
        <Form>
          <FormikInput
            name="userEmail"
            type="email"
            label={t("From")}
            valueNormalizer={emailNormalizer}
            required
            disabled
          />
          <FormikInput
            name="subject"
            type="text"
            label={t("Subject")}
            rows={2}
            required
            multiline
          />
          <FormDescription>
            {t(
              "Please tell us which meet or team you need help with to better assist you",
            )}
          </FormDescription>
          <FormikInput
            name="body"
            label={t("Email body")}
            rows={12}
            required
            multiline
          />
        </Form>
      )}
    </Formik>
  );
};

export default HelpForm;
