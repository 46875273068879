import {
  GET_MEET_TEAMS,
  actionFulfilled,
  GET_SEARCH_AVAILABLE_TEAMS,
  GET_SEARCH_AVAILABLE_TEAMS_BY_PAGE,
  GET_FILTERED_TEAMS,
  GET_MY_TEAM_MEMBERS,
  GET_TEAM_DETAILS,
  GET_FOLLOWERS,
  SWITCH_TEAM_FOLLOWED,
  REMOVE_TEAM_MEMBER,
  GET_INVITATIONS_LIST,
  LEAVE_TEAM,
  ASK_TEAM_MEMBERSHIP,
  CLEAR_MY_TEAM_DATA,
  CLEAR_TEAMS,
  UPDATE_FOLLOWERS,
  CANCEL_JOIN,
} from "store/actionTypes";

const initState = {
  selectorValues: [],
  userTeamId: "",
  members: {},
  invitations: [],
  search: [],
  followers: [],
  isAdmin: false,
  teamName: "",
  currentPage: 1,
  filtered: [],
};

const teamsReducer = (state = initState, action) => {
  switch (action.type) {
    case actionFulfilled(GET_MEET_TEAMS):
      return { ...state, [action.payload.meetId]: action.payload };
    case CLEAR_MY_TEAM_DATA:
      return {
        ...state,
        invitations: initState.invitations,
        followers: initState.followers,
        members: initState.members,
      };
    case actionFulfilled(GET_INVITATIONS_LIST):
      return {
        ...state,
        invitations: action.payload,
      };
    case actionFulfilled(GET_SEARCH_AVAILABLE_TEAMS):
      return { ...state, search: action.payload };
    case actionFulfilled(GET_SEARCH_AVAILABLE_TEAMS_BY_PAGE):
      return {
        ...state,
        search: [
          ...(action.payload.currentPage ? state.search : []),
          ...action.payload.teamsDto,
        ],
        currentPage: action.payload.currentPage,
      };
    case actionFulfilled(GET_FILTERED_TEAMS):
      return { ...state, filtered: action.payload.teamsDto, search: [] };
    case SWITCH_TEAM_FOLLOWED:
      return {
        ...state,
        search: state.search.map((team) =>
          team.id === action.payload
            ? { ...team, isFollowed: !team.isFollowed }
            : team,
        ),
      };
    case actionFulfilled(CANCEL_JOIN):
      return {
        ...state,
        pending: state.pending?.filter(({ key }) => key !== action.payload),
      };
    case actionFulfilled(ASK_TEAM_MEMBERSHIP):
      return {
        ...state,
        search: state.search.map((team) =>
          team.id === action.payload.teamId
            ? {
                ...team,
                pending: [action.payload],
              }
            : team,
        ),
      };
    case actionFulfilled(GET_MY_TEAM_MEMBERS):
      return { ...state, members: action.payload };
    case actionFulfilled(GET_TEAM_DETAILS):
      return { ...state, ...action.payload };
    case actionFulfilled(GET_FOLLOWERS):
      return { ...state, followers: action.payload };
    case actionFulfilled(UPDATE_FOLLOWERS): {
      const { user, followed } = action.payload;

      const followers = followed
        ? [...state.followers, user]
        : state.followers.filter(({ id }) => id !== user.id);

      return { ...state, followers };
    }
    case actionFulfilled(LEAVE_TEAM):
    case actionFulfilled(REMOVE_TEAM_MEMBER):
      return action.payload
        ? {
            ...state,
            members: {
              ...state.members,
              [action.payload.role]: state.members[action.payload.role].filter(
                ({ id }) => id !== action.payload.userId,
              ),
            },
          }
        : state;
    case CLEAR_TEAMS:
      return initState;
    default:
      return state;
  }
};

export default teamsReducer;
