import React, { useMemo } from "react";
import styled from "styled-components";

import { darkTheme } from "assets/styles/themes";

const DEFAULT_INPUT_WIDTH = 56;
const DEFAULT_INPUT_HEIGHT = 31;
const DEFULT_INPUT_PADDING = 2;
const THUMB_SIZE = 26;

const StyledSwitchHolder = styled.label`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
`;

const StyledInput = styled.input`
  display: none;

  &:checked + i {
    padding-right: ${({ thumbSize }) => thumbSize + DEFULT_INPUT_PADDING}px;
    padding-left: ${DEFULT_INPUT_PADDING}px;
    background-color: ${({
      theme,
      checkedBgColorKey,
      isSaveColorsOnColorModeChange,
    }) =>
      (isSaveColorsOnColorModeChange ? darkTheme : theme)[
        checkedBgColorKey || "activeBgColor"
      ]};
  }

  &:checked + i .icon,
  &:checked + i::after {
    transform: translateX(
      ${({ width, thumbSize }) =>
        width - thumbSize - DEFULT_INPUT_PADDING * 2}px
    );
  }

  &:checked + i .icon svg {
    fill: ${({ theme, checkedBgColorKey, isSaveColorsOnColorModeChange }) =>
      (isSaveColorsOnColorModeChange ? darkTheme : theme)[
        checkedBgColorKey || "activeBgColor"
      ]};
  }

  &:checked + i::before {
    content: "${({ checkedPlaceholder }) => checkedPlaceholder}";
    left: ${DEFULT_INPUT_PADDING}px;
  }
`;

const StyledThumb = styled.i`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  padding: ${DEFULT_INPUT_PADDING}px;
  padding-left: ${({ thumbSize }) => thumbSize + DEFULT_INPUT_PADDING}px;
  color: ${({ theme, isSaveColorsOnColorModeChange }) =>
    (isSaveColorsOnColorModeChange ? darkTheme : theme).basicTextColor};
  font-size: 12px;
  font-weight: 600;
  background-color: ${({ theme, bgColorKey, isSaveColorsOnColorModeChange }) =>
    (isSaveColorsOnColorModeChange ? darkTheme : theme)[
      bgColorKey || "lightGreyBorder"
    ]};
  border-radius: 23px;
  box-sizing: border-box;
  transition: all 0.3s linear;

  & .icon,
  &::before,
  &::after {
    position: absolute;
    height: ${({ thumbSize }) => thumbSize}px;
    transform: translateX(0);
    transition: all 0.2s ease-in-out;
  }

  &::before {
    content: "${({ placeholder }) => placeholder}";
    right: ${DEFULT_INPUT_PADDING}px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${({ width }) => width - DEFULT_INPUT_PADDING * 3 - THUMB_SIZE}px;
  }

  & .icon,
  &::after {
    content: "";
    left: ${DEFULT_INPUT_PADDING}px;
    width: ${({ thumbSize }) => thumbSize}px;
    border-radius: 50%;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);
  }

  & .icon {
    z-index: 15;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      fill: ${({ theme, bgColorKey, isSaveColorsOnColorModeChange }) =>
        (isSaveColorsOnColorModeChange ? darkTheme : theme)[
          bgColorKey || "lightGreyBorder"
        ]};
    }
  }

  &::after {
    z-index: 10;
    background-color: ${({ theme, isSaveColorsOnColorModeChange }) =>
      (isSaveColorsOnColorModeChange ? darkTheme : theme).basicBackGroundColor};
  }
`;

const SwitchIOS = ({
  name,
  onClick,
  label,
  field,
  checked,
  bgColorKey,
  placeholder = "",
  checkedBgColorKey,
  checkedPlaceholder = "",
  thumbIcon = null,
  width = DEFAULT_INPUT_WIDTH,
  height = DEFAULT_INPUT_HEIGHT,
  isSaveColorsOnColorModeChange = false,
  thumbSize = THUMB_SIZE,
}) => {
  const inputProps = useMemo(
    () =>
      field
        ? { ...field, onClick, checked: field.value }
        : { checked, onChange: onClick },
    [checked, field],
  );

  return (
    <StyledSwitchHolder className="form-switch">
      <StyledInput
        id={`input-field_${name}`}
        type="checkbox"
        checkedBgColorKey={checkedBgColorKey}
        width={width}
        checkedPlaceholder={checkedPlaceholder}
        isSaveColorsOnColorModeChange={isSaveColorsOnColorModeChange}
        thumbSize={thumbSize}
        {...inputProps}
      />
      {label}
      <StyledThumb
        bgColorKey={bgColorKey}
        width={width}
        height={height}
        placeholder={placeholder}
        isSaveColorsOnColorModeChange={isSaveColorsOnColorModeChange}
        thumbSize={thumbSize}
      >
        <span className="icon">{thumbIcon}</span>
      </StyledThumb>
    </StyledSwitchHolder>
  );
};

export default SwitchIOS;
