import { toast } from "react-toastify";

import { ROUTE } from "constants/routing";
import { INDOOR_THEME } from "assets/styles/themes";
import { CLEAR_JUDGE_DETAILS, CLEAR_LEADERSHIP } from "store/actionTypes";
import {
  COLOR_MODE_STATUS,
  AUTH_KEY,
  JM_AUTH_KEY,
} from "constants/storageKeys";

import { store } from "App";
import { setColorModeTheme } from "store/actions/settings";
import { logOutUser } from "helpers/user";
import { postJudgemodeFocus } from "API/judges";
import { removeLocalStorage } from "helpers/general";
import {
  logoutUnregisteredJudge,
  logoutRegisteredJudge,
} from "store/actions/user";

import { JM_EXIT_TYPE } from "./constants";

export const getNextDive = ({ allDives, currentDiveId, judgeLimit }) => {
  const currentDiveIndex = allDives.findIndex(
    ({ dive }) => dive.id === currentDiveId,
  );
  const futureDives = allDives.slice(currentDiveIndex + 1);
  const nextDive = futureDives.find(
    ({ isScratched, isSkipped, awards }) =>
      !isScratched && !isSkipped && awards.length !== judgeLimit,
  );

  return nextDive;
};

export const goToNextDive = ({
  allDives,
  currentDiveId,
  reScore = false,
  judgeLimit,
  eventId,
}) => {
  const nextDive = getNextDive({ allDives, currentDiveId, judgeLimit });

  if (currentDiveId)
    postJudgemodeFocus({
      nextDiveId: nextDive?.dive?.id,
      currentDiveId,
      reScore,
      eventId,
    });
};

export const logoutJudgeMode = ({ history, meetId }) => {
  toast.error("Access denied");
  store.dispatch(setColorModeTheme(INDOOR_THEME));
  store.dispatch({ type: CLEAR_JUDGE_DETAILS });

  const isJudgeUnregistered = !localStorage.getItem(AUTH_KEY);

  if (isJudgeUnregistered) {
    logOutUser(store.dispatch);
  } else {
    history.push({
      pathname: ROUTE.MEET_SHORT(meetId),
      search: "?tab=judges",
    });
  }
};

export const exitJudging = ({
  type,
  judgeModeWS,
  judgeModeMessagesWS,
  history,
  meetId,
  token,
  successDestination,
}) => {
  localStorage.removeItem(COLOR_MODE_STATUS);
  store.dispatch(setColorModeTheme(INDOOR_THEME));
  store.dispatch({ type: CLEAR_JUDGE_DETAILS });
  store.dispatch({ type: CLEAR_LEADERSHIP });

  judgeModeWS?.close(1000, `${type} Judge mode`);
  judgeModeMessagesWS?.close(1000, `${type} Judge mode messages`);

  const isJudgeUnregistered = !localStorage.getItem(AUTH_KEY);

  const logoutJudge = isJudgeUnregistered
    ? logoutUnregisteredJudge
    : logoutRegisteredJudge;

  const successCallback = () => {
    removeLocalStorage(JM_AUTH_KEY);

    if (successDestination) {
      successDestination();
    } else {
      history.replace({
        pathname: ROUTE.MEET_SHORT(meetId),
        search: "?tab=judges",
      });
    }
  };

  if (type === JM_EXIT_TYPE.EXIT) {
    store.dispatch(logoutJudge(token, successCallback));
  } else {
    successCallback();
  }
};
