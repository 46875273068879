import {
  GET_CURRENT_EVENT,
  GET_EVENT_SHORT,
  CLEAR_CURRENT_EVENT,
  SET_COMBINED_SYNCHRO,
  actionPending,
  actionFulfilled,
  actionRejected,
  SWITCH_JUDGE_MODE,
  CLOSE_EVENT,
  DIVE_DOWN_BAR_EVENT,
  DIVE_UP_BAR_EVENT,
  LOCK_BAR_EVENT,
  CANCEL_EVENT,
  REMOVE_DIVER_FROM_EVENT,
  START_EVENT,
  UNSTART_EVENT,
} from "store/actionTypes";
import { EVENT_STATUS } from "constants/status";

const initialState = {};

const eventReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionFulfilled(GET_CURRENT_EVENT):
    case actionFulfilled(GET_EVENT_SHORT):
      return {
        ...state,
        ...payload.event,
      };
    case CLEAR_CURRENT_EVENT: {
      return initialState;
    }
    case actionFulfilled(SET_COMBINED_SYNCHRO): {
      const newCombinedUsers = payload
        ? [{ ...payload, isNewAdded: true }]
        : [];

      return {
        ...state,
        combinedUsers: [
          ...newCombinedUsers,
          ...state.combinedUsers.map((user) => ({
            ...user,
            isNewAdded: false,
          })),
        ],
      };
    }
    case actionFulfilled(SWITCH_JUDGE_MODE): {
      return {
        ...state,
        judgeModeEnabled: !state.judgeModeEnabled,
      };
    }
    case actionFulfilled(START_EVENT):
    case actionFulfilled(CLOSE_EVENT): {
      const { event, time } = payload;

      return {
        ...state,
        status: event?.status,
        subEvents: state.subEvents?.map((subEvent) => ({
          ...subEvent,
          status: event?.status,
        })),
        timeLimit: {
          ...state.timeLimit,
          endTime: time,
        },
      };
    }
    case actionFulfilled(UNSTART_EVENT): {
      const { status } = payload;

      return {
        ...state,
        status,
        subEvents: state.subEvents?.map((subEvent) => ({
          ...subEvent,
          status,
        })),
        timeLimit: {
          startTime: null,
          endTime: null,
        },
      };
    }
    case actionPending(CANCEL_EVENT): {
      return {
        ...state,
        status: EVENT_STATUS.CANCELLED,
        oldStatus: state.status,
        splittedEvents: state.splittedEvents?.map((splittedEvent) => ({
          ...splittedEvent,
          status: EVENT_STATUS.CANCELLED,
          oldStatus: splittedEvent.status,
        })),
        subEvents: state.subEvents?.map((subEvent) => ({
          ...subEvent,
          status: EVENT_STATUS.CANCELLED,
          oldStatus: subEvent.status,
        })),
      };
    }
    case actionFulfilled(CANCEL_EVENT): {
      return {
        ...state,
        oldStatus: undefined,
        splittedEvents: state.splittedEvents?.map((splittedEvent) => ({
          ...splittedEvent,
          oldStatus: undefined,
        })),
        subEvents: state.subEvents?.map((subEvent) => ({
          ...subEvent,
          oldStatus: undefined,
        })),
      };
    }
    case actionRejected(CANCEL_EVENT): {
      return {
        ...state,
        status: state.oldStatus,
        oldStatus: undefined,
        splittedEvents: state.splittedEvents?.map((splittedEvent) => ({
          ...splittedEvent,
          status: splittedEvent.oldStatus,
          oldStatus: undefined,
        })),
        subEvents: state.subEvents?.map((subEvent) => ({
          ...subEvent,
          status: subEvent.oldStatus,
          oldStatus: undefined,
        })),
      };
    }
    case actionFulfilled(DIVE_UP_BAR_EVENT): {
      const { diveUpEnabled } = payload;
      return {
        ...state,
        diveUpEnabled,
      };
    }
    case actionFulfilled(DIVE_DOWN_BAR_EVENT): {
      const { diveDownEnabled } = payload;
      return {
        ...state,
        diveDownEnabled,
      };
    }
    case actionFulfilled(LOCK_BAR_EVENT): {
      const { locked } = payload;
      return {
        ...state,
        locked,
      };
    }
    case actionFulfilled(REMOVE_DIVER_FROM_EVENT): {
      const filterCallback = ({ userInfo }) => userInfo?.id !== +payload;

      return {
        ...state,
        diveSheets: state.diveSheets.filter(filterCallback),
        splittedEvents: state.splittedEvents.map((splittedEvent) => ({
          ...splittedEvent,
          diveSheets: splittedEvent.diveSheets.filter(filterCallback),
        })),
      };
    }
    default:
      return state;
  }
};

export default eventReducer;
