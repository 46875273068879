export const actionPending = (actionName) => `${actionName}_PENDING`;
export const actionFulfilled = (actionName) => `${actionName}_FULFILLED`;
export const actionRejected = (actionName) => `${actionName}_REJECTED`;

export const REMOVE_EVENT_ON_EDIT_MEET = "REMOVE_EVENT_ON_EDIT_MEET";
export const REMOVE_SESSION_AT_CREATE_MEET = "REMOVE_SESSION_AT_CREATE_MEET";
export const DELETE_SESSION_FROM_EDITED_MEET =
  "DELETE_SESSION_FROM_EDITED_MEET";
export const REORDER_COMBINED_EVENTS_ON_EDIT_MEET =
  "REORDER_COMBINED_EVENTS_ON_EDIT_MEET";
export const UNCOMBINE_EVENTS_FOR_EDIT_MEET = "UNCOMBINE_EVENTS_FOR_EDIT_MEET";
export const UPDATE_TEAMS_IN_NEW_MEET = "UPDATE_TEAMS_IN_NEW_MEET";
export const ADD_NEW_SESSION_TO_EDITED_MEET = "ADD_NEW_SESSION_TO_EDITED_MEET";
export const SET_JUDGES_SCHEMA_ID_ON_NEW_MEET =
  "SET_JUDGES_SCHEMA_ID_ON_NEW_MEET";
export const UPDATE_DIVE_SHEET_MODAL = "UPDATE_DIVE_SHEET_MODAL";
export const UPDATE_MODAL_DIVE_SHEET = "UPDATE_MODAL_DIVE_SHEET";
export const CLEAR_NEW_MEET_DATA = "CLEAR_NEW_MEET_DATA";
export const ADD_USERS_TO_MEET_USERS = "ADD_USERS_TO_MEET_USERS";
export const EDIT_MEET_DND_EVENTS = "EDIT_MEET_DND_EVENTS";
export const EDIT_MEET_COMBINE_EVENTS = "EDIT_MEET_COMBINE_EVENTS";
export const GET_USERS_BY_SEARCH_KEY = "GET_USERS_BY_SEARCH_KEY";
export const SEARCH_USERS = "SEARCH_USERS";
export const SEARCH_VENUES = "SEARCH_VENUES";
export const ADD_SEARCHED_VENUE_TO_LIST = "ADD_SEARCHED_VENUE_TO_LIST";
export const UPATE_MEET_SESSION_TIME = "UPATE_MEET_SESSION_TIME";
export const UPATE_GEN_WARM_TIME = "UPATE_GEN_WARM_TIME";
export const GET_CUSTOM_MEET_JUDGES = "GET_CUSTOM_MEET_JUDGES";
export const ADD_EVENT_TO_EDITED_MEET = "ADD_EVENT_TO_EDITED_MEET";
export const SET_UPDATED_EVENT_OR_SUBEVENT = "SET_UPDATED_EVENT_OR_SUBEVENT";
export const SET_EDITED_MEET_DAYS = "SET_EDITED_MEET_DAYS";
export const SET_EDIT_MEET_FORM = "SET_EDIT_MEET_FORM";
export const SET_EDITED_MEET_EVENT_IDS = "SET_EDITED_MEET_EVENT_IDS";
export const CLEAR_EDITED_MEET_EVENT_IDS = "CLEAR_EDITED_MEET_EVENT_IDS";
export const SET_EDITED_MEET_NAME = "SET_EDITED_MEET_NAME";
export const GET_MEET_FOR_EDITING = "GET_MEET_FOR_EDITING";
export const CLEAR_MEET_FOR_EDITING = "CLEAR_MEET_FOR_EDITING";
export const USER_LOG_IN = "USER_LOG_IN";
export const USER_LOG_OUT = "USER_LOG_OUT";
export const LOG_OUT_UNREGISTRED_JUDGE = "LOG_OUT_UNREGISTRED_JUDGE";
export const LOG_OUT_REGISTRED_JUDGE = "LOG_OUT_REGISTRED_JUDGE";
export const JUDGE_SIGN_IN_BY_CODE = "JUDGE_SIGN_IN_BY_CODE";
export const JUDGE_SIGN_IN_BY_QR_CODE = "JUDGE_SIGN_IN_BY_QR_CODE";
export const CLEAR_JUDGE_DETAILS = "CLEAR_JUDGE_DETAILS";
export const SESSION_EXPIRED = "SESSION_EXPIRED";
export const PERMISSION_DENIED = "PERMISSION_DENIED";
export const SET_PAGE_NOT_FOUND = "SET_PAGE_NOT_FOUND";
export const SET_SERVER_ERROR = "SET_SERVER_ERROR";
export const USER_REGISTRATION = "USER_REGISTRATION";
export const UPLOAD_USER_PROFILE = "UPLOAD_USER_PROFILE";
export const UPLOAD_USER_BG_PHOTO = "UPLOAD_USER_BG_PHOTO";
export const UPLOAD_VIDEO = "UPLOAD_VIDEO";
export const GET_CURRENT_USER_DETAILS = "GET_CURRENT_USER_DETAILS";
export const GET_AAU_CURRENT_USER_INFO = "GET_AAU_CURRENT_USER_INFO";
export const GET_CURRENT_JUDGE_DETAILS = "GET_CURRENT_JUDGE_DETAILS";
export const UPDATE_CURRENT_JUDGE_DETAILS = "UPDATE_CURRENT_JUDGE_DETAILS";
export const GET_CURRENT_EVENT_INFORMATION_FOR_JUDGE =
  "GET_CURRENT_EVENT_INFORMATION_FOR_JUDGE";
export const GET_CUSTOM_USER_DETAILS = "GET_CUSTOM_USER_DETAILS";

export const DELETE_PROFILE = "DELETE_PROFILE";
export const GET_MEET_PERMISSIONS_BY_TEAM = "GET_MEET_PERMISSIONS_BY_TEAM";
export const GET_MEET_PERMISSIONS = "GET_MEET_PERMISSIONS";

export const GET_REFRESH_TOKEN = "GET_REFRESH_TOKEN";
export const ADD_JUDGE = "ADD_JUDGE";
export const GET_ALL_JUDGES = "GET_ALL_JUDGES";
export const GET_JUDGES_BY_PAGE = "GET_JUDGES_BY_PAGE";
export const CLEAR_JUDGES_BY_PAGE = "CLEAR_JUDGES_BY_PAGE";
export const GET_MEET_TEAMS = "GET_MEET_TEAMS";
export const CREATE_DIVER = "CREATE_DIVER";
export const GET_AVAILABLE_ON_MEET_DIVERS = "GET_AVAILABLE_ON_MEET_DIVERS";
export const GET_ADDED_RECENTLY_DIVERS = "GET_ADDED_RECENTLY_DIVERS";
export const CLEAR_REGISTRATION_EMAIL_ERROR = "CLEAR_REGISTRATION_EMAIL_ERROR";

export const DND_ROUND_REPLACE = "DND_ROUND_REPLACE";

export const SET_NEW_MEET_START = "SET_NEW_MEET_START";
export const SET_NEW_MEET_END = "SET_NEW_MEET_END";
export const GET_DIVE_CODES_TABLE = "GET_DIVE_CODES_TABLE";
export const GET_DIVE_SHEET = "GET_DIVE_SHEET";
export const UPDATE_DIVE = "UPDATE_DIVE";
export const START_EVENT = "START_EVENT";
export const UNSTART_EVENT = "UNSTART_EVENT";
export const DND_DIVES_REPLACE = "DND_DIVES_REPLACE";
export const GET_ALL_EVENT_ROUNDS = "GET_ALL_EVENT_ROUNDS";
export const SET_USER_TEAM_ID = "SET_USER_TEAM_ID";
export const GET_AVAILABLE_GOVERNING_BODYES = "GET_AVAILABLE_GOVERNING_BODYES";
export const UPDATE_DIVE_IN_LIST = "UPDATE_DIVE_IN_LIST";
export const SET_DIVE_DIFFICULTY = "SET_DIVE_DIFFICULTY";
export const SUBMIT_DIVE_DIFFICULTY = "SUBMIT_DIVE_DIFFICULTY";
export const SET_EDIT_DIVE_TO_INITIAL = "SET_EDIT_DIVE_TO_INITIAL";
export const NEW_MEET_DND_EVENT_COMBINE = "NEW_MEET_DND_EVENT_COMBINE";
export const NEW_MEET_EVENT_UNCOMBINE = "NEW_MEET_EVENT_UNCOMBINE";
export const NEW_MEET_EVENT_REORDER = "NEW_MEET_EVENT_REORDER";
export const REORDER_COMBINED_EVENTS = "REORDER_COMBINED_EVENTS";
export const CLAER_ROUNDS_LIST = "CLAER_ROUNDS_LIST";
export const SHUFFLE_DIVERS = "SHUFFLE_DIVERS";
export const CANCEL_EVENT = "CANCEL_EVENT";
export const REMOVE_DIVER_FROM_EVENT = "REMOVE_DIVER_FROM_EVENT";
export const LEAVE_TEAM = "LEAVE_TEAM";
export const CANCEL_JOIN = "CANCEL_JOIN";

export const SET_MEET_DAYS = "SET_MEET_DAYS";
export const GET_SEARCH_AVAILABLE_TEAMS = "GET_SEARCH_AVAILABLE_TEAMS";
export const GET_SEARCH_AVAILABLE_TEAMS_BY_PAGE =
  "GET_SEARCH_AVAILABLE_TEAMS_BY_PAGE";
export const GET_FILTERED_TEAMS = "GET_FILTERED_TEAMS";
export const ASK_TEAM_MEMBERSHIP = "ASK_TEAM_MEMBERSHIP";
export const GET_MY_TEAM_MEMBERS = "GET_MY_TEAM_MEMBERS";
export const GET_FOLLOWERS = "GET_FOLLOWERS";
export const UPDATE_FOLLOWERS = "UPDATE_FOLLOWERS";
export const GET_REQUESTED_MEMBERSHIP = "GET_REQUESTED_MEMBERSHIP";
export const ADD_MEET_EVENTS = "ADD_MEET_EVENTS";
export const UPDATE_CREATED_EVENT = "UPDATE_CREATED_EVENT";
export const SWITCH_TEAM_FOLLOWED = "SWITCH_TEAM_FOLLOWED";
export const GET_USER_TEAMS = "GET_USER_TEAMS";
export const SEARCH_USER_TEAMS = "SEARCH_USER_TEAMS";
export const GET_NEW_MEET_JUDGES_BY_PAGE = "GET_NEW_MEET_JUDGES_BY_PAGE";
export const UPDATE_JUDGE_STATUS = "UPDATE_JUDGE_STATUS";
export const REMOVE_TEAM_MEMBER = "REMOVE_TEAM_MEMBER";
export const CLEAR_TEAMS = "CLEAR_TEAMS";
export const GET_INVITATIONS_LIST = "GET_INVITATIONS_LIST";
export const DECLINE_INVITATION = "DECLINE_INVITATION";
export const ACCEPT_INVITATION = "ACCEPT_INVITATION";
export const NEW_MEET_DND_EVENT_REPLACE = "NEW_MEET_DND_EVENT_REPLACE";
export const SET_MEET_TYPE = "SET_MEET_TYPE";
export const CLEAR_DIVERS_ADDED_RECENTLY = "CLEAR_DIVERS_ADDED_RECENTLY";
export const REMOVE_EVENT = "REMOVE_EVENT";
export const GET_SEARCH_MEETS = "GET_SEARCH_MEETS";
export const GET_SEARCH_MEETS_PER_PAGE = "GET_SEARCH_MEETS_PER_PAGE";
export const SET_UPDATED_MEETS = "SET_UPDATED_MEETS";
export const GET_SCHEDULE_MEETS_BY_PAGE = "GET_SCHEDULE_MEETS_BY_PAGE";
export const SEARCH_SCHEDULE_MEETS_BY_PAGE = "SEARCH_SCHEDULE_MEETS_BY_PAGE";
export const SET_EVENT_LEADERSHIP_ROUND = "SET_EVENT_LEADERSHIP_ROUND";
export const SET_NEXT_DIVER_ROUND = "SET_NEXT_DIVER_ROUND";
export const SET_IS_OPENED_SLIDER = "SET_IS_OPENED_SLIDER";
export const SET_FOCUSED_DIVER = "SET_FOCUSED_DIVER";
export const SET_SELECTED_DIVER = "SET_SELECTED_DIVER";
export const GET_MEET_SHEET = "GET_MEET_SHEET";
export const GET_MEET_USER_SHEET = "GET_MEET_USER_SHEET";
export const UPDATE_CUSTOM_DIFF_ON_MEET_REVIEW =
  "UPDATE_CUSTOM_DIFF_ON_MEET_REVIEW";
export const CLEAR_MEET_SHEET = "CLEAR_MEET_SHEET";
export const SET_REORDERED_DIVES = "SET_REORDERED_DIVES";
export const SET_DIVE_CODE = "SET_DIVE_CODE";
export const GET_DIVE_LIST_VIDEOS = "GET_DIVE_LIST_VIDEOS";
export const GET_DIVE_SHEET_VIDEOS = "GET_DIVE_SHEET_VIDEOS";
export const REMOVE_DIVE_VIDEO = "REMOVE_DIVE_VIDEO";
export const GET_NEXT_FEED_PAGE = "GET_NEXT_FEED_PAGE";
export const REMOVE_VIDEO_FROM_USER = "REMOVE_VIDEO_FROM_USER";
export const SET_CURRENT_DIVER_FOCUS = "SET_CURRENT_DIVER_FOCUS";
export const SET_DIVER_FOCUSED = "SET_DIVER_FOCUSED";
export const SET_UPDATED_STATUSES = "SET_UPDATED_STATUSES";
export const DELETE_MEET = "DELETE_MEET";
export const CLEAR_LEADERSHIP = "CLEAR_LEADERSHIP";
export const UPDATE_DIVE_TYPE = "UPDATE_DIVE_TYPE";
export const UPDATE_DIVE_HEIGHT = "UPDATE_DIVE_HEIGHT";
export const TOGGLE_OPTIONAL = "TOGGLE_OPTIONAL";
export const SET_PLATFORM_HEIGHT = "SET_PLATFORM_HEIGHT";
export const SET_ORIGINAL_SCORES = "SET_ORIGINAL_SCORES";
export const SET_LAST_SCORES = "SET_LAST_SCORES";
export const SET_EMPTY_CODE = "SET_EMPTY_CODE";
export const CURRENT_ROUND_SCREEN_SLIDED = "CURRENT_ROUND_SCREEN_SLIDED";
export const GET_ALL_USERS = "GET_ALL_USERS";
export const SEARCH_ALL_USERS = "SEARCH_ALL_USERS";
export const FOLLOW_DIVER = "FOLLOW_DIVER";
export const FOLLOW_USER = "FOLLOW_USER";
export const REMOVE_FOLLOWER = "REMOVE_FOLLOWER";
export const CLEAR_FOLLOWED_USERS = "CLEAR_FOLLOWED_USERS";
export const CLEAR_USER_FOLLOWERS = "CLEAR_USER_FOLLOWERS";
export const CUT_EXTRA_INVITED_JUDGES = "CUT_EXTRA_INVITED_JUDGES";
export const UPDATE_LEADERSHIP_SOCKET = "UPDATE_LEADERSHIP_SOCKET";
export const UPDATE_WS_CURRENT_ROUND = "UPDATE_WS_CURRENT_ROUND";
export const SET_CURRENT_DIVE_HEADER = "SET_CURRENT_DIVE_HEADER";
export const SET_ROUND_QUEUE = "SET_ROUND_QUEUE";
export const GET_TEAM_DETAILS = "GET_TEAM_DETAILS";
export const GET_CURRENT_USER_SUBSCRIPTION_PLAN =
  "GET_CURRENT_USER_SUBSCRIPTION_PLAN";
export const SET_EVENT_ID_FOR_EDITING = "SET_EVENT_ID_FOR_EDITING";
export const CLEAR_EDITED_EVENT_ID = "CLEAR_EDITED_EVENT_ID";
export const GET_MEET_ADMINS = "GET_MEET_ADMINS";
export const CLEAR_SCHEDULE = "CLEAR_SCHEDULE";
export const CLEAR_MY_TEAM_DATA = "CLEAR_MY_TEAM_DATA";
export const GET_AVAILABLET_ADMINS = "GET_AVAILABLET_ADMINS";
export const SET_ADMINS_FOR_MEET = "SET_ADMINS_FOR_MEET";
export const GET_TEAMS = "GET_TEAMS";
export const GET_INVITED_TEAMS = "GET_INVITED_TEAMS";
export const SEARCH_TEAMS = "SEARCH_TEAMS";
export const CLEAR_SEARCH_TEAMS = "CLEAR_SEARCH_TEAMS";
export const SET_SELECTED_TEAMS = "SET_SELECTED_TEAMS";
export const CREATE_MEET_INIT = "CREATE_MEET_INIT";
export const CLEAR_SEARCH_USERS = "CLEAR_SEARCH_USERS";
export const CLEAR_SEARCH_VENUES = "CLEAR_SEARCH_VENUES";
export const GET_MEW_MEET_JUDGES_BY_SEARCH_KEY =
  "GET_MEW_MEET_JUDGES_BY_SEARCH_KEY";
export const ON_GB_RESET_FOR_NEW_MEET = "ON_GB_RESET_FOR_NEW_MEET";
export const RESET_EVENTS = "RESET_EVENTS";
export const GET_TEAM_DIVERS = "GET_TEAM_DIVERS";

export const GET_VIDEO = "GET_VIDEO";
export const SET_SHARE_MODAL_DETAILS = "SET_SHARE_MODAL_DETAILS";

export const CREATE_TEAM_VENUE_INIT = "CREATE_TEAM_VENUE_INIT";
export const GET_COUNTRY_ISO = "GET_COUNTRY_ISO";
export const GET_TEAMS_VENUES = "GET_TEAMS_VENUES";

export const UPDATE_ROUNDS = "UPDATE_ROUNDS";
export const UPDATE_JUDGES = "UPDATE_JUDGES";
export const SET_JUDGES = "SET_JUDGES";
export const SWITCH_JUDGE_POSITION = "SWITCH_JUDGE_POSITION";
export const SWITCH_DIVER_POSITION = "SWITCH_DIVER_POSITION";
export const SET_FOCUSED_DIVE = "SET_FOCUSED_DIVE";
export const UPDATE_DIVER_DIVES = "UPDATE_DIVER_DIVES";
export const UPDATE_DIVER_DIVE = "UPDATE_DIVER_DIVE";

export const HANDLE_SPLIT_MODAL = "HANDLE_SPLIT_MODAL";
export const HANDLE_CANCEL_MODAL = "HANDLE_CANCEL_MODAL";
export const CREATE_SPLIT_EVENT = "CREATE_SPLIT_EVENT";
export const UPDATE_SPLIT_EVENT = "UPDATE_SPLIT_EVENT";
export const SPLIT_BOARD_PARAMS = "SPLIT_BOARD_PARAMS";
export const UNSPLIT_EVENT = "UNSPLIT_EVENT";
export const HANDLE_MOVE_DIVER_MODAL = "HANDLE_MOVE_DIVER_MODAL";
export const MOVE_DIVER = "MOVE_DIVER";
export const GET_ALL_COLORS_SPLIT_BOARDS = "GET_ALL_COLORS_SPLIT_BOARDS";

export const SET_COLOR_MODE_THEME = "SET_COLOR_MODE_THEME";
export const SET_ATTACHMENT = "SET_ATTACHMENT";
export const UPLOAD_ATTACHMENT = "UPLOAD_ATTACHMENT";

export const UNCOMBINE_SYNCHRO = "UNCOMBINE_SYNCHRO";
export const SET_JUDGE_MODE_WS = "SET_JUDGE_MODE_WS";
export const SET_JUDGE_MODE_MESSAGE = "SET_JUDGE_MODE_MESSAGE";
export const CLEAR_JUDGE_MODE_WS = "CLEAR_JUDGE_MODE_WS";
export const SET_JUDGE_MODE_MESSAGES_WS = "SET_JUDGE_MODE_MESSAGES_WS";
export const SET_JUDGE_MODE_MESSAGES_MESSAGE =
  "SET_JUDGE_MODE_MESSAGES_MESSAGE";
export const CLEAR_JUDGE_MODE_MESSAGES_WS = "CLEAR_JUDGE_MODE_MESSAGES_WS";
export const GET_CURRENT_EVENT = "GET_CURRENT_EVENT";
export const GET_EVENT_SHORT = "GET_EVENT_SHORT";
export const UPDATE_CONBINED_USERS_TEAM_NAME =
  "UPDATE_CONBINED_USERS_TEAM_NAME";
export const CLEAR_CURRENT_EVENT = "CLEAR_CURRENT_EVENT";
export const SET_COMBINED_SYNCHRO = "SET_COMBINED_SYNCHRO";
export const SET_COACH_OPTIONS = "SET_COACH_OPTIONS";
export const CLEAR_COACH_OPTIONS = "CLEAR_COACH_OPTIONS";
export const UPDATE_COMBINED_USER_TEAM_NAME = "UPDATE_COMBINED_USER_TEAM_NAME";
export const SWITCH_JUDGE_MODE = "SWITCH_JUDGE_MODE";
export const LOCK_EVENT = "LOCK_EVENT";
export const LOCK_BAR_EVENT = "LOCK_BAR_EVENT";
export const LOCK_SESSION = "LOCK_SESSION";
export const LOCK_DAY = "LOCK_DAY";
export const DIVE_UP_DAY = "DIVE_UP_DAY";
export const DIVE_DOWN_DAY = "DIVE_DOWN_DAY";
export const DIVE_UP_SESSION = "DIVE_UP_SESSION";
export const DIVE_DOWN_SESSION = "DIVE_DOWN_SESSION";
export const DIVE_UP_EVENT = "DIVE_UP_EVENT";
export const DIVE_DOWN_EVENT = "DIVE_DOWN_EVENT";
export const DIVE_UP_BAR_EVENT = "DIVE_UP_BAR_EVENT";
export const DIVE_DOWN_BAR_EVENT = "DIVE_DOWN_BAR_EVENT";
export const CLOSE_EVENT = "CLOSE_EVENT";
export const GET_EVENT_RULES = "GET_EVENT_RULES";
export const UPDATE_DIVE_SHEET_VALUES = "UPDATE_DIVE_SHEET_VALUES";

export const UPDATE_COMBINED_DIVERS = "UPDATE_COMBINED_DIVERS";
export const CLEAR_COMBINED_DIVERS = "CLEAR_COMBINED_DIVERS";
export const UPDATE_DIVE_SHEET = "UPDATE_DIVE_SHEET";
export const UPDATE_DIVE_SHEET_DIVES = "UPDATE_DIVE_SHEET_DIVES";
export const UPDATE_DIVE_SHEET_COACH_ON_REGISTER =
  "UPDATE_DIVE_SHEET_COACH_ON_REGISTER";
export const CLEAR_DIVE_SHEET = "CLEAR_DIVE_SHEET";

export const GET_CHAMPIONSHIP_PREV_INFO = "GET_CHAMPIONSHIP_PREV_INFO";
export const MOVE_DIVER_CHAMPIONSHIP = "MOVE_DIVER_CHAMPIONSHIP";
export const GET_CHAMPIONSHIP_MEET_INFO = "GET_CHAMPIONSHIP_MEET_INFO";
export const SET_EDITED_SHEET_ITEM = "SET_EDITED_SHEET_ITEM";
export const SAVE_CODE_FOR_DIVER = "SAVE_CODE_FOR_DIVER";
export const UPDATE_EDITED_DIVE_NAME = "UPDATE_EDITED_DIVE_NAME";
export const SET_EDITED_DIVE = "SET_EDITED_DIVE";
export const SET_EDITED_SHEET = "SET_EDITED_SHEET";
export const SET_NEXT_EVENT_INFO = "SET_NEXT_EVENT_INFO";
export const UPDATE_DAY_WARM_UP_TIME = "UPDATE_DAY_WARM_UP_TIME";
export const UPDATE_SESSION_WARM_UP_TIME = "UPDATE_SESSION_WARM_UP_TIME";
export const SET_MEET_REVIEW_DIVER = "SET_MEET_REVIEW_DIVER";
export const UPDATE_COACH_FOR_SHEET = "UPDATE_COACH_FOR_SHEET";
export const UPDATE_DEFUALT_COACH_FOR_SHEET = "UPDATE_DEFUALT_COACH_FOR_SHEET";
export const SET_LEADER_SCREEN_EVENTS = "SET_LEADER_SCREEN_EVENTS";
export const ADD_LEADER_SCREEN_EVENTS = "ADD_LEADER_SCREEN_EVENTS";

export const SET_TEAM_INVITE = "SET_TEAM_INVITE";
export const ASSIGN_DIVER_WITH_DIVES = "ASSIGN_DIVER_WITH_DIVES";
export const GET_FOLLOWED_USERS = "GET_FOLLOWED_USERS";
export const GET_USER_FOLLOWERS = "GET_USER_FOLLOWERS";
export const SEARCH_FOLLOWED_USERS = "SEARCH_FOLLOWED_USERS";
export const SEARCH_USER_FOLLOWERS = "SEARCH_USER_FOLLOWERS";
export const GET_USER_INFO_FOLLOWED = "GET_USER_INFO_FOLLOWED";
export const CLEAR_USER_INFO_FOLLOWED = "CLEAR_USER_INFO_FOLLOWED";

export const CHECK_AAU_MEMBERSHIP = "CHECK_AAU_MEMBERSHIP";
export const CLEAR_AAU_MEMBERSHIP_BY_ID = "CLEAR_AAU_MEMBERSHIP_BY_ID";

export const UNCOMBINE_SYNCHRO_PAIR = "UNCOMBINE_SYNCHRO_PAIR";
export const POST_ASSIGN_EVENTS_FOR_DIVER = "POST_ASSIGN_EVENTS_FOR_DIVER";
export const POST_ASSIGN_EVENTS_FOR_DIVER_BY_ID =
  "POST_ASSIGN_EVENTS_FOR_DIVER_BY_ID";
export const REMOVE_DIVE_SHEET = "REMOVE_DIVE_SHEET";
export const RESET_SHEETS = "RESET_SHEETS";
export const GET_MEET_USER_AGE = "GET_MEET_USER_AGE";
export const SET_MEET_INFO = "SET_MEET_INFO";

export const PASSWORD_CHANGE = "PASSWORD_CHANGE";
export const CLEAR_MEET_PERMISSIONS = "CLEAR_MEET_PERMISSIONS";
export const CLEAR_MEET_SCHEDULE = "CLEAR_MEET_SCHEDULE";
export const CLEAR_MEET_EVENTS_LIST = "CLEAR_MEET_EVENTS_LIST";

export const GET_MEET_SCHEDULE = "GET_MEET_SCHEDULE";
export const GET_MEET_DETAILS = "GET_MEET_DETAILS";
export const GET_MEET_TICKETS_INFO = "GET_MEET_TICKETS_INFO";
export const GET_MEET_JUDGES = "GET_MEET_JUDGES";
export const REMOVE_JUDGE_FROM_EVENT = "REMOVE_JUDGE_FROM_EVENT";

export const CLEAR_SEARCH_MEETS = "CLEAR_SEARCH_MEETS";
export const GET_SEARCH_PAST_MEETS = "GET_SEARCH_PAST_MEETS";
export const GET_SEARCH_CURRENT_MEETS = "GET_SEARCH_CURRENT_MEETS";
export const GET_SEARCH_FUTURE_MEETS = "GET_SEARCH_FUTURE_MEETS";
export const UPDATE_VALUES_FOR_SHEET = "UPDATE_VALUES_FOR_SHEET";

export const GET_MEET_INFO = "GET_MEET_INFO";
export const GET_MEET_FINALS_INFO = "GET_MEET_FINALS_INFO";

export const GET_METRICS_SHEETS = "GET_METRICS_SHEETS";
export const GET_METRICS_VIDEOS = "GET_METRICS_VIDEOS";
export const GET_METRICS_BESTS_POINTS = "GET_METRICS_BESTS_POINTS";
export const GET_METRICS_BESTS_PLACE = "GET_METRICS_BESTS_PLACE";
export const GET_METRICS_BESTS_TOTAL = "GET_METRICS_BESTS_TOTAL";
export const GET_METRICS_BESTS_DIVE_DD = "GET_METRICS_BESTS_DIVE_DD";
export const GET_METRICS_BESTS_DIVE_AWARD = "GET_METRICS_BESTS_DIVE_AWARD";
export const GET_METRICS_SEASONS = "GET_METRICS_SEASONS";
export const DELETE_METRICS_VIDEO = "DELETE_METRICS_VIDEO";
export const SET_FEATURED_DIVE_VIDEO = "SET_FEATURED_DIVE_VIDEO";
export const GET_METRICS_VIDEO_FEATURE = "GET_METRICS_VIDEO_FEATURE";

export const GET_CURRENCIES = "GET_CURRENCIES";

export const GET_ROUNDS_ANNOUNCER_VIEW = "GET_ROUNDS_ANNOUNCER_VIEW";
export const SET_FOCUSED_DIVE_ANNOUNCER_VIEW =
  "SET_FOCUSED_DIVE_ANNOUNCER_VIEW";
export const UPDATE_DIVER_DIVES_ANNOUNCER_VIEW =
  "UPDATE_DIVER_DIVES_ANNOUNCER_VIEW";
export const CLEAR_ANNOUNCER_VIEW = "CLEAR_ANNOUNCER_VIEW";
export const UPDATE_DIVER_DIVE_ANNOUNCER_VIEW =
  "UPDATE_DIVER_DIVE_ANNOUNCER_VIEW";
export const REPORT_VIDEO = "REPORT_VIDEO";
export const RESTORE_VIDEO = "RESTORE_VIDEO";
export const DELETE_REFEREE_FROM_LIST = "DELETE_REFEREE_FROM_LIST";
export const SET_REFEREE = "SET_REFEREE";
export const UPDATE_JUDGES_INFO = "UPDATE_JUDGES_INFO";
export const UPDATE_REFEREES = "UPDATE_REFEREES";
export const GET_EVENT_ANNOUNCER_VIEW = "GET_EVENT_ANNOUNCER_VIEW";
export const UPDATE_JUDGES_ANNOUNCER_VIEW = "UPDATE_JUDGES_ANNOUNCER_VIEW";

export const GET_CREATED_JUDGES = "GET_CREATED_JUDGES";
export const GET_EVENT_JUDGES_TOKENS = "GET_EVENT_JUDGES_TOKENS";
export const DELETE_JUDGE_FROM_TOKENS = "DELETE_JUDGE_FROM_TOKENS";
export const SET_JUDGE_TO_TOKENS = "SET_JUDGE_TO_TOKENS";
export const ASSIGN_EVENT = "ASSIGN_EVENT";
export const UPDATE_EVENT_JUDGES_TOKENS = "UPDATE_EVENT_JUDGES_TOKENS";

export const GET_MEET_EVENTS_LIST = "GET_MEET_EVENTS_LIST";
export const GET_MEET_ADMIN_EVENTS = "GET_MEET_ADMIN_EVENTS";
export const CLEAR_MEET_ADMIN_EVENTS = "CLEAR_MEET_ADMIN_EVENTS";
export const SCRATCH_DIVE_SHEET = "SCRATCH_DIVE_SHEET";
export const UNSKIP_DIVE = "UNSKIP_DIVE";

export const SET_LEADERBOARD_VIEW_EVENTS = "SET_LEADERBOARD_VIEW_EVENTS";
export const ADD_LEADERBOARD_VIEW_EVENTS = "ADD_LEADERBOARD_VIEW_EVENTS";
export const CLEAR_LEADERBOARD_VIEW = "CLEAR_LEADERBOARD_VIEW";
export const GET_EVENT_LEADERBOARD_VIEW = "GET_EVENT_LEADERBOARD_VIEW";
export const SET_PAGE_SIZE = "SET_PAGE_SIZE";
export const GO_TO_NEXT_PAGE = "GO_TO_NEXT_PAGE";

export const UPDATE_DIVER_DIVE_SHEET = "UPDATE_DIVER_DIVE_SHEET ";
export const EMAIL_VERIFY = "EMAIL_VERIFY";
export const PREFILL_DIVE_SHEET = "PREFILL_DIVE_SHEET";
