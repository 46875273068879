import {
  SET_JUDGE_MODE_WS,
  SET_JUDGE_MODE_MESSAGE,
  CLEAR_JUDGE_MODE_WS,
  SET_JUDGE_MODE_MESSAGES_WS,
  SET_JUDGE_MODE_MESSAGES_MESSAGE,
  CLEAR_JUDGE_MODE_MESSAGES_WS,
  SET_NEXT_EVENT_INFO,
} from "store/actionTypes";

const defaultState = {
  judgeMode: {},
  judgeModeMessages: {},
};

const websocketsReducer = (state = defaultState, { type, payload }) => {
  switch (type) {
    case SET_JUDGE_MODE_WS:
      return {
        ...state,
        judgeMode: { ...state.judgeMode, ws: payload },
      };
    case SET_JUDGE_MODE_MESSAGE:
      return {
        ...state,
        judgeMode: {
          ...state.judgeMode,
          message: {
            ...payload,
            iterration: (state.judgeMode.message?.iterration || 0) + 1,
          },
        },
      };
    case CLEAR_JUDGE_MODE_WS:
      return {
        ...state,
        judgeMode: {},
      };

    case SET_JUDGE_MODE_MESSAGES_WS:
      return {
        ...state,
        judgeModeMessages: { ...state.judgeModeMessages, ws: payload },
      };
    case SET_JUDGE_MODE_MESSAGES_MESSAGE:
      return {
        ...state,
        judgeModeMessages: { ...state.judgeModeMessages, message: payload },
      };
    case CLEAR_JUDGE_MODE_MESSAGES_WS:
      return {
        ...state,
        judgeModeMessages: {},
      };

    case SET_NEXT_EVENT_INFO:
      return {
        ...state,
        nextEventInfo: payload,
      };

    default:
      return state;
  }
};

export default websocketsReducer;
