import React, { useEffect } from "react";
import {
  ThemeProvider,
  createGlobalStyle,
  StyleSheetManager,
} from "styled-components";
import { Reset } from "styled-reset";
import { useSelector, useDispatch } from "react-redux";

import { COLOR_MODE_STATUS } from "constants/storageKeys";
import { IS_ENV_DEVELOPMENT } from "constants/env";
import { INDOOR_THEME } from "assets/styles/themes";
import { ACTIVE_LOADINGS } from "constants/storageKeys";

import { colorModeThemeSelector } from "store/selectors/settings";
import { setColorModeTheme } from "store/actions/settings";
import { getLocalStorage, removeSessionStorage } from "helpers/general";

import common from "assets/styles/common";
import { darkTheme, lightTheme } from "assets/styles/themes";

const FontsProvider = createGlobalStyle`
  body {
    font-family: Archivo, sans-serif;
    font-size: 15px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: ${({ theme }) => theme.accentTextColor};

    button,
    input,
    input.MuiInputBase-input,
    label.MuiInputLabel-root,
    .MuiInputBase-root,
    .MuiMenuItem-root,
    .Toastify__toast-body {
      font-family: Archivo, sans-serif;
    }
  }
`;

const GlobalThemeProvider = ({ children }) => {
  const dispatch = useDispatch();

  const colorModeTheme = useSelector(colorModeThemeSelector);

  const combinedTheme = {
    ...common,
    readingStartDirection: "left",
    ...(colorModeTheme === INDOOR_THEME ? darkTheme : lightTheme),
  };

  useEffect(() => {
    removeSessionStorage(ACTIVE_LOADINGS);

    const initValue = getLocalStorage(COLOR_MODE_STATUS);
    dispatch(setColorModeTheme(initValue || INDOOR_THEME));
  }, []);

  return (
    <StyleSheetManager disableVendorPrefixes={IS_ENV_DEVELOPMENT}>
      <ThemeProvider theme={combinedTheme}>
        <React.Fragment>
          <Reset />
          <FontsProvider />
          {children}
        </React.Fragment>
      </ThemeProvider>
    </StyleSheetManager>
  );
};

export default GlobalThemeProvider;
