import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import Button from "Components/Button";
import ConfirmationModal from "Components/ConfirmationModal";

const RulesDescription = styled.div`
  text-align: left;

  > div:not(:last-child) {
    margin-bottom: 15px;
  }

  .eventTitle {
    margin-bottom: 5px;
    font-weight: bold;
    text-align: center;
  }
`;

const RulesList = styled.ul`
  padding: 5px 0 10px 25px;
  font-size: 16px;
  line-height: 1.2;
  list-style: disc;

  & > li {
    margin-bottom: 7px;
  }
`;

const RulesButton = ({
  event = {},
  style = {},
  children,
  onClick,
  isHintInBottomPosition,
}) => {
  const { t } = useTranslation();

  const [modalComponent, setModalComponent] = useState(null);

  const showEventRules = (event = {}) => {
    const { subEvents = [] } = event;

    setModalComponent(
      <ConfirmationModal
        {...{
          message: (
            <RulesDescription>
              {[event, ...subEvents].map(
                ({ id, title, rulesDescription = "" }) => (
                  <div key={id}>
                    {title && <p className="eventTitle">{title}</p>}
                    <RulesList>
                      {rulesDescription?.split("\n")?.map((rule, i) => (
                        <li key={i}>{rule}</li>
                      ))}
                    </RulesList>
                  </div>
                ),
              )}
            </RulesDescription>
          ),
          onBackdropClick: () => setModalComponent(null),
        }}
        oneButton
      />,
    );
  };

  const onClickButton = () => {
    if (!onClick) return showEventRules(event);

    onClick(showEventRules);
  };

  return (
    <>
      {modalComponent}
      <Button
        data-test="rulesButton"
        styleType="link"
        onClick={onClickButton}
        style={style}
        hintDescription={t("Rule set for the current event")}
        type="button"
        isHintInBottomPosition={isHintInBottomPosition}
      >
        {children || t("Rules")}
      </Button>
    </>
  );
};

export default RulesButton;
