import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { IoMdArrowDropdown } from "react-icons/io";

import { support } from "constants/settings";
import { ROUTE } from "constants/routing";

import {
  selectedTeamSelector,
  userTeamsSelector,
  userDetailsSelector,
} from "store/selectors/user";
import {
  setUserTeamId,
  getUserTeams,
  searchUserTeams,
} from "store/actions/teams";
import { openTab } from "helpers/user";
import {
  convertQueryParamsToObject,
  convertObjectToQueryParams,
} from "helpers/general";
import useOutsideClick from "hooks/useOutsideClick";
import useDebouncedSearch from "hooks/useDebouncedSearch";
import { getUserTeamRolesRequest } from "API/meets";

import ButtonDropdown from "Components/ButtonDropdown";
import DiveLiveBtn from "Components/DiveLiveBtn";

import SearchTeam from "./SearchTeam";
import TeamAvatar from "./TeamAvatar";

const StyledRefHolder = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 7px;
  width: 100%;

  .questionButton button {
    height: auto;
    width: auto;
    padding: 0;
  }
`;

const StyledSelectorWrapper = styled.div`
  position: relative;
  z-index: 9;
  flex-grow: 1;
  display: flex;
  align-items: center;

  form {
    width: 100%;
  }

  .MuiInputBase-root {
    border-radius: 4px;
  }
`;

const StyledQuestionSign = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  color: ${({ theme }) => theme.basicTextColor};
  font-size: 16px;
  font-weight: 600;
  line-height: 1.2;
  background-color: ${({ theme }) => theme.basicBlue};
  border-radius: 50%;
`;

const TeamWrap = styled.div`
  display: inline-flex;
  font-size: 16px;
  ${({ isClickable }) => isClickable && "cursor: pointer;"}

  .name {
    margin-top: 3px;
  }

  svg {
    flex-shrink: 0;
    margin-top: 4px;
    margin-left: 5px;
  }
`;

const TeamSelect = ({
  isAdditionalButtons = true,
  setTeamSelectorIsOpen = () => {},
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { pathname, search } = useLocation();
  const dispatch = useDispatch();
  const teamSelectorRef = useRef();

  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const { list = [] } = useSelector(userTeamsSelector);
  const selectedTeam = useSelector(selectedTeamSelector);
  const { id: userId, isAdmin } = useSelector(userDetailsSelector);

  const { id: selectedTeamId, cloudAlias, name } = selectedTeam;
  const { teamId, ...restQueryParams } = convertQueryParamsToObject(search);

  const faqDropdownItems = [
    {
      route: "#",
      id: 2,
      text: t("Add a team"),
      callback: () => openTab(support.links.addYourTeam),
    },
    {
      route: "#",
      id: 1,
      text: t("FAQ"),
      callback: () => openTab(support.links.faq),
    },
    {
      route: "#",
      id: 4,
      text: t("Video tutorials"),
      callback: () => openTab(support.links.infoVideo),
    },
    {
      route: "#",
      id: 22,
      text: t("Contact us"),
      callback: () => history.push(ROUTE.HELP_REQUEST),
    },
  ];

  const onOpen = () => {
    if (list.length <= 1) return;

    setIsOpen(true);
  };

  useDebouncedSearch({
    searchValue,
    deps: [userId, searchValue],
    handleSearch: ([userId, searchValue]) => {
      if (!userId) return;

      dispatch(
        searchUserTeams({
          key: searchValue,
        }),
      );
    },
    handleNoSearch: ([userId]) => {
      if (!userId) return;

      const successCallback = async () => {
        if (!teamId) return;

        const teamDetails = await getUserTeamRolesRequest(teamId);

        if (!!teamDetails) {
          dispatch(setUserTeamId(+teamId));
        } else {
          toast.error(t(`There is no team with id ${teamId} in the list`));
        }

        history.replace({
          pathname,
          search: convertObjectToQueryParams(restQueryParams),
        });
      };

      dispatch(getUserTeams({ successCallback }));
    },
  });

  useEffect(() => {
    if (searchValue || isAdmin || !list.length) return;

    const isSelectedTeamInList = list.some(({ id }) => id === selectedTeamId);

    if (!isSelectedTeamInList)
      dispatch(setUserTeamId(list.length ? list[0].id : undefined));
  }, [list, isAdmin]);

  useEffect(() => {
    setTeamSelectorIsOpen(isOpen);
  }, [isOpen]);

  useOutsideClick(teamSelectorRef, () => {
    setIsOpen(false);
    setSearchValue("");
  });

  return (
    <StyledRefHolder>
      <StyledSelectorWrapper ref={teamSelectorRef}>
        {(!!list.length || !!searchValue) && (
          <>
            {isOpen ? (
              <SearchTeam
                setIsOpen={setIsOpen}
                value={searchValue}
                setValue={setSearchValue}
              />
            ) : (
              <TeamWrap
                className="team"
                isClickable={list.length > 1}
                onClick={onOpen}
              >
                <TeamAvatar avatar={cloudAlias} />
                <span className="name">{name}</span>
                {list.length > 1 && <IoMdArrowDropdown size={18} />}
              </TeamWrap>
            )}
          </>
        )}
      </StyledSelectorWrapper>
      {isAdditionalButtons && (
        <>
          <DiveLiveBtn />
          <ButtonDropdown
            items={faqDropdownItems}
            buttonIcon={<StyledQuestionSign children="?" />}
            className="questionButton"
          />
        </>
      )}
    </StyledRefHolder>
  );
};

export default TeamSelect;
