import VerticalDots from "./VerticalDots";
import InviteBack from "./InviteBack";
import NextDive from "./NextDive";
import Leave from "./Leave";
import Finalize from "./Finalize";

export const BUTTONS_MAP = {
  verticalDots: (props) => <VerticalDots {...props} />,
  inviteBack: (props) => <InviteBack {...props} />,
  nextDive: (props) => <NextDive {...props} />,
  leave: (props) => <Leave {...props} />,
  finalize: (props) => <Finalize {...props} />,
};
