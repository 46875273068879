import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useRouteMatch, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ROUTE } from "constants/routing";
import { LEADERSHIP_MODES } from "constants/leadershipModes";
import { IS_IOS, TOP_PADDING } from "constants/settings";
import { JM_EXIT_TYPE } from "Scenes/JudgeMode/constants";

import { customBreakpoints } from "assets/styles/customBreakpoints";
import { userIdSelector, judgeCodesSelector } from "store/selectors/user";
import { exitJudging } from "Scenes/JudgeMode/helpers";
import {
  selectJudgeModeWS,
  selectJudgeModeMessagesWS,
} from "store/selectors/websockets";

import TeamSelect from "Components/TeamSelect";
import ColorModeToggle from "Components/ColorModeToggle";
import BackgroundWaves from "Components/BackgroundWaves";
import JudgeModeRulesButton from "Scenes/JudgeMode/JudgeModeRulesButton";

const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-grow: 1;
`;

const StyledContainer = styled.div`
  position: relative;
  z-index: 0;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  background-color: ${({ theme }) => theme.basicBackGroundColor};
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: ${IS_IOS ? 55 : 5}px 20px 5px;
  width: 100%;
  box-sizing: border-box;
  ${({ isJudgeMode }) =>
    isJudgeMode &&
    customBreakpoints.xxs`
    padding: 1vh 20px 5px;
  `}
`;

const StyledTeamPlaceholder = styled.div`
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 3vh;
`;

const JudgeModeButtons = styled.div`
  flex-grow: 1;

  button {
    display: inline-flex;
    color: ${({ theme }) => theme.basicTextColor};
    padding: 9px 13px;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 23px;
    background-color: ${({ theme }) => theme.activeBgColor};
    border: none;
    cursor: pointer;

    :hover {
      background-image: linear-gradient(0, #00000028, #00000028);
    }
  }

  .close {
    background-color: ${({ theme }) => theme.pink};
  }
`;

const MobileWrapper = ({
  containerStyles,
  children,
  withTeamSelector,
  withColorModeToggle,
  isJudgeMode,
}) => {
  const history = useHistory();
  const { t } = useTranslation();

  const userId = useSelector(userIdSelector);
  const judgeModeWS = useSelector(selectJudgeModeWS);
  const judgeModeMessagesWS = useSelector(selectJudgeModeMessagesWS);
  const judgeInfoWithTokens = useSelector(judgeCodesSelector);

  const { params = {} } =
    useRouteMatch(
      ROUTE.LEADERSHIP(":eventId", ":screenName", ":extraScreenInfo"),
    ) || {};
  const { params: judgeModeParams = {} } =
    useRouteMatch(ROUTE.JUDGE_MODE(":meetId", ":eventId")) || {};
  const isDiverView =
    params.screenName === LEADERSHIP_MODES.currentRound &&
    params.extraScreenInfo === LEADERSHIP_MODES.diverView;

  const onExitJudgeMode = () => {
    exitJudging({
      type: JM_EXIT_TYPE.EXIT,
      judgeModeWS,
      judgeModeMessagesWS,
      history,
      meetId: judgeModeParams.meetId,
      token: judgeInfoWithTokens?.token,
    });
  };

  return (
    <StyledWrapper>
      <StyledContainer
        style={{
          maxWidth: isDiverView ? "100%" : "450px",
          paddingTop: withColorModeToggle ? 0 : TOP_PADDING,
          ...containerStyles,
        }}
      >
        <BackgroundWaves isMobile />
        {isJudgeMode || withColorModeToggle ? (
          <HeaderWrapper isJudgeMode={isJudgeMode}>
            {isJudgeMode && (
              <>
                <JudgeModeButtons>
                  <button
                    type="button"
                    className="close"
                    onClick={onExitJudgeMode}
                  >
                    {t("Exit judging")}
                  </button>
                </JudgeModeButtons>

                <JudgeModeRulesButton />
              </>
            )}
            {withColorModeToggle && <ColorModeToggle />}
          </HeaderWrapper>
        ) : null}
        {withTeamSelector && userId && (
          <StyledTeamPlaceholder>
            <TeamSelect />
          </StyledTeamPlaceholder>
        )}
        {children}
      </StyledContainer>
    </StyledWrapper>
  );
};

export default MobileWrapper;
