import React, { useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import breakpoint from "styled-components-breakpoint";
import { useDispatch } from "react-redux";

import bandage from "assets/images/bandage.svg";
import logo from "assets/images/logo.svg";

import { ROUTE } from "constants/routing";

import { logOutUser } from "helpers/user";

import BackgroundWaves from "Components/BackgroundWaves";
import Button from "Components/Button";

const ErrorWrapper = styled.div`
  width: 100%;
  padding: 100px 0 0;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  background-image: url(${logo});
  background-repeat: no-repeat;
  background-position: calc(50% + -1px) -5px;
  background-size: 320px;
  box-sizing: border-box;

  .balk {
    margin-top: 25px;
    font-size: 60px;
  }

  ${breakpoint("sm")`
    padding-left: 68px;
    font-size: 26px;
    background-position: calc(50% + 95px) 3px;
    background-size: 400px;

    .balk {
      font-size: 110px;
    }
  `};

  ${breakpoint("md")`
    padding-left: 88px;
    background-position: calc(50% + 236px) 0;
    background-size: 472px;

    .balk {
      font-size: 160px;
    }
  `};
`;

const ErrorStatusCode = styled.p`
  font-size: 150px;
  white-space: nowrap;

  span {
    position: relative;

    &::before {
      content: url(${bandage});
      position: absolute;
      left: 7.5px;
      top: -15px;
    }
  }

  ${breakpoint("md")`
    font-size: 200px;

    span::before {
      left: 22px;
      top: -28px;
    }
  `};
`;

const ErrorNavigation = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px 30px;
  margin: 30px 20px;
`;

const BackHomeLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 50px;
  color: ${({ theme }) => theme.basicTextColor};
  font-size: 16px;
  text-decoration: initial;
  background-color: ${({ theme }) => theme.darkBasicBgBlue};
  border: 2px solid ${({ theme }) => theme.darkBasicBgBlue};
  border-radius: 10px;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 0.5s;

  :hover {
    color: ${({ theme }) => theme.darkBasicBgBlue};
    box-shadow: 0 0 8px 0 ${({ theme }) => theme.darkBasicBgBlue};
    background: none;
  }
`;

const LogOutButton = styled(Button)`
  width: 150px;
  margin: 0;
  color: ${({ theme }) => theme.darkBasicBgBlue};
  border: 2px solid ${({ theme }) => theme.darkBasicBgBlue};
  background: none;
`;

const Balk = ({ statusCode }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onClickLogOut = () => logOutUser(dispatch);

  useEffect(() => {
    return () => {
      window.location.reload(true);
    };
  }, []);

  return (
    <ErrorWrapper>
      <BackgroundWaves />
      <ErrorStatusCode>{statusCode}</ErrorStatusCode>
      <p style={{ marginTop: -25 }}>{t("Error")}</p>
      <p className="balk">{t("It’s a Balk")}</p>
      <p>{t("Or something out of our control but we’re working on it")}</p>
      <ErrorNavigation>
        <BackHomeLink href={ROUTE.HOME}>{t("Back to home")}</BackHomeLink>
        <LogOutButton onClick={onClickLogOut}>{t("Log out")}</LogOutButton>
      </ErrorNavigation>
    </ErrorWrapper>
  );
};

export default Balk;
