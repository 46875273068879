import {
  CLEAR_REGISTRATION_EMAIL_ERROR,
  USER_REGISTRATION,
  actionRejected,
} from "../actionTypes";
import { ERR_MSG } from "constants/errors";

const defaultState = {
  registration: {},
};

const errorsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionRejected(USER_REGISTRATION):
      if (action.payload?.response?.data === "Not allowed email")
        return {
          ...state,
          registration: {
            ...state.registration,
            email: ERR_MSG().RESPONSE.EMAIL_EXISTS,
          },
        };

      return state;
    case CLEAR_REGISTRATION_EMAIL_ERROR:
      return {
        ...state,
        registration: {
          ...state.registration,
          email: "",
        },
      };

    default:
      return state;
  }
};

export default errorsReducer;
