import { toast } from "react-toastify";

import { actionFulfilled, SWITCH_JUDGE_POSITION } from "store/actionTypes";

const meetsReducer = (state = [], { type, payload }) => {
  switch (type) {
    case actionFulfilled(SWITCH_JUDGE_POSITION):
      if (!payload) return state;

      toast.success("Judges were successfully changed");

      return state.map((meet) =>
        meet.id === payload.id ? { ...meet, ...payload } : meet,
      );
    default:
      return state;
  }
};

export default meetsReducer;
