export const WS_TYPES = {
  SKIP: "SKIP",
  SCRATCH: "SCRATCH",
  UN_SCRATCH: "UN_SCRATCH",
  PASS_ON_DIVE: "PASS_ON_DIVE",
  BULK: "BULK",
  LAST_SCORE: "LAST_SCORE",
  ORIGINAL_SCORE: "ORIGINAL_SCORE",
  INCORRECT: "INCORRECT",
  ERROR: "ERROR",
};

export const MAIN_JUDGE_NUMBER = 1;

export const HALF_VALUE = 0.5;
export const KEYBOARD_NUMBERS = [
  { id: 1, value: 1 },
  { id: 2, value: 2 },
  { id: 3, value: 3 },
  { id: 4, value: 4 },
  { id: 5, value: 5 },
  { id: 6, value: 6 },
  { id: 7, value: 7 },
  { id: 8, value: 8 },
  { id: 9, value: 9 },
  { id: 10, value: 10 },
  { id: 11, value: 0 },
  { id: 12, value: HALF_VALUE },
];
export const TIME_TO_SEND_AWARD = 1000;
export const TIME_TO_GO_TO_NEXT_DIVE = 1000;
export const JUDGEMODE_DIVE_UPDATES_TIMEOUT = 1000;

export const PENALTY_DEFAULT_OPTIONS = {
  [WS_TYPES.INCORRECT]: {
    inProgress: false,
    successMessage: "Record fail/Incorrect was applied",
  },
  [WS_TYPES.PASS_ON_DIVE]: {
    inProgress: false,
    successMessage: "Pass on dive was applied",
  },
  [WS_TYPES.LAST_SCORE]: {
    inProgress: false,
    successMessage: "Last scores were setted",
  },
  [WS_TYPES.ORIGINAL_SCORE]: {
    inProgress: false,
    successMessage: "Original scores were setted",
  },
  [WS_TYPES.BULK]: {
    inProgress: false,
    successMessage: "Diver was declared balk",
  },
  [WS_TYPES.SKIP]: {
    inProgress: false,
    successMessage: "Diver was skipped",
  },
  [WS_TYPES.SCRATCH]: {
    inProgress: false,
    successMessage: "Diver was scratched",
  },
  [WS_TYPES.UN_SCRATCH]: {
    inProgress: false,
    successMessage: "Diver was unscratched",
  },
};

export const LOGOUT_TIMER_COUNTER = 30;

export const JM_EXIT_TYPE = {
  EXIT: "EXIT",
  DISCONNECT: "DISCONNECT",
};
