import {
  actionFulfilled,
  GET_METRICS_SHEETS,
  GET_METRICS_BESTS_POINTS,
  GET_METRICS_BESTS_PLACE,
  GET_METRICS_BESTS_TOTAL,
  GET_METRICS_BESTS_DIVE_DD,
  GET_METRICS_BESTS_DIVE_AWARD,
  GET_METRICS_VIDEOS,
  GET_METRICS_SEASONS,
  DELETE_METRICS_VIDEO,
  SET_FEATURED_DIVE_VIDEO,
  GET_METRICS_VIDEO_FEATURE,
} from "../actionTypes";

const initState = {
  sheets: {
    list: [],
    currentPage: 0,
    hasMore: true,
  },
  videos: {
    list: [],
    currentPage: 0,
    hasMore: true,
  },
  featuredVideos: [],
  bests: {
    points: {},
    place: {},
    total: [],
    diveDD: [],
    diveAward: [],
  },
  seasons: [],
};

const metricsReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case actionFulfilled(GET_METRICS_SHEETS): {
      const { list, currentPage } = payload;
      const newMeetIds = new Set(list.map(({ meetId }) => meetId));
      const newMeets = [...newMeetIds].map((meetId) => {
        const {
          meetName = "",
          venue = "",
          meetStartDate = null,
        } = list.find(({ meetId: sheetMeetId }) => sheetMeetId === meetId);

        return {
          meetId,
          meetName,
          venue,
          meetStartDate,
          eventsList: list.filter(
            ({ meetId: sheetMeetId }) => sheetMeetId === meetId,
          ),
        };
      });

      return {
        ...state,
        sheets: {
          ...payload,
          list: !currentPage ? newMeets : [...state.sheets.list, ...newMeets],
          hasMore: newMeets?.length === 10,
        },
      };
    }
    case actionFulfilled(GET_METRICS_BESTS_POINTS): {
      return {
        ...state,
        bests: {
          ...state.bests,
          points: payload,
        },
      };
    }
    case actionFulfilled(GET_METRICS_BESTS_PLACE): {
      return {
        ...state,
        bests: {
          ...state.bests,
          place: payload,
        },
      };
    }
    case actionFulfilled(GET_METRICS_BESTS_TOTAL): {
      return {
        ...state,
        bests: {
          ...state.bests,
          total: payload,
        },
      };
    }
    case actionFulfilled(GET_METRICS_BESTS_DIVE_DD): {
      return {
        ...state,
        bests: {
          ...state.bests,
          diveDD: payload,
        },
      };
    }
    case actionFulfilled(GET_METRICS_BESTS_DIVE_AWARD): {
      return {
        ...state,
        bests: {
          ...state.bests,
          diveAward: payload,
        },
      };
    }

    case actionFulfilled(GET_METRICS_VIDEOS): {
      const { list, currentPage } = payload;

      return {
        ...state,
        videos: {
          ...payload,
          list:
            currentPage === initState.videos.currentPage
              ? list
              : [...state.videos.list, ...list],
        },
      };
    }
    case actionFulfilled(DELETE_METRICS_VIDEO): {
      const filterCallback = ({ videoId }) => videoId !== payload;

      return {
        ...state,
        videos: {
          ...state.videos,
          list: state.videos.list.filter(filterCallback),
        },
        featuredVideos: state.featuredVideos.filter(filterCallback),
      };
    }
    case actionFulfilled(SET_FEATURED_DIVE_VIDEO): {
      const { videoId, featured } = payload;

      const featuredVideos = (function () {
        if (!featured)
          return state.featuredVideos.filter(
            (video) => video.videoId !== videoId,
          );

        const selectedVideo =
          state.videos.list.find((video) => video.videoId === videoId) || {};

        return [
          ...(state.featuredVideos || []),
          { ...selectedVideo, featured: true },
        ];
      })();

      return {
        ...state,
        videos: {
          ...state.videos,
          list: state.videos.list.map((video) => ({
            ...video,
            featured: videoId === video.videoId ? featured : video.featured,
          })),
        },
        featuredVideos,
      };
    }
    case actionFulfilled(GET_METRICS_VIDEO_FEATURE):
      return {
        ...state,
        featuredVideos: payload,
      };

    case actionFulfilled(GET_METRICS_SEASONS):
      return {
        ...state,
        seasons: payload,
      };
    default:
      return state;
  }
};

export default metricsReducer;
