import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { BsX } from "react-icons/bs";
import styled from "styled-components";

import { GCP_BUCKET_URL_IMAGES } from "constants/env";

import { sendMessageToMobile } from "helpers/general";

import Header2 from "Components/Header2";
import QRScanner from "Components/QRScanner";

import OrDivider from "./OrDivider";
import EnterCodeForm from "./EnterCodeForm";

const DividerContent = styled.div`
  width: 320px;
  padding-top: 24px;
`;

const OpenCameraButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin: 0 auto;
  background: ${({ theme }) => theme.accentBackgroundColor};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.highGray};
  cursor: pointer;
  transition: all 0.5s;

  :hover {
    box-shadow: 0 0 4px 0px #00000070;
  }

  svg {
    font-size: 40px;
  }
`;

const SigninByCode = () => {
  const { t } = useTranslation();

  const [isCameraOpen, setIsCameraOpen] = useState(false);

  const onClickCameraOpenButton = () => {
    if (isCameraOpen) {
      setIsCameraOpen(false);
      return;
    }

    sendMessageToMobile({
      message: "qrCodeScanner",
      onError: () => setIsCameraOpen(true),
    });
  };

  return (
    <>
      <Header2>{t("Sign in as a judge")}</Header2>

      {isCameraOpen ? <QRScanner /> : <EnterCodeForm />}

      <OrDivider text={isCameraOpen ? "" : "Or sign in using QR-code"}>
        <DividerContent>
          <OpenCameraButton onClick={onClickCameraOpenButton}>
            {isCameraOpen ? (
              <BsX name="iconClose" />
            ) : (
              <img
                src={`${GCP_BUCKET_URL_IMAGES}qr-scan-icon.png`}
                alt="scan-qr-code"
              />
            )}
          </OpenCameraButton>
        </DividerContent>
      </OrDivider>
    </>
  );
};

export default SigninByCode;
