// import { toast } from "react-toastify";

import { SOCKET_URL } from "constants/env";

import { showSpinner, hideSpinner, inIframe } from "helpers/general";

const ATTEMPT_NUMBER_DEFAULT = 1;
const MAX_ATTEMPT_NUMBER = 5;
const TIMEOUT_TO_RECONNECT = 3000;
const TIMEOUT_TO_RESET_ATTEMPT_NUMBER = 5000;
const TIMEOUT_TO_HEARTBEAT = 10000;
const WS_STATUS_CODE = {
  1000: "Normal Closure",
  1001: "Going Away",
  1002: "Protocol Error",
  1003: "Unsupported Data",
  1004: "(For future)",
  1005: "No Status Received",
  1006: "Abnormal Closure",
  1007: "Invalid frame payload data",
  1008: "Policy Violation",
  1009: "Message too big",
  1010: "Missing Extension",
  1011: "Internal Error",
  1012: "Service Restart",
  1013: "Try Again Later",
  1014: "Bad Gateway",
  1015: "TLS Handshake",
};

const heartbeat = (ws) => {
  if (!ws) return;
  if (ws.readyState !== 1) return;

  ws.send("HEARTBEAT");
  setTimeout(() => heartbeat(ws), TIMEOUT_TO_HEARTBEAT);
};

export const createWebsocket = ({
  path,
  onMessage,
  onSuccessClose = () => {},
  isSpinnerHidden = false,
}) => {
  const url = `${SOCKET_URL}${path}`;
  let ws;
  let resetAttemptNumberTimeout;
  let reconnectTimeout;

  const connect = (props = {}) => {
    !isSpinnerHidden && showSpinner({ path });

    const { attemptNumber = ATTEMPT_NUMBER_DEFAULT, onConnect = () => {} } =
      props;

    ws = new WebSocket(url);
    let customAttemptNumber = attemptNumber;

    ws.onopen = (event) => {
      !isSpinnerHidden && hideSpinner({ path });

      console.log("[open] WS connection established");

      onConnect({ ws });

      setTimeout(() => heartbeat(ws), TIMEOUT_TO_HEARTBEAT);

      resetAttemptNumberTimeout = setTimeout(() => {
        reconnectTimeout && clearTimeout(reconnectTimeout);

        customAttemptNumber = ATTEMPT_NUMBER_DEFAULT;
      }, TIMEOUT_TO_RESET_ATTEMPT_NUMBER);
    };

    ws.onmessage = (props) => onMessage(props);

    ws.reconnect = ({ onReconnect }) =>
      connect({ onConnect: (props) => onReconnect(props) });

    ws.onclose = (event) => {
      resetAttemptNumberTimeout && clearTimeout(resetAttemptNumberTimeout);

      const reason = event.reason || WS_STATUS_CODE[event.code];

      if (event.wasClean) {
        console.log(
          `[close] WS connection was closed clear. Code ${event.code}. Reason ${reason}`,
        );

        onSuccessClose();
      } else {
        if (customAttemptNumber <= MAX_ATTEMPT_NUMBER) {
          const reconnectTime = customAttemptNumber ** 2 * TIMEOUT_TO_RECONNECT;

          console.error(
            `[close] WS connection was interrupted. Reconnect will be attempted in ${
              reconnectTime / 1000
            } second.`,
            event,
          );

          reconnectTimeout = setTimeout(() => {
            connect({ attemptNumber: ++customAttemptNumber });
          }, reconnectTime);
        } else {
          console.error(
            "[close] WS connection was interrupted. Please, reconnect in a few minutes.",
            event,
          );
          // toast.error(
          //   `Websocket closed with errors. Code ${event.code}. Reason ${reason}. Please, reconnect in a few minutes`,
          // );
        }
      }
    };

    ws.onerror = (error) => {
      !isSpinnerHidden && hideSpinner({ path });

      console.error("[error]", error);
    };
  };

  const isWSInIframe = inIframe();

  !isWSInIframe && connect();

  return ws;
};
