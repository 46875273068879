import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { logOutUser } from "helpers/user";

import Balk from "Components/Balk";

const Forbidden = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage.getItem("xOlatToken")) logOutUser(dispatch);
  }, [localStorage.getItem("xOlatToken")]);

  return (
    <Balk
      statusCode={
        <>
          4<span>0</span>3
        </>
      }
    />
  );
};

export default Forbidden;
