import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { BsThreeDots } from "react-icons/bs";

import { feedContentSelector } from "store/selectors/feed";
import { getNextFeedPage } from "store/actions/feed";

import SplashScreen from "Components/SplashScreen";
import InfiniteScrollContainer from "Components/InfiniteScrollContainer";
import Loader from "Components/Loader";

import EmailVerification from "./Components/EmailVerification";
import VideosOfYou from "./Components/VideosOfYou";
import Divider from "./Components/Divider";
import FeedItemsSwitch from "./Components/FeedItemsSwitch";

const StyledBottonNote = styled.p`
  color: #d3d6e2;
  text-align: center;
  font-size: 20px;
  margin: 80px 0;
  display: block;
`;

const Home = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const scrollContainerRef = useRef();

  const {
    userVideos,
    feed: feedNews,
    hasMore,
    currentPage,
  } = useSelector(feedContentSelector) || {};

  const idFeedLoaded = !!feedNews && !!userVideos;
  const showSplashScreen =
    idFeedLoaded && !feedNews?.length && !userVideos?.length;

  const getNextPage = () => {
    dispatch(getNextFeedPage(currentPage + 1));
  };

  useEffect(() => {
    dispatch(getNextFeedPage());
  }, []);

  return showSplashScreen ? (
    <SplashScreen />
  ) : (
    <InfiniteScrollContainer
      wrapperRef={scrollContainerRef}
      topContent={
        <>
          <EmailVerification />
          <VideosOfYou />
          <Divider />
        </>
      }
      dataLength={feedNews?.length}
      next={getNextPage}
      hasMore={hasMore}
      loader={<Loader />}
      scrollableTarget="scrollableHomeFeedWrap"
      endMessage={
        <StyledBottonNote>
          <BsThreeDots /> {t("That's all for now")} <BsThreeDots />
        </StyledBottonNote>
      }
    >
      <div style={{ marginTop: 5 }}>
        {feedNews?.map((feedItem) =>
          Object.keys(feedItem).length ? (
            <FeedItemsSwitch key={feedItem.key} item={feedItem} />
          ) : null,
        )}
      </div>
    </InfiniteScrollContainer>
  );
};

export default Home;
