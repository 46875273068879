import React from "react";

import useSpinner from "hooks/useSpinner";

import Spinner from "Components/Spinner";

const LoaderContainer = () => {
  const [isSpinner] = useSpinner();

  return isSpinner && <Spinner />;
};

export default LoaderContainer;
