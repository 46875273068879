import {
  GET_MEET_PERMISSIONS_BY_TEAM,
  GET_MEET_SCHEDULE,
  GET_MEET_EVENTS_LIST,
  GET_MEET_JUDGES,
  GET_MEET_DETAILS,
  GET_MEET_TICKETS_INFO,
  REMOVE_JUDGE_FROM_EVENT,
} from "store/actionTypes";

import {
  getMeetPermissionsByTeamRequest,
  getMeetScheduleRequest,
  getMeetEventsListRequest,
  getMeetJudgesRequest,
  getMeetDetailsRequest,
  getMeetTicketsInfoRequest,
  removeJudgeFromEventRequest,
} from "API/meet";
import { groupCombinedEvents } from "helpers/event";

export const getMeetPermissionsByTeam = ({ meetId, teamId }) => ({
  type: GET_MEET_PERMISSIONS_BY_TEAM,
  payload: getMeetPermissionsByTeamRequest(meetId, teamId),
});

export const getMeetSchedule = (meetId) => ({
  type: GET_MEET_SCHEDULE,
  payload: getMeetScheduleRequest(meetId),
});

export const getMeetEventsList = (meetId) => ({
  type: GET_MEET_EVENTS_LIST,
  payload: getMeetEventsListRequest(meetId).then((response) =>
    groupCombinedEvents(response),
  ),
});

export const getMeetJudges = (data) => ({
  type: GET_MEET_JUDGES,
  payload: getMeetJudgesRequest(data).then((response) =>
    groupCombinedEvents(response),
  ),
});

export const removeJudgeFromEvent = ({
  successCallback = () => {},
  ...data
}) => ({
  type: REMOVE_JUDGE_FROM_EVENT,
  payload: removeJudgeFromEventRequest(data).then(() => {
    successCallback();

    return data;
  }),
});

export const getMeetDetails = ({ meetId, successCallback = () => {} }) => ({
  type: GET_MEET_DETAILS,
  payload: getMeetDetailsRequest(meetId).then((response) => {
    successCallback();

    return response;
  }),
});

export const getMeetTicketsInfo = (meetId) => ({
  type: GET_MEET_TICKETS_INFO,
  payload: getMeetTicketsInfoRequest(meetId),
});
