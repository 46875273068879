import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { BiErrorAlt } from "react-icons/bi";
import { useDispatch } from "react-redux";
import PullToRefresh from "react-simple-pull-to-refresh";

import { ROUTE } from "constants/routing";

import { logOutUser } from "helpers/user";

import BackgroundWaves from "Components/BackgroundWaves";
import Button from "Components/Button";

const ErrorWrapper = styled.div`
  width: 100%;
  padding: 100px 0 0;
  text-align: center;
  font-size: 24px;
  box-sizing: border-box;
`;

const ErrorNavigation = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px 30px;
  margin: 30px 20px;
`;

const BackHomeLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 50px;
  color: ${({ theme }) => theme.basicTextColor};
  font-size: 16px;
  text-decoration: initial;
  background-color: ${({ theme }) => theme.darkBasicBgBlue};
  border: 2px solid ${({ theme }) => theme.darkBasicBgBlue};
  border-radius: 10px;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 0.5s;

  :hover {
    color: ${({ theme }) => theme.darkBasicBgBlue};
    box-shadow: 0 0 8px 0 ${({ theme }) => theme.darkBasicBgBlue};
    background: none;
  }
`;

const LogOutButton = styled(Button)`
  width: 150px;
  margin: 0;
  color: ${({ theme }) => theme.darkBasicBgBlue};
  border: 2px solid ${({ theme }) => theme.darkBasicBgBlue};
  background: none;
`;

const ErrorFallback = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isRefresh, setIsRefresh] = useState(false);

  const onRefreshPage = () => {
    return new Promise((res) => {
      setTimeout(() => {
        res(
          (() => {
            setIsRefresh(true);

            window.location.reload(true);
          })(),
        );
      }, 1000);
    });
  };

  const onClickLogOut = () => logOutUser(dispatch);

  useEffect(() => {
    return () => {
      window.location.reload(true);
    };
  }, []);

  return (
    <PullToRefresh
      pullDownThreshold={50}
      onRefresh={onRefreshPage}
      className="refresh-container refresh-error"
    >
      {isRefresh ? null : (
        <ErrorWrapper>
          <BackgroundWaves />
          <BiErrorAlt size={150} />
          <p>
            {t("Something went wrong")}. {t("Please refresh the page")}
          </p>
          <ErrorNavigation>
            <BackHomeLink href={ROUTE.HOME}>{t("Back to home")}</BackHomeLink>
            <LogOutButton onClick={onClickLogOut}>{t("Log out")}</LogOutButton>
          </ErrorNavigation>
        </ErrorWrapper>
      )}
    </PullToRefresh>
  );
};

export default ErrorFallback;
