import { actionFulfilled, GET_CURRENCIES } from "store/actionTypes";

const defaultState = {
  currencies: [],
};

const generalReducer = (state = defaultState, { type, payload }) => {
  switch (type) {
    case actionFulfilled(GET_CURRENCIES): {
      return {
        ...state,
        currencies: payload,
      };
    }

    default:
      return state;
  }
};

export default generalReducer;
