import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import { useTranslation } from "react-i18next";
import {
  FiUser,
  FiUsers,
  FiCalendar,
  FiSearch,
  FiHome,
  FiLogOut,
} from "react-icons/fi";
import { Link, useRouteMatch } from "react-router-dom";

import { ROUTE } from "constants/routing";

import { userSelector } from "store/selectors/user";
import { logOutUser } from "helpers/user";
import useRouts from "hooks/useRouts";

import Icon from "Components/Icon";

const StyledDiv = styled.div`
  position: absolute;
  z-index: 99;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  flex-direction: column;
  height: 100%;
  background-color: ${({ theme }) => theme.navbarBackgroundColor};
  box-sizing: border-box;

  ${breakpoint("xs")`
    padding: 5px;
    display: none;
  `};
  ${breakpoint("sm")`
    padding: 10px;
    display: flex;
  `};
  ${breakpoint("md")`
    padding: 20px;
  `};
`;

const StyledOpenButtonHolder = styled.div`
  height: 80px;
  width: 100%;
  flex-shrink: 0;
`;

const StyledOpenButton = styled.div`
  cursor: pointer;
  padding: 7px;

  .icon {
    width: 30px;
    height: 30px;
    fill: ${({ theme }) => theme.accentTextColor};
  }
  &.close {
    padding: 10px;
  }
`;

const IconsWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding: 10px;
  font-weight: 600;
  font-size: 18px;

  a {
    display: inline-flex;
    align-items: center;
    gap: 15px;
    color: ${({ theme }) => theme.accentTextColor};
    text-decoration: none;

    &.selected {
      color: ${({ theme }) => theme.basicBlue};
    }
  }

  svg {
    font-size: 26px;
  }
`;

const NavBar = ({ withHardRefresh }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [expanded, toggleExpanded] = useState(false);

  const user = useSelector(userSelector);

  const { isProfileRouts, isSearchRouts } = useRouts();
  const teamProfile = useRouteMatch(ROUTE.GROUP(":teamId"));
  const isCustomTeamProfile =
    !!teamProfile && teamProfile.params?.teamId !== "_";

  const NAV_ICONS = {
    home: {
      text: "Home",
      to: ROUTE.HOME,
      icon: <FiHome />,
      selected: useRouteMatch(ROUTE.HOME),
    },
    search: {
      text: "Search",
      to: ROUTE.SEARCH,
      icon: <FiSearch />,
      selected: isSearchRouts || isCustomTeamProfile,
    },
    schedule: {
      text: "Schedule",
      to: ROUTE.SCHEDULE,
      icon: <FiCalendar />,
      selected: useRouteMatch(ROUTE.SCHEDULE),
    },
    group: {
      text: "My team",
      to: ROUTE.GROUP(),
      icon: <FiUsers />,
      selected: !!teamProfile && !isCustomTeamProfile,
    },
    profile: {
      text: "Profile",
      to: ROUTE.PROFILE(),
      icon: <FiUser />,
      selected: isProfileRouts,
    },
    logout: {
      text: "Log out",
      to: "#",
      icon: <FiLogOut />,
      onClick: () => logOutUser(dispatch),
      hidden: !user?.token,
    },
  };

  return (
    <StyledDiv>
      <StyledOpenButtonHolder>
        <StyledOpenButton
          className={expanded ? "close" : ""}
          onClick={() => toggleExpanded(!expanded)}
        >
          <Icon name={expanded ? "Close" : "Burger"} />
        </StyledOpenButton>
      </StyledOpenButtonHolder>
      <IconsWrapper>
        {Object.keys(NAV_ICONS).map((key) => {
          const {
            text,
            to,
            icon,
            onClick = () => {},
            hidden = false,
            selected = false,
          } = NAV_ICONS[key];

          if (hidden) return;

          const link = to || ROUTE.DEFAULT;
          const children = (
            <>
              {icon}
              {expanded ? t(text) : ""}
            </>
          );
          const params = {
            key,
            className: selected ? "selected" : "",
            onClick,
          };

          if (withHardRefresh)
            return (
              <a href={link} {...params}>
                {children}
              </a>
            );

          return (
            <Link to={link} {...params}>
              {children}
            </Link>
          );
        })}
      </IconsWrapper>
    </StyledDiv>
  );
};

export default NavBar;
