import React from "react";
import styled from "styled-components";
import { BsChevronLeft, BsX } from "react-icons/bs";
import { useTranslation } from "react-i18next";

import ProgressBar from "./ProgressBar";

const TITLE_LIMIT = 80;

const StyledTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 20px;
`;
const StyledIconButton = styled.button`
  padding: 0;
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;
const Stub = styled.div`
  width: 18px;
`;
const BlockTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 45px;
  padding-left: 50px;
`;
const Title = styled.h1`
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
  text-align: ${({ position }) => position};
`;
const NextPageTitle = styled.h4`
  color: ${({ theme }) => theme.basicBlue};
  font-weight: bold;
  margin-top: 5px;
  margin-left: 35px;
  visibility: ${({ isVisible }) => (isVisible ? "visible" : "hidden")};
`;
const SubTitle = styled.p`
  padding: 10px 20px 0;
  margin-bottom: -10px;
`;

const HeaderWithArrows = ({
  onBackClick,
  onRightButtonClick,
  headerText,
  helperText = "",
  textAlign = "center",
  currentStepIndex,
  totalSteps,
  nextPageTitle,
  withProgressBar = false,
  rightIcon,
}) => {
  const { t } = useTranslation();

  const isProgressBarVisible = !!(currentStepIndex && totalSteps);
  const title =
    headerText?.length > TITLE_LIMIT
      ? `${headerText.slice(0, TITLE_LIMIT)}...`
      : headerText;

  return (
    <>
      <StyledTitleWrapper textAlign={textAlign}>
        {onBackClick ? (
          <StyledIconButton
            type="button"
            onClick={onBackClick}
            style={{ marginTop: 5 }}
          >
            <BsChevronLeft size={18} />
          </StyledIconButton>
        ) : (
          <Stub />
        )}
        {withProgressBar ? (
          <BlockTitleWrapper>
            <ProgressBar current={currentStepIndex} total={totalSteps} />
            <div>
              <Title position="start">{title}</Title>
              {nextPageTitle && (
                <NextPageTitle isVisible={isProgressBarVisible}>
                  {`${t("Next")}: ${nextPageTitle}`}
                </NextPageTitle>
              )}
            </div>
          </BlockTitleWrapper>
        ) : (
          <Title position="center">{title}</Title>
        )}

        {onRightButtonClick ? (
          <StyledIconButton
            type="button"
            onClick={onRightButtonClick}
            style={{ marginTop: 2 }}
          >
            {rightIcon || <BsX size={25} />}
          </StyledIconButton>
        ) : (
          <Stub />
        )}
      </StyledTitleWrapper>
      {helperText && <SubTitle>{helperText}</SubTitle>}
    </>
  );
};

export default HeaderWithArrows;
