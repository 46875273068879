import React, { useState } from "react";
import styled from "styled-components";
import { Field } from "formik";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";

const StyledComponentWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 18px;

  .MuiInputBase-root {
    border-radius: 10px;
  }

  .MuiFormHelperText-root {
    ${({ helperTextPositionAbsolute }) =>
      helperTextPositionAbsolute
        ? "position: absolute; top: calc(100% + 2px);"
        : ""}
  }
`;

const FormikInput = ({
  name,
  label,
  type,
  placeholder,
  icon,
  iconEnd,
  onClickIconEnd = () => {},
  onBlur: propsOnBlur = () => {},
  onFocus,
  onChange: propsOnChange = () => {},
  disabled = false,
  valueNormalizer,
  checked,
  required,
  rows,
  maxRows,
  multiline,
  autoComplete,
  hideErrorMessage = false,
  style = {},
  helperTextPositionAbsolute = false,
}) => {
  const [alterFieldType, setAlterFieldType] = useState("");

  const startAdornment = icon ? (
    <InputAdornment position="start">{icon}</InputAdornment>
  ) : null;
  const endAdornment =
    iconEnd || type === "password" ? (
      <InputAdornment position="end">
        <IconButton
          aria-label="toggle password visibility"
          onClick={
            iconEnd
              ? onClickIconEnd
              : () => setAlterFieldType(alterFieldType ? "" : "text")
          }
          edge="end"
          className="endIconButton"
        >
          {iconEnd ||
            (alterFieldType ? (
              <AiFillEyeInvisible size={28} color="#197ead" />
            ) : (
              <AiFillEye size={28} color="#197ead" />
            ))}
        </IconButton>
      </InputAdornment>
    ) : null;

  return (
    <Field name={name}>
      {({ field, meta }) => {
        const value = valueNormalizer
          ? valueNormalizer(field.value)
          : field.value;
        const onChange = (e) => {
          propsOnChange(e);
          field.onChange(e);
        };
        const onBlur = (e) => {
          propsOnBlur(e);
          field.onBlur(e);
        };

        return (
          <StyledComponentWrapper
            style={style}
            helperTextPositionAbsolute={helperTextPositionAbsolute}
          >
            <TextField
              required={required}
              id={`input-field_${name}`}
              name={name}
              label={label}
              placeholder={placeholder}
              variant="outlined"
              size="small"
              fullWidth
              error={!!meta.error && meta.touched}
              helperText={
                !!meta.error && meta.touched && !hideErrorMessage
                  ? meta.error
                  : ""
              }
              onChange={onChange}
              onBlur={onBlur}
              onFocus={onFocus}
              disabled={disabled}
              checked={checked}
              value={value}
              type={alterFieldType || type}
              rows={rows}
              maxRows={maxRows}
              multiline={multiline}
              autoComplete={autoComplete}
              InputProps={{
                startAdornment,
                endAdornment,
              }}
            />
          </StyledComponentWrapper>
        );
      }}
    </Field>
  );
};

export default FormikInput;
