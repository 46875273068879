import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { Fancybox } from "@fancyapps/ui/dist/fancybox.esm.js";
import styled from "styled-components";

import { GCP_URL } from "constants/env";
import { VIDEO_ACTIONS_MAP } from "Components/ThumbnailVideo/constants";

import { userVideosFeedContentSelector } from "store/selectors/feed";
import { removeVideoFromUser } from "store/actions/feed";

import ThumbnailVideo from "Components/ThumbnailVideo";

import VideosRowHolder from "./VideosRowHolder";

const FANCYBOX_THUMBNAIL_HEIGHT = 120;
const FANCYBOX_THUMBNAIL_WIDTH = 120;

const VideosOfYouWrapper = styled.div`
  padding: 0 20px;
`;

const VideosOfYou = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const userVideos = useSelector(userVideosFeedContentSelector);

  const fancyboxOptions = {
    infinity: false,
    autoFocus: false,
  };
  const fancyboxItems = userVideos
    ?.sort(({ id: a }, { id: b }) => b - a)
    ?.map(({ url, thumbnailUrl }) => ({
      src: `${GCP_URL}${url}`,
      thumb: `${GCP_URL}${thumbnailUrl}`,
      format: "video/mp4",
    }));

  const onClickPlay =
    (startIndex = 0) =>
    () => {
      Fancybox.show(fancyboxItems, { ...fancyboxOptions, startIndex });
    };

  const onRemoveDiveVideo = (id) => {
    dispatch(removeVideoFromUser(id));
  };

  return !!userVideos?.length &&
    (!userVideos?.length === 1 || userVideos[0].url) ? (
    <VideosOfYouWrapper>
      <VideosRowHolder header={t("Videos of you")}>
        {userVideos?.map(({ diver, secondDiver, ...props }, index) => (
          <ThumbnailVideo
            {...props}
            key={props.id}
            divers={[diver, secondDiver].filter((item) => item)}
            height={FANCYBOX_THUMBNAIL_HEIGHT}
            width={FANCYBOX_THUMBNAIL_WIDTH}
            onClickPlay={onClickPlay(index)}
            url={props.thumbnailUrl}
            title={props.diveCode}
            onRemove={onRemoveDiveVideo}
            actionsConfig={[
              VIDEO_ACTIONS_MAP.SHARE,
              VIDEO_ACTIONS_MAP.REPORT,
              VIDEO_ACTIONS_MAP.DELETE,
            ]}
          />
        ))}
      </VideosRowHolder>
    </VideosOfYouWrapper>
  ) : null;
};

export default VideosOfYou;
