export const selectJudgeModeWS = (state) => state.websockets.judgeMode?.ws;

export const selectJudgeModeMessage = (state) =>
  state.websockets.judgeMode?.message;

export const selectJudgeModeMessagesWS = (state) =>
  state.websockets.judgeModeMessages?.ws;

export const selectJudgeModeMessagesMessage = (state) =>
  state.websockets.judgeModeMessages?.message;

export const selectNextEventInfo = (state) => state.websockets.nextEventInfo;
