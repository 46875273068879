import {
  SET_EVENT_LEADERSHIP_ROUND,
  SET_NEXT_DIVER_ROUND,
  SET_IS_OPENED_SLIDER,
  SET_FOCUSED_DIVER,
  SET_SELECTED_DIVER,
} from "store/actionTypes";

export const updateEventLeadershipRound = (payload) => {
  return {
    type: SET_EVENT_LEADERSHIP_ROUND,
    payload,
  };
};

export const setNextDiverRound = (payload) => {
  return {
    type: SET_NEXT_DIVER_ROUND,
    payload,
  };
};

export const setIsOpenedSlider = (payload) => {
  return {
    type: SET_IS_OPENED_SLIDER,
    payload,
  };
};

export const setFocusedDiverRound = (payload) => {
  return {
    type: SET_FOCUSED_DIVER,
    payload,
  };
};

export const setSelectedDiver = (payload) => {
  return {
    type: SET_SELECTED_DIVER,
    payload,
  };
};
