import {
  UPDATE_COMBINED_DIVERS,
  CLEAR_COMBINED_DIVERS,
  UPDATE_DIVE_SHEET,
  UPDATE_DIVE_SHEET_DIVES,
  UPDATE_DIVE_SHEET_COACH_ON_REGISTER,
  CLEAR_DIVE_SHEET,
  UPDATE_CONBINED_USERS_TEAM_NAME,
  UPDATE_DIVE_SHEET_VALUES,
} from "store/actionTypes";

export const DEFAULT_VALUE_INPUT_FIELD = new Array(2).fill({});

const defaultState = {
  combinedDivers: DEFAULT_VALUE_INPUT_FIELD,
  initialDiveSheet: {},
  diveSheet: {},
};

const registerDiversReducer = (state = defaultState, { type, payload }) => {
  switch (type) {
    case UPDATE_COMBINED_DIVERS:
      return { ...state, combinedDivers: payload };
    case CLEAR_COMBINED_DIVERS:
      return { ...state, combinedDivers: DEFAULT_VALUE_INPUT_FIELD };
    case UPDATE_DIVE_SHEET:
      const { initialDiveSheet, diveSheet } = payload;

      return {
        ...state,
        initialDiveSheet: initialDiveSheet || state.initialDiveSheet,
        diveSheet: diveSheet
          ? {
              ...diveSheet,
              dives: diveSheet.dives?.map((dive) => ({
                ...dive,
                isValidHeight: true,
              })),
            }
          : {},
      };
    case UPDATE_DIVE_SHEET_DIVES:
      return {
        ...state,
        diveSheet: {
          ...state.diveSheet,
          dives: payload,
        },
      };
    case UPDATE_CONBINED_USERS_TEAM_NAME:
      return {
        ...state,
        diveSheet: {
          ...state.diveSheet,
          combinedUserInfo: {
            ...state.diveSheet.combinedUserInfo,
            teamName: payload,
          },
        },
      };
    case UPDATE_DIVE_SHEET_COACH_ON_REGISTER:
      return {
        ...state,
        diveSheet: {
          ...state.diveSheet,
          coach: payload,
        },
      };
    case UPDATE_DIVE_SHEET_VALUES:
      return {
        ...state,
        diveSheet: {
          ...state.diveSheet,
          ...payload,
        },
      };
    case CLEAR_DIVE_SHEET:
      return {
        ...state,
        initialDiveSheet: {},
        diveSheet: {},
      };
    default:
      return state;
  }
};

export default registerDiversReducer;
