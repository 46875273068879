import React from "react";
import styled from "styled-components";

import Base from "./Base";

const StyledButton = styled(Base)`
  width: 44px;
  height: 44px;
  font-size: 20px;
  flex-direction: column;
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.disabledBtnBg : "#EBF3FF"};
  color: ${({ theme }) => theme.lighterBlue};
  border-radius: 21px;

  & svg {
    margin: -4px;
  }

  &:hover {
    box-shadow: none;
  }
`;

const InviteBack = (props) => <StyledButton {...props} />;

export default InviteBack;
