import { store } from "App";

import { WEBSOCKET_MSG_TYPES } from "constants/types";
import {
  FINALS_STAGE_TYPE_SHORT_NAME,
  FORMAT_CODES,
} from "constants/championships";

import { createWebsocket } from "API/websockets";
import { updateEventInfo, getEventInfo } from "helpers/websocketAdmin";
import { sendWSData } from "helpers/websocket";
// import { updateCurrentJudgeInfo } from "store/actions/user";
import {
  setJudgeModeWSMessage,
  setJudgeModeWS,
  clearJudgeModeWS,
  setJudgeModeMessagesWS,
  setJudgeModeMessagesWSMessage,
  clearJudgeModeMessagesWS,
  setNextEventInfo,
} from "store/actions/websockets";
import {
  setHeaderForCurrentRound_2,
  updateWsCurrentRound,
  setRoundQueue_2,
} from "store/actions/leadershipScreens";
import { logoutJudgeMode } from "Scenes/JudgeMode/helpers";

export const getEventDetails = ({ rounds, separator = "\n" }) => {
  const baseRound = Object.values(rounds).find(({ title }) => title) || {};
  const {
    subRounds = [],
    eventColour,
    eventId,
    eventType,
    isSplitted,
    splitBoardName,
    title,
    rulesDescription,
    judgeModeEnabled,
  } = baseRound;

  const titles = [baseRound, ...subRounds].flatMap(
    ({ title, eventType, championshipId }) =>
      championshipId
        ? `${title} ${FINALS_STAGE_TYPE_SHORT_NAME[eventType]}`
        : title,
  );
  const eventRulesDescription = {
    id: eventId,
    title,
    rulesDescription,
    subEvents: subRounds.map(
      ({
        eventId: subEventId,
        title: subEventTitle,
        rulesDescription: subEventRulesDescription,
      }) => ({
        id: subEventId,
        title: subEventTitle,
        rulesDescription: subEventRulesDescription,
      }),
    ),
  };

  const combinedEvents = subRounds.length
    ? [baseRound, ...subRounds]
    : undefined;

  return {
    eventName: titles.join(separator),
    eventColour,
    eventId,
    eventType,
    isSplitted,
    splitBoardName,
    combinedEvents,
    eventRulesDescription,
    judgeModeEnabled: judgeModeEnabled,
  };
};

export const setDataIntoStore = (info) => {
  const { rounds } = getEventInfo({ ...info });

  // GET CURRENT BASE ROUND

  const currentBaseRound =
    Object.values(rounds).find(({ dives, subRounds = [] }) =>
      [...dives, ...subRounds.flatMap(({ dives }) => dives)].some(
        ({ current }) => current,
      ),
    ) || {};

  // SET HEADER INFO

  const { subRounds = [], ...baseRound } = currentBaseRound;
  const currentRound =
    [baseRound, ...subRounds].find(({ dives = [] }) =>
      dives.some(({ current }) => current),
    ) || {};
  const {
    number: currentRoundNumber,
    eventId: currentRoundEventId,
    dives: currentRoundDives,
    sequenceStart = 0,
  } = currentRound;
  const currentDive = currentRoundDives?.find(({ current }) => current) || {};
  const {
    dive: currentDiveDetails,
    user,
    combinedUser,
    teamName,
    warnings,
    coach,
    exhibition,
    prequalified,
  } = currentDive;
  const allRounds = Object.values(rounds)?.flatMap(
    ({ subRounds, ...baseRound }) => [baseRound, ...subRounds],
  );
  const allCurrentRoundDives = [
    baseRound,
    ...subRounds
      .sort(({ sequenceStart: a }, { sequenceStart: b }) => a - b)
      .map(({ dives, sequenceStart, ...rest }) => ({
        ...rest,
        dives: dives.map(({ dive, ...rest }) => ({
          ...rest,
          dive: {
            ...dive,
            roundOrder: dive.roundOrder + sequenceStart,
          },
        })),
      })),
  ]
    .flatMap(({ dives }) => dives)
    .filter((dive) => dive)
    .sort(({ dive: a }, { dive: b }) => a.roundOrder - b.roundOrder);

  const headerInfo = {
    round: {
      current: currentRoundNumber,
      total: allRounds.filter(({ eventId }) => eventId === currentRoundEventId)
        .length,
    },
    diver: {
      current: currentDiveDetails?.roundOrder + sequenceStart,
      total: allCurrentRoundDives.length,
    },
  };
  store.dispatch(setHeaderForCurrentRound_2(headerInfo));

  // UPDATE CURRENT ROUND

  const diverOnScreen = user
    ? [{ ...user, teamName, coach }]
    : combinedUser?.users.map((user) => ({
        ...user,
        teamName: combinedUser?.teamName,
      }));
  const eventDetails = getEventDetails({ rounds });
  const diveOnScreen = {
    ...currentDiveDetails,
    eventId: currentRoundEventId,
    eventDetails,
  };
  store.dispatch(
    updateWsCurrentRound({
      diverOnScreen: diverOnScreen,
      warnings,
      exhibition,
      prequalified,
      diveOnScreen,
    }),
  );

  // SET ROUND DIVERS

  const currentDiveIndex = allCurrentRoundDives.findIndex(
    ({ current }) => current,
  );
  const roundDivers = allCurrentRoundDives.map(
    (
      { isSkipped, isScratched, averageJudgeAward, dive, current, ...rest },
      index,
    ) => ({
      ...rest,
      isDone: !!averageJudgeAward,
      isLive: current,
      isUpNext: index === currentDiveIndex + 1,
      scratched: isScratched,
      jumped: !!averageJudgeAward,
      roundOrder: dive.roundOrder,
      dive: {
        ...dive,
        skipped: isSkipped,
      },
    }),
  );
  store.dispatch(
    setRoundQueue_2({
      roundDivers,
    }),
  );
};

const updateDataInStoreBySheet = ({ newDives, roundStatuses }) => {
  const { websockets = {} } = store.getState();
  const { judgeMode = {} } = websockets;
  const { message = {} } = judgeMode;
  const { iterration = 0, info = {} } = message;

  const updatedMessage = {
    ...message,
    iterration: iterration + 1,
    info: {
      ...info,
      rounds: info.rounds
        ?.filter((round) => round)
        ?.map((round) => {
          return {
            ...round,
            status: roundStatuses[round.id],
            dives: round.dives.map((dive) => {
              const updatedDive = newDives.find(
                (newDive) => newDive.dive.id === dive.dive.id,
              ) || { ...dive };

              return {
                ...updatedDive,
              };
            }),
            subRounds: round.subRounds.map((subRound) => ({
              ...subRound,
              status: roundStatuses[subRound.id],
              dives: subRound.dives.map((dive) => {
                const updatedDive = newDives.find(
                  (newDive) => newDive.dive.id === dive.dive.id,
                ) || { ...dive };

                return {
                  ...updatedDive,
                };
              }),
            })),
          };
        }),
    },
  };

  store.dispatch(setJudgeModeWSMessage(updatedMessage));
  const { info: updatedMessageInfo } = updatedMessage;

  updateEventInfo(updatedMessageInfo);
  setDataIntoStore(updatedMessageInfo);
};

const updateDataInStoreByFocus = ({ newFocusedId, roundStatuses }) => {
  const { websockets = {} } = store.getState();
  const { judgeMode = {} } = websockets;
  const { message = {} } = judgeMode;
  const { iterration = 0, info = {} } = message;

  const updatedMessage = {
    ...message,
    iterration: iterration + 1,
    info: {
      ...info,
      rounds: info.rounds
        ?.filter((round) => round)
        ?.map((round) => {
          return {
            ...round,
            status: roundStatuses[round.id],
            dives: round.dives.map((dive) => ({
              ...dive,
              current: dive.dive.id === newFocusedId,
            })),
            subRounds: round.subRounds.map((subRound) => ({
              ...subRound,
              status: roundStatuses[subRound.id],
              dives: subRound.dives.map((subRoundDive) => ({
                ...subRoundDive,
                current: subRoundDive.dive.id === newFocusedId,
              })),
            })),
          };
        }),
    },
  };

  store.dispatch(setJudgeModeWSMessage(updatedMessage));
  const { info: updatedMessageInfo } = updatedMessage;
  // const { judgePanel } = updatedMessageInfo;

  updateEventInfo(updatedMessageInfo);
  setDataIntoStore(updatedMessageInfo);
  // TODO: doesn't work after removing tokens from judgePanel
  // store.dispatch(updateCurrentJudgeInfo(judgePanel));
};

export const updateDataInStoreByDive = ({ newDive }) => {
  const { websockets = {} } = store.getState();
  const { judgeMode = {} } = websockets;
  const { message = {} } = judgeMode;
  const { iterration = 0, info = {} } = message;

  const updatedMessage = {
    ...message,
    iterration: iterration + 1,
    info: {
      ...info,
      rounds: info.rounds
        ?.filter((round) => round)
        ?.map((round) => ({
          ...round,
          judgeModeEnabled: newDive.judgeModeEnabled,
          dives: round.dives.map((dive) =>
            newDive.dive.id === dive.dive.id ? { ...newDive } : { ...dive },
          ),
          subRounds: round.subRounds.map((subRound) => ({
            ...subRound,
            dives: subRound.dives.map((dive) =>
              newDive.dive.id === dive.dive.id ? { ...newDive } : { ...dive },
            ),
          })),
        })),
    },
  };

  store.dispatch(setJudgeModeWSMessage(updatedMessage));
  const { info: updatedMessageInfo } = updatedMessage;

  updateEventInfo(updatedMessageInfo);
  setDataIntoStore(updatedMessageInfo);
};

export const createWS = ({ eventId, token, logout }) => {
  const ws = createWebsocket({
    path: `judge/event/${eventId}/token/${token}`,
    onMessage: (event) => {
      const message = JSON.parse(event.data);

      if (message === null) return;

      const { info, type: messageType, nextEventInfo } = message;

      if (
        messageType === WEBSOCKET_MSG_TYPES.EVENT_CLOSED &&
        (!nextEventInfo ||
          (!!nextEventInfo &&
            nextEventInfo.championshipFormat !==
              FORMAT_CODES.NFHS_SEMI_FINALS_AND_FINALS))
      ) {
        logout();

        return;
      }

      if (messageType === WEBSOCKET_MSG_TYPES.EVENT_CLOSED) {
        store.dispatch(setNextEventInfo(nextEventInfo));

        return;
      }

      if (["PAUSED", "RESUMED"].includes(messageType)) return; // TODO: remove after removing ws

      if (info) {
        const { type } = info;

        switch (type) {
          case "SHEET": {
            const { dives, roundStatuses } = info;
            updateDataInStoreBySheet({
              newDives: dives,
              roundStatuses,
            });

            break;
          }
          case "FOCUS": {
            const { newFocus, roundStatuses } = info;
            updateDataInStoreByFocus({
              newFocusedId: newFocus,
              roundStatuses,
            });

            break;
          }
          default: {
            return;
          }
        }

        return;
      }

      store.dispatch(setJudgeModeWSMessage(message));
    },
    onSuccessClose: () => {
      store.dispatch(clearJudgeModeWS());
    },
  });

  store.dispatch(setJudgeModeWS(ws));
};

export const createJudgeModeMessagesWS = ({ eventId, judgeNumber }) => {
  const ws = createWebsocket({
    path: `messages/judges/${eventId}/judge/${judgeNumber}`,
    onMessage: (event) => {
      const message = JSON.parse(event.data);

      store.dispatch(setJudgeModeMessagesWSMessage(message));
    },
    onSuccessClose: () => {
      store.dispatch(clearJudgeModeMessagesWS());
    },
  });

  store.dispatch(setJudgeModeMessagesWS(ws));
};

export const sendJudgeModeWS = ({ dispatch, ...props }) =>
  sendWSData({ ...props, resetWS: (ws) => dispatch(setJudgeModeWS(ws)) });

export const connectJudgeMode = ({
  pathname,
  judgeInfoWithTokens,
  history,
}) => {
  const { websockets = {} } = store.getState();
  const { judgeMode = {}, judgeModeMessages = {} } = websockets;
  // eslint-disable-next-line
  const [, judgeModeKey, meetId, currentEventKey, eventId] =
    pathname.split("/");

  createWS({
    eventId,
    token: judgeInfoWithTokens.socketToken,
    logout: () => {
      logoutJudgeMode({
        history,
        meetId,
      });

      judgeMode.ws?.close(1000, "BAD_REQUEST Judge mode");
      judgeModeMessages.ws?.close(1000, "BAD_REQUEST Judge mode messages");
    },
  });

  createJudgeModeMessagesWS({
    eventId,
    judgeNumber: judgeInfoWithTokens.judgeNumber,
  });
};
