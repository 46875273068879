import { INDOOR_THEME } from "assets/styles/themes";
import {
  SET_COLOR_MODE_THEME,
  PERMISSION_DENIED,
  SET_PAGE_NOT_FOUND,
  SET_SERVER_ERROR,
} from "store/actionTypes";

const initState = {
  colorMode: {
    theme: INDOOR_THEME,
  },
  forbiddenPagePath: "",
  isPageNotFound: false,
};

const settingsReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case SET_COLOR_MODE_THEME:
      return {
        ...state,
        colorMode: {
          theme: payload,
        },
      };
    case PERMISSION_DENIED:
      return { ...state, forbiddenPagePath: payload };
    case SET_PAGE_NOT_FOUND:
      return { ...state, isPageNotFound: payload };
    case SET_SERVER_ERROR:
      return { ...state, serverError: payload };
    default:
      return state;
  }
};

export default settingsReducer;
