import { getIsDiverHasCode } from "helpers/diveCode";
import { getCurrentEvent } from "helpers/websocket";

export const getFutureDive = (payload) => {
  const { events } = payload || {};
  const currentEvent = getCurrentEvent(events);
  const { roundNumber: eventRoundNumber } = currentEvent;

  const allEventsInfo = events?.flatMap(({ info }) => info);
  const divers =
    allEventsInfo?.sort(({ roundOrder: a }, { roundOrder: b }) => a - b) || [];

  const isAllJumped = divers?.every(({ jumped }) => jumped);
  const roundNumber = isAllJumped ? eventRoundNumber + 1 : eventRoundNumber;

  const futureDivers = divers?.filter((diver) => !diver.jumped) || [];
  const futureDiver = futureDivers?.length
    ? futureDivers[0] || {}
    : divers[0] || {};
  const futureDive =
    futureDiver?.dives?.find((dive) => dive.number === roundNumber) || {};

  return {
    currentDivers: futureDivers,
    currentDiver: futureDiver,
    currentDive: futureDive,
  };
};

export const getCurrentDiver = (payload, dive, isNextDiver = false) => {
  // payload = socketMessage,

  const { events } = payload;
  const currentEvent = getCurrentEvent(events);
  const roundNumber = isNextDiver
    ? dive.currentDivers.length
      ? currentEvent.roundNumber
      : currentEvent.roundNumber + 1
    : currentEvent.roundNumber;

  return {
    roundNumber,
    roundOrder: dive.currentDiver?.roundOrder || "",
    place: dive.currentDiver?.place || "",
    user: {
      cloudAlias: dive.currentDiver?.user?.cloudAlias,
      name: `${dive.currentDiver?.user?.firstName || ""} ${
        dive.currentDiver?.user?.lastName || ""
      }`,
      teamName: dive.currentDiver?.user?.teamName,
      id: dive.currentDiver?.user?.id,
    },
    diveName: dive.currentDive.diveDifficulty?.dive?.name,
    code: dive.currentDive?.code,
    currentDiveId: dive.currentDive.id,
    height:
      dive.currentDive.diveDifficulty?.diveHeight?.heightName?.abbreviation,
    position: dive.currentDive.diveDifficulty?.divePosition,
    dd: dive.currentDive.diveDifficulty?.difficulty,
    score: {
      totalAward: dive.currentDiver?.totalAward,
      roundAward: dive.currentDive.roundAward,
      awards: dive.currentDive.awards,
    },
  };
};

export const getRound = (payload) => {
  return {
    ...payload,
    info: payload.info.filter(getIsDiverHasCode(payload.roundNumber)),
  };
};

export const getAwardInfo = (leadershipRoundInfo) =>
  leadershipRoundInfo
    .map(({ user, totalAward }) => ({
      userId: user?.id,
      totalAward,
    }))
    .sort((a, b) => +b.totalAward - +a.totalAward);

export const getPlace = (awardInfo, diver) =>
  awardInfo.findIndex((item) => +item.totalAward === +diver.totalAward) + 1;
