import {
  DND_ROUND_REPLACE,
  SUBMIT_DIVE_DIFFICULTY,
  CLAER_ROUNDS_LIST,
  SHUFFLE_DIVERS,
  actionFulfilled,
  UPDATE_JUDGES,
} from "../actionTypes";

const defaultState = {};

const roundsJudgesReducer = (state = defaultState, { type, payload }) => {
  switch (type) {
    case actionFulfilled(DND_ROUND_REPLACE):
    case actionFulfilled(SHUFFLE_DIVERS):
    case actionFulfilled(SUBMIT_DIVE_DIFFICULTY):
    case UPDATE_JUDGES:
      return { ...payload.judges };
    case CLAER_ROUNDS_LIST:
      return { ...defaultState };
    default:
      return state;
  }
};

export default roundsJudgesReducer;
