import React from "react";
import styled from "styled-components";

const StyledH2 = styled.h2`
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  letter-spacing: 0;
  margin-bottom: 21px;
  color: ${(props) => props.theme.accentTextColor};
  white-space: pre-line;
`;

function Header2(props) {
  return <StyledH2> {props.children} </StyledH2>;
}

export default Header2;
