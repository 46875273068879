import { useEffect, useRef } from "react";
import { debounce } from "lodash";

import { SEARCH_LENGTH_MIN, SEARCH_TIMEOUT } from "constants/settings";

const useDebouncedSearch = ({
  searchValue = "",
  handleSearch = () => {},
  wait = SEARCH_TIMEOUT,
  minSearchLength = SEARCH_LENGTH_MIN,
  handleNoSearch = () => {},
  deps = [],
  beforeAction = () => {},
}) => {
  const debouncedSearch = useRef(
    debounce((params) => {
      handleSearch(params);
    }, wait),
  ).current;

  useEffect(() => {
    beforeAction();

    debouncedSearch.cancel();

    if (searchValue?.length >= minSearchLength) {
      debouncedSearch(deps);

      return;
    }

    if (!searchValue?.length) handleNoSearch(deps);
  }, deps);
};

export default useDebouncedSearch;
