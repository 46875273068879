import {
  GET_DIVE_LIST_VIDEOS,
  GET_DIVE_SHEET_VIDEOS,
  REMOVE_DIVE_VIDEO,
} from "store/actionTypes";

import {
  getLeadershipVideosRequest,
  getDiveSheetVideosRequest,
} from "API/divers";
import { deleteDiveVideo } from "API/videos";

export const getDiveVideos = (diveId, config) => ({
  type: GET_DIVE_LIST_VIDEOS,
  payload: getLeadershipVideosRequest(diveId, config).then((videos) => ({
    [diveId]: videos,
  })),
});

export const removeDiveVideo = ({ id, diveId }) => ({
  type: REMOVE_DIVE_VIDEO,
  payload: deleteDiveVideo(id).then(() => ({ id, diveId })),
});

export const getDiveSheetVideos = (sheetId) => ({
  type: GET_DIVE_SHEET_VIDEOS,
  payload: getDiveSheetVideosRequest(sheetId).then((videos) =>
    videos.reduce((acc, cur) => {
      const { diveId: key } = cur;

      return acc[key]
        ? { ...acc, [key]: [...acc[key], cur] }
        : { ...acc, [key]: [cur] };
    }, {}),
  ),
});
