import React from "react";
import Radio from "@mui/material/Radio";
import RadioGroupMui from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { BsInfoCircle } from "react-icons/bs";
import styled from "styled-components";

import HintWarn from "Components/HintWarn";

const RadioGroupWrapper = styled(FormControl)`
  &.radioGroup {
    margin-bottom: 10px;
  }
`;

const Label = styled(FormLabel)`
  &.radioLabel {
    color: ${({ theme }) => theme.accentTextColor} !important;
    font-weight: 600;
  }
`;

const InfoHint = styled.div`
  position: relative;
  top: 3px;
  display: inline-block;
  margin-left: 7px;

  svg {
    font-size: 18px;
    color: ${({ theme }) => theme.basicBlue};
  }
`;

const RadioGroup = ({
  options = [],
  value,
  groupTitle,
  name,
  row,
  onChange,
  infoText,
}) => (
  <RadioGroupWrapper className="radioGroup">
    {groupTitle && (
      <Label className="radioLabel" id={`${name}-label`}>
        {groupTitle}
        {infoText && (
          <InfoHint>
            <HintWarn description={infoText} descriptionSeparator="\n">
              <BsInfoCircle />
            </HintWarn>
          </InfoHint>
        )}
      </Label>
    )}
    <RadioGroupMui
      aria-labelledby={`${name}-label`}
      value={value}
      name={name}
      row={row}
      onChange={onChange}
    >
      {options.map(({ title, disabled, code }) => (
        <FormControlLabel
          key={code}
          checked={value === code}
          value={code}
          control={<Radio />}
          label={title}
          disabled={disabled}
        />
      ))}
    </RadioGroupMui>
  </RadioGroupWrapper>
);

export default RadioGroup;
