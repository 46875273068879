import React from "react";
import { QrReader } from "react-qr-reader";
import styled from "styled-components";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

const CameraWrapper = styled.div`
  width: 100%;

  > section {
    width: 100%;
    height: 250px;

    > div {
      width: 100%;
      height: 100%;
      padding-top: 0 !important;
    }
  }
`;

const QRScanner = () => {
  const history = useHistory();

  const onResult = (result, error) => {
    if (!!result) {
      const { pathname, search } = new URL(result.text);

      history.push(pathname + search);
    }

    if (!!error) {
      toast.error(error);
    }
  };

  return (
    <CameraWrapper>
      <QrReader
        onResult={onResult}
        constraints={{
          facingMode: "environment",
        }}
        style={{ width: "100%", height: "100%" }}
      />
    </CameraWrapper>
  );
};

export default QRScanner;
