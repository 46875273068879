import axiosInstance from "./index";

export const getMeetPermissionsByTeamRequest = (meetId, teamId = "noteam") =>
  axiosInstance
    .get(`/meet/${meetId}/permissions/${teamId}`)
    .then((response) => response.data);

export const getMeetScheduleRequest = (meetId) =>
  axiosInstance
    .get(`/meet/${meetId}/schedule`)
    .then((response) => response.data);

export const getMeetEventsListRequest = (meetId) =>
  axiosInstance
    .get(`/meet/${meetId}/events/list`)
    .then((response) => response?.data);

export const getMeetDetailsRequest = (meetId) =>
  axiosInstance
    .get(`/meet/${meetId}/details`)
    .then((response) => response.data);

export const getMeetTicketsInfoRequest = (meetId) =>
  axiosInstance
    .get(`/meet/${meetId}/tickets/info`)
    .then((response) => response.data);

export const getMeetJudgesRequest = ({ meetId, userId }) =>
  axiosInstance
    .get(`/meet/${meetId}/events/list/judge/${userId}`)
    .then((response) => response.data);

export const getEventJudgesRequest = ({ eventId }) =>
  axiosInstance
    .get(`/event/${eventId}/judges`)
    .then((response) => response.data);

export const removeJudgeFromEventRequest = ({ eventId }) =>
  axiosInstance
    .put(`/event/${eventId}/unassign`)
    .then((response) => response.data);

export const getMeetTeamsInfo = (meetId) =>
  axiosInstance
    .get(`meet/${meetId}/teams/info`)
    .then((response) => response.data);

export const setSplitEventRequest = (data) =>
  axiosInstance
    .post("events/splitted/split", data)
    .then((response) => response.data);

export const updateSplitEventRequest = (data) =>
  axiosInstance
    .post("events/splitted/edit", data)
    .then((response) => response.data);

export const unsplitEventRequest = (eventId) =>
  axiosInstance
    .post(`events/splitted/${eventId}/unsplit`)
    .then((response) => response.data);

export const getCoachMeetRegistrationRequest = ({ meetId, userId }) =>
  axiosInstance
    .get(`coach/registration/${userId}/meet/${meetId}`)
    .then((response) => response.data);

export const postCoachMeetRegistrationRequest = (data) =>
  axiosInstance
    .post("coach/registration/meet", data)
    .then((response) => response.data);

export const deleteCoachMeetRegistrationRequest = ({ meetId, userId }) =>
  axiosInstance
    .delete(`coach/registration/${userId}/meet/${meetId}`)
    .then((response) => response.data);

export const getCoachMeetEventsRegistrationRequest = ({ meetId, userId }) =>
  axiosInstance
    .get(`coach/registration/${userId}/events/${meetId}`)
    .then((response) => response.data);

export const postCoachEventRegistrationRequest = (data) =>
  axiosInstance
    .post("coach/registration/event", data)
    .then((response) => response.data);
