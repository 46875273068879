import React from "react";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";

import { IS_IOS } from "constants/settings";

const StyledButton = styled.button`
  width: ${({ styleType, theme }) => {
    switch (styleType) {
      case "closeToast":
        return "20px";
      case "ticketDots":
      case "profileSettings":
        return "25px";
      case "delete":
        return "35px";
      case "search":
      case "filter":
      case "close":
        return "40px";
      case "icon":
        return "44px";
      case "back":
      case "live":
        return "104px";
      case "removeDive":
        return "120px";
      case "combineEvents":
        return "130px";
      case "small":
        return "150px";
      case "resumeEvent":
        return "297px";
      case "print":
      case "smallRubber":
      case "link":
        return "auto";
      case "addJudge":
      case "meetNext":
      case "editProfileSave":
      case "addMore":
      case "diveSheets":
      case "eventNext":
      case "iamDone":
      case "addSession":
      case "warning":
      case "wideSubmit":
      case "clear":
        return "100%";
      default:
        return theme.inputWidth;
    }
  }};
  margin-right: ${({ styleType }) => {
    switch (styleType) {
      case "resumeEvent":
      case "startEvent":
      case "closeMeet":
        return "initial";
      default:
        return "inherit";
    }
  }};
  ${breakpoint("xs")`
    width: ${({ styleType }) => {
      switch (styleType) {
        case "closeMeet":
        case "startEvent":
        case "nav":
          return "120px";
        default:
          return undefined;
      }
    }};
  `};
  ${breakpoint("sm")`
    width: ${({ styleType }) => {
      switch (styleType) {
        case "startEvent":
        case "closeMeet":
        case "nav":
          return "202px";
        default:
          return undefined;
      }
    }};
  `};
  max-width: ${({ styleType }) => {
    switch (styleType) {
      case "meetNext":
      case "editProfileSave":
      case "eventNext":
      case "iamDone":
      case "addMore":
      case "warning":
      case "clear":
        return "450px";
      default:
        return "";
    }
  }};
  height: ${({ styleType }) => {
    switch (styleType) {
      case "ticketDots":
        return "18px";
      case "closeToast":
        return "20px";
      case "combineEvents":
        return "fit-content";
      case "profileSettings":
      case "live":
        return "30px";
      case "smallRubber":
      case "icon":
        return "30px";
      case "print":
        return "35px";
      case "search":
      case "filter":
      case "delete":
        return "35px";
      case "logIn":
      case "closeMeet":
      case "startEvent":
      case "nav":
      case "removeDive":
      case "close":
        return "40px";
      case "addJudge":
      case "meetNext":
      case "editProfileSave":
      case "addMore":
      case "diveSheets":
      case "eventNext":
      case "iamDone":
      case "wideSubmit":
      case "resumeEvent":
      case "warning":
      case "clear":
        return "75px";
      case "addSession":
      case "link":
        return "auto";
      default:
        return "50px";
    }
  }};
  ${({ customCSS }) => customCSS}
  flex-shrink: ${({ styleType }) => {
    switch (styleType) {
      case "diveSheets":
      case "addJudge":
        return 0;
      default:
        return "initial";
    }
  }};
  padding: ${({ styleType }) => {
    switch (styleType) {
      case "delete":
      case "print":
      case "close":
      case "link":
      case "closeToast":
      case "ticketDots":
        return 0;
      case "combineEvents":
        return "3px 12px";
      case "back":
        return "22px 12px 0 24px";
      case "addSession":
        return "20px";
      case "filter":
        return "0 3px";
      default:
        return "0 12px";
    }
  }};
  font-size: ${({ styleType }) => {
    switch (styleType) {
      case "link":
        return "18px";
      case "addJudge":
      case "profileSettings":
      case "resumeEvent":
      case "ticketDots":
        return "20px";
      case "closeToast":
        return "22px";
      case "delete":
        return "40px";
      case "print":
      case "close":
        return "27px";
      default:
        return "16px";
    }
  }};
  font-weight: ${({ styleType }) => {
    switch (styleType) {
      case "closeMeet":
      case "startEvent":
      case "resumeEvent":
      case "meetNext":
      case "editProfileSave":
      case "addMore":
      case "diveSheets":
      case "eventNext":
      case "iamDone":
      case "wideSubmit":
      case "warning":
      case "clear":
        return "bold";
      default:
        return "normal";
    }
  }};
  text-decoration: ${({ styleType }) => {
    switch (styleType) {
      case "link":
        return "underline";
      default:
        return "initial";
    }
  }};
  text-transform: ${({ styleType }) => {
    switch (styleType) {
      case "nav":
      case "wideSubmit":
      case "live":
      case "iamDone":
      case "startEvent":
        return "uppercase";
      default:
        return "initial";
    }
  }};
  display: flex;
  flex-direction: ${({ styleType }) => {
    switch (styleType) {
      case "profileSettings":
        return "column";
      default:
        return "row";
    }
  }};
  align-items: center;
  justify-content: center;
  background-color: ${({ theme, backgroundColor, disabled, styleType }) => {
    if (backgroundColor) return backgroundColor;
    if (disabled)
      switch (styleType) {
        case "removeDive":
          return "#969696";
        case "diveSheets":
          return "#9dffc0";
        case "addJudge":
          return "#b3dffa";
        case "print":
        case "close":
          return "inherit";
        default:
          return theme.disabledBtnBg;
      }
    switch (styleType) {
      case "closeMeet":
      case "icon":
      case "combineEvents":
      case "back":
      case "addSession":
      case "closeToast":
      case "print":
      case "close":
      case "link":
        return "transparent";
      case "delete":
        return theme.grayButtonHover;
      case "logIn":
        return theme.darkBasicBgBlue;
      case "search":
      case "filter":
      case "addJudge":
      case "nav":
      case "startEvent":
      case "resumeEvent":
        return theme.basicBlue;
      case "removeDive":
        return "#969696";
      case "facebook":
        return "#3872C9";
      case "profileSettings":
      case "ticketDots":
        return "inherit";
      case "meetNext":
      case "editProfileSave":
      case "addMore":
      case "wideSubmit":
        return theme.modalBlueBtnColor;
      case "eventNext":
      case "iamDone":
      case "diveSheets":
        return theme.activeBgColor;
      case "live":
        return "#F08803";
      case "warning":
        return "#f08803";
      case "clear":
        return theme.lightBgColor;
      default:
        return theme.accentTextColor;
    }
  }};
  color: ${({ styleType, theme, disabled }) => {
    if (disabled) {
      switch (styleType) {
        case "print":
          return theme.disabledBtnBg;
        default:
          break;
      }
    }
    switch (styleType) {
      case "startEvent":
      case "resumeEvent":
      case "ticketDots":
      case "removeDive":
        return "#fff";
      case "closeMeet":
      case "close":
        return theme.semiGray;
      case "combineEvents":
      case "back":
      case "addSession":
      case "print":
      case "link":
      case "clear":
      case "profileSettings":
        return theme.basicBlue;
      default:
        return theme.basicTextColor;
    }
  }};
  border-radius: ${({ styleType }) => {
    switch (styleType) {
      case "addJudge":
      case "meetNext":
      case "editProfileSave":
      case "addMore":
      case "diveSheets":
      case "eventNext":
      case "iamDone":
      case "wideSubmit":
      case "warning":
      case "link":
      case "clear":
        return "0px";
      case "profileSettings":
      case "ticketDots":
      case "live":
        return "5px";
      case "closeMeet":
      case "resumeEvent":
      case "icon":
      case "print":
        return "15px";
      case "search":
      case "filter":
        return "0px 17.5px 17.5px 0px";
      case "addSession":
      case "close":
        return "20px";
      case "startEvent":
        return "5px";
      default:
        return "10px";
    }
  }};
  border: ${({ theme, disabled, styleType }) => {
    if (disabled)
      switch (styleType) {
        case "startEvent":
          return theme.disabledBtnBg;
        case "live":
        case "addMore":
        case "addJudge":
        case "meetNext":
        case "wideSubmit":
        case "removeDive":
        case "iamDone":
        case "editProfileSave":
        case "diveSheets":
        case "print":
        case "eventNext":
        case "clear":
          return "none";
        default:
          return "";
      }
    switch (styleType) {
      case "closeMeet":
        return "1px solid " + theme.semiGray;
      case "combineEvents":
      case "startEvent":
      case "resumeEvent":
        return "1px solid " + theme.basicBlue;
      case "addSession":
        return "1px solid #d7d7d7";
      default:
        return "none";
    }
  }};
  cursor: ${({ disabled }) => {
    if (disabled) return "default";

    return "pointer";
  }};
  position: ${({ styleType }) => {
    switch (styleType) {
      case "editProfileSave":
        return "sticky";
      default:
        return "unset";
    }
  }};
  z-index: ${({ styleType }) => {
    switch (styleType) {
      case "editProfileSave":
        return 9;
      default:
        return "initial";
    }
  }};
  bottom: ${({ styleType }) => {
    switch (styleType) {
      case "addMore":
        return IS_IOS ? "72px" : "60px";
      case "editProfileSave":
        return 0;
      default:
        return "";
    }
  }};
  opacity: ${({ disabled, styleType }) => {
    if (disabled)
      switch (styleType) {
        case "print":
          return 0.5;
        default:
          return 1;
      }

    return 1;
  }};

  & svg {
    margin: ${(props) => {
      switch (props.styleType) {
        case "ticketDots":
          return "-4px";
        default:
          return "0";
      }
    }};
  }

  &:hover {
    text-decoration: ${({ styleType }) => {
      switch (styleType) {
        case "link":
          return "none";
        default:
          return "inherit";
      }
    }};
    box-shadow: 0 0 8px 0
      ${({ disabled, styleType, theme }) => {
        if (disabled) return "transparent";
        switch (styleType) {
          case "icon":
          case "delete":
          case "ticketDots":
          case "close":
            return theme.grayButtonHover;
          case "closeMeet":
            return theme.semiGray;
          case "back":
          case "search":
          case "filter":
          case "meetNext":
          case "editProfileSave":
          case "addMore":
          case "diveSheets":
          case "eventNext":
          case "iamDone":
          case "wideSubmit":
          case "closeToast":
          case "warning":
          case "removeDive":
          case "print":
          case "link":
          case "clear":
            return "none";
          case "profileSettings":
            return "#cdcdcd";
          case "live":
            return "#F08803";
          default:
            return theme.basicBlue;
        }
      }};
    background: ${({ theme, disabled, styleType, backgroundColor }) => {
      if (disabled)
        switch (styleType) {
          case "diveSheets":
            return "#9dffc0";
          case "removeDive":
            return "#969696";
          case "close":
          case "print":
            return "inherit";
          default:
            return `${theme.disabledBtnBg}bf`;
        }
      if (backgroundColor) return `${backgroundColor}e3`;
      switch (styleType) {
        case "icon":
        case "ticketDots":
        case "close":
          return theme.grayButtonHover;
        case "delete":
          return "#e04558";
        case "logIn":
          return theme.darkBasicBgBlue;
        case "search":
        case "filter":
        case "addJudge":
        case "nav":
        case "startEvent":
        case "resumeEvent":
          return theme.basicBlue;
        case "facebook":
          return "#3872C9";
        case "removeDive":
          return "#969696";
        case "meetNext":
        case "editProfileSave":
        case "addMore":
        case "wideSubmit":
          return theme.modalBlueBtnColor;
        case "diveSheets":
        case "eventNext":
        case "iamDone":
          return theme.activeBgColor;
        case "live":
          return "#F08803";
        case "warning":
          return "#f08803";
        case "clear":
        case "profileSettings":
          return theme.lightBgColor;
        default:
          return "none";
      }
    }};
  }
  &:focus {
    outline: none;
  }

  &.active {
    ${({ styleType }) => {
      switch (styleType) {
        case "link":
          return `
            text-decoration: none;
          `;
        default:
          return "";
      }
    }}
  }
`;

const Base = (props) => <StyledButton {...props} />;

export default Base;
