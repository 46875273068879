import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { BOARD_TYPES } from "Scenes/SplitEventModal/constants";

const DropdownBody = styled.div`
  position: fixed;
  z-index: 3000;
  padding: 15px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.basicBackGroundColor};
  box-shadow: ${({ theme }) =>
    `0 2px 4px 0 rgba(${theme.basicBgRevertRgb}, 0.3)`};

  .link {
    font-size: 16px;
    color: ${({ theme }) => theme.basicBlue};
    text-decoration: none;

    &.disabled {
      color: #3b3c5245;
      cursor: default;
      pointer-events: none;
    }
  }

  @media print {
    display: none;
  }
`;

const StyledUl = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 7px;
  text-align: left;
`;

const Span = styled.span`
  ${({ color }) => {
    const [baseColor, secondaryColor] =
      BOARD_TYPES[color]?.color?.split("/") || [];

    const fontColor =
      baseColor !== BOARD_TYPES.WHITE.color ? baseColor : "inherit";
    const background = secondaryColor
      ? `
        background: -webkit-linear-gradient(0deg, ${baseColor} 0%, ${baseColor} 50%, ${secondaryColor} 50%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      `
      : "";

    return `
    color: ${fontColor};
    ${background}
    `;
  }}
`;

const DropdownList = ({ items, style = {}, wrapRef }) => (
  <DropdownBody style={style} className="dropdown" ref={wrapRef}>
    <StyledUl>
      {items.map(
        ({
          text,
          content,
          route,
          callback = () => {},
          disabled,
          hidden,
          filter,
          id,
          board,
          ...rest
        }) =>
          !hidden && (
            <li key={id}>
              {content ? (
                <div onClick={!disabled ? callback : () => {}}>{content}</div>
              ) : (
                <Link
                  to={route || "#"}
                  className={`link${disabled ? " disabled" : ""}`}
                  onClick={!disabled ? callback : () => {}}
                  disabled={disabled}
                  {...rest}
                >
                  {text}
                  {!!board && (
                    <Span color={board.color}>{`(${board.name})`}</Span>
                  )}
                </Link>
              )}
            </li>
          ),
      )}
    </StyledUl>
  </DropdownBody>
);

export default DropdownList;
