import {
  DND_ROUND_REPLACE,
  GET_DIVE_SHEET,
  UPDATE_DIVE,
  DND_DIVES_REPLACE,
  GET_ALL_EVENT_ROUNDS,
  UPDATE_DIVE_IN_LIST,
  SET_DIVE_DIFFICULTY,
  SUBMIT_DIVE_DIFFICULTY,
  SET_EDIT_DIVE_TO_INITIAL,
  SHUFFLE_DIVERS,
  CLAER_ROUNDS_LIST,
  UPDATE_DIVE_TYPE,
  UPDATE_DIVE_HEIGHT,
  SET_ORIGINAL_SCORES,
  SET_LAST_SCORES,
  UPDATE_DIVE_SHEET_MODAL,
  UPDATE_MODAL_DIVE_SHEET,
  UPDATE_ROUNDS,
  UPDATE_JUDGES,
  SET_FOCUSED_DIVE,
  UPDATE_DIVER_DIVES,
  UPDATE_DIVER_DIVE,
  SCRATCH_DIVE_SHEET,
  UPDATE_DIVER_DIVE_SHEET,
  UNSKIP_DIVE,
} from "store/actionTypes";

import {
  roundDndRequest,
  getDiveSheetRequest,
  submitDifficultyRequest,
  shuffleDiversRequest,
  setOriginalScoresRequest,
  setLastScoresRequest,
  setDiveDifficultyRequest,
  getEventRoundsListRequest,
  scratchDiverRequest,
  unscratchDiverRequest,
  unskipDiveRequest,
} from "API/rounds";
import { assignDiverWithDivesRequest } from "API/divers";

export const getAllEventRounds = (eventId) => ({
  type: GET_ALL_EVENT_ROUNDS,
  payload: getEventRoundsListRequest(eventId),
});

export const roundDndReplaveced = (eventId, roundNumber, source, dest) => {
  return {
    type: DND_ROUND_REPLACE,
    payload: roundDndRequest(eventId, roundNumber, source, dest),
  };
};

export const updateDiveSheetModal = (diveSheet) => ({
  type: UPDATE_DIVE_SHEET_MODAL,
  payload: { diveSheet },
});

export const updateModalDiveSheet = (payload) => ({
  type: UPDATE_MODAL_DIVE_SHEET,
  payload,
});

export const getDiveSheet = (id) => {
  return {
    type: GET_DIVE_SHEET,
    payload: getDiveSheetRequest(id).then((response) => {
      const { userInfo, combinedUserInfo, coach } = response;
      const diveSheet = {
        ...response,
        coach: combinedUserInfo
          ? combinedUserInfo.users.map((user) => ({
              ...(user.coach || coach),
              diverId: user.id,
            }))
          : [{ ...coach, diverId: userInfo.id }],
      };

      return {
        initialDiveSheet: diveSheet,
        diveSheet,
      };
    }),
  };
};

export const updateDive = (diveId, diveData, platformHeight) => {
  return {
    type: UPDATE_DIVE,
    payload: { diveId, diveData, platformHeight },
  };
};

export const updateDiveTypeAction = (diveId) => {
  return {
    type: UPDATE_DIVE_TYPE,
    payload: diveId,
  };
};

export const updateDiveHeightAction = (diveId, platformHeight) => {
  return {
    type: UPDATE_DIVE_HEIGHT,
    payload: { diveId, platformHeight },
  };
};

export const divesDndReplace = (payload) => ({
  type: DND_DIVES_REPLACE,
  payload,
});

export const updateDiveInList = (payload) => ({
  type: UPDATE_DIVE_IN_LIST,
  payload,
});

export const submitDiveDifficulty = ({
  diveId,
  requestData,
  successCallback = () => {},
  errorCallback = () => {},
}) => ({
  type: SUBMIT_DIVE_DIFFICULTY,
  payload: submitDifficultyRequest(diveId, requestData, {
    onError: errorCallback,
  }).then((response) => {
    successCallback();

    return response;
  }),
});

export const setDiveDifficulty = ({
  diveId,
  requestData,
  successCallback = () => {},
  errorCallback = () => {},
}) => ({
  type: SET_DIVE_DIFFICULTY,
  payload: setDiveDifficultyRequest(diveId, requestData, {
    onError: errorCallback,
  }).then((response) => {
    successCallback(response);

    return response;
  }),
});

export const getBackToInitialValues = (payload) => ({
  type: SET_EDIT_DIVE_TO_INITIAL,
  payload,
});

export const clearRoundsList = () => {
  return {
    type: CLAER_ROUNDS_LIST,
  };
};

export const shuffleDivers = (props) => ({
  type: SHUFFLE_DIVERS,
  payload: shuffleDiversRequest(props),
});

export const setLastScores = (data) => ({
  type: SET_LAST_SCORES,
  payload: setLastScoresRequest(data),
});

export const setOriginalScores = (data) => ({
  type: SET_ORIGINAL_SCORES,
  payload: setOriginalScoresRequest(data),
});

export const updateRounds = (payload) => ({
  type: UPDATE_ROUNDS,
  payload,
});

export const updateJudges = (payload) => ({
  type: UPDATE_JUDGES,
  payload,
});

export const setFocusedDive = (payload) => ({
  type: SET_FOCUSED_DIVE,
  payload,
});

export const updateDiverDives = (payload) => ({
  type: UPDATE_DIVER_DIVES,
  payload,
});

export const updateDiverDive = (payload) => ({
  type: UPDATE_DIVER_DIVE,
  payload,
});

export const scratchDiver = ({ sheetId, ...data }) => ({
  type: SCRATCH_DIVE_SHEET,
  payload: scratchDiverRequest(data).then(() => ({
    sheetId,
    isScratched: true,
  })),
});

export const unscratchDiver = ({ sheetId, ...data }) => ({
  type: SCRATCH_DIVE_SHEET,
  payload: unscratchDiverRequest(data).then(() => ({
    sheetId,
    isScratched: false,
  })),
});

export const assignDiverWithDives = ({
  eventId,
  data,
  successCallback = () => {},
}) => ({
  type: UPDATE_DIVER_DIVE_SHEET,
  payload: assignDiverWithDivesRequest(eventId, data).then((response) => {
    successCallback(response);

    return response;
  }),
});

export const unskipDive = (data) => ({
  type: UNSKIP_DIVE,
  payload: unskipDiveRequest(data).then(() => data),
});
