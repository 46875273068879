import {
  SET_COLOR_MODE_THEME,
  PERMISSION_DENIED,
  SET_PAGE_NOT_FOUND,
  SET_SERVER_ERROR,
} from "../actionTypes";

export const setColorModeTheme = (payload) => ({
  type: SET_COLOR_MODE_THEME,
  payload,
});

export const setForbiddenPagePath = (payload) => ({
  type: PERMISSION_DENIED,
  payload,
});

export const setPageNotFound = (payload) => ({
  type: SET_PAGE_NOT_FOUND,
  payload,
});

export const setServerError = (payload) => ({
  type: SET_SERVER_ERROR,
  payload,
});
