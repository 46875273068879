import React from "react";
import styled from "styled-components";

import Button from "Components/Button";

const StyledButtonsContainer = styled.div`
  display: flex;
  width: 100%;
`;

const ButtonsPanel = ({ buttons, customCSS }) => (
  <StyledButtonsContainer>
    {buttons.map(({ name, hidden, ...props }) =>
      !hidden ? (
        <Button key={name} {...props} customCSS={customCSS}>
          {name}
        </Button>
      ) : null,
    )}
  </StyledButtonsContainer>
);

export default ButtonsPanel;
