export const BOARD_TYPES = {
  RED: { name: "Red", color: "#FF0000" },
  BLUE: { name: "Blue", color: "#0000FF" },
  GREEN: { name: "Green", color: "#1B9D2C" },
  WHITE: {
    name: "White",
    color: "#FFFFFF",
    withBorder: true,
    withAccentTextColor: true,
  },
  ORANGE: { name: "Orange", color: "#FF7F0A" },
  BLACK: { name: "Black", color: "#000000" },
  YELLOW: { name: "Yellow", color: "#FFFF00", withAccentTextColor: true },
  PURPLE: { name: "Purple", color: "#A763EC" },
  PINK: { name: "Pink", color: "#FE7FAA" },
  YELLOW_OR_ORANGE: {
    name: "Yellow or Orange",
    color: "#FFFF00/#FF7F0A",
    withAccentTextColor: true,
  },
  PINK_OR_PURPLE: { name: "Pink or Purple", color: "#FE7FAA/#A763EC" },
  GREEN_OR_BLUE: { name: "Green or Blue", color: "#1B9D2C/#0000FF" },
  RED_OR_BLACK: { name: "Red or Black", color: "#FF0000/#000000" },
};

export const MIN_BOARDS_AMOUNT = 1;
export const DEFAULT_BOARDS_AMOUNT = 2;
