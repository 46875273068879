import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter } from "react-router-dom";
import "@fancyapps/ui/dist/fancybox.css";

import configureStore from "store/configureStore";

import GlobalThemeProvider from "HOC/GlobalThemeProvider";
import GlobalLocalisationProvider from "HOC/GlobalLocalisationProvider";
import GlobalNotificationProvider from "HOC/GlobalNotificationProvider";
import GlobalGoogleAnalyticsProvider from "HOC/GlobalGoogleAnalyticsProvider";

import Router from "Routing";

import "./App.css";

const { store, persistor } = configureStore({});

const App = () => (
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <BrowserRouter>
        <GlobalGoogleAnalyticsProvider>
          <GlobalThemeProvider store={store}>
            <GlobalLocalisationProvider store={store}>
              <GlobalNotificationProvider>
                <Router />
              </GlobalNotificationProvider>
            </GlobalLocalisationProvider>
          </GlobalThemeProvider>
        </GlobalGoogleAnalyticsProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>
);

export default App;

export { store };
