import {
  GET_VIDEO,
  SET_SHARE_MODAL_DETAILS,
  REPORT_VIDEO,
  RESTORE_VIDEO,
} from "store/actionTypes";

import {
  getVideoRequest,
  reportVideoRequest,
  restoreVideoRequest,
} from "API/videos";

export const getVideo = (id) => ({
  type: GET_VIDEO,
  payload: getVideoRequest(id),
});

export const setShareModalDetails = (payload) => ({
  type: SET_SHARE_MODAL_DETAILS,
  payload,
});

export const reportVideo = (data) => ({
  type: REPORT_VIDEO,
  payload: reportVideoRequest(data).then(() => data?.id),
});

export const restoreVideo = (payload) => ({
  type: RESTORE_VIDEO,
  payload: restoreVideoRequest(payload),
});
