import {
  GET_SCHEDULE_MEETS_BY_PAGE,
  SEARCH_SCHEDULE_MEETS_BY_PAGE,
  CLEAR_SCHEDULE,
} from "store/actionTypes";

import { getEligibleMeetsRequest, searchEligibleMeetsRequest } from "API/meets";

export const getEligibleMeets = (data) => ({
  type: GET_SCHEDULE_MEETS_BY_PAGE,
  payload: getEligibleMeetsRequest(data),
});

export const searchEligibleMeets = (data) => ({
  type: SEARCH_SCHEDULE_MEETS_BY_PAGE,
  payload: searchEligibleMeetsRequest(data),
});

export const clearSchedule = () => {
  return {
    type: CLEAR_SCHEDULE,
  };
};
