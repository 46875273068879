import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { useSelector } from "react-redux";

import { ROUTE } from "constants/routing";

import { judgeCodesSelector } from "store/selectors/user";

import AuthLayout from "Layouts/Auth";
import DownloadAppLinks from "Components/DownloadAppLinks";
import Tabs from "Components/Tabs";

import LoginForm from "./LoginForm";
import SigninByCode from "./SigninByCode";

const LOGIN_PAGE_TABS = {
  LOGIN: "Login",
  CODE: "Judging mode",
};

const StyledLoginFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 5px 10px;
  margin: 0 auto;
  min-height: 350px;
  max-width: 100%;
  box-sizing: border-box;

  > form {
    max-width: 100%;
  }

  button[type="submit"] {
    margin-top: 20px;
    max-width: 100%;
  }
`;

const Login = () => {
  const history = useHistory();

  const code = useSelector(judgeCodesSelector);

  const [selectedOption, setSelectedOption] = useState(LOGIN_PAGE_TABS.LOGIN);

  window.dataLayer = window.dataLayer || [];

  function gtag() {
    window.dataLayer.push(arguments);
  }

  const handleOptionChange = (e) => {
    setSelectedOption(e?.target.value);
  };

  useEffect(() => {
    if (process.env.REACT_APP_ENV === "PROD") {
      gtag("config", "G-4MG5F8VB3L", {
        page_path: history.location.pathname,
        page_title: "Login",
      });
    }
  }, []);

  useEffect(() => {
    if (code) {
      history.push(ROUTE.JUDGE_MODE(code.meetId, code.eventId));
    }
  }, [code]);

  return (
    <AuthLayout>
      <StyledLoginFormWrapper>
        <Tabs
          options={[
            {
              checked: selectedOption === LOGIN_PAGE_TABS.LOGIN,
              value: LOGIN_PAGE_TABS.LOGIN,
              onChange: handleOptionChange,
            },
            {
              checked: selectedOption === LOGIN_PAGE_TABS.CODE,
              value: LOGIN_PAGE_TABS.CODE,
              onChange: handleOptionChange,
            },
          ]}
          isDarkTheme
        />

        {selectedOption === LOGIN_PAGE_TABS.LOGIN ? <LoginForm /> : null}
        {selectedOption === LOGIN_PAGE_TABS.CODE ? <SigninByCode /> : null}
        <DownloadAppLinks />
      </StyledLoginFormWrapper>
    </AuthLayout>
  );
};

export default Login;
