import {
  GET_MEET_TEAMS,
  SET_USER_TEAM_ID,
  GET_SEARCH_AVAILABLE_TEAMS,
  GET_SEARCH_AVAILABLE_TEAMS_BY_PAGE,
  GET_FILTERED_TEAMS,
  ASK_TEAM_MEMBERSHIP,
  GET_MY_TEAM_MEMBERS,
  GET_FOLLOWERS,
  SWITCH_TEAM_FOLLOWED,
  GET_USER_TEAMS,
  SEARCH_USER_TEAMS,
  REMOVE_TEAM_MEMBER,
  LEAVE_TEAM,
  GET_INVITATIONS_LIST,
  DECLINE_INVITATION,
  ACCEPT_INVITATION,
  CLEAR_MY_TEAM_DATA,
  GET_TEAM_DETAILS,
  SET_TEAM_INVITE,
  UPDATE_FOLLOWERS,
  CANCEL_JOIN,
} from "store/actionTypes";

import {
  getMeetTeamsRequest,
  getSearchAvailableTeamsRequest,
  getSearchAvailableTeamsByPageRequest,
  getFilteredTeamsRequest,
  askTeamMembershipRequest,
  getMyTeamMembersRequest,
  getFollowersRequest,
  getUserTeamsRequest,
  searchUserTeamsRequest,
  removeTeamMemberRequest,
  getInvitationsListRequest,
  acceptInvitationRequest,
  declineInvitationRequest,
  getTeamDetailsRequest,
  leaveTeamRequest,
} from "API/meets";
import { setTeamInviteRequest, cancelJoinRequest } from "API/teams";
import { postFollowTeamRequest, postUnfollowTeamRequest } from "API/divers";

export const getMeetTeamsList = (meetId) => {
  return {
    type: GET_MEET_TEAMS,
    payload: getMeetTeamsRequest(meetId),
  };
};

export const getSearchAvailableTeams = () => {
  return {
    type: GET_SEARCH_AVAILABLE_TEAMS,
    payload: getSearchAvailableTeamsRequest(),
  };
};

export const getSearchAvailableTeamsByPage = (page, size) => ({
  type: GET_SEARCH_AVAILABLE_TEAMS_BY_PAGE,
  payload: getSearchAvailableTeamsByPageRequest(page, size),
});

export const getFilteredTeams = (key) => ({
  type: GET_FILTERED_TEAMS,
  payload: getFilteredTeamsRequest(key),
});

export const joinTeamAskAction = (teamId, role, callback = () => {}) => ({
  type: ASK_TEAM_MEMBERSHIP,
  payload: askTeamMembershipRequest({ teamId, role }).then((response) => {
    callback(response);

    return response;
  }),
});

export const getMyTeamMembers = (teamId) => ({
  type: GET_MY_TEAM_MEMBERS,
  payload: getMyTeamMembersRequest(teamId).then((res) => ({
    teamId,
    ...res,
  })),
});

export const getFollowersList = (teamId) => ({
  type: GET_FOLLOWERS,
  payload: getFollowersRequest(teamId),
});

export const followTeam = ({ teamId, user }) => ({
  type: UPDATE_FOLLOWERS,
  payload: postFollowTeamRequest(teamId).then(() => ({ user, followed: true })),
});

export const unfollowTeam = ({ teamId, user }) => ({
  type: UPDATE_FOLLOWERS,
  payload: postUnfollowTeamRequest(teamId).then(() => ({
    user,
    followed: false,
  })),
});

export const setUserTeamId = (payload) => ({
  type: SET_USER_TEAM_ID,
  payload,
});

export const switchTeamFollowed = (teamId) => {
  return {
    type: SWITCH_TEAM_FOLLOWED,
    payload: teamId,
  };
};

export const getUserTeams = ({ successCallback = () => {}, page = 0 }) => ({
  type: GET_USER_TEAMS,
  payload: getUserTeamsRequest({ page }).then((response) => {
    successCallback(response);

    return {
      currentPage: page,
      list: response.sort(({ name: a }, { name: b }) =>
        a.trim().localeCompare(b.trim()),
      ),
    };
  }),
});

export const searchUserTeams = ({ key, page = 0 }) => ({
  type: SEARCH_USER_TEAMS,
  payload: searchUserTeamsRequest({ key, page }).then((response) => ({
    currentPage: page,
    list: response.sort(({ name: a }, { name: b }) =>
      a.trim().localeCompare(b.trim()),
    ),
  })),
});

export const leaveTeam = (teamId, data, successCallback = () => {}) => ({
  type: LEAVE_TEAM,
  payload: leaveTeamRequest(teamId, data).then(() => {
    successCallback();

    return { ...data, teamId };
  }),
});

export const removeTeamMember = (teamId, data, successCallback = () => {}) => ({
  type: REMOVE_TEAM_MEMBER,
  payload: removeTeamMemberRequest(teamId, data).then(() => {
    successCallback();

    return data;
  }),
});

export const getInvitationsList = (teamId) => ({
  type: GET_INVITATIONS_LIST,
  payload: getInvitationsListRequest(teamId),
});

export const acceptInvitation = (key, callback) => ({
  type: ACCEPT_INVITATION,
  payload: acceptInvitationRequest(key).then((response) => {
    callback();
  }),
});

export const declineInvitation = (key, callback) => ({
  type: DECLINE_INVITATION,
  payload: declineInvitationRequest(key).then((response) => {
    callback();
  }),
});

export const getTeamDetails = (id) => ({
  type: GET_TEAM_DETAILS,
  payload: getTeamDetailsRequest(id),
});

export const clearMyTeamsInfo = () => ({
  type: CLEAR_MY_TEAM_DATA,
});

export const setTeamInvite = (data) => ({
  type: SET_TEAM_INVITE,
  payload: setTeamInviteRequest(data).then(() => data),
});

export const cancelJoin = (key, successCallback = () => {}) => ({
  type: CANCEL_JOIN,
  payload: cancelJoinRequest(key).then(() => {
    successCallback();

    return key;
  }),
});
