import React, { useEffect, useRef } from "react";
import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
// import GoogleLogin from "react-google-login";
// import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
// import { ImFacebook } from "react-icons/im";
import { HiOutlineMail } from "react-icons/hi";
import { BiLockOpen } from "react-icons/bi";

// import { clientID } from "constants/googleAPI";
import { ROUTE } from "constants/routing";

import { userSelector } from "store/selectors/user";
import { userLogIn } from "store/actions/user";
import { emailNormalizer } from "helpers/general";

import Header2 from "Components/Header2";
import Link from "Components/Link";
import FormikInput from "Components/FormikInputs/FormikInput";
import Button from "Components/Button";
import SignUpAndLogin from "Components/SignUpAndLogin";
// import OrDivider from "./OrDivider";

const StyledModeChangeText = styled.div`
  margin-top: -12px;
  width: ${({ theme }) => theme.inputWidth};
  text-align: right;
  max-width: 100%;
`;

const LoginForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const formikRef = useRef();

  const user = useSelector(userSelector);

  const handleSignInClick = (values) => {
    dispatch(
      userLogIn({ ...values, username: emailNormalizer(values.username) }),
    );
  };

  const goToForgotPasswordPage = () => history.push(ROUTE.FORGOT_PASSWORD);

  // const handleGoogleSignInClick = (googleUser) => {
  //   const id_token = googleUser.getAuthResponse().id_token;

  //   const profile = googleUser.getBasicProfile();
  //   console.log("ID: " + profile.getId()); // Do not send to your backend! Use an ID token instead.
  //   console.log("Name: " + profile.getName());
  //   console.log("Image URL: " + profile.getImageUrl());
  //   console.log("Email: " + profile.getEmail()); // This is null if the 'email' scope is not present.

  // userGoogleLogInRequest(id_token).then(({ data }) => {
  //   if (process.env.REACT_APP_ENV === "PROD") {
  //     gtag("event", "login", {
  //       method: "Google",
  //     });
  //   }
  //   dispatch({
  //     type: actionFulfilled(USER_LOG_IN),
  //     payload: { identityKey: data.identityKey, token: id_token },
  //   });
  // });
  // };

  useEffect(() => {
    if (formikRef.current) {
      user.username &&
        formikRef.current.setFieldValue("username", user?.details?.email || "");
    }
  }, [user]);

  return (
    <>
      <Header2>{t("Ready to dive in?")}</Header2>
      <Formik
        enableReinitialize
        initialValues={{ username: "", password: "" }}
        innerRef={formikRef}
        onSubmit={handleSignInClick}
      >
        <Form>
          <FormikInput
            name="username"
            type="email"
            valueNormalizer={emailNormalizer}
            label={t("Email")}
            icon={<HiOutlineMail />}
            required
          />
          <FormikInput
            name="password"
            type="password"
            label={t("Password")}
            icon={<BiLockOpen />}
            required
          />
          <StyledModeChangeText>
            <Link onClick={goToForgotPasswordPage}>
              {t("Forgot password?")}{" "}
            </Link>
          </StyledModeChangeText>

          <Button data-test="loginSubmitButton" type="submit" styleType="logIn">
            {t("Log In")}
          </Button>
        </Form>
      </Formik>
      {/* Facebook and google authorization is not implemented yet */}
      {/* <OrDivider>
         <FacebookLogin
          appId="1088597931155576"
          autoLoad={true}
          fields="name,email,picture"
          // onClick={componentClicked}
          // callback={responseFacebook}
          render={(renderProps) => (
        <Button onClick={() => ""} styleType="facebook">
          <ImFacebook />
          facebook
        </Button>
        )}
         />
        <GoogleLogin
          clientId={clientID}
          onSuccess={handleGoogleSignInClick}
          redirectUri="http://localhost:3000/"
          children="google"
        />
      </OrDivider>*/}
      <SignUpAndLogin
        text={t("Don't have an account?")}
        linkRoute={ROUTE.REGISTRATION}
        linkText={t("Sign Up")}
      />
    </>
  );
};

export default LoginForm;
