import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useRouteMatch, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { ROUTE } from "constants/routing";
import { GCP_BUCKET_URL_IMAGES } from "constants/env";
import { IS_SAFARI } from "constants/settings";

import { connectJudgeMode } from "helpers/websocketJudgeMode";
import { judgeCodesSelector, userIdSelector } from "store/selectors/user";
import {
  selectJudgeModeWS,
  selectJudgeModeMessagesWS,
} from "store/selectors/websockets";
import {
  forbiddenPagePathSelector,
  isPageNotFoundSelector,
  serverErrorSelector,
} from "store/selectors/settings";
import { setForbiddenPagePath } from "store/actions/settings";
import { logOutUser } from "helpers/user";
import { getCurrentUserInfo } from "store/actions/user";
import { getAvailableCurrencies } from "store/actions/general";
import useRouts from "hooks/useRouts";

import MobileWrapper from "Components/MobileWrapper";
import Forbidden from "Scenes/Forbidden";
import PageNotFound from "Scenes/PageNotFound";
import ServerErrorPage from "Scenes/ServerErrorPage";
import NavigationLayout from "Components/NavigationLayout";

import Header from "./Header";

const PrintedPageFooter = styled.div`
  counter-increment: page;
  ${IS_SAFARI
    ? ""
    : `
    position: fixed;
    bottom: 0;
    left: 0;
  `}
  display: none;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 75px;
  padding: 20px 0 5px;
  box-sizing: border-box;

  img {
    width: auto;
    height: 50px;
  }

  @media print {
    display: flex;
  }
`;

const CommonLayout = ({ match, children }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const judgeInfoWithTokens = useSelector(judgeCodesSelector);
  const judgeModeWS = useSelector(selectJudgeModeWS);
  const judgeModeMessagesWS = useSelector(selectJudgeModeMessagesWS);
  const forbiddenPagePath = useSelector(forbiddenPagePathSelector);
  const isPageNotFound = useSelector(isPageNotFoundSelector);
  const serverError = useSelector(serverErrorSelector);
  const userId = useSelector(userIdSelector);

  const [isUserExist, setIsUserExist] = useState(!userId);

  const isMeets = match.url === "/Meets";
  const isSchedule = useRouteMatch(ROUTE.SCHEDULE)?.isExact;
  const isHome = useRouteMatch(ROUTE.HOME)?.isExact;
  const isHelpRequest = useRouteMatch(ROUTE.HELP_REQUEST)?.isExact;
  const isVideo = useRouteMatch(ROUTE.VIDEO(":userId"))?.isExact;

  const {
    isCreateMeetRouts,
    isProfileRouts,
    isTeamRouts,
    isGroupInvite,
    isGroupMergeAccounts,
    isJudgeMode,
    isJudgeModeRouts,
    isMeetRegistration,
    isDiveSheetsRouts,
    isMeetShort,
    isDiverView,
    isAnnouncerView,
    isJumbotronView,
    isMeetEventGrid,
    isMeetInfoRouts,
    isSearchRouts,
  } = useRouts();

  const isMeetsWrapper =
    isMeets && !isAnnouncerView && !isMeetEventGrid && !isJumbotronView;

  const withMobileWrapper =
    isCreateMeetRouts ||
    isHelpRequest ||
    isHome ||
    isSearchRouts ||
    (isMeetInfoRouts && !isAnnouncerView && !isJumbotronView) ||
    isSchedule ||
    isTeamRouts ||
    isDiveSheetsRouts ||
    isProfileRouts ||
    isVideo ||
    isJudgeModeRouts;
  const withTeamSelector =
    isGroupInvite ||
    isGroupMergeAccounts ||
    isSearchRouts ||
    isSchedule ||
    isHome ||
    isMeetShort ||
    isMeetRegistration;
  const withoutNavigation =
    isJudgeModeRouts || isDiverView || isAnnouncerView || isJumbotronView;
  const withColorModeToggle = isJudgeMode;
  const isError =
    pathname === forbiddenPagePath || isPageNotFound || !!serverError;
  const navigationLayoutStyle = isJumbotronView ? { background: "black" } : {};

  const getContainerStyles = () => {
    if (isHelpRequest) return { position: "relative" };
  };

  const renderError = () => {
    if (!!serverError) return <ServerErrorPage />;
    if (isPageNotFound) return <PageNotFound />;

    return <Forbidden />;
  };

  useEffect(() => {
    if (!!userId) {
      dispatch(
        getCurrentUserInfo({
          successCallback: () => setIsUserExist(true),
          errorCallback: () => logOutUser(dispatch),
        }),
      );
      dispatch(getAvailableCurrencies());
    }

    return () => {
      judgeModeWS?.close(1000, "Close Common component");
      judgeModeMessagesWS?.close(1000, "Close Common component");
    };
  }, []);

  useEffect(() => {
    if (isJudgeModeRouts && judgeInfoWithTokens?.socketToken) {
      judgeModeWS?.close(1000, "Update Judge mode");
      judgeModeMessagesWS?.close(1000, "Update Judge mode messages");

      connectJudgeMode({
        pathname,
        judgeInfoWithTokens,
        history,
      });
    }
  }, [isJudgeModeRouts, judgeInfoWithTokens?.socketToken]);

  useEffect(() => {
    if (forbiddenPagePath && pathname !== forbiddenPagePath)
      dispatch(setForbiddenPagePath());

    // TODO: find out the reason where we need this toast
    // if (forbiddenPagePath && pathname === forbiddenPagePath)
    //   toast.error("Permission denied");
  }, [pathname, forbiddenPagePath]);

  return (
    <NavigationLayout
      {...{
        withoutNavigation,
        withMobileWrapper,
        style: navigationLayoutStyle,
      }}
    >
      {isError ? (
        renderError()
      ) : (
        <>
          {isUserExist && (
            <>
              {isMeetsWrapper && <Header />}
              <main
                className={isMeetsWrapper ? "meetsWrapper" : "contentWrapper"}
              >
                {withMobileWrapper ? (
                  <MobileWrapper
                    withTeamSelector={withTeamSelector}
                    containerStyles={getContainerStyles()}
                    withColorModeToggle={withColorModeToggle}
                    isJudgeMode={isJudgeMode}
                  >
                    {children}
                  </MobileWrapper>
                ) : (
                  children
                )}
              </main>
              <tfoot>
                <tr>
                  <td>
                    <div className="emptyFooter" />
                  </td>
                </tr>
              </tfoot>
              <PrintedPageFooter>
                <img
                  src={`${GCP_BUCKET_URL_IMAGES}dive-logo-blue.png`}
                  alt="logo"
                />
                <span className="printedTime" />
              </PrintedPageFooter>
            </>
          )}
        </>
      )}
    </NavigationLayout>
  );
};

export default CommonLayout;
