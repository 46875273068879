import {
  SET_DIVER_FOCUSED,
  SET_SELECTED_DIVER,
  CLEAR_LEADERSHIP,
} from "store/actionTypes";

import { getLeftQueue } from "helpers/diverInfoList";

const defaultState = {
  roundFocused: {
    totalRounds: "",
    totalDivers: "",
  },
  diverFocused: {
    eventStatus: "",
    roundNumber: "",
    roundOrder: "",
    user: {
      cloudAlias: "",
      firstName: "",
      lastName: "",
      teamName: "",
    },
    focusedDive: {
      number: "",
    },
  },
  roundNumber: "",
};

const diverInfoListReducer = (state = defaultState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_DIVER_FOCUSED: {
      const { info } = payload;

      const currentInfo =
        info?.find(({ dives }) => dives.some(({ current }) => current)) || {};

      return {
        ...state,
        roundFocused: payload,
        diverFocused: {
          ...currentInfo,
          focusedDive: currentInfo.dives?.find(({ current }) => current) || {},
        },
        roundNumber: state.roundNumber
          ? getLeftQueue(state, payload).roundNumber
          : payload.roundNumber,
      };
    }
    case SET_SELECTED_DIVER:
      return {
        ...state,
        diverFocused: {
          ...payload,
          focusedDive:
            payload?.dives?.find(
              (dive) => dive.number === state.roundFocused.roundNumber,
            ) || {},
        },
      };
    case CLEAR_LEADERSHIP:
      return defaultState;
    default:
      return state;
  }
};

export default diverInfoListReducer;
