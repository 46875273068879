import {
  actionFulfilled,
  CHECK_AAU_MEMBERSHIP,
  CLEAR_AAU_MEMBERSHIP_BY_ID,
} from "store/actionTypes";

const defaultState = {};

const membershipReducer = (state = defaultState, { type, payload }) => {
  switch (type) {
    case actionFulfilled(CHECK_AAU_MEMBERSHIP): {
      const updatedState = {};

      Object.keys(payload).map((key) => {
        updatedState[key] = {
          ...state[key],
          ...payload[key],
        };
      });

      return {
        ...state,
        ...updatedState,
      };
    }
    case CLEAR_AAU_MEMBERSHIP_BY_ID: {
      const newData = { ...state };

      delete newData[payload];

      return {
        ...newData,
      };
    }

    default:
      return state;
  }
};

export default membershipReducer;
