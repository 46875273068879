import { toast } from "react-toastify";
import _ from "lodash";

import { ERR_MSG, HTTP_RESPONSE_STATUS_CODES } from "constants/errors";
import {
  UNAUTHORIZED_STATUS,
  FORBIDDEN_STATUS,
  NOT_FOUND_STATUS,
  SERVER_ERROR_STATUS,
  BAD_GATEWAY_STATUS,
  GATEWAY_TIMEOUT_STATUS,
} from "constants/errors";
import {
  USER_LOG_OUT,
  SESSION_EXPIRED,
  actionFulfilled,
} from "store/actionTypes";
import { CURRENCY_CONFIG } from "constants/types";
import { ACTIVE_LOADINGS } from "constants/storageKeys";

import { store } from "App";
import { getFileFromGCP, getGCPToken } from "API/files";
import {
  setForbiddenPagePath,
  setPageNotFound,
  setServerError,
} from "store/actions/settings";

export const setLocalStorage = ({ key, value, isStringified = false }) => {
  const itemValue = isStringified ? value : JSON.stringify(value);
  localStorage.setItem(key, itemValue);

  const event = new StorageEvent("storage", {
    isTrusted: true,
    bubbles: true,
    cancelable: false,
  });

  window.dispatchEvent(event);
};

export const getLocalStorage = (key) => {
  const value = localStorage.getItem(key);

  try {
    return JSON.parse(value);
  } catch (err) {
    console.warn("Not JSON format");

    return value;
  }
};

export const removeLocalStorage = (key) => {
  localStorage.removeItem(key);

  const event = new StorageEvent("storage", {
    isTrusted: true,
    bubbles: true,
    cancelable: false,
  });

  window.dispatchEvent(event);
};

export const setSessionStorage = ({ key, value, isStringified = false }) => {
  const itemValue = isStringified ? value : JSON.stringify(value);
  sessionStorage.setItem(key, itemValue);

  const event = new StorageEvent("storage", {
    isTrusted: true,
    bubbles: true,
    cancelable: false,
  });

  window.dispatchEvent(event);
};

export const getSessionStorage = (key) => {
  const value = sessionStorage.getItem(key);

  try {
    return JSON.parse(value);
  } catch (err) {
    console.warn("Not JSON format");

    return value;
  }
};

export const removeSessionStorage = (key) => {
  sessionStorage.removeItem(key);

  const event = new StorageEvent("storage", {
    isTrusted: true,
    bubbles: true,
    cancelable: false,
  });

  window.dispatchEvent(event);
};

const formatNumber = (num, decimalPlacesAmount) =>
  (Math.round(num * 10 * 10) / 100).toFixed(decimalPlacesAmount);

export const formatNumberWith1DecimalPlaces = (num) => formatNumber(num, 1);

export const formatNumberWith2DecimalPlaces = (num) => formatNumber(num, 2);

export const convertQueryParamsToObject = (search = "") => {
  const params = search.includes("?") ? search.substring(1) : search;
  const result = {};

  if (!params) return result;

  params.split("&").map((param) => {
    const [key, value] = param.split("=");

    result[key] = value;

    return param;
  });

  return result;
};

export const convertObjectToQueryParams = (object = {}) => {
  if (!Object.keys(object).length) return "";

  const params = [];

  Object.keys(object)
    .sort((a, b) => a.localeCompare(b))
    .map((key) => {
      params.push(`${key}=${object[key]}`);
    });

  return `?${params.join("&")}`;
};

export const sendMessageToMobile = ({ message = "", onError = () => {} }) => {
  try {
    window.webkit.messageHandlers.appClickListener.postMessage(message);
  } catch (err) {
    console.warn(ERR_MSG().NOT_IOS);

    try {
      window.appInterface.postMessage(message);
    } catch (err) {
      console.warn(ERR_MSG().NOT_ANDROID);

      onError();
    }
  }
};

export const vibrate = () => {
  sendMessageToMobile({
    message: "vibrate",
    notIOS: () => {
      if (navigator.vibrate) navigator.vibrate(200);
    },
  });
};

export const getFileFormPath = (path) => {
  const fileName = path.split("/").slice(-1).join();
  const separatorIndex = fileName.indexOf("_");

  return fileName.slice(separatorIndex + 1);
};

export const onDownloadFileFromGCP = (props) => (e) => {
  e.preventDefault();
  const { name } = props || {};
  const gcpName = name.split("/").slice(-2).join("/");

  getGCPToken().then(({ token }) => {
    getFileFromGCP({ token, name: gcpName }).then(({ mediaLink }) => {
      const link = document.createElement("a");
      link.href = mediaLink;
      link.download = gcpName;
      link.click();
    });
  });
};

export const errorCallback = (error) => {
  let message = "Error";

  if (error?.response) {
    const { data, status } = error.response;

    const defaultErrorMessage =
      data && typeof data === "string"
        ? data
        : `Error status ${status}. ${HTTP_RESPONSE_STATUS_CODES[status]}`;

    switch (status) {
      case UNAUTHORIZED_STATUS:
        store.dispatch({ type: SESSION_EXPIRED });
        store.dispatch({ type: actionFulfilled(USER_LOG_OUT) });

        message = defaultErrorMessage;

        break;
      case FORBIDDEN_STATUS:
        store.dispatch(setForbiddenPagePath(window.location.pathname));

        message = defaultErrorMessage;

        break;
      case NOT_FOUND_STATUS:
        store.dispatch(setPageNotFound(true));

        break;

      case BAD_GATEWAY_STATUS:
      case GATEWAY_TIMEOUT_STATUS:
        store.dispatch(setServerError(status));

        message = HTTP_RESPONSE_STATUS_CODES[status];

        break;
      case SERVER_ERROR_STATUS:
        message = HTTP_RESPONSE_STATUS_CODES[status];

        break;
      default:
        message = defaultErrorMessage;
    }
  }

  toast.error(message);
};

export const emailNormalizer = (value) => value?.toLowerCase()?.trim();

export const numberValueNormalizer =
  (props = {}) =>
  (value) => {
    const { decimalPlacesAmount = 2, isOnBlur = false } = props;

    if (!value) return "";

    const formattedValue = value.replace(",", ".").replace(/[^.\d]+/g, "");
    const splitNumber = formattedValue.split(".");

    return splitNumber.length > 2 ||
      splitNumber[1]?.length >= decimalPlacesAmount
      ? `${Number(`${splitNumber[0]}.${splitNumber[1]}`).toFixed(
          decimalPlacesAmount,
        )}`
      : `${
          isOnBlur
            ? Number(formattedValue).toFixed(decimalPlacesAmount)
            : formattedValue
        }`;
  };

export const getAppOwnerFee = ({ price, currency }) => {
  if (!price) return 0;

  const { min, cutoff, after } = CURRENCY_CONFIG[currency] || {};

  if (price < cutoff)
    return numberValueNormalizer({ isOnBlur: true })(`${price + min}`);

  const fee = (Math.floor(price * after * 10 * 10) / 100).toFixed(2);
  const cents = +`${fee}`.slice(-2);

  let result = price + Math.floor(fee);

  if (cents >= 25 && cents < 75) result = result + 0.5;
  if (cents >= 75 && cents < 100) result = result + 1;

  return numberValueNormalizer({ isOnBlur: true })(`${result}`);
};

export const lazyRetry = (componentImport) =>
  new Promise((resolve, reject) => {
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem("retry-lazy-refreshed") || "false",
    );

    componentImport()
      .then((component) => {
        window.sessionStorage.setItem("retry-lazy-refreshed", "false");
        resolve(component);
      })
      .catch((error) => {
        if (!hasRefreshed) {
          window.sessionStorage.setItem("retry-lazy-refreshed", "true");
          return window.location.reload();
        }

        reject(error);
      });
  });

//  to prevent Formik's default form submission by pressing "Enter
export const preventSubmissionByPressingEnter = (keyEvent) => {
  if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
    keyEvent.preventDefault();
  }
};

export const isWindowInFullScreen = () => {
  if (window["fullScreen"] !== undefined) return window.fullScreen;

  return window.innerHeight === window.screen.height;
};

export const getUniqArrayItems = (array, key) => {
  if (key)
    return array.reduce(
      (acc, cur) =>
        acc.some((item) => item[key] === cur[key]) ? acc : [...acc, cur],
      [],
    );

  return array.reduce(
    (acc, cur) => (acc.includes(cur) ? acc : [...acc, cur]),
    [],
  );
};

export const showSpinner = (payload) => {
  const { path } = payload;

  const existedLoadings = getSessionStorage(ACTIVE_LOADINGS) || {};
  existedLoadings[path] = true;

  setSessionStorage({ key: ACTIVE_LOADINGS, value: existedLoadings });
};

export const hideSpinner = (payload) => {
  const { path } = payload;

  const existedLoadings = getSessionStorage(ACTIVE_LOADINGS) || {};
  delete existedLoadings[path];

  setSessionStorage({ key: ACTIVE_LOADINGS, value: existedLoadings });
};

export const inIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

export const isRangesIntersection = (arr1, arr2) => {
  const fullArr1 = new Array(arr1[1] - arr1[0] + 1)
    .fill(0)
    .map((_, index) => arr1[0] + index);
  const fullArr2 = new Array(arr2[1] - arr2[0] + 1)
    .fill(0)
    .map((_, index) => arr2[0] + index);
  const intersectionArray = _.intersection(fullArr1, fullArr2);

  return !!intersectionArray.length;
};
