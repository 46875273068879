import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Button,
  Tooltip,
} from "@mui/material";
import { BsX } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import {
  FacebookShareButton,
  WhatsappShareButton,
  TwitterShareButton,
  EmailShareButton,
  LinkedinShareButton,
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  WhatsappIcon,
  TwitterIcon,
} from "react-share";
import { useSelector, useDispatch } from "react-redux";

import { SHARE_HASHTAGS, SHARE_HASHTAGS_STRING } from "constants/settings";

import { selectShareModalDetails } from "store/selectors/video";
import { setShareModalDetails } from "store/actions/video";

const ICON_SIZE = "45";
const TIMEOUT_TO_SHOW_COPIED_TOOLTIP = 1000;

const StyledContentHolder = styled.div`
  display: grid;
  grid-gap: 15px 19px;
  grid-template-columns: repeat(4, 1fr);
`;

const StyledDialog = styled(Dialog)`
  & .MuiDialog-paperWidthFalse {
    max-width: 285px;
    border-radius: 10px;
  }
`;

const DialogHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DialogFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 24px 16px;
`;

const Url = styled.div`
  flex-grow: 1;
  padding: 11px 13px 10px;
  margin-right: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-radius: 12.5px;
  border: 1px solid ${({ theme }) => theme.lightBorderColor};
  overflow: hidden;
`;

const CopyButton = styled(Button)`
  > span {
    font-size: 16px;
    font-weight: 600;
    color: ${({ theme }) => theme.basicBlue};
  }
`;

const ShareModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isCopied, setIsCopied] = useState(false);

  const shareModalDetails = useSelector(selectShareModalDetails);
  const { url: storeUrl } = shareModalDetails || {};
  const url = storeUrl || "";
  const sharedMessage = `${shareModalDetails?.text} ${SHARE_HASHTAGS_STRING}`;

  const handleTooltipClose = () => {
    setIsCopied(false);
  };

  const onCopy = () => {
    navigator.clipboard.writeText(url).then(() => {
      setIsCopied(true);
    });
  };

  const onCloseModal = () => dispatch(setShareModalDetails());

  useEffect(() => {
    if (isCopied) {
      setTimeout(handleTooltipClose, TIMEOUT_TO_SHOW_COPIED_TOOLTIP);
    }
  }, [isCopied]);

  return (
    <StyledDialog
      open
      onClose={onCloseModal}
      scroll="body"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth={false}
    >
      <DialogHeader>
        <DialogTitle>{t("Share")}</DialogTitle>
        <IconButton onClick={onCloseModal}>
          <BsX />
        </IconButton>
      </DialogHeader>
      <DialogContent>
        <StyledContentHolder>
          <FacebookShareButton
            url={url}
            onShareWindowClose={onCloseModal}
            quote={shareModalDetails?.text}
            hashtag={SHARE_HASHTAGS_STRING}
          >
            <FacebookIcon size={ICON_SIZE} round />
          </FacebookShareButton>

          <WhatsappShareButton
            url={url}
            onShareWindowClose={onCloseModal}
            title={sharedMessage}
          >
            <WhatsappIcon size={ICON_SIZE} round />
          </WhatsappShareButton>
          <TwitterShareButton
            url={url}
            onShareWindowClose={onCloseModal}
            title={shareModalDetails?.text}
            hashtags={SHARE_HASHTAGS}
          >
            <TwitterIcon size={ICON_SIZE} round />
          </TwitterShareButton>
          <EmailShareButton
            url={url}
            onShareWindowClose={onCloseModal}
            body={sharedMessage}
          >
            <EmailIcon size={ICON_SIZE} round />
          </EmailShareButton>
          <LinkedinShareButton
            url={url}
            onShareWindowClose={onCloseModal}
            title={sharedMessage}
          >
            <LinkedinIcon size={ICON_SIZE} round />
          </LinkedinShareButton>
        </StyledContentHolder>
      </DialogContent>
      {url && (
        <DialogFooter>
          <Url>{url}</Url>
          <Tooltip
            title={t("Copied")}
            placement="top"
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleTooltipClose}
            open={isCopied}
            disableFocusListener
            disableHoverListener
            disableTouchListener
          >
            <CopyButton onClick={onCopy}>{t("COPY")}</CopyButton>
          </Tooltip>
        </DialogFooter>
      )}
    </StyledDialog>
  );
};

export default ShareModal;
