import React from "react";
import styled from "styled-components";
import { BounceLoader } from "react-spinners";

const StyledLoaderContainer = styled.div`
  width: fit-content;
  margin: 30px auto;

  & span {
    display: inline-block;

    & > span {
      &:first-child {
        background-color: #dce4ff;
      }
      &:last-child {
        background-color: #dae8ff;
      }
    }
  }
`;

const Loader = () => (
  <StyledLoaderContainer>
    <BounceLoader />
  </StyledLoaderContainer>
);

export default Loader;
