import React from "react";
import styled from "styled-components";

const StyledContainer = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: ${({ isContentWidth }) =>
    isContentWidth ? "initial" : "space-between"};
  width: 100%;
  overflow: visible;
  margin-bottom: 20px;
  gap: ${({ isContentBox }) => (isContentBox ? 20 : 0)}px;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 0;
    display: block;
    width: 100%;
    height: 3px;
    background-color: ${({ theme }) => theme.navbarBackgroundColor};
  }

  & input {
    display: none;
  }

  & label {
    z-index: 3;
    flex-grow: ${({ isContentBox }) => (isContentBox ? 0 : 1)};
    width: ${({ amount, isContentBox }) =>
      isContentBox ? "initial" : `${100 / amount}%`};
    padding: 8px 0 6px;
    font-size: 14px;
    line-height: 1.2;
    text-align: center;
    color: ${({ theme }) => theme.semiGray};
    border-bottom: 3px solid ${({ theme }) => theme.navbarBackgroundColor};
    cursor: pointer;

    &.selected {
      color: ${({ theme, isDarkTheme }) =>
        theme[isDarkTheme ? "darkBasicBgBlue" : "basicBlue"]};
      border-bottom-color: ${({ theme, isDarkTheme }) =>
        theme[isDarkTheme ? "darkBasicBgBlue" : "basicBlue"]};
    }

    &.disabled {
      opacity: 0.5;
      cursor: default;
    }
  }
`;

const Tabs = ({
  dataTest = "",
  options = [],
  isContentBox,
  isDarkTheme = false,
  isContentWidth = false,
  style = {},
  className,
}) => {
  const amount = options.filter(({ visible = true }) => visible).length;

  return (
    <StyledContainer
      data-test={dataTest}
      amount={amount}
      isContentBox={isContentBox}
      isDarkTheme={isDarkTheme}
      isContentWidth={isContentWidth}
      style={style}
      className={className}
    >
      {options.map(
        ({
          checked,
          value,
          onChange,
          text,
          visible = true,
          disabled = false,
        }) =>
          visible ? (
            <label
              key={text || value}
              className={`${checked ? "selected" : ""} ${
                disabled ? "disabled" : ""
              }`}
            >
              <input
                type="checkbox"
                value={value}
                checked={checked}
                onChange={onChange}
                disabled={disabled}
              />
              {text || value}
            </label>
          ) : null,
      )}
    </StyledContainer>
  );
};

export default Tabs;
