import { useEffect } from "react";

const useOutsideClick = (ref, callback) => {
  const handleClick = (e) => {
    e.cancelBubble = true;
    e.stopPropagation();

    if (ref.current && !ref.current.contains(e?.target)) {
      callback(e);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.addEventListener("mousedown", handleClick);
    };
  }, []);
};

export default useOutsideClick;
