import { getUserFullName } from "./user";

export const formatDive = ({
  user,
  combinedUser,
  dive: currentDive,
  totalAward = "",
  averageJudgeAward = "",
  averageTotalAward = "",
  teamName,
  isDropdownVisible,
  canBeMoved,
  checkedIn,
  ...rest
}) => {
  const {
    roundOrder,
    code,
    diveDifficulty,
    id: diveId,
    optional,
  } = currentDive || {};
  const { difficulty, diveHeight, dive, divePosition, customDifficulty } =
    diveDifficulty || {};
  const { name } = dive || {};
  const { meters } = diveHeight || {};
  const {
    id: combinedUserId,
    users,
    teamName: combinedUserTeamName,
  } = combinedUser || {};
  const isSynchro = !Object.keys(user || {}).length && !!combinedUser;
  const fullName = isSynchro
    ? users?.map((user) => getUserFullName(user))
    : user
    ? [getUserFullName(user)]
    : [];
  const resultTeamName = isSynchro ? combinedUserTeamName : teamName;
  const divers = isSynchro ? users : user ? [user] : [];

  return {
    ...rest,
    diveId,
    diverId: user?.id || combinedUserId,
    checkedIn: user?.checkedIn,
    paid: user?.paid,
    orderId: user?.paidEventOrderId,
    number: roundOrder,
    code,
    divePosition,
    difficulty,
    diveName: name,
    fullName,
    height: meters,
    judgesAward: averageJudgeAward,
    diveTotal: averageTotalAward,
    currentTotal: totalAward,
    teamName: resultTeamName,
    isDropdownVisible,
    canBeMoved,
    optional,
    customDifficulty,
    divers,
  };
};

export const divesMapCallback = ({
  user,
  combinedUser,
  dive: currentDive,
  teamName,
  ...rest
}) => {
  const { roundOrder, id: diveId } = currentDive || {};
  const { users, teamName: combinedUserTeamName } = combinedUser || {};
  const isSynchro = !Object.keys(user || {}).length && !!combinedUser;
  const fullName = isSynchro
    ? users?.map((user) => getUserFullName(user))
    : user
    ? [getUserFullName(user)]
    : [];
  const resultTeamName = isSynchro ? combinedUserTeamName : teamName;

  return {
    ...rest,
    diveId,
    number: roundOrder,
    fullName,
    teamName: resultTeamName,
  };
};

export const getDiveData = (schema, propsCode, height) => {
  const code = propsCode || "";
  const current = schema?.find(
    ({ diveHeight: { meters }, code: diveCode }) =>
      +meters === +height && code.toLowerCase() === diveCode.toLowerCase(),
  );
  const name = current?.dive?.name || "";
  const difficulty = current?.difficulty || "";
  const divePosition = current?.divePosition || "";
  const meters = current?.diveHeight?.meters || "";

  return current
    ? {
        divePosition,
        name,
        difficulty,
        customDifficulty: difficulty,
        code: code.toUpperCase(),
        meters,
      }
    : "";
};

export const getEmptyDive = (index) => ({
  id: `empty_${index}`,
  code: "",
  number: +index + 1,
  diveDifficulty: {
    divePosition: "",
    difficulty: "",
    dive: { name: "" },
    diveHeight: { meters: "" },
  },
});

export const updateDive = (
  oldDive,
  {
    code,
    customDifficulty,
    difficulty,
    divePosition,
    name,
    meters,
    isValidHeight,
  },
  height,
) => {
  return {
    ...oldDive,
    code,
    customDifficulty,
    diveDifficulty: {
      divePosition,
      customDifficulty,
      difficulty,
      dive: { name },
      diveHeight: { meters: meters || height },
    },
    isValidHeight,
  };
};

export const getDiveWarnObject = (string = "", separator = ",") =>
  string
    .split(separator)
    .reduce(
      (acc, name, id) =>
        name ? [...acc, { error: name?.trim(), id }] : [...acc],
      [],
    );

export const getJumpSchemaString = (jumpSchema) => {
  if (!jumpSchema) return "";
  const arrVolOpt = jumpSchema.split("-");
  arrVolOpt.shift();
  return arrVolOpt
    .map((group) => {
      // it transforms str "1v-2o-..." to str "voo..."
      const ending = group.slice(group.length - 1);
      const number = group.slice(0, group.length - 1);
      return ending.repeat(+number);
    })
    .join("");
};

const getExcluded = (awards = []) => {
  const unExcluded = awards.filter(({ excluded }) => !excluded);

  if (unExcluded.length <= 3) return awards;

  const unExcludedNumbers = unExcluded.map(({ score }) => score);

  const min = Math.min(...unExcludedNumbers);
  const max = Math.max(...unExcludedNumbers);

  const minIndex = awards.findIndex(
    ({ score, excluded }) => !excluded && score === min,
  );
  const maxIndex = awards.findIndex(
    ({ score, excluded }) => !excluded && score === max,
  );

  const newAwards = awards.map((award, index) =>
    [minIndex, maxIndex].includes(index) ? { ...award, excluded: true } : award,
  );

  return getExcluded(newAwards);
};

export const getDiveTotal = ({ awards = [], dive }) => {
  const { customDifficulty } = dive;

  const diveTotal =
    getExcluded(awards)
      .filter(({ excluded }) => !excluded)
      .reduce((acc, { score }) => acc + score, 0) * customDifficulty;

  return awards.length === 2 ? diveTotal * 1.5 : diveTotal;
};
