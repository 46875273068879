import React from "react";
import styled from "styled-components";

import Base from "./Base";

const StyledButton = styled(Base)`
  width: 20px;
  height: 30px;
  font-size: 20px;
  flex-direction: column;
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.disabledBtnBg : "inherit"};
  color: ${({ theme }) => theme.semiGray};
  border-radius: 5px;

  & svg {
    margin: -4px;
  }

  &:hover {
    box-shadow: 0 0 8px 0
      ${({ disabled, theme }) =>
        disabled ? "transparent" : theme.grayButtonHover};
    background: ${({ theme, disabled }) =>
      disabled ? `${theme.disabledBtnBg}bf` : theme.grayButtonHover};
  }
`;

const VerticalDots = (props) => <StyledButton {...props} />;

export default VerticalDots;
