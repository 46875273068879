import React from "react";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";

import diveLogo from "assets/images/loginScene/dive-logo.png";
import wavesBottom from "assets/images/waves-bottom.png";

const StyledLoginPageLayout = styled.main`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: ${({ theme }) =>
    theme.readingStartDirection === "left" ? "row" : "row-reverse"};
  background: ${({ theme }) => theme.basicBackGroundColor} url(${wavesBottom})
    bottom no-repeat;
  background-size: 100% 50%;
`;

const StyledContentPlaceholder = styled.section`
  background: ${({ theme }) => theme.red}00;
  position: relative;
  height: ${({ height }) => height || "567px"};
  padding: 0;
  box-shadow: 0 2px 20px 0 rgba(${({ theme }) => theme.basicBgRevertRgb}, 0.2);
  ${breakpoint("xs")`
    width: 90%;
  `};
  ${breakpoint("sm")`
    width: 65%;
  `};
  ${breakpoint("md")`
    max-width: 903px;
    width: 95%;
  `};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  //TODO? manage vertical overflow to fit small screens
  overflow: auto;
`;

const StyledDecorationsContainer = styled.div`
  width: 26%;
  height: 100%;
  background: ${({ theme }) => theme.darkBasicBgBlue};
  position: relative;
  ${breakpoint("xs")`
    display: none;
  `};
  ${breakpoint("md")`
    display: initial;
  `};
`;

const StyledLogoContainer = styled.div`
  border-radius: 100%;
  background: ${({ theme }) => theme.darkBasicBgBlue};
  position: absolute;
  top: calc(50% - 92px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${breakpoint("sm")`
    display: none;
  `};
  ${breakpoint("md")`
    width: 180px;
    height: 180px;
    right: -90px;
    display: flex;
  `};
`;

const StyledDiveLogo = styled.img`
  position: relative;
  right: 50px;
  top: -10px;
  height: 100px;
`;

const AuthLayout = ({ height, children }) => (
  <StyledLoginPageLayout>
    <StyledContentPlaceholder height={height}>
      <StyledDecorationsContainer>
        <StyledLogoContainer>
          <StyledDiveLogo src={diveLogo} alt="logo" />
        </StyledLogoContainer>
      </StyledDecorationsContainer>
      {children}
    </StyledContentPlaceholder>
  </StyledLoginPageLayout>
);

export default AuthLayout;
