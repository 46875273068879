import { toast } from "react-toastify";
import { format } from "date-fns";

import { baseURL } from "constants/env";
import { ROLES } from "constants/types";
import { ERR_MSG } from "constants/errors";
import { actionFulfilled, USER_LOG_OUT } from "store/actionTypes";
import { ROUTE } from "constants/routing";
import { USER_AGE } from "constants/user";

import { getTimezone } from "helpers/time";

const {
  DIVER,
  HEAD_COACH,
  PARENT,
  COACH,
  TEAM_ADMIN,
  SWIM_COACH,
  ALUMNUS,
  SWIMMER,
} = ROLES;

export const getUserFullName = ({ firstName, lastName, userInfo }) => {
  const FName = firstName || userInfo?.firstName || "";
  const LName = lastName || userInfo?.lastName || "";

  return `${FName}${LName ? ` ${LName}` : ""}`;
};

export const getAvatarUrl = ({ cloudAlias, userInfo }) =>
  cloudAlias || userInfo?.cloudAlias;

export const getUserId = ({ id, userInfo } = {}) => {
  if (id === 0 || id === "0") return id?.toString();
  if (userInfo?.id === 0 || userInfo?.id === "0") return userInfo?.toString();
  return (id || userInfo?.id)?.toString() || "";
};

export const emplyArray = (n = 1) => {
  const array = new Array(n);
  return [...array.fill({})];
};

export const isMobile =
  (window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth) < 576;

export const openTab = (url) => {
  setTimeout(() => {
    try {
      window.appInterface.postMessage(`link_${url}`);
    } catch (err) {
      console.warn(ERR_MSG().NOT_ANDROID);

      window.open(url, "_blank")?.focus();
    }
  });
};

const openEmptySheet = (eventId) => {
  if (!eventId) return;

  const link = `${baseURL}pdf/files/empty/dive/sheets/${eventId}?zone=${getTimezone()}`;
  openTab(link);
};

export const openAllDiveSheets = (eventId) => {
  if (!eventId) return;

  const link = `${baseURL}pdf/files/dive/sheets/all/${eventId}?zone=${getTimezone()}`;
  openTab(link);
};

const openPrefilledSheet = (diveSheetId, isPrefilledWithDives) => {
  if (!diveSheetId) return;

  const link = `${baseURL}pdf/files/dive/sheets/${diveSheetId}?empty=${Boolean(
    isPrefilledWithDives,
  )}&zone=${getTimezone()}`;
  openTab(link);
};

export const openDivingOrderWithLists = (eventId) => {
  if (!eventId) return;

  const link = `${baseURL}pdf/files/events/results/${eventId}`;
  openTab(link);
};

export const openResultsByBoard = (eventId) => {
  if (!eventId) {
    toast.warn("Event ID is empty");
    return;
  }

  const link = `${baseURL}pdf/files/events/results/${eventId}/board`;
  openTab(link);
};

const openStartListWithDescription = (eventId) => {
  if (!eventId) return;

  const link = `${baseURL}pdf/files/events/start-lists/${eventId}`;
  openTab(link);
};

const openStartListWithoutDescription = (eventId) => {
  if (!eventId) return;

  const link = `${baseURL}pdf/files/events/start-lists-cut/${eventId}`;
  openTab(link);
};

export const getSheetPrintItems = ({ diveSheetId, eventId }, t) => [
  {
    route: "#",
    id: `PrintSheet_${diveSheetId}`,
    text: t("Print sheet"),
    disabled: !diveSheetId,
    callback: () => {
      openPrefilledSheet(diveSheetId);
    },
  },
  {
    route: "#",
    id: `PrintBlankSheet_${eventId}`,
    text: t("Print blank sheet"),
    disabled: !eventId,
    callback: () => {
      openPrefilledSheet(diveSheetId, true);
    },
  },
];

const printRounds = () => {
  const now = new Date();
  const printedTime = document.querySelector(".printedTime");
  printedTime.innerText = `Printed: ${format(now, "MM-dd-yyyy HH:mm")}`;

  window.print();
};

export const getAdministrationPrintItems = (t, currentEvent, mainEvent) => {
  const { id: eventId, splitted } = currentEvent;

  if (!eventId) return [];

  const events = mainEvent
    ? [mainEvent, ...(mainEvent.splittedEvents || [])]
    : [currentEvent, ...(currentEvent.splittedEvents || [])];

  const printItems = events.map(({ id, splitBoardName, eventColour }) => ({
    route: "#",
    id: `DiveSheets_${id}`,
    text: t("Dive sheets "),
    disabled: !id,
    callback: () => openAllDiveSheets(id),
    board: splitted
      ? {
          color: eventColour,
          name: splitBoardName,
        }
      : null,
  }));

  return [
    ...printItems,
    {
      route: "#",
      id: `DiveSheetBlank_${eventId}`,
      text: t("Dive sheet (blank)"),
      disabled: !eventId,
      callback: () => openEmptySheet(eventId),
    },
    {
      route: "#",
      id: `ResultsMerged_${eventId}`,
      text: t("Results merged"),
      disabled: !eventId,
      callback: () => openDivingOrderWithLists(eventId),
    },
    {
      route: "#",
      id: `ResultsByBoard_${eventId}`,
      text: t("Results by board"),
      disabled: !eventId || !splitted,
      callback: () => openResultsByBoard(eventId),
    },
    {
      route: "#",
      id: `StartListWithDescription_${eventId}`,
      text: t("Start list with description"),
      disabled: !eventId,
      callback: () => openStartListWithDescription(eventId),
    },
    {
      route: "#",
      id: `StartListWithoutDescription_${eventId}`,
      text: t("Start list without description"),
      disabled: !eventId,
      callback: () => openStartListWithoutDescription(eventId),
    },
    {
      route: "#",
      id: `ScribeSheet`,
      text: t("Scribe sheet"),
      callback: () => printRounds(),
    },
    {
      route: "#",
      id: `ScribeSheetBlank`,
      text: t("Scribe sheet (blank)"),
      callback: () => {
        const meetsWrapper = document.querySelector(".meetsWrapper");
        meetsWrapper.classList.add("printedBlank");

        printRounds();

        meetsWrapper.classList.remove("printedBlank");
      },
    },
  ];
};

export const getRoleName = (role, t) => {
  switch (role) {
    case DIVER:
      return t("diver");
    case HEAD_COACH:
      return t("head diving coach");
    case PARENT:
      return t("parent");
    case COACH:
      return t("diving coach");
    case TEAM_ADMIN:
      return t("team administrator");
    case SWIM_COACH:
      return t("swim coach");
    case ALUMNUS:
      return t("alum");
    case SWIMMER:
      return t("swimmer");
    default:
      return "...";
  }
};

export const isMobileDevice = () => {
  let check = false;

  /* eslint-disable no-useless-escape */
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a,
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4),
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  /* eslint-enable */

  return check;
};

export const isAndroid = () => {
  const ua = navigator.userAgent.toLowerCase();
  const check = ua.indexOf("android") > -1;

  return check;
};

export const isPixel3XLApp = () => {
  const ua = navigator.userAgent.toLowerCase();
  const check = ua.indexOf("pixel 3 xl build/") > -1;

  return check;
};

export const isTouchDevice = () =>
  "ontouchstart" in window ||
  navigator.maxTouchPoints > 0 ||
  navigator.msMaxTouchPoints > 0;

export const logOutUser = (dispatch, isRefresh = true) => {
  dispatch({ type: actionFulfilled(USER_LOG_OUT) });

  isRefresh && window.location.assign(ROUTE.LOGIN);
};

export const getUserAge = (dob) => {
  const today = new Date();
  const birthDate = new Date(dob);

  let age = today.getFullYear() - birthDate.getFullYear();
  let m = today.getMonth() - birthDate.getMonth();

  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  return age;
};

export const validateMinUserAge = (dob) => {
  if (!dob || dob === "Invalid Date") return true;

  const age = getUserAge(dob);

  return age >= USER_AGE.MIN;
};

export const validateMaxUserAge = (dob) => {
  if (!dob || dob === "Invalid Date") return true;

  const age = getUserAge(dob);

  return age <= USER_AGE.MAX;
};

export const validateUserAge = ({ dob, min, max }) => {
  if (!dob) return false;

  const age = getUserAge(dob);

  return age >= min && age <= max;
};
