import { toast } from "react-toastify";

import { GCP_BUCKET_UPLOAD_URL } from "constants/env";
import {
  USER_LOG_IN,
  USER_REGISTRATION,
  GET_CURRENT_USER_DETAILS,
  GET_CUSTOM_USER_DETAILS,
  UPLOAD_VIDEO,
  GET_CURRENT_USER_SUBSCRIPTION_PLAN,
  JUDGE_SIGN_IN_BY_CODE,
  JUDGE_SIGN_IN_BY_QR_CODE,
  GET_CURRENT_JUDGE_DETAILS,
  UPDATE_CURRENT_JUDGE_DETAILS,
  GET_CURRENT_EVENT_INFORMATION_FOR_JUDGE,
  GET_AAU_CURRENT_USER_INFO,
  DELETE_PROFILE,
  UPLOAD_USER_PROFILE,
  UPLOAD_USER_BG_PHOTO,
  PASSWORD_CHANGE,
  LOG_OUT_UNREGISTRED_JUDGE,
  LOG_OUT_REGISTRED_JUDGE,
  FOLLOW_USER,
  EMAIL_VERIFY,
} from "store/actionTypes";

import { hideSpinner } from "helpers/general";
import {
  userLogInRequest,
  userRegistrationRequest,
  getCurrentUserInfoRequest,
  getCustomUserInfoRequest,
  getCurrentSubscriptionPlanRequest,
  judgeSignInByCodeRequest,
  judgeSignInByQRCodeRequest,
  getCurrentJudgeInfoRequest,
  judgeLogoutRequest,
  getCurrentEventInformationForJudgeRequest,
  getAAUMembership,
  deleteProfileRequest,
  passwordChangeRequest,
  emailVerifyRequest,
} from "API/user";
import {
  uploadFileToGCP,
  postVideoInfo,
  sendProfilePhoto,
  sendProfileBgPhoto,
} from "API/files";
import { followDiverRequest, unfollowDiverRequest } from "API/divers";
import { getDiveVideos } from "store/actions/leadership";

export const userLogIn = (data) => ({
  type: USER_LOG_IN,
  payload: userLogInRequest(data),
});

export const passwordChange = ({ successCallback = () => {}, ...data }) => ({
  type: PASSWORD_CHANGE,
  payload: passwordChangeRequest(data).then(successCallback),
});

export const logoutUnregisteredJudge = (token, noAppVerification) => ({
  type: LOG_OUT_UNREGISTRED_JUDGE,
  payload: judgeLogoutRequest(token, noAppVerification),
});

export const logoutRegisteredJudge = (data, successCallback = () => {}) => ({
  type: LOG_OUT_REGISTRED_JUDGE,
  payload: judgeLogoutRequest(data).then((response) => {
    successCallback();

    return response;
  }),
});

export const judgeSignInByCode = (
  data,
  successCallback = () => {},
  config = {},
) => ({
  type: JUDGE_SIGN_IN_BY_CODE,
  payload: judgeSignInByCodeRequest(data, config).then((response) => {
    successCallback();

    return response;
  }),
});

export const judgeSignInByQRCode = ({
  successCallback = () => {},
  errorCallback,
  ...data
}) => ({
  type: JUDGE_SIGN_IN_BY_QR_CODE,
  payload: judgeSignInByQRCodeRequest(data, { onError: errorCallback }).then(
    (data) => {
      successCallback(data);

      return data;
    },
  ),
});

export const userRegistration = (data, callback = () => {}) => {
  return {
    type: USER_REGISTRATION,
    payload: userRegistrationRequest(data).then((data) => {
      callback();

      return data;
    }),
  };
};

export const getCurrentUserInfo = ({
  successCallback = () => {},
  errorCallback = () => {},
}) => ({
  type: GET_CURRENT_USER_DETAILS,
  payload: getCurrentUserInfoRequest({ onError: errorCallback }).then(
    (response) => {
      successCallback();

      return response;
    },
  ),
});

export const getAAUCurrentUserInfo = () => ({
  type: GET_AAU_CURRENT_USER_INFO,
  payload: getAAUMembership(),
});

export const getCurrentJudgeInfo = ({ eventId, errorCallback }) => ({
  type: GET_CURRENT_JUDGE_DETAILS,
  payload: getCurrentJudgeInfoRequest(eventId, { onError: errorCallback }),
});

export const updateCurrentJudgeInfo = (payload) => ({
  type: UPDATE_CURRENT_JUDGE_DETAILS,
  payload,
});

export const getCurrentEventInformationForJudge = (token) => {
  return {
    type: GET_CURRENT_EVENT_INFORMATION_FOR_JUDGE,
    payload: getCurrentEventInformationForJudgeRequest(token),
  };
};

export const getCustomUserInfo = (userId) => ({
  type: GET_CUSTOM_USER_DETAILS,
  payload: getCustomUserInfoRequest(userId),
});

export const uploadUserProfile = (data) => ({
  type: UPLOAD_USER_PROFILE,
  payload: sendProfilePhoto(data),
});

export const uploadUserBgPhoto = (data) => ({
  type: UPLOAD_USER_BG_PHOTO,
  payload: sendProfileBgPhoto(data),
});

export const uploadDiveVideo =
  ({
    data,
    token,
    diveId,
    name,
    ownerTeamId,
    withSpinner,
    successMessage = "The video has been uploaded successfully",
  }) =>
  (dispatch) => {
    const url = GCP_BUCKET_UPLOAD_URL();

    return {
      type: UPLOAD_VIDEO,
      payload: uploadFileToGCP({ data, token, name, url }).then(async () => {
        withSpinner && hideSpinner({ path: name });
        await postVideoInfo(diveId, { name, ownerTeamId }, { withSpinner });
        dispatch(getDiveVideos(diveId, { withSpinner }));

        toast.success(successMessage);
      }),
    };
  };

export const getCurrentSubscriptionPlan = () => ({
  type: GET_CURRENT_USER_SUBSCRIPTION_PLAN,
  payload: getCurrentSubscriptionPlanRequest(),
});

export const deleteProfile = () => ({
  type: DELETE_PROFILE,
  payload: deleteProfileRequest().then((response) => {
    toast.success("User deleted successfully");

    return response;
  }),
});

export const followUser = ({ followedUserId, user }) => ({
  type: FOLLOW_USER,
  payload: followDiverRequest(followedUserId).then(() => ({
    follow: true,
    user,
  })),
});

export const unfollowUser = ({ followedUserId, user }) => ({
  type: FOLLOW_USER,
  payload: unfollowDiverRequest(followedUserId).then(() => ({
    follow: false,
    user,
  })),
});

export const emailVerify = (
  data,
  successCallback = () => {},
  errorCallback,
) => ({
  type: EMAIL_VERIFY,
  payload: emailVerifyRequest(data, { onError: errorCallback }).then(
    (response) => {
      successCallback(response);

      return response;
    },
  ),
});
