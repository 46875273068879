import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import breakpoint from "styled-components-breakpoint";
import { useHistory } from "react-router";

import { ROUTE } from "constants/routing";

import { getFormattedDate } from "helpers/time";
import { getRoleName } from "helpers/user";

import AvatarWithDefaultImage from "Components/AvatarWithDefaultImage";
import Divider from "./Divider";

const JoinedItemHolder = styled.div`
  display: flex;
  margin: 5px 20px 14px 20px;
`;
const StyledFullname = styled.span`
  margin-bottom: 3px;
  color: #222222;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 1;
  cursor: ${({ onClick }) => (onClick ? "pointer" : "default")};
`;
const StyledUserDataHolder = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: -moz-available;
  width: -webkit-fill-available;
  width: fill-available;
  ${breakpoint("sm")`width: 290px`}

  & .additional {
    color: #9b9b9b;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 1.2;
  }
`;

const JoinedTeamItem = ({ info = {} }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const { user, teamName, createdDate, role } = info?.jointDiverList || {};
  const { firstName, lastName, cloudAlias, id: userId } = user || {};

  const joinTeamData = `${t("Joined ")} ${teamName} ${t("as")} ${getRoleName(
    role,
    t,
  )} ${t("at")} ${getFormattedDate({
    date: createdDate,
    dateFormat: "hh:mm aa",
    useOffset: true,
  })} ${t("on")} ${getFormattedDate({
    date: createdDate,
    dateFormat: "MMM. d, y",
    useOffset: true,
  })}`;

  const onUserClick = (userId) => {
    return userId ? () => history.push(ROUTE.PROFILE(userId)) : "";
  };

  return (
    <React.Fragment>
      <JoinedItemHolder>
        <AvatarWithDefaultImage
          src={cloudAlias}
          isSmall={false}
          key={cloudAlias}
          onAvatarClick={onUserClick(userId)}
        />
        <StyledUserDataHolder>
          <StyledFullname
            onClick={onUserClick(userId)}
          >{`${firstName} ${lastName}`}</StyledFullname>
          <span className="additional">{joinTeamData}</span>
        </StyledUserDataHolder>
      </JoinedItemHolder>
      <Divider />
    </React.Fragment>
  );
};

export default JoinedTeamItem;
