import {
  SET_EVENT_LEADERSHIP_ROUND,
  SET_NEXT_DIVER_ROUND,
  SET_IS_OPENED_SLIDER,
  SET_FOCUSED_DIVER,
} from "store/actionTypes";
import { getFutureDive, getCurrentDiver } from "helpers/leadershipRound";

const initState = {
  round: {
    events: [],
  },
  currentDiver: {
    roundNumber: "",
    roundOrder: "",
    place: "",
    user: {
      cloudAlias: "",
      name: "",
      teamName: "",
    },
    diveName: "",
    code: "",
    height: "",
    position: "",
    dd: "",
    score: {
      totalAward: "",
      roundAward: "",
      awards: [],
    },
  },
  focusedRoundNumber: "",
  isOpenedSlider: false,
};

const leadershipRoundReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case SET_EVENT_LEADERSHIP_ROUND:
      return {
        ...state,
        round: payload,
      };
    case SET_NEXT_DIVER_ROUND:
      const nextDive = getFutureDive(payload);

      return {
        ...state,
        currentDiver: getCurrentDiver(payload, nextDive, true),
      };
    case SET_IS_OPENED_SLIDER:
      return {
        ...state,
        isOpenedSlider: payload,
      };
    case SET_FOCUSED_DIVER:
      return {
        ...state,
        focusedRoundNumber: payload,
      };
    default:
      return state;
  }
};

export default leadershipRoundReducer;
