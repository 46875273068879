import { useRouteMatch } from "react-router-dom";

import { ROUTE } from "constants/routing";
import { LEADERSHIP_MODES } from "constants/leadershipModes";

const useRouts = () => {
  // MeetAdminRouts
  const isRegisterDivers = useRouteMatch(
    ROUTE.MEETS_REGISTER(":meetId"),
  )?.isExact;
  const isAddDivers = useRouteMatch(
    ROUTE.MEETS_REGISTER_ADD(":meetId"),
  )?.isExact;
  const isRegisterDiversOnEvent = useRouteMatch(
    ROUTE.MEETS_REGISTER_ON_EVENT(":meetId", ":eventId"),
  )?.isExact;
  const isEnterSheet = useRouteMatch(
    ROUTE.MEETS_REGISTER_ENTER_SHEET(":meetId", ":eventId", ":diverId"),
  )?.isExact;
  const isRegisterJudges = useRouteMatch(
    ROUTE.MEETS_JUDGES(":meetId", ":routeTab", ":eventId"),
  )?.isExact;
  const isEventsList = useRouteMatch(ROUTE.MEETS_EVENTS(":meetId"))?.isExact;
  const isRounds = useRouteMatch(
    ROUTE.MEET_EVENT_ROUNDS(":meetId", ":eventId"),
  )?.isExact;
  const isSendMessages = useRouteMatch(
    ROUTE.MEETS_MESSAGES(":meetId"),
  )?.isExact;
  const isFinals = useRouteMatch(ROUTE.MEETS_FINALS(":meetId"))?.isExact;

  // JudgeModeRouts
  const isLoginDetailsPage = useRouteMatch(ROUTE.LOGIN_DETAILS)?.isExact;
  const isJudgeMode = useRouteMatch(
    ROUTE.JUDGE_MODE(":meetId", ":eventId"),
  )?.isExact;
  const isSwitchJudgePosition = useRouteMatch(
    ROUTE.SWITCH_JUDGE_POSITION(":meetId", ":eventId"),
  )?.isExact;
  const isSwitchDiverPosition = useRouteMatch(
    ROUTE.SWITCH_DIVER_POSITION(":meetId", ":eventId"),
  )?.isExact;

  // CreateMeetRouts
  const isCreateMeet = useRouteMatch(ROUTE.CREATE_MEET)?.isExact;
  const isEditMeet = useRouteMatch(
    ROUTE.EDIT_MEET(":meetId", ":step"),
  )?.isExact;

  // ProfileRouts
  const isProfile = useRouteMatch(ROUTE.PROFILE(":userId"))?.isExact;
  const isProfileEdit = useRouteMatch(ROUTE.PROFILE_EDIT)?.isExact;
  const isProfileSettings = useRouteMatch(ROUTE.PROFILE_SETTINGS)?.isExact;
  const isProfileSubscriptions = useRouteMatch(
    ROUTE.PROFILE_SUBSCRIPTIONS,
  )?.isExact;
  const isProfileMembership = useRouteMatch(ROUTE.PROFILE_MEMBERSHIP)?.isExact;
  const isProfileUsers = useRouteMatch(ROUTE.PROFILE_USERS(":userId"))?.isExact;
  const isChangePassword = useRouteMatch(ROUTE.CHANGE_PASSWORD)?.isExact;
  const isProfileRecruiting = useRouteMatch(ROUTE.PROFILE_RECRUITING)?.isExact;

  // DiveSheetsRouts
  const isMeetRegistration = useRouteMatch(
    ROUTE.MEET_REGISTRATION(":meetId"),
  )?.isExact;
  const isDiveSheets = useRouteMatch(
    ROUTE.DIVE_SHEETS(":meetId", ":eventId"),
  )?.isExact;
  const isDiveSheetsSynchro = useRouteMatch(
    ROUTE.DIVE_SHEETS_SYNCHRO(":meetId", ":eventId"),
  )?.isExact;
  const isSynchroDiveSheet = useRouteMatch(
    ROUTE.SYNCHRO_DIVE_SHEET(":meetId", ":eventId", ":combinedUserId"),
  )?.isExact;
  const isDiveCode = useRouteMatch(
    ROUTE.DIVE_CODE(":meetId", ":eventId", ":diveNumber"),
  )?.isExact;
  const isSynchroDiveCode = useRouteMatch(
    ROUTE.SYNCHRO_DIVE_CODE(
      ":meetId",
      ":eventId",
      ":combinedUserId",
      ":diveNumber",
    ),
  )?.isExact;
  const isDiverDiveSheets = useRouteMatch(
    ROUTE.DIVER_DIVE_SHEETS(":meetId", ":eventId"),
  )?.isExact;
  const isDiverDiveSheetsSynchro = useRouteMatch(
    ROUTE.DIVER_DIVE_SHEETS_SYNCHRO(":meetId", ":eventId"),
  )?.isExact;
  const isSynchroDiverDiveSheet = useRouteMatch(
    ROUTE.SYNCHRO_DIVER_DIVE_SHEET(":meetId", ":eventId", ":combinedUserId"),
  )?.isExact;
  const isDiverDiveCode = useRouteMatch(
    ROUTE.DIVER_DIVE_CODE(":meetId", ":eventId", ":diveNumber"),
  )?.isExact;
  const isSynchroDiverDiveCode = useRouteMatch(
    ROUTE.SYNCHRO_DIVER_DIVE_CODE(
      ":meetId",
      ":eventId",
      ":combinedUserId",
      ":diveNumber",
    ),
  )?.isExact;

  // MeetInfoRouts
  const isMeetShort = useRouteMatch(ROUTE.MEET_SHORT(":meetId"))?.isExact;
  const { isExact: isLeaderShip, params = {} } =
    useRouteMatch(
      ROUTE.LEADERSHIP(":eventId", ":screenName", ":extraScreenInfo"),
    ) || {};
  const isMeetEventGrid = useRouteMatch(
    ROUTE.MEET_EVENT_GRID(":meetId", ":eventId"),
  )?.isExact;
  const isAnnouncerView = useRouteMatch(
    ROUTE.MEET_EVENT_ANNOUNCER_VIEW(":meetId", ":eventId"),
  )?.isExact;
  const isDiverView =
    params.screenName === LEADERSHIP_MODES.currentRound &&
    params.extraScreenInfo === LEADERSHIP_MODES.diverView;
  const isJumbotronView = useRouteMatch(
    ROUTE.MEET_EVENT_JUMBOTRON_VIEW(":meetId", ":eventId"),
  )?.isExact;
  const isLeaderboardView = useRouteMatch(
    ROUTE.MEET_EVENT_LEADERBOARD_VIEW(":meetId", ":eventId"),
  )?.isExact;

  // SearchRouts
  const isSearch = useRouteMatch(ROUTE.SEARCH)?.isExact;
  const isSearchDiver = useRouteMatch(ROUTE.SEARCH_DIVER)?.isExact;
  const isSearchTeam = useRouteMatch(ROUTE.SEARCH_TEAM)?.isExact;
  const isSearchMeet = useRouteMatch(ROUTE.SEARCH_MEET)?.isExact;

  // TeamRouts
  const isGroup = useRouteMatch(ROUTE.GROUP(":teamId"))?.isExact;
  const isJoinTeamRequest = useRouteMatch(
    ROUTE.JOIN_TEAM_REQUEST(":id"),
  )?.isExact;
  const isGroupInvite = useRouteMatch(
    ROUTE.INVITE_TEAM_MEMBER(":teamId", ":userId"),
  )?.isExact;
  const isGroupMergeAccounts = useRouteMatch(
    ROUTE.MERGE_ACCOUNTS(":teamId"),
  )?.isExact;
  const isTeamFollowers = useRouteMatch(
    ROUTE.TEAM_FOLLOWERS(":teamId"),
  )?.isExact;

  return {
    isMeetAdminRouts:
      isRegisterDivers ||
      isAddDivers ||
      isRegisterDiversOnEvent ||
      isEnterSheet ||
      isRegisterJudges ||
      isEventsList ||
      isRounds ||
      isSendMessages ||
      isFinals,
    isJudgeMode,
    isJudgeModeRouts:
      isJudgeMode || isSwitchJudgePosition || isSwitchDiverPosition,
    isJudgeModeRoutsFull:
      isLoginDetailsPage ||
      isJudgeMode ||
      isSwitchJudgePosition ||
      isSwitchDiverPosition,
    isTeamRouts:
      isGroup ||
      isJoinTeamRequest ||
      isGroupInvite ||
      isGroupMergeAccounts ||
      isTeamFollowers,
    isGroupInvite,
    isGroupMergeAccounts,
    isCreateMeetRouts: isCreateMeet || isEditMeet,
    isProfileRouts:
      isProfile ||
      isProfileEdit ||
      isProfileSettings ||
      isProfileSubscriptions ||
      isProfileMembership ||
      isProfileUsers ||
      isChangePassword ||
      isProfileRecruiting,
    isMeetRegistration,
    isDiveSheetsRouts:
      isMeetRegistration ||
      isDiveSheets ||
      isDiveSheetsSynchro ||
      isSynchroDiveSheet ||
      isDiveCode ||
      isSynchroDiveCode ||
      isDiverDiveSheets ||
      isDiverDiveSheetsSynchro ||
      isSynchroDiverDiveSheet ||
      isDiverDiveCode ||
      isSynchroDiverDiveCode,
    isMeetShort,
    isLeaderShip,
    isDiverView,
    isAnnouncerView,
    isJumbotronView: isJumbotronView || isLeaderboardView,
    isMeetEventGrid,
    isMeetInfoRouts:
      isMeetShort ||
      isLeaderShip ||
      isMeetEventGrid ||
      isAnnouncerView ||
      isJumbotronView ||
      isLeaderboardView,
    isSearchRouts: isSearch || isSearchDiver || isSearchTeam || isSearchMeet,
  };
};

export default useRouts;
