import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const StyledLink = styled(Link)`
  font-size: 14px;
  letter-spacing: 0;
  line-height: 14px;
  color: ${({ theme }) => theme.basicBlue};
  cursor: pointer;
`;

const StyledP = styled.p`
  color: ${({ theme }) => theme.semiGray};
  font-size: 14px;
  margin-top: 20px;
  padding: 5px 10px;
`;

const SignUpAndLogin = ({ text, linkRoute, linkText }) => (
  <StyledP>
    {text} <StyledLink to={linkRoute}>{linkText}</StyledLink>
  </StyledP>
);

export default SignUpAndLogin;
