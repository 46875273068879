import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import InfiniteScroll from "react-infinite-scroll-component";

const InfiniteScrollContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow-y: auto;
  height: 0px;
`;

const InfiniteScrollContainer = ({
  wrapperRef,
  customWrapRef,
  dataLength,
  next,
  hasMore,
  loader = <div />,
  endMessage,
  topContent = null,
  bottomContent = null,
  children,
  noResults = null,
  dataTest,
  scrollableTarget = "scrollableDiv",
}) => {
  const scrollableContentRef = useRef();

  const [wrapHeight, setWrapHeight] = useState(0);
  const [contentHeight, setContentHeight] = useState(0);

  useEffect(() => {
    const wrapRef = customWrapRef || wrapperRef;

    if (!wrapRef?.current || !scrollableContentRef?.current) return;

    setWrapHeight(wrapRef?.current?.clientHeight || 0);
    setContentHeight(scrollableContentRef?.current?.clientHeight || 0);
  }, [dataLength]);

  useEffect(() => {
    const wrapRef = customWrapRef || wrapperRef;

    if (!wrapRef?.current || !scrollableContentRef?.current) return;

    if (wrapHeight > contentHeight && dataLength) {
      next();
    }
  }, [wrapHeight, contentHeight]);

  return (
    <InfiniteScrollContainerWrapper
      ref={wrapperRef}
      id={scrollableTarget}
      data-test={dataTest}
    >
      {topContent}
      {dataLength ? (
        <InfiniteScroll
          dataLength={dataLength}
          next={next}
          hasMore={hasMore}
          loader={loader}
          scrollableTarget={scrollableTarget}
          endMessage={endMessage}
        >
          <div ref={scrollableContentRef}>{children}</div>
        </InfiniteScroll>
      ) : (
        noResults
      )}
      {bottomContent}
    </InfiniteScrollContainerWrapper>
  );
};

export default InfiniteScrollContainer;
