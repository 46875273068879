import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { BsX } from "react-icons/bs";
import breakpoint from "styled-components-breakpoint";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import { IS_IOS } from "constants/settings";
import { ROUTE } from "constants/routing";
import { PROFILE_EDIT_TABS } from "Scenes/ProfileEdit/constants";

import { userEmailSelector } from "store/selectors/user";
import { verificationStartRequest } from "API/user";
import { convertObjectToQueryParams } from "helpers/general";

import Button from "Components/Button";

const StyledWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  height: ${({ isOpen }) =>
    isOpen ? `calc(100vh - ${IS_IOS ? 72 : 60}px)` : 0};
  background-color: rgba(39, 46, 61, 0.32);
  overflow: hidden;
  transition: all 0.2s linear;

  ${breakpoint("sm")`
    height: ${({ isOpen }) => (isOpen ? "100vh" : 0)};
  `};
`;

const StyledContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: ${({ isOpen }) => (isOpen ? 70 : 0)}%;
  background-color: ${({ theme }) => theme.basicBackGroundColor};
  border-radius: 15px 15px 0 0;
  transition: all 0.4s ease-in-out;
  filter: drop-shadow(0px -4px 6px rgba(64, 79, 89, 0.12));
`;

const StyledForm = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 20px;
  box-sizing: border-box;
`;

const FilterHeader = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 15px;
  padding: 0 20px;
  margin-bottom: 20px;
`;

const StyledH2 = styled.h2`
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
`;

const CloseIcon = styled.button`
  padding: 0;
  color: ${({ theme }) => theme.accentTextColor};
  border: none;
  background: transparent;
  cursor: pointer;
`;

const FormContent = styled.div`
  flex: 1 1 auto;
  height: 0px;
  overflow: hidden auto;
  padding: 0 20px 20px;
  line-height: 1.5;
  font-size: 16px;

  b {
    font-weight: 700;
  }

  ul {
    padding-left: 30px;
    margin: 20px 0;
    list-style: disc;
  }

  li {
    margin: 5px 0;
  }

  a {
    color: ${({ theme }) => theme.basicBlue};

    :hover {
      text-decoration: none;
    }
  }

  label {
    font-size: 16px;

    ::before {
      top: 2px;
    }

    ::after {
      top: 4px;
    }
  }
`;

const EmailVerificationModal = ({ isOpen, setIsOpen }) => {
  const { t } = useTranslation();
  const containerRef = useRef();
  const listContainerRef = useRef();

  const email = useSelector(userEmailSelector);

  const onClose = () => {
    setIsOpen(false);
    listContainerRef.current?.scrollTo(0, 0);
  };

  const onClickWrapper = (e) => {
    const element = e.target;
    const role = element.getAttribute("data-role");

    if (role === "wrapper") onClose();
  };

  const onSubmit = () => {
    verificationStartRequest().then(() => {
      toast.success(
        "Verification email has been sent. Please check your spam folder.",
      );
      onClose();
    });
  };

  return (
    <StyledWrapper isOpen={isOpen} data-role="wrapper" onClick={onClickWrapper}>
      <StyledContainer isOpen={isOpen} ref={containerRef}>
        <StyledForm>
          <FilterHeader>
            <StyledH2>{t("Verify your email")}</StyledH2>
            <CloseIcon onClick={onClose} data-test="crossIcon" type="button">
              <BsX size={26} />
            </CloseIcon>
          </FilterHeader>
          <FormContent>
            <p>
              Your email address <b>{email}</b> hasn’t been verified. You won’t
              receive emails:
            </p>
            <ul>
              <li>{t("Meet results")}</li>
              <li>{t("Receipts for paid competitions")}</li>
              <li>{t("Team join requests")}</li>
            </ul>
            <Link
              to={{
                pathname: ROUTE.PROFILE_EDIT,
                search: convertObjectToQueryParams({
                  tab: PROFILE_EDIT_TABS.DETAILS,
                }),
              }}
            >
              {t("Change my email address")}
            </Link>
          </FormContent>
          <Button styleType="iamDone" type="button" onClick={onSubmit}>
            {t("Verify my email")}
          </Button>
        </StyledForm>
      </StyledContainer>
    </StyledWrapper>
  );
};

export default EmailVerificationModal;
