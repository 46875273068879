import React from "react";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";

import { IS_IOS } from "constants/settings";

import NavBar from "Components/NavBar";
import NavBarMobile from "Components/NavBarMobile";

const NavigationLayoutWrapper = styled.div`
  height: 100vh;
  display: flex;
  background-color: ${({ theme }) => theme.basicBackGroundColor};
  box-sizing: border-box;

  .headerAndContentWrapper {
    z-index: 9;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: ${({ withMobileWrapper, theme }) =>
      withMobileWrapper ? theme.mainBackground : "initial"};
    overflow: hidden;

    .meetsWrapper {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      box-sizing: border-box;
      scroll-behavior: smooth;
      padding: 0px 20px;

      ${breakpoint("sm")`
        padding-left: 70px;
      `};
      ${breakpoint("md")`
        padding-left: 100px;
      `};
      ${breakpoint("xl")`
        padding-left: 120px;
      `};

      @media print {
        height: auto;
        padding: 0;
        overflow: initial;

        &.printedBlank {
          .mark,
          .judgeCell > input {
            color: transparent;
          }

          .excluded {
            text-decoration: none;
          }

          .orangeBorder {
            border: 1px solid #e2e1e1;
          }
        }
      }
    }

    .contentWrapper {
      display: flex;
      flex-direction: column;
      height: calc(
        100vh -
          ${({ withoutNavigation }) =>
            withoutNavigation ? 0 : IS_IOS ? 72 : 60}px
      );
      overflow: auto;

      ${breakpoint("sm")`
        height: 100vh;
      `};
    }

    @media print {
      display: table;
      overflow: initial;

      .emptyFooter {
        height: 75px;
      }
    }
  }
`;

const NavigationLayout = ({
  withoutNavigation,
  withMobileWrapper,
  withHardRefresh,
  children,
  style = {},
}) => (
  <NavigationLayoutWrapper {...{ withoutNavigation, withMobileWrapper, style }}>
    {!withoutNavigation && <NavBar {...{ withHardRefresh }} />}
    <div className="headerAndContentWrapper">
      {children}
      {!withoutNavigation && <NavBarMobile {...{ withHardRefresh }} />}
    </div>
  </NavigationLayoutWrapper>
);

export default NavigationLayout;
