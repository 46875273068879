const getDivers = (diversInfo, currentDiveId) => {
  const numericDivers = diversInfo.map((diver, i) => ({
    ...diver,
    roundOrder: i + 1,
  }));
  const currentDiverIndex = numericDivers.findIndex(({ dives }) =>
    currentDiveId
      ? dives?.some(({ id }) => id === currentDiveId)
      : dives?.some(({ current }) => current),
  );

  const jumpedDivers =
    currentDiverIndex >= 0 ? numericDivers.slice(0, currentDiverIndex) : [];
  const futureDivers =
    currentDiverIndex >= 0
      ? numericDivers.slice(currentDiverIndex)
      : [...numericDivers];

  return {
    currentDiver: numericDivers[currentDiverIndex],
    jumpedDivers,
    futureDivers,
  };
};

export const getRoundQueue = ({ sortedDivers, currentDiver, nextDiver }) =>
  sortedDivers?.map((diverInfo) => {
    const { user, roundOrder, diveSheetId } = diverInfo;

    return {
      ...diverInfo,
      isLive:
        currentDiver?.user?.id === user?.id &&
        currentDiver?.diveSheetId === diveSheetId,
      isUpNext:
        nextDiver?.user?.id === user?.id &&
        nextDiver?.diveSheetId === diveSheetId,
      isDone: roundOrder < currentDiver?.roundOrder,
    };
  });

export const createRoundQueue = (diversInfo, currentDiveId) => {
  const {
    jumpedDivers,
    futureDivers,
    currentDiver = {},
  } = getDivers(diversInfo, currentDiveId);
  const sortedDivers = [...futureDivers, ...jumpedDivers];

  const { dives } = currentDiver;
  const currentDive =
    dives?.find(({ id, current }) =>
      currentDiveId ? id === currentDiveId : current,
    ) || {};
  const { number: currentRound } = currentDive;

  let nextDiver = futureDivers?.find(
    ({ diveSheetId, scratched, roundOrder }) =>
      currentDiver?.diveSheetId !== diveSheetId &&
      !scratched &&
      roundOrder > currentDiver?.roundOrder,
  );

  if (!nextDiver && currentRound < dives?.length) {
    nextDiver = jumpedDivers.find(
      ({ scratched, dives }) =>
        !scratched && dives.some(({ number }) => number > currentRound),
    );
  }

  return getRoundQueue({ sortedDivers, currentDiver, nextDiver });
};

export const getRoundDivers = (diversInfo) => {
  const {
    jumpedDivers,
    futureDivers,
    currentDiver = {},
  } = getDivers(diversInfo);
  const sortedDivers = [...jumpedDivers, ...futureDivers];

  const { dives } = currentDiver;
  const currentDive = dives?.find(({ current }) => current) || {};
  const { number: currentRound } = currentDive;

  const nextDiver = futureDivers?.find(
    ({ diveSheetId, dives, scratched }) =>
      currentDiver?.diveSheetId !== diveSheetId &&
      !scratched &&
      dives?.some(
        ({ judgeAward, number }) => currentRound === number && !+judgeAward,
      ),
  );

  return getRoundQueue({ sortedDivers, currentDiver, nextDiver });
};

export const getLeftQueue = (state, { info = [] }) => {
  const currentInfo =
    info.find(({ dives }) => dives.some(({ current }) => current)) || {};
  const currentDive = currentInfo.dives?.find(({ current }) => current) || {};

  const previousInfo =
    info.find(({ dives }) => dives.some(({ previous }) => previous)) || {};
  const previousDive =
    previousInfo?.dives?.find(({ previous }) => previous) || {};

  const updatedRoundNumber =
    currentDive.number > previousDive.number
      ? currentDive.number
      : state.roundNumber;

  if (currentDive.number < previousDive.number) {
    return {
      roundNumber: state.roundNumber,
      queue: state.queue?.map((diver) => ({
        ...diver,
        isLive: false,
        isUpNext: diver.isLive,
      })),
    };
  }

  if (
    currentDive.number > previousDive.number ||
    currentInfo.roundOrder > previousInfo.roundOrder
  ) {
    const basicFlowQueue = info
      .map((diver) => {
        const dive = diver.dives.find(
          (dive) => dive.number === updatedRoundNumber,
        );

        return {
          ...diver,
          scratched: diver.scratched,
          roundOrder: diver.roundOrder,
          jumped: diver.jumped,
          user: diver.user,
          skipped: dive?.skipped,
          isLive: dive?.current,
          dive,
        };
      })
      .sort(({ roundOrder: a }, { roundOrder: b }) => a - b);
    const basicFlowIndexIsLive = basicFlowQueue.findIndex(
      (diver) => diver.isLive,
    );
    const basicFlowIndexUpNext = basicFlowQueue.findIndex((diver, index) => {
      return (
        index > basicFlowIndexIsLive && !diver?.skipped && !diver.scratched
      );
    });

    return {
      roundNumber: updatedRoundNumber,
      queue: basicFlowQueue.map((diver, index) => {
        return {
          ...diver,
          isDone:
            index < basicFlowIndexIsLive && !diver?.skipped && !diver.scratched,
          isUpNext: index === basicFlowIndexUpNext,
        };
      }),
    };
  }
  const indexIsLiveInRound = state.queue?.findIndex((diver) => diver.isLive);

  return {
    roundNumber: state.roundNumber,
    queue: state.queue?.map((diver, index) => ({
      ...diver,
      isUpNext: index === indexIsLiveInRound,
      isLive: currentInfo.roundOrder === index + 1,
    })),
  };
};

export const sortDiversQueue = (divers) => {
  const liveDiverIndex = divers.findIndex(({ isLive }) => isLive);

  const jumpedDivers =
    liveDiverIndex >= 0 ? divers.slice(0, liveDiverIndex) : [];
  const futureDivers =
    liveDiverIndex >= 0 ? divers.slice(liveDiverIndex) : [...divers];

  return [...futureDivers, ...jumpedDivers];
};
