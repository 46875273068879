import React from "react";
import styled from "styled-components";

const StyledButton = styled.button`
  width: 125px;
  height: 40px;
  color: ${({ theme }) => theme.basicTextColor};
  background-color: ${({ theme, disabled }) =>
    disabled ? `${theme.modalBlueBtnColor}4d` : theme.basicBlue};
  border: none;
  border-radius: 10px;

  &:hover {
    background: ${({ theme, disabled }) =>
      disabled ? `${theme.modalBlueBtnColor}4d` : theme.basicBlue};
  }
`;

const NextDive = (props) => <StyledButton {...props} />;

export default NextDive;
