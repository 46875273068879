import React, { useRef } from "react";
import styled from "styled-components";
import { Dialog, DialogActions } from "@mui/material";
import { Formik, Form } from "formik";
import { BsX } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { REPORT_TYPES } from "constants/types";

import { reportVideo } from "store/actions/video";

import Button from "Components/Button";
import FormikInput from "Components/FormikInputs/FormikInput";
import RadioGroup from "Components/RadioGroup";

const StyledDialog = styled(Dialog)`
  & .MuiDialog-paperWidthFalse {
    max-width: 340px;
    min-width: 300px;
    border-radius: 4px;
  }
  & .MuiDialogContent-root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
  }
  & #form {
    max-height: 440px;
    overflow-y: auto;
    padding: 0 30px;
  }
`;

const StyledHeader = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0 10px;
  position: relative;
`;
const StyledTitleHeader = styled.div`
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  & .subName {
    margin-top: 10px;
  }
`;
const StyledIconButton = styled.button`
  padding: 0;
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 5px;
  top: 5px;
  &:focus {
    outline: none;
  }
`;
const StyledDialogActions = styled(DialogActions)`
  padding: 0 !important;
`;
const FormContent = styled.div`
  padding: 20px 20px 10px;
`;

const ReportVideoModal = ({ id, onClose, onSuccess }) => {
  const formikRef = useRef();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const options = [
    // {
    //   title: "Nudity or sexual activity",
    //   code: REPORT_TYPES.NUDITY,
    // },
    // {
    //   title: "Hate speech or symbols",
    //   code: REPORT_TYPES.HATE,
    // },
    // {
    //   title: "Scam or fraud",
    //   code: REPORT_TYPES.SCAM,
    // },
    // {
    //   title: "Violence or dangerous organizations",
    //   code: REPORT_TYPES.VIOLENCE,
    // },
    // { title: "Sale of illegal or regulated goods", code: REPORT_TYPES.SALE },
    // { title: "Bullying or harassment", code: REPORT_TYPES.BULLING },
    // {
    //   title: "Intellectual property violation",
    //   code: REPORT_TYPES.INTELLECTUAL,
    // },
    // { title: "Suicide or self-injury", code: REPORT_TYPES.SUICIDE },
    // { title: "Spam", code: REPORT_TYPES.SPAM },
    { title: "Wrong diver", code: REPORT_TYPES.WRONG },
    { title: "Other", code: REPORT_TYPES.OTHER },
  ];

  const onSubmit = (values) => {
    dispatch(reportVideo({ id, ...values }));
    onClose();
  };

  return (
    <StyledDialog
      open
      onClose={onClose}
      scroll="body"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth={false}
    >
      <StyledHeader>
        <StyledIconButton onClick={onClose}>
          <BsX size={25} className="gray" />
        </StyledIconButton>
        <StyledTitleHeader>
          <p>{t("Select a problem to report")}</p>
        </StyledTitleHeader>
      </StyledHeader>
      <Formik
        innerRef={formikRef}
        initialValues={{
          reason: REPORT_TYPES.WRONG,
          comment: "",
        }}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form id="print-event-pdf-form">
            <FormContent>
              <RadioGroup
                name="reason"
                options={options}
                value={values.reason}
                onChange={(e, value) => setFieldValue("reason", value)}
              />
              <FormikInput
                name="comment"
                rows={3}
                label={t("Description")}
                required={values.reason === REPORT_TYPES.OTHER}
                multiline
              />
            </FormContent>
            <StyledDialogActions>
              <Button
                type="submit"
                data-test="ReportVideoModalSubmitButton"
                color="primary"
                styleType="wideSubmit"
                disabled={
                  values.reason === REPORT_TYPES.OTHER && !values.comment
                }
              >
                {t("Report")}
              </Button>
            </StyledDialogActions>
          </Form>
        )}
      </Formik>
    </StyledDialog>
  );
};

export default ReportVideoModal;
