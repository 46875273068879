import { combineReducers } from "redux";

import {
  actionFulfilled,
  actionRejected,
  USER_LOG_OUT,
  PASSWORD_CHANGE,
  DELETE_PROFILE,
  LOG_OUT_UNREGISTRED_JUDGE,
} from "store/actionTypes";
import { AUTH_KEY } from "constants/storageKeys";

import { sendMessageToMobile } from "helpers/general";

import user from "./user";
import settings from "./settings";
import meets from "./meets";
import rounds from "./rounds";
import roundsData from "./roundsData";
import teams from "./teams";
import divers from "./divers";
import errors from "./errors";
import newMeet from "./newMeet";
import otherMeets from "./otherMeets";
import schedule from "./schedule";
import leadershipRound from "./leadershipRound";
import meetReview from "./meetReview";
import diverInfo from "./diverInfo";
import diverInfoList from "./diverInfoList";
import follow from "./follow";
import leadershipScreens from "./leadershipScreens";
import roundsJudges from "./roundsJudges";
import editMeet from "./editMeet";
import users from "./users";
import video from "./video";
import websockets from "./websockets";
import event from "./event";
import registerDivers from "./registerDivers";
import championship from "./championship";
import membership from "./membership";
import meetAdmin from "./meetAdmin";
import metrics from "./metrics";
import general from "./general";
import announcerView from "./announcerView";
import meet from "./meet";
import leaderboardView from "./leaderboardView";
import feed from "./feed";

const appReducer = combineReducers({
  users,
  user,
  settings,
  meets,
  rounds,
  roundsData,
  roundsJudges,
  teams,
  divers,
  diverInfo,
  diverInfoList,
  errors,
  newMeet,
  editMeet,
  otherMeets,
  schedule,
  leadershipRound,
  meetReview,
  follow,
  leadershipScreens,
  video,
  websockets,
  event,
  registerDivers,
  championship,
  membership,
  meetAdmin,
  metrics,
  general,
  announcerView,
  meet,
  leaderboardView,
  feed,
});

const rootReducer = (state, action) => {
  const isLogOutUserAction = [
    USER_LOG_OUT,
    PASSWORD_CHANGE,
    DELETE_PROFILE,
  ].some((actionType) => action.type === actionFulfilled(actionType));
  const isLogOutJudgeAction = [
    actionFulfilled(LOG_OUT_UNREGISTRED_JUDGE),
    actionRejected(LOG_OUT_UNREGISTRED_JUDGE),
  ].includes(action.type);
  const isLogOutAction = isLogOutUserAction || isLogOutJudgeAction;

  if (!isLogOutAction) return appReducer(state, action);

  if (localStorage.getItem(AUTH_KEY) || localStorage.getItem("xOlatToken")) {
    sendMessageToMobile({ message: "logout" });
  }

  localStorage.clear();

  return appReducer(undefined, action);
};

export default rootReducer;
