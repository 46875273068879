import {
  GET_DIVE_SHEET,
  UPDATE_DIVE,
  DND_DIVES_REPLACE,
  UPDATE_DIVE_TYPE,
  UPDATE_DIVE_HEIGHT,
  actionFulfilled,
  UPDATE_DIVE_SHEET_MODAL,
  UPDATE_MODAL_DIVE_SHEET,
} from "../actionTypes";
import {
  GOVERNING_BODIES,
  NFHS_NUMBER_OF_DIVES_FOR_FINALS,
} from "constants/types";

import { updateDive } from "helpers/dives";

const defaultState = { initialDiveSheet: {}, diveSheetModal: {} };

const roundsReducer = (state = defaultState, { type, payload }) => {
  switch (type) {
    case actionFulfilled(GET_DIVE_SHEET):
    case UPDATE_DIVE_SHEET_MODAL:
      const { initialDiveSheet, diveSheet } = payload || {};

      return {
        ...state,
        initialDiveSheet: initialDiveSheet || state.initialDiveSheet,
        diveSheetModal: diveSheet
          ? {
              ...diveSheet,
              dives: diveSheet.dives.map((dive) => ({
                ...dive,
                isValidHeight: true,
              })),
            }
          : {},
      };
    case UPDATE_MODAL_DIVE_SHEET: {
      return {
        ...state,
        diveSheetModal: {
          ...state.diveSheetModal,
          ...payload,
        },
      };
    }
    case UPDATE_DIVE:
      const { diveId, diveData, platformHeight } = payload;
      const newDives = [...state.diveSheetModal?.dives].map((dive) =>
        dive.id.toString() === diveId.toString()
          ? updateDive(dive, diveData, platformHeight)
          : dive,
      );
      return {
        ...state,
        diveSheetModal: { ...state.diveSheetModal, dives: newDives },
      };
    case UPDATE_DIVE_TYPE:
      return {
        ...state,
        diveSheetModal: {
          ...state.diveSheetModal,
          dives: state.diveSheetModal.dives.map((dive) =>
            dive.id === payload ? { ...dive, optional: !dive.optional } : dive,
          ),
        },
      };
    case UPDATE_DIVE_HEIGHT:
      return {
        ...state,
        diveSheetModal: {
          ...state.diveSheetModal,
          dives: state.diveSheetModal.dives.map((dive) =>
            dive.id === payload.diveId
              ? {
                  ...dive,
                  diveDifficulty: {
                    ...dive.diveDifficulty,
                    diveHeight: {
                      ...dive.diveDifficulty.diveHeight,
                      meters: payload.platformHeight,
                    },
                  },
                  isValidHeight: false,
                }
              : dive,
          ),
        },
      };
    case DND_DIVES_REPLACE:
      const { sourceIndex, destinationIndex, governingBody } = payload;

      if (state.diveSheetModal?.dives[destinationIndex].canBeMoved === false) {
        return state;
      }

      const editedDives = state.diveSheetModal?.dives;
      let dndDives = [...state.diveSheetModal?.dives];
      const draggableDive = dndDives.slice(sourceIndex, sourceIndex + 1)[0];
      dndDives = dndDives.filter(({ id }) => id !== draggableDive.id);
      dndDives.splice(destinationIndex, 0, draggableDive);
      dndDives = dndDives.map((dive, index) => ({
        ...dive,
        number: index + 1,
        optional:
          governingBody === GOVERNING_BODIES.NFHS &&
          editedDives.length === NFHS_NUMBER_OF_DIVES_FOR_FINALS
            ? dive.optional
            : editedDives.find(({ number }) => number === index + 1)?.optional,
      }));

      return {
        ...state,
        diveSheetModal: { ...state.diveSheetModal, dives: dndDives },
      };

    default:
      return state;
  }
};

export default roundsReducer;
