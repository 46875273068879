import { EVENT_STATUS } from "constants/status";

const DIVE_ESTIMATION = {
  0: 40,
  1: 30,
  3: 33,
  10: 40,
};

export const getGeneralStatus = (event = {}) => {
  const { splittedEvents, splitted } = event || {};
  const allEvents = [event, ...(splittedEvents || [])];
  const allStatuses = allEvents.flatMap(({ status, subEvents = [] }) => [
    status,
    ...subEvents.flatMap(({ status }) => status),
  ]);

  const isAllInPaused = allStatuses.every(
    (status) => status === EVENT_STATUS.PAUSED,
  );
  if (isAllInPaused) return EVENT_STATUS.PAUSED;

  const isAllInClosed = allStatuses.every(
    (status) => status === EVENT_STATUS.CLOSED,
  );
  if (isAllInClosed) return EVENT_STATUS.CLOSED;

  const isAllInCancelled = allStatuses.every(
    (status) => status === EVENT_STATUS.CANCELLED,
  );
  const isSomeInCanceled = allStatuses.some(
    (status) => status === EVENT_STATUS.CANCELLED,
  );

  if (isAllInCancelled || (splitted && isSomeInCanceled))
    return EVENT_STATUS.CANCELLED;

  const isAllInNew = allStatuses.every((status) => status === EVENT_STATUS.NEW);
  if (isAllInNew) return EVENT_STATUS.NEW;

  return EVENT_STATUS.IN_PROGRESS;
};

export const groupSplittedEvents = (events = []) => {
  const eventsObject = events
    .sort(
      (a, b) =>
        a.eventOrder - b.eventOrder || a.splitBoardNumber - b.splitBoardNumber,
    )
    .reduce((acc, event) => {
      const key = event.splittedEventId || event.id;

      return acc[key]
        ? {
            ...acc,
            [key]: {
              ...acc[key],
              splittedEvents: [...acc[key].splittedEvents, event],
            },
          }
        : { ...acc, [key]: { ...event, splittedEvents: [] } };
    }, {});

  return Object.values(eventsObject)
    ?.flatMap((event) => event)
    ?.sort(({ eventOrder: a }, { eventOrder: b }) => a - b);
};

export const groupCombinedEvents = (events = []) => {
  const eventsObject = events
    .sort(
      (a, b) =>
        a.eventOrder - b.eventOrder || a.combinedOrder - b.combinedOrder,
    )
    .reduce((acc, event) => {
      const key = event.combinedEvent || event.id;

      return acc[key]
        ? {
            ...acc,
            [key]: {
              ...acc[key],
              subEvents: [...acc[key].subEvents, event].sort(
                (a, b) => a.combinedOrder - b.combinedOrder,
              ),
            },
          }
        : { ...acc, [key]: { ...event, subEvents: [] } };
    }, {});

  return Object.values(eventsObject)?.flatMap((event) => event);
};

export const getEventName = ({ championship, title = "", type = "" }) =>
  championship ? `${title} ${type}` : title;

const getSumOfDivers = (event) =>
  [event, ...(event.subEvents || [])].reduce(
    (acc, cur) => acc + cur.numberOfDivers,
    0,
  );

const getSingleEventDuration = (event) => {
  const { eventHeight, numberOfDivers, numberOfDives } = event;
  const diveDuration = DIVE_ESTIMATION[eventHeight] || 0;

  return diveDuration * numberOfDivers * numberOfDives;
};

export const getEventDuration = (event) => {
  const { splittedEvents = [] } = event;
  const [theBiggestEventBoard] = [event, ...splittedEvents].sort(
    (a, b) => getSumOfDivers(b) - getSumOfDivers(a),
  );

  const duration = [
    theBiggestEventBoard,
    ...(theBiggestEventBoard.subEvents || []),
  ].reduce((acc, cur) => acc + getSingleEventDuration(cur), 0);

  if (!duration) return "";

  const date = new Date(0);
  date.setSeconds(duration);

  const [h, m, s] = date.toISOString().substring(11, 19).split(":");

  let result = "";
  if (+h) result = result + `${+h} hr`;
  if (+m) result = result + `${+h ? " " : ""}${+s ? +m + 1 : +m} min`;

  return result;
};
