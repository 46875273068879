import { getUserId } from "helpers/user";
import {
  actionFulfilled,
  GET_USERS_BY_SEARCH_KEY,
  SEARCH_USERS,
  SEARCH_VENUES,
  GET_ALL_JUDGES,
  CLEAR_SEARCH_USERS,
  CLEAR_SEARCH_VENUES,
  ADD_USERS_TO_MEET_USERS,
  GET_JUDGES_BY_PAGE,
  CLEAR_JUDGES_BY_PAGE,
  GET_CREATED_JUDGES,
  ADD_JUDGE,
} from "../actionTypes";

const initState = {
  allJudges: [],
  searchUsers: null,
  meetUsers: [],
  searchVenues: null,
};

const usersReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case actionFulfilled(GET_USERS_BY_SEARCH_KEY):
      return {
        ...state,
        searchUsers: payload,
      };
    case actionFulfilled(SEARCH_USERS):
      return {
        ...state,
        searchUsers: payload,
      };
    case actionFulfilled(SEARCH_VENUES):
      return {
        ...state,
        searchVenues: payload,
      };
    case ADD_USERS_TO_MEET_USERS:
      return {
        ...state,
        meetUsers: [...payload, ...state.meetUsers]
          .reverse()
          .filter(
            (user, index, users) =>
              users.findIndex((t) => getUserId(t) === getUserId(user)) ===
              index,
          )
          .reverse(),
      };
    case actionFulfilled(GET_ALL_JUDGES):
      return {
        ...state,
        allJudges: payload,
      };
    case actionFulfilled(GET_JUDGES_BY_PAGE): {
      const { currentPage, usersDto } = payload;

      return {
        ...state,
        allJudges: !currentPage ? usersDto : [...state.allJudges, ...usersDto],
        currentJudgesPage: currentPage,
      };
    }
    case CLEAR_JUDGES_BY_PAGE:
      return {
        ...state,
        allJudges: [],
        currentJudgesPage: undefined,
      };
    case CLEAR_SEARCH_USERS:
      return {
        ...state,
        searchUsers: null,
      };
    case CLEAR_SEARCH_VENUES:
      return {
        ...state,
        searchVenues: null,
      };
    case actionFulfilled(ADD_JUDGE):
    case actionFulfilled(GET_CREATED_JUDGES): {
      return {
        ...state,
        allJudges: payload,
      };
    }

    default:
      return state;
  }
};

export default usersReducer;
