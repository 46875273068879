import {
  actionFulfilled,
  GET_CHAMPIONSHIP_PREV_INFO,
  GET_CHAMPIONSHIP_MEET_INFO,
} from "store/actionTypes";

const initState = {
  passedDivers: {},
  championships: [],
};

const championshipReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case actionFulfilled(GET_CHAMPIONSHIP_PREV_INFO): {
      const { eventId, data } = payload;

      return {
        ...state,
        passedDivers: {
          ...state.passedDivers,
          [eventId]: data,
        },
      };
    }
    case actionFulfilled(GET_CHAMPIONSHIP_MEET_INFO): {
      return {
        ...state,
        championships: payload,
      };
    }

    default:
      return state;
  }
};

export default championshipReducer;
