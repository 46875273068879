import {
  GET_SCHEDULE_MEETS_BY_PAGE,
  SEARCH_SCHEDULE_MEETS_BY_PAGE,
  DELETE_MEET,
  CLEAR_SCHEDULE,
  actionFulfilled,
} from "store/actionTypes";

const initState = {
  currentPage: 0,
  meets: [],
  totalElements: 0,
  hasMore: true,
};

const scheduleReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case actionFulfilled(GET_SCHEDULE_MEETS_BY_PAGE):
    case actionFulfilled(SEARCH_SCHEDULE_MEETS_BY_PAGE):
      const { currentPage, meets, totalElements } = payload;

      return {
        ...state,
        ...payload,
        meets: !currentPage ? meets : [...state.meets, ...meets],
        hasMore: meets.length === totalElements,
      };
    case DELETE_MEET:
      return {
        ...state,
        meets: state.meets.filter(({ id }) => id !== payload),
      };
    case CLEAR_SCHEDULE:
      return initState;
    default:
      return state;
  }
};

export default scheduleReducer;
