import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { BAD_GATEWAY_STATUS } from "constants/errors";

import { setServerError } from "store/actions/settings";
import { serverErrorSelector } from "store/selectors/settings";

import Balk from "Components/Balk";

const ServerErrorPage = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const serverError = useSelector(serverErrorSelector);

  useEffect(() => {
    return () => {
      dispatch(setServerError());
    };
  }, [pathname]);

  return (
    <Balk
      statusCode={
        <>
          5<span>0</span>
          {serverError === BAD_GATEWAY_STATUS ? 2 : 4}
        </>
      }
    />
  );
};

export default ServerErrorPage;
