import React from "react";

import HintWarn from "Components/HintWarn";

import { BUTTONS_MAP } from "./constants";
import Base from "./Base";

const Button = ({
  hintDescription,
  className,
  styleType,
  isHintInBottomPosition,
  ...rest
}) => {
  const renderButton = !!BUTTONS_MAP[styleType]
    ? BUTTONS_MAP[styleType]
    : (props) => <Base {...props} />;

  return (
    <HintWarn
      description={hintDescription}
      isBottomPosition={isHintInBottomPosition}
    >
      {renderButton({ styleType, className: `button ${className}`, ...rest })}
    </HintWarn>
  );
};

export default Button;
