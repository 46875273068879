import {
  UPDATE_LEADERSHIP_SOCKET,
  SET_CURRENT_DIVE_HEADER,
  SET_ROUND_QUEUE,
  SET_LEADER_SCREEN_EVENTS,
  ADD_LEADER_SCREEN_EVENTS,
  UPDATE_WS_CURRENT_ROUND,
} from "../actionTypes";

export const updateWebsocketData_2 = (payload) => {
  return {
    type: UPDATE_LEADERSHIP_SOCKET,
    payload,
  };
};

export const updateWsCurrentRound = (payload) => ({
  type: UPDATE_WS_CURRENT_ROUND,
  payload,
});

export const setHeaderForCurrentRound_2 = (newValue) => {
  return {
    type: SET_CURRENT_DIVE_HEADER,
    payload: newValue,
  };
};

export const setRoundQueue_2 = (payload) => ({
  type: SET_ROUND_QUEUE,
  payload,
});

export const setLeaderScreenEvents = (payload) => ({
  type: SET_LEADER_SCREEN_EVENTS,
  payload,
});

export const addLeaderScreenEvents = (payload) => ({
  type: ADD_LEADER_SCREEN_EVENTS,
  payload,
});
