import React from "react";
import styled from "styled-components";
import { BsX } from "react-icons/bs";
import { RiSearchLine } from "react-icons/ri";

import Button from "Components/Button";
// import FormikInput from "Components/FormikInputs/FormikInput";

const StyledSearchHolder = styled.div`
  display: flex;
  width: ${({ width }) => width || "280px"};
  height: 35px;
  display: flex;
  align-items: center;

  button {
    padding: 0;
  }
`;
const StyledInput = styled.input`
  box-sizing: border-box;
  color: ${({ theme }) => theme.basicBlue};
  font-size: 14px;
  letter-spacing: 0;
  line-height: 15px;
  width: ${({ width }) => width || "240px"};
  height: 35px;
  background-color: ${({ theme }) => theme.accentBackgroundColor};
  border-top-left-radius: 17.5px;
  border-bottom-left-radius: 17.5px;
  border: solid 1px #d3d3d3;
  padding: 9px 16px 8px 16px;
  &:focus {
    outline: none;
  }
  &.error {
    border-color: ${({ theme }) => theme.errorColor};
  }
  &::placeholder {
    font-size: 14px;
    color: #9b9b9b;
    letter-spacing: 0;
    line-height: 15px;
  }
`;

export default function SearchInput({
  name,
  type,
  placeholder,
  searchValue,
  setSearchValue,
  width,
  style = {},
}) {
  const onValueChange = ({ target: { value } }) => {
    setSearchValue(value);
  };

  const onSearchButtonClick = () => {
    if (searchValue) return setSearchValue("");

    return;
  };

  return (
    // TODO: Go to new ui
    // <FormikInput
    //   name="search"
    //   label={placeholder || "Find event"}
    //   iconEnd={searchValue ? <BsX /> : <RiSearchLine />}
    //   onClickIconEnd={onSearchButtonClick}
    //   onChange={onValueChange}
    // />
    <StyledSearchHolder width={width} style={style}>
      <StyledInput
        id={`input-field_${name}`}
        type={type}
        placeholder={placeholder || "Find event"}
        value={searchValue}
        width={width}
        onChange={onValueChange}
      />
      <Button
        data-test="searchInputButton"
        styleType="search"
        onClick={onSearchButtonClick}
        type="button"
      >
        {searchValue ? <BsX size={30} /> : <RiSearchLine size={20} />}
      </Button>
    </StyledSearchHolder>
  );
}
