import { format, isEqual, subMinutes, setMinutes, setHours } from "date-fns";

const BORDER_HOURH_VALUE = 12;

export const getTimezone = () => new Date().getTimezoneOffset() * -1;

const offset = new Date().getTimezoneOffset();

export const getFormattedDate = ({
  date,
  dateFormat = "MMM d.yyyy - hh:mm aa",
  useOffset = false,
}) => {
  if (!date) return "";

  if (typeof date === "string") {
    const isoDate = new Date(date).toISOString().slice(0, -1);

    return format(new Date(isoDate), dateFormat);
  }

  const { year = 1990, monthValue = 1, dayOfMonth = 1, hour, minute } = date;

  const result =
    hour !== undefined && minute !== undefined
      ? format(
          new Date(
            year,
            monthValue - 1,
            dayOfMonth,
            hour,
            minute - (useOffset ? offset : 0),
          ),
          dateFormat,
        )
      : format(new Date(year, monthValue - 1, dayOfMonth), dateFormat);

  return result?.replace("May.", "May");
};

export const getDateFromIso = (
  isoString = "",
  currentFormat = "MMM d.yyyy - hh:mm aa",
  useOffset = true,
) =>
  format(
    subMinutes(new Date(isoString), useOffset ? offset : 0),
    currentFormat,
  );

export const getDateRange = (
  startDate,
  endDate,
  dateFormat = "MMM. d, yyyy",
) => {
  const start =
    startDate &&
    startDate?.year &&
    new Date(startDate.year, startDate.monthValue - 1, startDate.dayOfMonth);
  const end =
    endDate &&
    endDate.year &&
    new Date(endDate.year, endDate.monthValue - 1, endDate.dayOfMonth);

  return dateRange(start, end, dateFormat);
};

export const dateRange = (start, end, dateFormat = "MMM. d, yyyy") => {
  if (!start && !end) return "";

  if (isEqual(start, end) || !end) {
    return format(start, dateFormat).replace(/May./g, "May");
  }

  return `${format(start, dateFormat)} - ${format(end, dateFormat)}`.replace(
    /May./g,
    "May",
  );
};

export const getTimeObject = (time, useOffset = true) =>
  new Date(
    time.year,
    time.monthValue - 1,
    time.dayOfMonth,
    time.hour,
    time.minute - (useOffset ? offset : 0),
  );

const getFullHH = (isAm, hh) => {
  if (isAm) {
    if (hh < BORDER_HOURH_VALUE) return hh;

    return hh - BORDER_HOURH_VALUE;
  }

  if (hh < BORDER_HOURH_VALUE) return hh + BORDER_HOURH_VALUE;

  return hh;
};

export const getTimeObjInBackendFormat = (hh, mm, isAm) => {
  if (isNaN(hh) || isNaN(mm)) return {};

  const fullHH = getFullHH(isAm, +hh);

  let date = new Date();
  date = setHours(date, fullHH);
  date = setMinutes(date, +mm);

  const [hour, minute] = format(date, "H-m").split("-").map(Number);

  return (!isNaN(hour) && !isNaN(minute) && { hour, minute }) || {};
};

export const getISODate = ({ date, isAm, hh, mm }) => {
  if (isNaN(hh) || isNaN(mm)) return "";

  const hours = `0${getFullHH(isAm, hh)}`.slice(-2);
  const minutes = `0${mm}`.slice(-2);
  const ISODate = `${format(
    new Date(date),
    "yyyy-MM-dd",
  )}T${hours}:${minutes}:00`;

  return ISODate;
};
