import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { MEETS_TYPES } from "constants/types";

import { scheduleSelector } from "store/selectors/user";
import {
  userTeamIdSelector,
  availableTeamsSelector,
} from "store/selectors/user";
import {
  clearSchedule,
  getEligibleMeets,
  searchEligibleMeets,
} from "store/actions/schedule";
import useDebouncedSearch from "hooks/useDebouncedSearch";

import SplashScreen from "Components/SplashScreen";
import ScheduleSection from "./ScheduleSection";

const SEARCH_LENGTH_MIN = 2;

const Schedule = () => {
  const dispatch = useDispatch();
  const listContainerRef = useRef();

  const [meetsType, setMeetsType] = useState(MEETS_TYPES.UPCOMING);
  const [searchValue, setSearchValue] = useState("");

  const userAvailableTeams = useSelector(availableTeamsSelector);
  const { meets, currentPage } = useSelector(scheduleSelector) || {};
  const selectedTeamId = useSelector(userTeamIdSelector);

  const showSplashScreen = !meets?.length && !userAvailableTeams.length;

  const getNextPage = () => {
    if (searchValue?.length >= SEARCH_LENGTH_MIN) {
      dispatch(
        searchEligibleMeets({
          teamId: +selectedTeamId,
          page: currentPage + 1,
          isFuture: meetsType === MEETS_TYPES.UPCOMING,
          key: searchValue,
        }),
      );

      return;
    }

    dispatch(
      getEligibleMeets({
        teamId: +selectedTeamId,
        page: currentPage + 1,
        isFuture: meetsType === MEETS_TYPES.UPCOMING,
      }),
    );
  };

  useEffect(() => {
    return () => {
      dispatch(clearSchedule());
    };
  }, []);

  useDebouncedSearch({
    searchValue,
    deps: [selectedTeamId, meetsType, searchValue],
    handleSearch: ([selectedTeamId, meetsType, searchValue]) =>
      dispatch(
        searchEligibleMeets({
          teamId: +selectedTeamId,
          isFuture: meetsType === MEETS_TYPES.UPCOMING,
          key: searchValue,
        }),
      ),
    minSearchLength: SEARCH_LENGTH_MIN,
    handleNoSearch: ([selectedTeamId, meetsType]) =>
      dispatch(
        getEligibleMeets({
          teamId: +selectedTeamId,
          isFuture: meetsType === MEETS_TYPES.UPCOMING,
        }),
      ),
    beforeAction: () => {
      listContainerRef.current?.scrollTo(0, 0);
    },
  });

  return showSplashScreen ? (
    <SplashScreen />
  ) : (
    <ScheduleSection
      {...{
        meetsType,
        setMeetsType,
        getNextPage,
        listContainerRef,
        searchValue,
        setSearchValue,
      }}
    />
  );
};

export default Schedule;
