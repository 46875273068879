import {
  SET_JUDGE_MODE_WS,
  SET_JUDGE_MODE_MESSAGE,
  CLEAR_JUDGE_MODE_WS,
  SET_JUDGE_MODE_MESSAGES_WS,
  SET_JUDGE_MODE_MESSAGES_MESSAGE,
  CLEAR_JUDGE_MODE_MESSAGES_WS,
  SET_NEXT_EVENT_INFO,
} from "store/actionTypes";

export const setJudgeModeWS = (payload) => ({
  type: SET_JUDGE_MODE_WS,
  payload,
});

export const setJudgeModeWSMessage = (payload) => ({
  type: SET_JUDGE_MODE_MESSAGE,
  payload,
});

export const clearJudgeModeWS = () => ({
  type: CLEAR_JUDGE_MODE_WS,
});

export const setJudgeModeMessagesWS = (payload) => ({
  type: SET_JUDGE_MODE_MESSAGES_WS,
  payload,
});

export const setJudgeModeMessagesWSMessage = (payload) => ({
  type: SET_JUDGE_MODE_MESSAGES_MESSAGE,
  payload,
});

export const clearJudgeModeMessagesWS = () => ({
  type: CLEAR_JUDGE_MODE_MESSAGES_WS,
});

export const setNextEventInfo = (payload) => ({
  type: SET_NEXT_EVENT_INFO,
  payload,
});
