import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ToastContainer } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

import { ROLES } from "constants/types";
import { APP_NEW_VERSION } from "constants/storageKeys";
import { ROUTE } from "constants/routing";

import { createWebsocket } from "API/websockets";
import { userIdSelector } from "store/selectors/user";
import { logOutUser } from "helpers/user";
import { getLocalStorage, removeLocalStorage } from "helpers/general";
import useLocalStorage from "hooks/useLocalStorage";

import ConfirmationModal from "Components/ConfirmationModal";

const DELETED_USER_MESSAGE = "deleted";

const StyledModalTitle = styled.span`
  text-align: center;
  margin-top: 15px;
  display: block;
  font-weight: 600;
`;

export default function GlobalNotificationProvider({ children }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const userId = useSelector(userIdSelector);

  const [modalComponent, setModalComponent] = useState(null);
  const [ws, setWs] = useState();

  const onWsMessage = function ({ data }) {
    const message = JSON.parse(data);

    if (message === DELETED_USER_MESSAGE) return logOutUser(dispatch);

    const { teamName, role } = message;

    const roleMark = (role) => {
      switch (role) {
        case ROLES.PARENT:
          return t("as a parent");
        case ROLES.HEAD_COACH:
          return t("as a head diving coach");
        case ROLES.COACH:
          return t("as a diving coach");
        case ROLES.SWIM_COACH:
          return t("as a swim coach");
        case ROLES.TEAM_ADMIN:
          return t("as a team administrator");
        case ROLES.ALUMNUS:
          return t("as an alumnus");
        case ROLES.DIVER:
          return t("as a diver");
        case ROLES.SWIMMER:
          return t("as a swimmer");
        default:
          return "";
      }
    };

    setModalComponent(
      <ConfirmationModal
        {...{
          title: <StyledModalTitle>{t("Congratulations!")}</StyledModalTitle>,
          message: `${t("You were accepted into the")} ${teamName} ${t(
            "team",
          )} ${roleMark(role)}`,
          onBackdropClick: () => {
            window.location.reload(true);
          },
        }}
        oneButton
      />,
    );
  };

  useEffect(() => {
    if (userId) {
      setWs(
        createWebsocket({
          path: `team/${userId}/invitation`,
          onMessage: onWsMessage,
        }),
      );
    } else {
      ws?.close(1000, t("No user"));
    }
  }, [userId]);

  useEffect(() => {
    return () => ws?.close(1000, t("Close component"));
  }, []);

  useLocalStorage(() => {
    const newVersion = getLocalStorage(APP_NEW_VERSION);

    if (!newVersion) return;

    const isTechnicalPage = [
      ROUTE.LOGIN_DETAILS,
      ROUTE.EMAIL_VERIFICATION(),
      ROUTE.UNSUBSCRIBE(),
    ].some((route) => pathname.includes(route));

    if (isTechnicalPage) return;

    setModalComponent(
      <ConfirmationModal
        {...{
          title: <StyledModalTitle>{t("New release!")}</StyledModalTitle>,
          message: t(
            "Dive Live released new app version. The app will be reloaded!",
          ),
          onBackdropClick: () => {
            removeLocalStorage(APP_NEW_VERSION);
            window.location.reload();
          },
        }}
        oneButton
      />,
    );
  }, [pathname]);

  return (
    <>
      {modalComponent}
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {children}
    </>
  );
}
