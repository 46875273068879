import {
  actionFulfilled,
  GET_SEARCH_MEETS,
  GET_SEARCH_MEETS_PER_PAGE,
  SET_UPDATED_MEETS,
  GET_SEARCH_PAST_MEETS,
  GET_SEARCH_CURRENT_MEETS,
  GET_SEARCH_FUTURE_MEETS,
  CLEAR_SEARCH_MEETS,
} from "store/actionTypes";
import { SEARCH_MEET_TABS } from "constants/types";

const { PAST, TODAY, FUTURE } = SEARCH_MEET_TABS;

const initialState = {
  [PAST]: [],
  [TODAY]: [],
  [FUTURE]: [],
};

const otherMeetsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionFulfilled(GET_SEARCH_MEETS):
      return { ...state, ...action.payload };
    case actionFulfilled(GET_SEARCH_MEETS_PER_PAGE):
      return {
        ...state,
        [action.payload.type]: [
          ...state[action.payload.type],
          ...action.payload.meets,
        ],
      };
    case actionFulfilled(GET_SEARCH_PAST_MEETS): {
      const { meets, page } = action.payload;

      return {
        ...state,
        [PAST]: !page ? [...meets] : [...state[PAST], ...meets],
      };
    }
    case actionFulfilled(GET_SEARCH_CURRENT_MEETS): {
      const { meets, page } = action.payload;

      return {
        ...state,
        [TODAY]: !page ? [...meets] : [...state[TODAY], ...meets],
      };
    }
    case actionFulfilled(GET_SEARCH_FUTURE_MEETS): {
      const { meets, page } = action.payload;

      return {
        ...state,
        [FUTURE]: !page ? [...meets] : [...state[FUTURE], ...meets],
      };
    }
    case SET_UPDATED_MEETS:
      return action.payload;
    case CLEAR_SEARCH_MEETS:
      return initialState;
    default:
      return state;
  }
};

export default otherMeetsReducer;
