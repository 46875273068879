import { capitalize } from "lodash";

import { GENDER_KEYS } from "constants/types";

export const USER_AGE = {
  MIN: 2,
  MAX: 120,
};

export const COLLEGE_RECRUITING_USER_AGE = {
  MIN: 15,
  MAX: 20,
};

export const USER_GENDER_CONFIG = [
  {
    key: GENDER_KEYS.MALE,
    children: capitalize(GENDER_KEYS.MALE),
  },
  {
    key: GENDER_KEYS.FEMALE,
    children: capitalize(GENDER_KEYS.FEMALE),
  },
  {
    key: GENDER_KEYS.NOT_SPECIFIED,
    children: capitalize(GENDER_KEYS.NOT_SPECIFIED),
  },
];
