import React from "react";
import styled from "styled-components";

const StyledDivider = styled.div`
  width: -webkit-fill-available;
  border: 1px solid #ebe9e9;
  margin: 17px 20px;
  background: #ebe9e9;
`;
const Divider = () => {
  return <StyledDivider></StyledDivider>;
};

export default Divider;
