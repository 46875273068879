import React from "react";
import styled from "styled-components";

const StyledLink = styled.a`
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
  color: ${({ theme }) => theme.basicBlue};
  cursor: pointer;
`;

function Link(props) {
  const handleClick = (event) => {
    event.preventDefault();
    props.onClick();
  };

  return <StyledLink onClick={handleClick}>{props.children} </StyledLink>;
}

export default Link;
