// import "./wdyr";

import React from "react";
import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";

import { ENVS } from "constants/env";
import {
  FORBIDDEN_STATUS,
  UNAUTHORIZED_STATUS,
  BAD_GATEWAY_STATUS,
} from "constants/errors";

import App from "App";

if (ENVS[process.env.REACT_APP_ENV].SHOW_SENTRY) {
  Sentry.init({
    dsn: ENVS[process.env.REACT_APP_ENV].SENTRY_DSN_LINK,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
        networkDetailAllowUrls: [window.location.origin],
      }),
    ],
    tracesSampleRate: 1.0,
    attachStacktrace: "on",
    ignoreErrors: ["Non-Error promise rejection captured"],
    beforeSend: function (event, hint) {
      const isBadCredentials =
        hint.originalException.request?.responseURL?.includes("/auth/login") &&
        [UNAUTHORIZED_STATUS, FORBIDDEN_STATUS].includes(
          hint.originalException.request?.status,
        );
      const isBadGateway =
        hint.originalException.request?.status === BAD_GATEWAY_STATUS;
      const isNonErrorException =
        event.exception.values[0].value.startsWith(
          "Non-Error exception captured",
        ) ||
        hint.originalException["message"].startsWith(
          "Non-Error exception captured",
        ) ||
        hint.originalException["message"].startsWith("Track has ended") ||
        hint.originalException["message"].startsWith("Request aborted") ||
        hint.originalException["message"].startsWith("timeout exceeded") ||
        hint.originalException["message"].startsWith("Network Error") ||
        hint.originalException["message"].startsWith("Unexpected token") ||
        hint.originalException["message"].startsWith(
          "Request failed with status code 401",
        ) ||
        hint.originalException["message"].startsWith(
          "UnknownError: Unable to open database file on disk",
        ) ||
        hint.originalException.name === "Rage Click" ||
        ["Email is already registered", "Email is already verified"].includes(
          hint.originalException.response.data,
        );

      if (isBadCredentials || isBadGateway || isNonErrorException) return null;

      if (
        event !== undefined &&
        event.exception !== undefined &&
        event.exception.values !== undefined &&
        event.exception.values.length === 1
      ) {
        const e = event.exception.values[0];

        if (
          e.type === "UnhandledRejection" &&
          e.value === "Non-Error promise rejection captured with value: "
        )
          return null;
      }

      // Sentry.addBreadcrumb(
      //   {
      //     message:
      //       "Request details: " + hint.originalException.response.config?.data,
      //     category: "log",
      //   },
      //   hint,
      // );

      return event;
    },
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  Sentry.withErrorBoundary(App, {
    fallback: <p>An error has occurred. Please try again</p>,
  });
}

const container = document.getElementById("root");
const root = createRoot(container);

root.render(<App />);
