export const getDiveDownHintWarnDescription = (diveDown, place) =>
  `${diveDown ? "Prohibit" : "Allow"} divers dive down in the current ${place}`;

export const getDiveUpHintWarnDescription = (diveUp, place) =>
  `${diveUp ? "Deactivate" : "Activate"} dive up for the current ${place}`;

export const getDiveDownModalMessage = (diveDown, place) =>
  `Do you want to ${
    diveDown ? "prevent divers from competing" : "allow divers to compete"
  } in age groups lower than their current age for this ${place}?`;
export const getDiveUpModalMessage = (diveUp, place) =>
  `Do you want to ${
    diveUp ? "prevent divers from competing" : "allow divers to compete"
  } in age groups higher than their current age for this ${place}?`;
export const getDiveDownToastMessage = (diveDown, place) =>
  `Dive down for current ${place} was ${
    diveDown ? "activated" : "deactivated"
  }`;
export const getDiveUpToastMessage = (diveUp, place) =>
  `Dive up for current ${place} was ${diveUp ? "activated" : "deactivated"}`;
export const getDiveUpDownAvatarHint = (diveUp) =>
  `Diver is competing in an age group ${
    diveUp ? "older" : "younger"
  } than their actual age`;
