import React from "react";
import styled from "styled-components";

const StyledButton = styled.button`
  width: 100%;
  max-width: 450px;
  height: 75px;
  font-weight: bold;
  color: ${({ theme }) => theme.basicTextColor};
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.disabledBtnBg : theme.pink};
  border-radius: 0;
  border: none;

  &:hover {
    box-shadow: 0 0 8px 0
      ${({ disabled }) => (disabled ? "transparent" : "none")};
    background: ${({ theme, disabled }) =>
      disabled ? `${theme.disabledBtnBg}bf` : theme.pink};
  }
`;

const Leave = (props) => <StyledButton {...props} />;

export default Leave;
