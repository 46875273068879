import React from "react";
import styled from "styled-components";

const VideosRowHeader = styled.h6`
  color: ${({ theme }) => theme.semiGray};
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.2;
  margin-bottom: 10px;
`;

const VideoItemsRow = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  overflow-x: auto;
  width: 100%;

  & > div {
    width: 120px;
  }
`;

const VideosRowHolder = ({ header, children, itemsRowStyle = {} }) => (
  <>
    {header && <VideosRowHeader>{header}</VideosRowHeader>}

    <VideoItemsRow style={itemsRowStyle}>{children}</VideoItemsRow>
  </>
);

export default VideosRowHolder;
