import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import { setPageNotFound } from "store/actions/settings";

import Balk from "Components/Balk";

const PageNotFound = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    return () => {
      dispatch(setPageNotFound(false));
    };
  }, [pathname]);

  return (
    <Balk
      statusCode={
        <>
          4<span>0</span>4
        </>
      }
    />
  );
};

export default PageNotFound;
