import {
  actionFulfilled,
  GET_ROUNDS_ANNOUNCER_VIEW,
  SET_FOCUSED_DIVE_ANNOUNCER_VIEW,
  UPDATE_DIVER_DIVES_ANNOUNCER_VIEW,
  CLEAR_ANNOUNCER_VIEW,
  UPDATE_DIVER_DIVE_ANNOUNCER_VIEW,
  GET_EVENT_ANNOUNCER_VIEW,
  UPDATE_JUDGES_ANNOUNCER_VIEW,
} from "store/actionTypes";

const getUpdatedRounds = ({ rounds, getDives }) =>
  Object.keys(rounds).reduce((acc, cur) => {
    const round = { ...rounds[cur] };

    return {
      ...acc,
      [cur]: {
        ...round,
        dives: getDives(round.dives),
        subRounds: round.subRounds.map((subRound) => ({
          ...subRound,
          dives: getDives(subRound.dives),
        })),
      },
    };
  }, {});

const defaultState = { rounds: {}, judges: {}, mainEvent: {} };

const announcerViewReducer = (state = defaultState, { type, payload }) => {
  switch (type) {
    case actionFulfilled(GET_ROUNDS_ANNOUNCER_VIEW): {
      const { rounds } = payload;

      if (!rounds) return state;

      return {
        ...state,
        rounds: Object.keys(rounds).reduce(
          (acc, cur) => ({ ...acc, [cur]: rounds[cur] }),
          {},
        ),
      };
    }
    case UPDATE_JUDGES_ANNOUNCER_VIEW: {
      return {
        ...state,
        judges: payload,
      };
    }
    case UPDATE_DIVER_DIVES_ANNOUNCER_VIEW: {
      const { dives: newDives } = payload;

      const getDives = (dives) =>
        (dives || []).map((dive) => {
          const newDive = newDives.find(
            (newDive) => newDive.dive.id === dive.dive.id,
          );

          if (!newDive) return dive;

          return newDive;
        });

      return {
        ...state,
        rounds: getUpdatedRounds({ rounds: state.rounds, getDives }),
      };
    }
    case SET_FOCUSED_DIVE_ANNOUNCER_VIEW: {
      const { newFocus: focusedDiveId } = payload;

      const getDives = (dives) =>
        (dives || []).map((dive) => ({
          ...dive,
          current: dive.dive.id === focusedDiveId,
        }));

      return {
        ...state,
        rounds: getUpdatedRounds({ rounds: state.rounds, getDives }),
      };
    }
    case UPDATE_DIVER_DIVE_ANNOUNCER_VIEW: {
      const { dive: newDive } = payload;

      const getDives = (dives) =>
        (dives || []).map((dive) => {
          if (dive.dive.id === newDive.dive.id) return newDive;

          return dive;
        });

      return {
        ...state,
        rounds: getUpdatedRounds({ rounds: state.rounds, getDives }),
      };
    }
    case actionFulfilled(GET_EVENT_ANNOUNCER_VIEW): {
      return {
        ...state,
        mainEvent: payload,
      };
    }
    case CLEAR_ANNOUNCER_VIEW:
      return defaultState;

    default:
      return state;
  }
};

export default announcerViewReducer;
