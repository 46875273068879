import {
  actionFulfilled,
  CLEAR_MEET_FOR_EDITING,
  GET_MEET_FOR_EDITING,
  SET_EDITED_MEET_NAME,
  ADD_NEW_SESSION_TO_EDITED_MEET,
  SET_EDITED_MEET_EVENT_IDS,
  CLEAR_EDITED_MEET_EVENT_IDS,
  ADD_EVENT_TO_EDITED_MEET,
  SET_EDIT_MEET_FORM,
  SET_EDITED_MEET_DAYS,
  SET_UPDATED_EVENT_OR_SUBEVENT,
  GET_CUSTOM_MEET_JUDGES,
  UPATE_GEN_WARM_TIME,
  UPATE_MEET_SESSION_TIME,
  EDIT_MEET_DND_EVENTS,
  EDIT_MEET_COMBINE_EVENTS,
  UNCOMBINE_EVENTS_FOR_EDIT_MEET,
  REMOVE_EVENT_ON_EDIT_MEET,
  REORDER_COMBINED_EVENTS_ON_EDIT_MEET,
  DELETE_SESSION_FROM_EDITED_MEET,
  HANDLE_SPLIT_MODAL,
  HANDLE_MOVE_DIVER_MODAL,
  SPLIT_BOARD_PARAMS,
  GET_ALL_EVENT_ROUNDS,
  GET_ALL_COLORS_SPLIT_BOARDS,
  HANDLE_CANCEL_MODAL,
} from "store/actionTypes";

import { replaceEventOrSubEventInMeet } from "helpers/meets";

const defaultState = {
  meetData: {},
  editEventIds: {},
  judges: [],
  removedChampionships: [],
  splitModal: {
    isOpen: false,
    eventId: null,
    title: "",
    subEventsNames: [],
    eventStatus: "",
  },
  cancelEventModal: {
    isOpen: false,
    eventId: null,
    title: "",
    subEventsNames: [],
  },
  modalMoveDiver: {
    mainEventId: null,
    isOpen: false,
  },
  splitBoard: {
    eventColour: "",
    splitBoardName: "",
  },
  splitBoardRound: {},
  allColorsSplitBoards: [],
  selectedColorSplitBoard: "",
};

const getEvent = (meet, dayId, sessionId, eventIndex) => ({
  ...meet?.days
    ?.find(({ id }) => id === dayId)
    ?.sessions?.find(({ id }) => id === sessionId)?.events[eventIndex],
});

const getDaysWithoutEvent = (meet, eventId) =>
  meet.days.map((day) => ({
    ...day,
    sessions: day.sessions.map((session) => ({
      ...session,
      events: session.events
        .filter(({ id }) => id !== eventId)
        .map((event, eventOrder) => ({ ...event, eventOrder })),
    })),
  }));

const editMeetReducer = (state = defaultState, { type, payload }) => {
  switch (type) {
    case actionFulfilled(EDIT_MEET_DND_EVENTS):
      const draggedEvent = getEvent(
        state?.meetData,
        payload.source.dayId,
        payload.source.sessionId,
        payload.source.index,
      );
      const daysWithoutEvent = getDaysWithoutEvent(
        state.meetData,
        draggedEvent.id,
      );
      const destinationDay = daysWithoutEvent.find(
        ({ id }) => id === payload.destination.dayId,
      );
      const destinationSession = destinationDay.sessions.find(
        ({ id }) => id === payload.destination.sessionId,
      );
      const newEvents = [
        ...destinationSession.events.slice(0, payload.destination.index),
        draggedEvent,
        ...destinationSession.events.slice(payload.destination.index),
      ].map(({ subEvents = [], ...event }, eventOrder) => ({
        ...event,
        eventOrder,
        subEvents: [
          ...subEvents.map((subEvent) => ({
            ...subEvent,
            eventOrder,
          })),
        ],
      }));

      destinationSession.events.splice(payload.destination.index, draggedEvent);

      return {
        ...state,
        meetData: {
          ...state.meetData,
          days: daysWithoutEvent.map((day) =>
            day.id === payload.destination.dayId
              ? {
                  ...day,
                  sessions: day.sessions.map((session) =>
                    session.id === payload.destination.sessionId
                      ? {
                          ...session,
                          events: newEvents,
                        }
                      : { ...session },
                  ),
                }
              : { ...day },
          ),
        },
      };
    case actionFulfilled(EDIT_MEET_COMBINE_EVENTS):
      if (!payload) return { ...state };

      const combineChildEvent = getEvent(
        state?.meetData,
        payload.source.dayId,
        payload.source.sessionId,
        payload.source.eventIndex,
      );

      const daysWithoutChildEvent = getDaysWithoutEvent(
        state.meetData,
        combineChildEvent.id,
      );
      return {
        ...state,
        meetData: {
          ...state.meetData,
          days: daysWithoutChildEvent.map((day) =>
            day.id === payload.destination.dayId
              ? {
                  ...day,
                  sessions: day.sessions.map((session) =>
                    session.id === payload.destination.sessionId
                      ? {
                          ...session,
                          events: session.events.map((event) =>
                            event.id === payload.destination.eventId
                              ? {
                                  ...event,
                                  subEvents: [
                                    ...(event.subEvents || []),
                                    combineChildEvent,
                                  ],
                                }
                              : { ...event },
                          ),
                        }
                      : { ...session },
                  ),
                }
              : { ...day },
          ),
        },
      };
    case UPATE_MEET_SESSION_TIME:
      return {
        ...state,
        meetData: {
          ...state.meetData,
          days: state.meetData.days.map((day) =>
            day.id === payload.dayId
              ? {
                  ...day,
                  sessions: day.sessions.map((session) =>
                    session.id === payload.sessionId
                      ? {
                          ...session,
                          warmUp: {
                            ...session.warmUp,
                            [payload.period]: {
                              ...(session.warmUp[payload.period] || day.date),
                              ...payload.newValue,
                            },
                          },
                        }
                      : { ...session },
                  ),
                }
              : { ...day },
          ),
        },
      };
    case REMOVE_EVENT_ON_EDIT_MEET:
      return {
        ...state,
        meetData: {
          ...state.meetData,
          days: state.meetData.days.map((day) => ({
            ...day,
            sessions: day.sessions.map((session) => ({
              ...session,
              events: session.events
                .filter(({ id }) => id !== payload.eventId)
                .map(({ subEvents = [], ...event }, eventOrder) => ({
                  ...event,
                  eventOrder,
                  subEvents: [
                    ...subEvents.map((subEvent) => ({
                      ...subEvent,
                      eventOrder,
                    })),
                  ],
                })),
            })),
          })),
        },
        removedChampionships: payload.removeChampionshipId
          ? [...state.removedChampionships, payload.removeChampionshipId]
          : state.removedChampionships,
      };
    case REORDER_COMBINED_EVENTS_ON_EDIT_MEET:
      return {
        ...state,
        meetData: {
          ...state.meetData,
          days: state.meetData.days.map((day) => ({
            ...day,
            sessions: day.sessions.map((session) => ({
              ...session,
              events: session.events.map((event) => {
                if (event?.id === payload) {
                  const eventWithSubEventsArray = [
                    { ...event, subEvents: [] },
                    ...event.subEvents,
                  ];
                  delete eventWithSubEventsArray[0].subEvents;
                  const reversedEventsArray = eventWithSubEventsArray.reverse();
                  const newEvent = {
                    ...reversedEventsArray[0],
                    subEvents: reversedEventsArray.slice(1),
                  };
                  return { ...newEvent };
                } else return { ...event };
              }),
            })),
          })),
        },
      };
    case UPATE_GEN_WARM_TIME:
      return {
        ...state,
        meetData: {
          ...state.meetData,
          days: state.meetData.days.map((day) =>
            day.id === payload.dayId
              ? {
                  ...day,
                  warmUp: {
                    ...day.warmUp,
                    [payload.period]: {
                      ...(day.warmUp[payload.period] || day.date),
                      ...payload.newValue,
                    },
                  },
                }
              : { ...day },
          ),
        },
      };

    case actionFulfilled(GET_CUSTOM_MEET_JUDGES):
      return {
        ...state,
        judges: payload,
      };
    case actionFulfilled(UNCOMBINE_EVENTS_FOR_EDIT_MEET):
      return {
        ...state,
        meetData: {
          ...state.meetData,
          days: state.meetData.days.map(({ sessions, ...day }) => ({
            ...day,
            sessions: sessions.map(({ events = [], ...session }) => ({
              ...session,
              events: events.flatMap((event) =>
                event?.id === payload
                  ? [{ ...event, subEvents: [] }, ...event.subEvents]
                  : [{ ...event }],
              ),
            })),
          })),
        },
      };
    case ADD_EVENT_TO_EDITED_MEET: {
      const { dayId, sessionId, newEvents } = payload;

      return {
        ...state,
        meetData: {
          ...state.meetData,
          days: state.meetData.days.map((day) =>
            day.id === dayId
              ? {
                  ...day,
                  sessions: day.sessions.map((session) =>
                    session.id === sessionId
                      ? {
                          ...session,
                          events: [...session.events, ...newEvents].map(
                            ({ subEvents = [], ...event }, eventOrder) => ({
                              ...event,
                              eventOrder,
                              subEvents: [
                                ...subEvents.map((subEvent) => ({
                                  ...subEvent,
                                  eventOrder,
                                })),
                              ],
                            }),
                          ),
                        }
                      : { ...session },
                  ),
                }
              : { ...day },
          ),
        },
      };
    }
    case SET_UPDATED_EVENT_OR_SUBEVENT:
      return {
        ...state,
        meetData: replaceEventOrSubEventInMeet(
          state.meetData,
          payload.newEvent,
          payload.ids,
        ),
      };
    case SET_EDITED_MEET_EVENT_IDS:
      return { ...state, editEventIds: payload };
    case HANDLE_SPLIT_MODAL:
      return {
        ...state,
        splitModal: { ...payload },
      };
    case HANDLE_CANCEL_MODAL:
      return {
        ...state,
        cancelEventModal: { ...payload },
      };
    case HANDLE_MOVE_DIVER_MODAL:
      return {
        ...state,
        modalMoveDiver: {
          ...state.modalMoveDiver,
          ...payload,
        },
      };
    case CLEAR_EDITED_MEET_EVENT_IDS:
      return { ...state, editEventIds: {} };

    case SET_EDITED_MEET_DAYS:
      return { ...state, meetData: { ...state.meetData, days: payload } };
    case SET_EDIT_MEET_FORM:
      return {
        ...state,
        meetData: {
          ...state.meetData,
          group: { ...state.meetData.group, name: payload.meetName },
        },
      };
    case ADD_NEW_SESSION_TO_EDITED_MEET:
      return {
        ...state,
        meetData: {
          ...state.meetData,
          days: state.meetData.days.map((day) => ({
            ...day,
            sessions:
              day.id === payload.dayId
                ? [...day.sessions, payload.newSession]
                : day.sessions,
          })),
        },
      };
    case DELETE_SESSION_FROM_EDITED_MEET:
      return {
        ...state,
        meetData: {
          ...state.meetData,
          days: state.meetData.days.map((day) =>
            day.id === payload.dayId
              ? {
                  ...day,
                  sessions: payload.onlyNumber
                    ? day.sessions.map((session) =>
                        session.id === payload.sessionId
                          ? { ...session, sessionNumber: 0 }
                          : { ...session },
                      )
                    : day.sessions
                        .filter(({ id }) => id !== payload.sessionId)
                        .map((session, i) => ({
                          ...session,
                          sessionNumber: i + 1,
                        })),
                }
              : { ...day },
          ),
        },
      };

    case actionFulfilled(GET_MEET_FOR_EDITING):
      return { ...state, meetData: payload };
    case SET_EDITED_MEET_NAME:
      return {
        ...state,
        meetData: {
          ...state.meetData,
          group: { ...state.meetData.group, name: payload },
        },
      };
    case CLEAR_MEET_FOR_EDITING:
      return defaultState;
    case SPLIT_BOARD_PARAMS:
      return {
        ...state,
        splitBoard: { ...payload },
      };
    case actionFulfilled(GET_ALL_EVENT_ROUNDS):
      return {
        ...state,
        splitBoardRound: { ...payload.splitBoard },
      };
    case actionFulfilled(GET_ALL_COLORS_SPLIT_BOARDS):
      return {
        ...state,
        allColorsSplitBoards: payload,
      };
    default:
      return state;
  }
};

export default editMeetReducer;
