import React, { useEffect, lazy, memo, useCallback } from "react";
import { Switch, Route, useHistory } from "react-router-dom";

import { ROUTE } from "constants/routing";

import { lazyRetry } from "helpers/general";
import useRouts from "hooks/useRouts";

import CommonLayout from "Layouts/Common";
import RefreshContainer from "Layouts/RefreshContainer";
import Home from "Scenes/Home";
import Schedule from "Scenes/Schedule";
import HelpRequest from "Scenes/HelpRequest";
import Video from "Scenes/Video";
import PageNotFound from "Scenes/PageNotFound";

const MeetAdminRouter = lazy(() =>
  lazyRetry(() => import("./MeetAdminRouter")),
);
const JudgeModeRouter = lazy(() =>
  lazyRetry(() => import("./JudgeModeRouter")),
);
const TeamRouter = lazy(() => lazyRetry(() => import("./TeamRouter")));
const CreateMeetRouter = lazy(() =>
  lazyRetry(() => import("./CreateMeetRouter")),
);
const ProfileRouter = lazy(() => lazyRetry(() => import("./ProfileRouter")));
const DiveSheetsRouter = lazy(() =>
  lazyRetry(() => import("./DiveSheetsRouter")),
);
const MeetInfoRouter = lazy(() => lazyRetry(() => import("./MeetInfoRouter")));
const SearchRouter = lazy(() => lazyRetry(() => import("./SearchRouter")));

const CommonRouter = memo((props) => {
  const history = useHistory();

  let currentPageTitle = false;

  const {
    isMeetAdminRouts,
    isJudgeModeRoutsFull,
    isTeamRouts,
    isCreateMeetRouts,
    isProfileRouts,
    isDiveSheetsRouts,
    isMeetInfoRouts,
    isSearchRouts,
  } = useRouts();

  const renderScene = ({ Scene, pageTitle, withRefresh }) => {
    return ({ match }) => {
      currentPageTitle = pageTitle;

      return withRefresh ? (
        <RefreshContainer>
          <Scene {...props} match={match} />
        </RefreshContainer>
      ) : (
        <Scene {...props} match={match} />
      );
    };
  };

  useEffect(() => {
    if (currentPageTitle) {
      if (process.env.REACT_APP_ENV === "PROD") {
        window.dataLayer = window.dataLayer || [];

        function gtag() {
          window.dataLayer.push(arguments);
        }
        gtag("config", "G-4MG5F8VB3L", {
          page_path: history.location.pathname,
          page_title: currentPageTitle,
        });
      }
    }
  }, [currentPageTitle, history.location]);

  const renderContent = useCallback(() => {
    if (isMeetAdminRouts) return <MeetAdminRouter {...props} />;
    if (isJudgeModeRoutsFull) return <JudgeModeRouter {...props} />;
    if (isTeamRouts) return <TeamRouter {...props} />;
    if (isCreateMeetRouts) return <CreateMeetRouter {...props} />;
    if (isProfileRouts) return <ProfileRouter {...props} />;
    if (isDiveSheetsRouts) return <DiveSheetsRouter {...props} />;
    if (isMeetInfoRouts) return <MeetInfoRouter {...props} />;
    if (isSearchRouts) return <SearchRouter {...props} />;

    return (
      <Switch>
        <Route
          path={ROUTE.HOME}
          exact
          render={renderScene({ Scene: Home, pageTitle: "Home" })}
        />
        <Route
          path={ROUTE.SCHEDULE}
          exact
          render={renderScene({ Scene: Schedule, pageTitle: "Schedule" })}
        />
        <Route
          path={ROUTE.HELP_REQUEST}
          exact
          render={renderScene({
            Scene: HelpRequest,
            pageTitle: "Help request form",
          })}
        />
        <Route
          path={ROUTE.VIDEO(":key")}
          exact
          render={renderScene({
            Scene: Video,
            pageTitle: "Video",
            withRefresh: true,
          })}
        />
        <Route
          path="*"
          render={renderScene({
            Scene: PageNotFound,
          })}
        />
      </Switch>
    );
  }, [
    isMeetAdminRouts,
    isJudgeModeRoutsFull,
    isTeamRouts,
    isCreateMeetRouts,
    isProfileRouts,
    isDiveSheetsRouts,
    isMeetInfoRouts,
    isSearchRouts,
  ]);

  return <CommonLayout {...props}>{renderContent()}</CommonLayout>;
});

CommonRouter.displayName = "CommonRouter";

export default CommonRouter;
