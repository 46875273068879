import React from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router";

import { IS_ENV_DEVELOPMENT, SITE_ENVS } from "constants/env";

const MetaTags = ({
  title,
  description,
  imageUrl,
  imgType,
  imgWidth,
  imgHeight,
  customPageUrl,
}) => {
  const history = useHistory();

  const pageUrl =
    customPageUrl ||
    `${IS_ENV_DEVELOPMENT ? SITE_ENVS.DEV : window.location.origin}${
      history.location.pathname
    }`;

  return (
    <Helmet>
      {title && <title>{title}</title>}
      {description && <meta name="description" content={description} />}

      {pageUrl && <meta property="og:url" content={pageUrl} />}
      {description && <meta property="og:description" content={description} />}
      {imageUrl && <meta property="og:image" content={imageUrl} />}
      {imageUrl && <meta property="og:image:secure_url" content={imageUrl} />}
      {imgType && <meta property="og:image:type" content="image/jpeg" />}
      {imgWidth && <meta property="og:image:width" content={imgWidth} />}
      {imgHeight && <meta property="og:image:height" content={imgHeight} />}

      {description && (
        <meta property="twitter:description" content={description} />
      )}
      {imageUrl && <meta property="twitter:image" content={imageUrl} />}
      {imageUrl && <meta property="twitter:image:src" content={imageUrl} />}
    </Helmet>
  );
};

export default MetaTags;
