import {
  SET_CURRENT_DIVER_FOCUS,
  SET_UPDATED_STATUSES,
  CLEAR_LEADERSHIP,
  CURRENT_ROUND_SCREEN_SLIDED,
  SET_COACH_OPTIONS,
  CLEAR_COACH_OPTIONS,
} from "store/actionTypes";
import { getCurrentDive } from "helpers/websocket";

const initState = {
  currentDiver: {},
  currentDiverInfo: {},
  previousDiverInfo: {},
  current: {},
  previous: {},
  eventLeadershipRound: {},
  currentLoaded: {},
  displayedDiver: {
    eventStatus: "",
    roundNumber: "",
    totalRounds: "",
    totalDivers: "",
    eventName: "",
    diver: {},
  },
  roundNumber: "",
  currentRoundScreenSlided: false,
  coachOptions: [],
};

const diverInfoReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case CLEAR_LEADERSHIP:
      return initState;
    case CURRENT_ROUND_SCREEN_SLIDED:
      return {
        ...state,
        currentRoundScreenSlided: payload,
      };
    case SET_CURRENT_DIVER_FOCUS: {
      const { events = [] } = payload;

      const allEventsInfo = events.flatMap(({ info }) => info);
      const currentDiver =
        events.find(({ currentDiveId, info }) =>
          info.find(({ dives }) => getCurrentDive({ currentDiveId, dives })),
        ) || {};

      const currentInfo =
        allEventsInfo.find((diver) =>
          diver.dives.some((dive) => dive.current),
        ) || {};
      const previousInfo =
        allEventsInfo.find((diver) =>
          diver.dives.some((dive) => dive.previous),
        ) || {};

      return {
        ...state,
        currentDiver,
        current: {
          ...currentInfo,
          currentDive: currentInfo.dives?.find((dive) => dive.current) || {},
          eventStatus: currentDiver.eventStatus,
          roundNumber: currentDiver.roundNumber,
          totalRounds: currentDiver.totalRounds,
          totalDivers: currentDiver.totalDivers,
          eventName: currentDiver.eventName,
          eventId: currentDiver.eventId,
        },
        previous: {
          ...previousInfo,
          previousDive:
            previousInfo?.dives?.find((dive) => dive.previous) || {},
        },
      };
    }
    case SET_UPDATED_STATUSES:
      if (
        payload.current.currentDive.number >
          payload.previous.previousDive.number ||
        payload.current.roundOrder > payload.previous.roundOrder
      ) {
        const updatedRoundNumber =
          payload.current.currentDive.number >
          payload.previous.previousDive.number
            ? payload.current.currentDive.number
            : state.roundNumber;

        return {
          ...state,
          roundNumber: updatedRoundNumber,
        };
      }

      return {
        ...state,
      };
    case SET_COACH_OPTIONS: {
      return {
        ...state,
        coachOptions: [
          ...state.coachOptions,
          ...payload.filter(
            ({ id, teamId }) =>
              !state.coachOptions.some(
                ({ id: existCoachId, teamId: existCoachTeamId }) =>
                  id === existCoachId && teamId === existCoachTeamId,
              ),
          ),
        ],
      };
    }
    case CLEAR_COACH_OPTIONS: {
      return {
        ...state,
        coachOptions: [],
      };
    }
    default:
      return state;
  }
};

export default diverInfoReducer;
