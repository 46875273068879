import React from "react";
import { BiBrightnessHalf } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";

import { COLOR_MODE_STATUS } from "constants/storageKeys";
import { INDOOR_THEME, OUTDOOR_THEME } from "assets/styles/themes";

import { colorModeThemeSelector } from "store/selectors/settings";
import { setColorModeTheme } from "store/actions/settings";
import { setLocalStorage } from "helpers/general";

import SwitchIOS from "Components/SwitchIOS";

const TOGGLE_WIDTH = 95;
const THUMB_ICON_SIZE = 19;

const ColorModeToggle = () => {
  const dispatch = useDispatch();
  const colorMode = useSelector(colorModeThemeSelector);

  const onClick = () => {
    const newValue = colorMode === INDOOR_THEME ? OUTDOOR_THEME : INDOOR_THEME;
    dispatch(setColorModeTheme(newValue));
    setLocalStorage({ key: COLOR_MODE_STATUS, value: newValue });
  };

  return (
    <SwitchIOS
      checked={colorMode === OUTDOOR_THEME}
      onClick={onClick}
      bgColorKey="semiGray"
      placeholder="INDOOR"
      thumbIcon={<BiBrightnessHalf fontSize={THUMB_ICON_SIZE} />}
      checkedBgColorKey="accentTextColor"
      checkedPlaceholder="OUTDOOR"
      width={TOGGLE_WIDTH}
      isSaveColorsOnColorModeChange
    />
  );
};

export default ColorModeToggle;
