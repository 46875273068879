import axios from "axios";

import { GCP_BUCKET_URL } from "constants/env";
import { baseURL } from "constants/env";
import { AUTH_KEY } from "constants/storageKeys";

import { errorCallback, hideSpinner } from "helpers/general";

import axiosInstance from "./index";

export const getGCPToken = (config) => {
  return axiosInstance
    .get(`/video/token`, config)
    .then((response) => response.data);
};

export const gcpRequest = ({
  method,
  token,
  name,
  url = `${GCP_BUCKET_URL}/`,
  data,
}) =>
  axios({
    method,
    baseURL: `${url}${encodeURIComponent(name)}`,
    headers: {
      "Content-Type": data?.type,
      Authorization: token ? `Bearer ${token}` : "",
    },
    data,
  })
    .then((response) => response.data)
    .catch((error) => {
      hideSpinner({ path: name });

      errorCallback(error);
    });

const uploadPhoto = ({ baseURL, data, successCallback }) => {
  const formData = new FormData();
  formData.append("file", data);

  return axios({
    method: "POST",
    baseURL,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: localStorage.getItem(AUTH_KEY)
        ? `Bearer ${localStorage.getItem(AUTH_KEY)}`
        : "",
    },
    data: formData,
  })
    .then((response) => {
      successCallback(response.data);

      return response.data;
    })
    .catch((error) => {
      const { url } = error.config;
      hideSpinner({ path: url });

      errorCallback(error);
    });
};

export const sendProfilePhoto = ({ successCallback, data }) =>
  uploadPhoto({
    baseURL: `${baseURL}user/my/portrait/upload`,
    successCallback,
    data,
  });

export const sendProfileBgPhoto = ({ successCallback, data }) =>
  uploadPhoto({
    baseURL: `${baseURL}user/my/portrait/background/upload`,
    successCallback,
    data,
  });

export const uploadFileToGCP = ({ data, token, name, url }) =>
  gcpRequest({ method: "POST", token, name, url, data });

export const deleteFileFromGCP = (props) =>
  gcpRequest({ method: "DELETE", ...props });

export const getFileFromGCP = (props) =>
  gcpRequest({ method: "GET", ...props });

export const postVideoInfo = (diveId, data, config) =>
  axiosInstance
    .post(`/dive/${diveId}/video/upload`, data, config)
    .then((response) => response.data);
