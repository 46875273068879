import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Fancybox } from "@fancyapps/ui/dist/fancybox.esm.js";

import { GCP_URL } from "constants/env";
import { VIDEO_ACTIONS_MAP } from "Components/ThumbnailVideo/constants";

import { getYesNoModalDefaultState } from "constants/types";
import { getFormattedDate } from "helpers/time";
import { removeVideoFromUser } from "store/actions/feed";
import { userDetailsSelector } from "store/selectors/user";

import ThumbnailVideo from "Components/ThumbnailVideo";
import DiversTopAvatars from "Components/DiversTopAvatars";
import ConfirmationModal from "Components/ConfirmationModal";
import ReportVideoModal from "Components/ReportVideoModal";

import Divider from "./Divider";

const FANCYBOX_THUMBNAIL_WIDTH = "100%";
const FANCYBOX_THUMBNAIL_EMBDED_HEIGHT = "191px";
const FANCYBOX_THUMBNAIL_COMMON_HEIGHT = "205px";

const StyledItemHolder = styled.div`
  margin: 5px 20px;

  .thumbnailNavIconsTopRight button {
    width: 28px;
    height: 28px;
  }

  .thumbnailNavIconsTopRight button svg {
    font-size: 16px;
  }
`;
const StyledAuthorHolder = styled.div`
  display: flex;
  margin-bottom: ${({ isSmall }) => (isSmall ? 10 : 20)}px;
`;
const StyledRepostHolder = styled.div`
  padding-left: 15px;
  border-left: 2px solid #dde8fb;
`;
const StyledDiveInfo = styled.p`
  line-height: 17px;
  font-size: 16px;
  margin-bottom: 6px;

  b {
    font-weight: 600;
  }
`;

const VideoFeedItem = ({ video }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const userDetails = useSelector(userDetailsSelector);

  const [modalComponent, setModalComponent] = useState(null);

  const {
    diver,
    owner,
    url = "",
    id,
    thumbnailUrl = "",
    totalAward,
    meetName,
    diveCode,
    diveHeight,
    secondDiver,
    synchroTeamName,
  } = video?.teamVideos || {};
  const uploadedAt = video.teamVideos.uploadedAt || video.date;
  const postDescription = `${t("Posted a video at")} ${getFormattedDate({
    date: uploadedAt,
    dateFormat: "hh:mm aa",
    useOffset: true,
  })} ${t("on")} ${getFormattedDate({
    date: uploadedAt,
    dateFormat: "MMM. d, y",
    useOffset: true,
  })}`;
  const posterSrc = `${GCP_URL}${thumbnailUrl}`;
  const fancyboxOptions = {
    infinity: false,
    autoFocus: false,
  };
  const fancyboxItems = [
    {
      src: `${GCP_URL}${url}`,
      thumb: posterSrc,
      format: "video/mp4",
    },
  ];
  const divers = [diver, secondDiver].filter((item) => item);
  const isRepost = divers.length && !divers.some(({ id }) => id === owner?.id);
  const users = isRepost
    ? [
        {
          ...owner,
          description: postDescription,
        },
      ]
    : divers;
  const videoText = video?.teamVideos
    ? `${divers
        .map(({ firstName, lastName }) => `${firstName} ${lastName}`)
        .join(
          " and ",
        )} scored ${totalAward} points on ${diveHeight}M ${diveCode} at ${meetName}`
    : "";
  const isVideoCanBeDeleted =
    divers.some(({ id }) => id === userDetails?.id) || userDetails?.isAdmin;
  const videoDropdownItems = [
    {
      id: 1,
      route: "#",
      text: t("Share video"),
      callback: async (e) => {
        e.preventDefault();

        const { onClickShareVideo } = await import("helpers/video");

        onClickShareVideo({ id, text: videoText });
      },
    },
    {
      id: 2,
      route: "#",
      text: t("Report video"),
      callback: (e) => {
        e.preventDefault();

        setModalComponent(
          <ReportVideoModal id={id} onClose={() => setModalComponent(null)} />,
        );
      },
    },
    {
      id: 3,
      route: "#",
      text: t("Delete video"),
      callback: (e) => {
        e.preventDefault();

        setModalComponent(
          <ConfirmationModal
            {...{
              ...getYesNoModalDefaultState(t),
              message: t("Are you sure you want to delete this video?"),
              onLeftBtnClick: (e) => {
                e.stopPropagation();
                dispatch(removeVideoFromUser(id));
              },
              onBackdropClick: () => setModalComponent(null),
            }}
          />,
        );
      },
      hidden: !isVideoCanBeDeleted,
    },
  ];

  const onRemoveDiveVideo = (id) => {
    dispatch(removeVideoFromUser(id));
  };

  const diveInfo = (
    <StyledDiveInfo>
      {t("Scored")} <b>{totalAward}</b> {t("points on")}{" "}
      <b>
        {diveHeight}
        {t("M")} {diveCode}
      </b>{" "}
      {t("at")} <b>{meetName}</b>
    </StyledDiveInfo>
  );

  const onClickPlay = () => {
    Fancybox.show(fancyboxItems, fancyboxOptions);
  };

  const renderVideo = (thumbnailHeight) => (
    <>
      {diveInfo}
      <ThumbnailVideo
        id={id}
        divers={divers}
        owner={owner}
        totalAward={totalAward}
        diveHeight={diveHeight}
        diveCode={diveCode}
        meetName={meetName}
        url={thumbnailUrl}
        height={thumbnailHeight}
        width={FANCYBOX_THUMBNAIL_WIDTH}
        onClickPlay={onClickPlay}
        onRemove={onRemoveDiveVideo}
        actionsConfig={[VIDEO_ACTIONS_MAP.SHARE, VIDEO_ACTIONS_MAP.REPORT]}
      />
    </>
  );

  return (
    <>
      <StyledItemHolder>
        <StyledAuthorHolder>
          {modalComponent}
          <DiversTopAvatars
            users={users}
            teamName={!isRepost ? synchroTeamName : ""}
            isSmall={users.length > 1}
            description={!isRepost ? postDescription : ""}
            dropdownItems={videoDropdownItems}
          />
        </StyledAuthorHolder>

        {isRepost ? (
          <StyledRepostHolder>
            <StyledAuthorHolder isSmall>
              <DiversTopAvatars
                users={divers}
                teamName={synchroTeamName}
                isSmall
              />
            </StyledAuthorHolder>
            {renderVideo(FANCYBOX_THUMBNAIL_EMBDED_HEIGHT)}
          </StyledRepostHolder>
        ) : (
          renderVideo(FANCYBOX_THUMBNAIL_COMMON_HEIGHT)
        )}
      </StyledItemHolder>
      <Divider />
    </>
  );
};

export default VideoFeedItem;
