import React from "react";
import styled from "styled-components";

import { SIZE } from "assets/styles/progressBar";

const { CX, CY, R } = SIZE;

const StyledContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-around;
  visibility: ${({ isVisible }) => (isVisible ? "visible" : "hidden")};
`;
const StyledContainerCard = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  text-align: center;
  overflow: hidden;
  transition: 0.5s;
`;
const StyledPercent = styled.div`
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 50%;
`;
const StyledPercentNum = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`;
const StyledTitle = styled.h2`
  color: ${({ theme }) => theme.grayTextColor};
  font-weight: 700;
  font-size: 10px;
  & span {
    font-size: 8px;
    margin: 0 2px;
  }
`;
const StyledSvg = styled.svg`
  position: relative;
  width: 50px;
  height: 50px;
`;
const StyledCircleFirst = styled.circle`
  width: 100%;
  height: 100%;
  fill: none;
  stroke: ${({ theme }) => theme.progressBarEmpty};
  stroke-width: 3;
  stroke-linecap: round;
  transform: translate(5px, 5px);
  stroke-dashoffset: 124;
`;
const StyledCircleSecond = styled.circle`
  width: 100%;
  height: 100%;
  fill: none;
  stroke: ${({ theme }) => theme.progressBarFill};
  stroke-width: 3;
  stroke-linecap: round;
  transform: translate(5px, 5px);
  stroke-dasharray: 124;
  stroke-dashoffset: ${({ part }) => `calc(124 - (124 * ${part}) / 100)`};
`;

const ProgressBar = ({ current = 0, total = 0 }) => {
  const isVisible = current && total;
  const part = isVisible ? (+current * 100) / +total : 0;

  return (
    <StyledContainer isVisible={isVisible}>
      <StyledContainerCard>
        <div>
          <StyledPercent>
            <StyledSvg>
              <StyledCircleFirst cx={CX} cy={CY} r={R} />
              <StyledCircleSecond part={part} cx={CX} cy={CY} r={R} />
            </StyledSvg>
            <StyledPercentNum>
              <StyledTitle>
                {current}
                <span>of</span>
                {total}
              </StyledTitle>
            </StyledPercentNum>
          </StyledPercent>
        </div>
      </StyledContainerCard>
    </StyledContainer>
  );
};

export default ProgressBar;
