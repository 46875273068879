import React from "react";
import styled from "styled-components";
import useResizeObserver from "use-resize-observer";

import { TICKET_CANVAS_TYPES } from "constants/types";

const COLORS = {
  [TICKET_CANVAS_TYPES.DEFAULT]: "#DDE5F4",
  [TICKET_CANVAS_TYPES.HIGH_SCHOOL]: "#2DCC4A",
  [TICKET_CANVAS_TYPES.COLLEGE]: "#9545E0",
  [TICKET_CANVAS_TYPES.CLUB]: "#E3BF0E",
  [TICKET_CANVAS_TYPES.MEET]: "#056CF2",
  [TICKET_CANVAS_TYPES.ENDED_MEET]: "#222222",
  [TICKET_CANVAS_TYPES.FOOTER]: "#0056C6",
};
const BORDER_RADIUS = 24;

const TicketBoxWrapper = styled.div`
  position: relative;
  z-index: 3;

  ${({ isBests }) =>
    isBests
      ? ""
      : `
  .ticketStage {
    position: absolute;
  }
`}
`;

const TicketCanvas = ({
  type,
  children,
  noGradient = false,
  isBests,
  gradientTransform,
  firstPointStopColor = "rgba(0, 0, 0, 0.2)",
  secondPointStopColor = "rgba(0, 0, 0, 0)",
  style,
  withGradientAnimation = false,
  onClick = () => {},
}) => {
  const { ref, width, height } = useResizeObserver();

  const pathData = `M0 ${BORDER_RADIUS}C0 10.7452 10.7452 0 ${BORDER_RADIUS} 0H${
    width / 2 - 26.616
  }C${width / 2 - 21.634} 0 ${width / 2 - 16.82} 1.6514 ${
    width / 2 - 12.361
  } 3.87358C${width / 2 - 8.724} 5.68622 ${width / 2 - 4.123} 7.45227 ${
    width / 2
  } 7.45227C${width / 2 + 4.123} 7.45227 ${width / 2 + 8.724} 5.68623 ${
    width / 2 + 12.361
  } 3.87359C${width / 2 + 16.82} 1.6514 ${width / 2 + 21.634} 0 ${
    width / 2 + 26.616
  } 0H${width - BORDER_RADIUS}C${
    width - 10.745
  } 0 ${width} 10.7452 ${width} ${BORDER_RADIUS}V${
    height - BORDER_RADIUS
  }C${width} ${height - 10.745} ${width - 10.745} ${height} ${
    width - BORDER_RADIUS
  } ${height}H${width / 2 + 25.407}C${width / 2 + 21.182} ${height} ${
    width / 2 + 17.055
  } ${height - 1.176} ${width / 2 + 13.169} ${height - 2.834}C${
    width / 2 + 9.405
  } ${height - 4.441} ${width / 2 + 4.397} ${height - 6.133} ${width / 2} ${
    height - 6.133
  }C${width / 2 - 4.397} ${height - 6.133} ${width / 2 - 9.405} ${
    height - 4.441
  } ${width / 2 - 13.17} ${height - 2.834}C${width / 2 - 17.055} ${
    height - 1.176
  } ${width / 2 - 21.182} ${height} ${
    width / 2 - 25.407
  } ${height}H${BORDER_RADIUS}C10.7452 ${height} 0 ${height - 10.745} 0 ${
    height - BORDER_RADIUS
  }V${BORDER_RADIUS}Z`;

  return (
    <TicketBoxWrapper isBests={isBests} style={style} onClick={onClick}>
      {!!height && (
        <>
          <svg height={height} width={width} className="ticketStage ">
            <path
              d={pathData}
              fill={COLORS[type || TICKET_CANVAS_TYPES.DEFAULT]}
            />
            {!noGradient
              ? !!type && (
                  <>
                    <defs>
                      <linearGradient
                        id="grad1"
                        x1="0%"
                        y1="0%"
                        x2="0%"
                        y2="100%"
                        gradientTransform={gradientTransform}
                      >
                        {withGradientAnimation ? (
                          <>
                            <stop
                              offset="0%"
                              stopColor={firstPointStopColor}
                              stopOpacity="1"
                            >
                              <animate
                                attributeName="stop-color"
                                values={[
                                  firstPointStopColor,
                                  secondPointStopColor,
                                  firstPointStopColor,
                                  secondPointStopColor,
                                  firstPointStopColor,
                                ].join(";")}
                                dur="14s"
                                repeatCount="indefinite"
                              />
                              <animate
                                attributeName="offset"
                                values=".95;.80;.60;.40;.20;0;.20;.40;.60;.80;.95"
                                dur="14s"
                                repeatCount="indefinite"
                              />
                            </stop>
                            <animateTransform
                              attributeName="gradientTransform"
                              type="translate3d"
                              from="-90px, 0, 0"
                              to="85px, 0, 0"
                              dur="10s"
                              repeatCount="indefinite"
                            />
                          </>
                        ) : (
                          <>
                            <stop
                              offset="0%"
                              style={{
                                stopColor: firstPointStopColor,
                                stopOpacity: 1,
                              }}
                            />
                            <stop
                              offset="100%"
                              style={{
                                stopColor: secondPointStopColor,
                                stopOpacity: 1,
                              }}
                            />
                          </>
                        )}
                      </linearGradient>
                    </defs>
                    <path d={pathData} fill="url(#grad1)" />
                  </>
                )
              : null}
          </svg>
        </>
      )}
      <div ref={ref}>{children}</div>
    </TicketBoxWrapper>
  );
};

export default TicketCanvas;
