import { useEffect } from "react";
import _ from "lodash";

const useLocalStorage = (callback, deps = []) => {
  if (!_.isFunction(callback)) {
    throw new Error("Callback in useLocalStorage is not a function");
  }

  if (!_.isArray(deps)) {
    throw new Error("Depends in useLocalStorage is not a Array");
  }

  useEffect(() => {
    window.addEventListener("storage", callback, false);

    return () => window.removeEventListener("storage", callback);
  }, deps);
};

export default useLocalStorage;
