import { MEETS_TYPES } from "constants/types";

export const config = [
  {
    key: MEETS_TYPES.PAST,
    children: "Past",
  },
  {
    key: MEETS_TYPES.UPCOMING,
    children: "Upcoming",
  },
];
