import { createBreakpoint } from "styled-components-breakpoint";

const getBreakPoints = (props) => createBreakpoint(props)("min", "max");

const xxs = getBreakPoints({ min: 0, max: 349 });
const xs = getBreakPoints({ min: 350, max: 480 });
const sm = getBreakPoints({ min: 481, max: 768 });
const md = getBreakPoints({ min: 769, max: 1024 });
const lg = getBreakPoints({ min: 1025, max: 1200 });
const xl = getBreakPoints({ min: 1201 });

export const customBreakpoints = {
  xxs,
  xs,
  sm,
  md,
  lg,
  xl,
};
