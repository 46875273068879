import React from "react";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import { FiUser, FiUsers, FiCalendar, FiSearch, FiHome } from "react-icons/fi";
import { Link, useRouteMatch } from "react-router-dom";

import { ROUTE } from "constants/routing";
import { IS_IOS } from "constants/settings";

import useRouts from "hooks/useRouts";

const StyledDiv = styled.div`
  position: fixed;
  left: 50%;
  bottom: 0;
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  max-width: 450px;
  height: 60px;
  background-color: ${({ theme }) => theme.activeBackground};
  transform: translateX(-50%);

  svg {
    font-size: 40px;
    color: #5d5e85;
  }

  .selected svg {
    color: ${({ theme }) => theme.basicTextColor};
  }

  ${breakpoint("xs")`
    padding-bottom: ${IS_IOS ? "12px" : "0"};
  `};
  ${breakpoint("sm")`
    display: none;
    padding-bottom: 0;
  `};

  @media print {
    display: none;
  }
`;

const NavBarMobile = ({ withHardRefresh }) => {
  const { isProfileRouts, isSearchRouts } = useRouts();
  const teamProfile = useRouteMatch(ROUTE.GROUP(":teamId"));
  const isCustomTeamProfile =
    !!teamProfile && teamProfile.params?.teamId !== "_";

  const NAV_ICONS = {
    home: {
      to: ROUTE.HOME,
      icon: <FiHome />,
      selected: useRouteMatch(ROUTE.HOME),
    },
    search: {
      to: ROUTE.SEARCH,
      icon: <FiSearch />,
      selected: isSearchRouts || isCustomTeamProfile,
    },
    schedule: {
      to: ROUTE.SCHEDULE,
      icon: <FiCalendar />,
      selected: useRouteMatch(ROUTE.SCHEDULE),
    },
    group: {
      to: ROUTE.GROUP(),
      icon: <FiUsers />,
      selected: !!teamProfile && !isCustomTeamProfile,
    },
    profile: {
      to: ROUTE.PROFILE(),
      icon: <FiUser />,
      selected: isProfileRouts,
    },
  };

  return (
    <StyledDiv>
      {Object.keys(NAV_ICONS).map((key) => {
        const { to, icon, selected = false } = NAV_ICONS[key];
        const link = to || ROUTE.DEFAULT;
        const params = {
          key,
          className: selected ? "selected" : "",
        };

        if (withHardRefresh)
          return (
            <a href={link} {...params}>
              {icon}
            </a>
          );

        return (
          <Link to={link} {...params}>
            {icon}
          </Link>
        );
      })}
    </StyledDiv>
  );
};

export default NavBarMobile;
