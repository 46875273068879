const DEFAULT_TRANSLATE_FUNCTION = (string) => string;

export const ERR_MSG = (t = DEFAULT_TRANSLATE_FUNCTION) => ({
  MIN_AGE_VALIDATION: t(
    "The user is under 2 years old. Please enter the valid date of birth",
  ),
  MAX_AGE_VALIDATION: t(
    "The user is above 120 years old. Please enter the valid date of birth",
  ),
  INVALID_DOB:
    t("Please enter the valid date of birth in format ") + "mm/dd/yyyy",
  PASSWORD_STRENGTH: t(
    "Your password must be 8 to 30 characters long, and contain one capital letter, one character, and a number.",
  ),
  VIDEO_EXCEED_SIZE: t("Videos larger than 100MB cannot be uploaded"),
  VIDEO_WRONG_FORMAT: `${t("Uploading video failed")}.${t("Please try again")}`,
  MAX_DIVE_VIDEO_SIZE: 100000000, // 100Mb
  AVATAR_EXCEED_SIZE: t("Please choose an image that is less than 20Mb"),
  MAX_AVATAR_SIZE: 20000000, // 20Mb
  AVATAR_WRONG_FORMAT: `${t("Incorrect format of image")}. ${t(
    "Please try again",
  )}`,
  NO_CURRENT_PASSWORD: t("Please provide current password"),
  NO_NEW_PASSWORD: t("Please provide new password"),
  PASSWORD_SAME_TO_OLD: t("Old and new password must be different"),
  PLEASE_CONFIRM_PASSWORD: t("Please confirm new password"),
  PASSWORDS_MISSMATCH: t("Passwords must match"),
  REQUIRED: t("The field is required"),
  REQUIRED_EMAIL: t("Email is required"),
  RESPONSE: {
    EMAIL_EXISTS: t("Email exists"),
  },
  MAX_HELP_BODY_LENGTH: 1000,
  MAX_HELP_SBJ_LENGTH: 100,
  TEXT_EXCEED_SIZE: (max, current) =>
    `${max} ${t("characters are allowed")}. ${current && t("Now")}: ${
      current || ""
    }`,
  INVALID_FORMAT: t("Invalid format"),
  INVALID_DATE_FORMAT: t("Invalid date format"),
  FORGOT_PASSWORD_TEXT: t(`Can't remember your password?
Click the "Send New Link" button to receive the recovery link in your email`),
  NOT_IOS: t("Not IOS device"),
  NOT_ANDROID: t("Not Android device"),
  NO_AAU_MEMBERSHIP_DATA: t(
    "We were unable to verify your membership against the AAU server. Please check your entries and try again.",
  ),
  SUCCESS_AAU_MEMBERSHIP_DATA: t(
    "Your diver and coach membership status has been checked and updated successfully",
  ),
});

export const UNAUTHORIZED_STATUS = 401;
export const FORBIDDEN_STATUS = 403;
export const NOT_FOUND_STATUS = 404;
export const SERVER_ERROR_STATUS = 500;
export const BAD_GATEWAY_STATUS = 502;
export const GATEWAY_TIMEOUT_STATUS = 504;

export const HTTP_RESPONSE_STATUS_CODES = {
  400: "Bad Request",
  401: "Unauthorized",
  402: "Payment Required Experimental",
  403: "Forbidden",
  404: "Not Found",
  405: "Method Not Allowed",
  406: "Not Acceptable",
  407: "Proxy Authentication Required",
  408: "Request Timeout",
  409: "Conflict",
  410: "Gone",
  411: "Length Required",
  412: "Precondition Failed",
  413: "Payload Too Large",
  414: "URI Too Long",
  415: "Unsupported Media Type",
  416: "Range Not Satisfiable",
  417: "Expectation Failed",
  418: "I am a teapot",
  421: "Misdirected Request",
  422: "Unprocessable Entity (WebDAV)",
  423: "Locked (WebDAV)",
  424: "Failed Dependency (WebDAV)",
  425: "Too Early Experimental",
  426: "Upgrade Required",
  428: "Precondition Required",
  429: "Too Many Requests",
  431: "Request Header Fields Too Large",
  451: "Unavailable For Legal Reasons",
  500: "Internal Server Error",
  501: "Not Implemented",
  502: "Bad Gateway",
  503: "Service Unavailable",
  504: "Gateway Timeout",
  505: "HTTP Version Not Supported",
  506: "Variant Also Negotiates",
  507: "Insufficient Storage (WebDAV)",
  508: "Loop Detected (WebDAV)",
  510: "Not Extended",
  511: "Network Authentication Required",
};
