import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled, { keyframes } from "styled-components";
import {
  BsEnvelopeXFill,
  BsArrowRightShort,
  BsFillEnvelopeExclamationFill,
} from "react-icons/bs";
import { useLocation } from "react-router-dom";

import { emailVerifiedSelector } from "store/selectors/user";
import { convertQueryParamsToObject } from "helpers/general";

import EmailVerificationModal from "Components/EmailVerificationModal";

const attention = keyframes`
  from {
    border-color: #bec5d17a;
    background-color: transparent;
  }
  to {
    border-color: #ED433745;
    background-color: #ED433720;
  }
`;

const EmailVerificationWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 20px 15px;
  margin: 5px 20px 10px;
  border-radius: 8px;
  border: 1.6px solid #bec5d17a;
  animation: ${attention} 3s ease-in 3s infinite reverse;
  transition: all 0.5s;

  :hover {
    background-color: ${({ theme }) => theme.basicBackGroundColor};
    box-shadow: 0 0 4px 0px #00000070;
  }
`;

const EmailVerificationIcon = styled.div`
  svg {
    color: #020340;
    font-size: 24px;
  }

  svg path:last-child {
    fill: ${({ theme, error }) =>
      error ? theme.errorColor : theme.greenButtonColor};
  }
`;

const EmailVerificationDescription = styled.div`
  flex-grow: 1;
  line-height: 1.3;
`;

const EmailVerificationActions = styled.div`
  svg {
    color: ${({ theme }) => theme.basicBlue};
    font-size: 26px;
  }
`;

const EmailVerification = () => {
  const { t } = useTranslation();
  const { search } = useLocation();

  const { emailVerificationToken } = convertQueryParamsToObject(search);

  const emailVerified = useSelector(emailVerifiedSelector);

  const [isOpen, setIsOpen] = useState(false);

  const onClickVerify = () => {
    if (emailVerificationToken) return;

    setIsOpen(true);
  };

  if (typeof emailVerified !== "boolean") return;

  return !emailVerified ? (
    <>
      <EmailVerificationWrapper onClick={onClickVerify}>
        <EmailVerificationIcon error={!emailVerificationToken}>
          {emailVerificationToken ? (
            <BsFillEnvelopeExclamationFill />
          ) : (
            <BsEnvelopeXFill />
          )}
        </EmailVerificationIcon>
        <EmailVerificationDescription>
          {emailVerificationToken ? (
            t("Your email is in the process of verification")
          ) : (
            <>
              {t("Your email address has not been verified")}.{" "}
              {t("Please set your email preferences now")}
            </>
          )}
        </EmailVerificationDescription>
        {!emailVerificationToken && (
          <EmailVerificationActions>
            <BsArrowRightShort />
          </EmailVerificationActions>
        )}
      </EmailVerificationWrapper>
      {isOpen && (
        <EmailVerificationModal isOpen={isOpen} setIsOpen={setIsOpen} />
      )}
    </>
  ) : null;
};

export default EmailVerification;
