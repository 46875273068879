import React from "react";

import VideoFeedItem from "./VideoFeedItem";
import JoinedTeamItem from "./JoinedTeamItem";
import ResultFeedItem from "./ResultFeedItem";
import MeetFeedItem from "./MeetFeedItem";

import { TYPES } from "../constants";

const FeedItemsSwitch = ({ item }) => {
  if (item.type === TYPES.VIDEO) {
    return <VideoFeedItem video={item} />;
  }
  if (item.type === TYPES.EVENT) {
    return <ResultFeedItem resultInfo={item} />;
  }
  if (item.type === TYPES.MEET || item.type === TYPES.MEET_ENDED) {
    return <MeetFeedItem meetData={item} />;
  }
  if (item.type === TYPES.DIVER) {
    return <JoinedTeamItem info={item} />;
  }
  console.log(`WARN! Unexpected feed item type: ${item.type}`);
  return null;
};

export default FeedItemsSwitch;
