import React, { useEffect, useRef, useState } from "react";
import { BsDot, BsThreeDotsVertical } from "react-icons/bs";
import { createPortal } from "react-dom";
import styled from "styled-components";

import useOutsideClick from "hooks/useOutsideClick";

import Button from "Components/Button";

import DropdownList from "./DropdownList";

const DEFAULT_BODY_STYLE = {
  top: 0,
  left: 0,
  visibility: "hidden",
};

const ButtonDropdownWrapper = styled.div`
  .unclickable {
    pointer-events: none;
  }
`;

const ButtonDropdown = ({
  items,
  onClick,
  small,
  buttonIcon,
  buttonStyleType,
  dataTest = "",
  className = "",
  buttonStyle = {},
}) => {
  const menuRef = useRef();

  const [isVisible, setVisible] = useState(false);
  const [bodyStyle, setBodyStyle] = useState(DEFAULT_BODY_STYLE);

  const callbackItems = items.map(({ callback, ...item }) => ({
    ...item,
    callback: (e) => {
      e.cancelBubble = true;
      e.stopPropagation();

      setVisible(false);

      callback && callback(e);
    },
  }));

  const onButtonClick = (e) => {
    e.cancelBubble = true;
    e.stopPropagation();

    const { clientX = 0, clientY = 0 } = e;
    const menuWidth = menuRef.current.offsetWidth || 0;
    const menuHeight = menuRef.current.offsetHeight || 0;
    const left =
      window.innerWidth - clientX > menuWidth ? clientX : clientX - menuWidth;
    let top = clientY + 15;

    if (menuHeight + top > window.innerHeight)
      top = window.innerHeight - menuHeight;

    setVisible(true);
    setBodyStyle({
      top,
      left,
      visibility: "visible",
    });

    onClick && onClick();
  };

  useOutsideClick(menuRef, () => {
    setVisible(false);
  });

  useEffect(() => {
    if (!isVisible) setBodyStyle(DEFAULT_BODY_STYLE);
  }, [isVisible]);

  return (
    <ButtonDropdownWrapper className={className}>
      <Button
        type="button"
        styleType={buttonStyleType || "verticalDots"}
        onClick={onButtonClick}
        data-test={dataTest}
        style={buttonStyle}
        className={isVisible && "unclickable"}
      >
        {buttonIcon ? (
          buttonIcon
        ) : small ? (
          <BsThreeDotsVertical />
        ) : (
          <>
            <BsDot /> <BsDot /> <BsDot />
          </>
        )}
      </Button>
      {createPortal(
        <DropdownList
          items={callbackItems}
          style={bodyStyle}
          wrapRef={menuRef}
        />,
        document.body,
      )}
    </ButtonDropdownWrapper>
  );
};

export default ButtonDropdown;
