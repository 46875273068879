export const SWIPE_STATUS = {
  NULL: "NULL",
  INVITED: "INVITED",
  FOLLOWED: "FOLLOWED",
  JOINED: "JOINED",
  DECLINED: "DECLINED",
  JOIN_PENDING: "JOIN PENDING",
  ADDED: "ADDED",
  PENDING: "PENDING",
};

export const DIVE_STATUSES = {
  NEW: "NEW",
  CLOSED: "CLOSED",
  EVENT_STARTED: "EVENT_STARTED",
  LIVE: "LIVE",
  UP_NEXT: "UP_NEXT",
  DONE: "DONE",
  NULL: "NULL",
  SKIPPED: "SKIPPED",
  SCRATCHED: "SCRATCHED",
  IN_PROGRESS: "IN_PROGRESS",
};

export const EVENT_STATUS = {
  NEW: "NEW",
  CLOSED: "CLOSED",
  CANCELLED: "CANCELLED",
  IN_PROGRESS: "IN_PROGRESS",
  COMBINED: "COMBINED",
  PAUSED: "PAUSED",
  // other
};

export const MEET_STATUS = {
  NEW: "NEW",
  CLOSED: "CLOSED",
  CANCELLED: "CANCELLED",
  IN_PROGRESS: "IN_PROGRESS",
  // other
};

export const PLANS = {
  NO_SUBSCRIPTION: "UNSUBSCRIBE",
  BASIC: "BASIC",
};

export const REQUEST_STATUS = {
  NOT_FOUND: 404,
};

export const ROUND_STATUS = {
  NEW: "NEW",
  IN_PROGRESS: "IN_PROGRESS",
  ENDED: "ENDED",
};

export const MEMBERSHIP_CATEGORY = {
  DIVER: "DIVER",
  NON_DIVER: "NON_DIVER",
};

export const AAU_STATUS = {
  FAILED: "FAILED",
  SUCCESS: "SUCCESS",
};

export const RENDERTRON_STATUS = {
  UNDEFINED: undefined,
  IN_PROGRESS: "1",
  NOT_WORKING: "0",
};

export const WS_STATUSES = {
  CONNECTING: 0,
  OPEN: 1,
  CLOSING: 2,
  CLOSED: 3,
};
