import { REMOVE_VIDEO_FROM_USER, GET_NEXT_FEED_PAGE } from "store/actionTypes";

import { getNextFeedPageRequest } from "API/user";
import { deleteDiveVideo } from "API/videos";

export const getNextFeedPage = (pageNumber) => ({
  type: GET_NEXT_FEED_PAGE,
  payload: getNextFeedPageRequest(pageNumber, {
    withSpinner: false,
  }),
});

export const removeVideoFromUser = (videoId) => ({
  type: REMOVE_VIDEO_FROM_USER,
  payload: deleteDiveVideo(videoId).then(() => videoId),
});
