import React from "react";

import { AVATAR_TYPE } from "constants/types";

import AvatarItem from "Components/AvatarItem";

const TeamAvatar = ({ avatar }) => (
  <AvatarItem
    avatar={avatar}
    avatarType={AVATAR_TYPE.TEAM}
    style={{
      height: 25,
      width: 25,
      marginRight: 10,
      borderRadius: 2,
      backgroundColor: "#f7f7f7",
    }}
  />
);

export default TeamAvatar;
