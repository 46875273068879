import {
  DEFAULT_PLATFORM_HEIGHT,
  MIXED_EVENT_HEIGHT,
} from "constants/settings";

export const getIsDiverHasCode = (currentRound) => (diver) => {
  const dive = diver?.dives?.find(({ number }) => number === currentRound);
  return Boolean(dive?.code);
};

export const getHeight = (eventHeight, diveHeight) => {
  const isEventMixedByHeight = eventHeight === MIXED_EVENT_HEIGHT;
  const isPlatform = eventHeight === DEFAULT_PLATFORM_HEIGHT;

  if (isEventMixedByHeight || isPlatform) return diveHeight;

  return eventHeight;
};
