import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { ROUTE } from "constants/routing";
import { GCP_BUCKET_URL_IMAGES } from "constants/env";

const StyledSplashHolder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  width: 100%;
  padding: 0 20px;
  background-image: url(${GCP_BUCKET_URL_IMAGES}newUserScreen.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left bottom;
  box-sizing: border-box;
`;

const StyledFindBtn = styled(Link)`
  display: inline-block;
  padding: 11px 25px 12px;
  margin-bottom: 15px;
  color: ${({ theme }) => theme.basicTextColor};
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.basicBlue};
`;

const StyledH1 = styled.h1`
  margin-bottom: 23px;
  font-size: 23px;
  text-align: center;
`;

const LabelText = styled.p`
  text-align: center;
  color: ${({ theme }) => theme.semiGray};

  a {
    color: ${({ theme }) => theme.basicBlue};
  }
`;

const SplashScreen = () => {
  const { t } = useTranslation();

  return (
    <StyledSplashHolder>
      <StyledH1>{t("Ready to make a splash?")}</StyledH1>
      <StyledFindBtn data-test="findTeamButton" to={ROUTE.SEARCH}>
        {t("Find a team, diver or meet")}
      </StyledFindBtn>
      <LabelText>
        {t("If you don't have a team, join ")}
        <Link to={ROUTE.SEARCH_TEAM}>{t("Unattached")}</Link>
      </LabelText>
    </StyledSplashHolder>
  );
};

export default SplashScreen;
