import React, { useRef } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { support } from "constants/settings";
import { ERR_MSG } from "constants/errors";

import HeaderWithArrows from "Components/HeaderWithArrows";
import HelpForm from "./Components/HelpForm";
import Button from "Components/Button";

const StyledBottomNote = styled.p`
  color: ${({ theme }) => theme.unimportantText};
  text-align: center;

  a {
    color: ${({ theme }) => theme.basicBlue};

    :hover {
      text-decoration: none;
    }
  }
`;

const StyledContentBody = styled.div`
  flex: 1 1 auto;
  height: 0px;
  padding: 23px 20px;
  overflow-y: auto;
`;

const HelpRequest = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const formRef = useRef();

  const phoneLink = `tel:${support.phone}`;

  const onSubmitClick = () => {
    formRef?.current?.handleSubmit();
  };

  const onBackCloseClick = () => {
    history.go(-2);
  };

  const onClickPhone = (numberPhone) => {
    const phone = numberPhone
      .split("-")
      .map((e) => e.split(" ").join(""))
      .join("");

    try {
      window.webkit.messageHandlers.appClickListener.postMessage(
        `openNativePhoneCall_${phone}`,
      );
    } catch (err) {
      console.warn(ERR_MSG(t).NOT_IOS);
    }
  };

  return (
    <>
      <HeaderWithArrows
        onBackClick={onBackCloseClick}
        onRightButtonClick={onBackCloseClick}
        headerText={t("How can we help you?")}
      />
      <StyledContentBody>
        <HelpForm formRef={formRef} onBackCloseClick={onBackCloseClick} />
        <StyledBottomNote>
          {t("If you need urgent assistance")},<br /> {t("call")}{" "}
          <a href={phoneLink} onClick={() => onClickPhone(support.phone)}>
            {support.phone}
          </a>
        </StyledBottomNote>
      </StyledContentBody>
      <Button
        data-test="helpRequestButton"
        type="submit"
        styleType="meetNext"
        onClick={onSubmitClick}
      >
        {t("SEND")}
      </Button>
    </>
  );
};

export default HelpRequest;
