import { createStore, applyMiddleware, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { createLogger } from "redux-logger";
import thunk from "redux-thunk";
import promise from "redux-promise-middleware";

import { ENVS } from "constants/env";

import rootReducer from "store/reducers/root";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const composeEnchancers =
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configureStore = (initState) => {
  const logger = createLogger();
  let middleware = [promise, thunk];

  if (ENVS[process.env.REACT_APP_ENV].IS_LOGGER) {
    middleware = [...middleware, logger];
  }

  const store = createStore(
    persistedReducer,
    initState,
    composeEnchancers(applyMiddleware(...middleware)),
  );
  const persistor = persistStore(store);

  return { store, persistor };
};

export default configureStore;
