import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { SITE_ENVS } from "constants/env";

import { openTab } from "helpers/user";

const StyledMessageHolder = styled.div`
  display: flex;
  flex-direction: column;
  & h6 {
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: 600;
  }
  & p {
    font-size: 14px;
    line-height: 16px;
  }
`;

const SwitchToPcModalMessage = () => {
  const { t } = useTranslation();

  const onClickExternalLink = (e) => {
    e.preventDefault();
    openTab(SITE_ENVS.PROD);
  };

  return (
    <StyledMessageHolder>
      <h6>{t("MISSING FEATURE")}</h6>
      <p>
        {t(
          "Meet administration should be run on a desktop or laptop. Please log in to",
        )}{" "}
        <a href={SITE_ENVS.PROD} onClick={onClickExternalLink}>
          DiveLiveApp.com
        </a>{" "}
        {t("on your computer to run the competition.")}
      </p>
    </StyledMessageHolder>
  );
};

export default SwitchToPcModalMessage;
