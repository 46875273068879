import React from "react";
import styled from "styled-components";

import BackgroundWaves from "Components/BackgroundWaves";

const StyledHead = styled.div`
  position: relative;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const HeaderBar = () => (
  <StyledHead>
    <BackgroundWaves />
  </StyledHead>
);

export default HeaderBar;
