import React from "react";

import { IS_ENV_DEVELOPMENT } from "constants/env";

//import * as webpackContext from "../assets/images/icons";
const webpackContext = require.context("assets/images/icons", true);

const iconMap = webpackContext.keys().reduce((acc, k) => {
  const iconName = k
    .split("/")
    .slice(-1)[0]
    .replace(/\.[^/.]+$/, "");

  acc[iconName] = webpackContext(k);

  return acc;
}, {});

const Icon = ({ name, ...restProps }) => {
  const src = iconMap[name];

  if (!src) {
    IS_ENV_DEVELOPMENT && console.warn("Wrong icon name:", name);
    return null;
  }
  return <img src={src} alt={name} className="icon" {...restProps} />;
};

export default Icon;
