import React from "react";
import styled from "styled-components";

const StyledOrWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 320px;
`;

const StyledP = styled.p`
  color: #9b9b9b;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 15px;
  text-align: center;
  margin: 34px auto 0 auto;
  width: 100%;
`;

const OrDivider = ({ text = "Or connect with", children }) => (
  <StyledOrWrapper>
    {text && <StyledP>{text}</StyledP>}
    {children}
  </StyledOrWrapper>
);

export default OrDivider;
