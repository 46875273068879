import axiosInstance from "./index";

export const createDiverRequest = (meetId, diver) =>
  axiosInstance
    .post(`/meet/${meetId}/divers/add`, diver)
    .then((response) => ({ meetId, divers: response?.data }));

export const getAvailableMeetDiversRequest = (eventId) =>
  axiosInstance
    .get(`/event/${eventId}/divers`)
    .then((response) => response?.data);

export const getAddedDiversRequest = (meetId) =>
  axiosInstance
    .get(`/meet/${meetId}/divers`)
    .then((response) => response?.data);

export const getCodesTableRequest = (ddList) =>
  axiosInstance
    .get(`/difficulty/${ddList}`)
    .then((response) => ({ [ddList]: response?.data?.flat() }));

export const assignDiverWithDivesRequest = (eventId, dives) =>
  axiosInstance
    .post(`/event/${eventId}/diver/assign`, dives)
    .then((response) => response?.data);

export const validateSheetRequest = (eventId, dives) =>
  axiosInstance
    .post(`/event/${eventId}/sheet/validate`, dives)
    .then((response) => response?.data);

export const currentUserValidateSheetRequest = (eventId, dives) =>
  axiosInstance
    .post(`event/${eventId}/validate/user/current`, dives)
    .then((response) => response?.data);

export const getLeadershipVideosRequest = (diveId, config) =>
  axiosInstance
    .get(`/dive/${diveId}/video`, config)
    .then((response) => response?.data);

export const getDiveSheetVideosRequest = (sheetId) =>
  axiosInstance
    .get(`/sheet/${sheetId}/video`)
    .then((response) => response?.data);

export const postFocusChangedRequest = (data) =>
  axiosInstance
    .post("/meet/focus/changed", data)
    .then((response) => response?.data);

export const postFollowTeamRequest = (teamId) =>
  axiosInstance
    .post(`/team/${teamId}/follow`)
    .then((response) => response?.data);

export const postUnfollowTeamRequest = (teamId) =>
  axiosInstance
    .post(`/team/${teamId}/unfollow`)
    .then((response) => response?.data);

export const getAllUsersRequest = (page = 0) =>
  axiosInstance.get(`/user/all/${page}`).then((response) => response?.data);

export const searchUsersByKeyRequest = ({ key = "", page = 0 }) =>
  axiosInstance
    .get(`/user/search/${encodeURIComponent(key)}/${page}`)
    .then((response) => response?.data);

export const searchAllUsersRequest = (key) =>
  axiosInstance
    .get(`user/all/search/${encodeURIComponent(key)}`)
    .then((response) => response.data);

export const followDiverRequest = (diverId) =>
  axiosInstance
    .post(`/user/${diverId}/follow`)
    .then((response) => ({ ...response?.data, diverId }));

export const unfollowDiverRequest = (diverId) =>
  axiosInstance
    .post(`/user/${diverId}/unfollow`)
    .then((response) => ({ ...response?.data, diverId }));

export const getTeamDiversRequest = ({ teamId }) =>
  axiosInstance
    .get(`/team/${teamId}/divers`)
    .then((response) => response?.data);

export const getEventTeamDiversRequest = ({ eventId, teamId }) =>
  axiosInstance
    .get(`/event/${eventId}/team/${teamId}/divers`)
    .then((response) => response?.data);

export const setCombinedSynchroRequest = (data) =>
  axiosInstance
    .post("/user/combined/combine", data)
    .then((response) => response?.data);

export const uncombineSynchroRequest = (data) =>
  axiosInstance
    .post("/user/combined/uncombine", data)
    .then((response) => response?.data);
