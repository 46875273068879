import {
  GET_AVAILABLE_ON_MEET_DIVERS,
  GET_ADDED_RECENTLY_DIVERS,
  CREATE_DIVER,
  GET_DIVE_CODES_TABLE,
  CLEAR_DIVERS_ADDED_RECENTLY,
  actionFulfilled,
  GET_TEAM_DIVERS,
} from "../actionTypes";

const defaultState = {
  availableOnMeet: [],
  recentlyAddedOnMeet: [],
  difficultyList: {},
  teamDivers: [],
};

const diversReducer = (state = defaultState, { type, payload }) => {
  switch (type) {
    case actionFulfilled(GET_AVAILABLE_ON_MEET_DIVERS):
      return { ...state, availableOnMeet: payload };
    case actionFulfilled(GET_ADDED_RECENTLY_DIVERS):
      return { ...state, recentlyAddedOnMeet: payload };
    case actionFulfilled(CREATE_DIVER):
      return payload?.divers
        ? { ...state, recentlyAddedOnMeet: payload?.divers }
        : state;
    case actionFulfilled(GET_DIVE_CODES_TABLE):
      return {
        ...state,
        difficultyList: { ...state.difficultyList, ...(payload || {}) },
      };

    case CLEAR_DIVERS_ADDED_RECENTLY:
      return {
        ...state,
        recentlyAddedOnMeet: [],
      };
    case actionFulfilled(GET_TEAM_DIVERS):
      return { ...state, teamDivers: payload?.divers };

    default:
      return state;
  }
};

export default diversReducer;
