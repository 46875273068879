import { toast } from "react-toastify";

import { PLANS } from "constants/status";
import {
  actionFulfilled,
  USER_LOG_IN,
  SESSION_EXPIRED,
  USER_REGISTRATION,
  GET_CURRENT_USER_DETAILS,
  GET_CUSTOM_USER_DETAILS,
  GET_USER_TEAMS,
  SEARCH_USER_TEAMS,
  SET_USER_TEAM_ID,
  LEAVE_TEAM,
  GET_CURRENT_USER_SUBSCRIPTION_PLAN,
  JUDGE_SIGN_IN_BY_CODE,
  JUDGE_SIGN_IN_BY_QR_CODE,
  GET_CURRENT_JUDGE_DETAILS,
  UPDATE_CURRENT_JUDGE_DETAILS,
  GET_CURRENT_EVENT_INFORMATION_FOR_JUDGE,
  CLEAR_JUDGE_DETAILS,
  GET_AAU_CURRENT_USER_INFO,
  SET_TEAM_INVITE,
  FOLLOW_USER,
  EMAIL_VERIFY,
} from "store/actionTypes";
import { AUTH_KEY, JM_AUTH_KEY } from "constants/storageKeys";

import { sendMessageToMobile, setLocalStorage } from "helpers/general";

const PAGINATION_TEAMS_SIZE = 20;
const initState = {
  teams: {
    list: [],
    currentPage: 0,
    selectedTeam: null,
  },
  subscription: PLANS.NO_SUBSCRIPTION,
  memberships: {},
};

const userReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case CLEAR_JUDGE_DETAILS:
      return {
        ...state,
        codes: undefined,
      };
    case actionFulfilled(USER_LOG_IN): {
      const {
        token,
        subscription,
        userId,
        username,
        dob,
        isAdmin,
        firstName,
        lastName,
      } = payload;

      setLocalStorage({ key: AUTH_KEY, value: token, isStringified: true });
      sendMessageToMobile({ message: `jwtToken_${token}` });

      return {
        ...state,
        subscription,
        token,
        details: {
          id: userId,
          email: username,
          dob,
          isAdmin,
          firstName,
          lastName,
        },
      };
    }
    case SESSION_EXPIRED:
      if (state.token) toast.error("Session has expired");
      return state;
    case actionFulfilled(GET_CUSTOM_USER_DETAILS):
      return {
        ...state,
        profile: payload,
      };
    case actionFulfilled(FOLLOW_USER): {
      return {
        ...state,
        profile: {
          ...state.profile,
          followed: payload.follow,
        },
      };
    }
    case actionFulfilled(GET_CURRENT_USER_DETAILS):
      return {
        ...state,
        profile: payload,
        details: payload,
      };
    case actionFulfilled(GET_AAU_CURRENT_USER_INFO):
      return {
        ...state,
        memberships: payload,
      };
    case actionFulfilled(GET_CURRENT_JUDGE_DETAILS):
      return {
        ...state,
        codes: payload,
      };
    case UPDATE_CURRENT_JUDGE_DETAILS:
      const newCodes = payload?.tokens?.find(
        ({ token, socketToken }) =>
          token === state.codes?.token ||
          socketToken === state.codes?.socketToken,
      );

      return {
        ...state,
        codes: newCodes
          ? {
              ...newCodes,
            }
          : undefined,
      };
    case actionFulfilled(GET_CURRENT_EVENT_INFORMATION_FOR_JUDGE):
      return {
        ...state,
        eventInformation: payload,
      };
    case actionFulfilled(JUDGE_SIGN_IN_BY_CODE):
    case actionFulfilled(JUDGE_SIGN_IN_BY_QR_CODE): {
      const { jwtToken, ...rest } = payload;

      setLocalStorage({
        key: JM_AUTH_KEY,
        value: jwtToken,
        isStringified: true,
      });

      return {
        ...state,
        codes: rest?.token ? rest : undefined,
      };
    }
    case actionFulfilled(USER_REGISTRATION):
      return {
        ...state,
        username: payload.username,
      };

    case actionFulfilled(GET_USER_TEAMS):
    case actionFulfilled(SEARCH_USER_TEAMS): {
      const { currentPage, list } = payload;
      const newList = currentPage ? [...state.teams?.list, ...list] : list;

      return {
        ...state,
        teams: {
          ...state.teams,
          currentPage,
          hasMore: list.length === PAGINATION_TEAMS_SIZE,
          list: newList,
          selectedTeam:
            !state.teams?.selectedTeam?.id && newList.length
              ? newList[0]
              : state.teams?.selectedTeam,
        },
      };
    }
    case actionFulfilled(LEAVE_TEAM): {
      const { role, teamId } = payload;

      const list = state.teams?.list
        ?.map((team) => {
          if (team.id === teamId)
            return {
              ...team,
              roles: team.roles.filter((oldRole) => oldRole !== role),
            };

          return team;
        })
        .filter(({ roles }) => !!roles.length);
      const selectedTeam =
        list?.find(
          (team) => team.id === Number(state.teams?.selectedTeam?.id),
        ) || list[0];

      return {
        ...state,
        teams: {
          ...state.teams,
          list,
          selectedTeam,
        },
      };
    }
    case SET_USER_TEAM_ID:
      return {
        ...state,
        teams: {
          ...state.teams,
          selectedTeam: payload
            ? state.teams?.list?.find(({ id }) => id === payload) || {
                id: payload,
              }
            : null,
        },
      };
    case actionFulfilled(GET_CURRENT_USER_SUBSCRIPTION_PLAN):
      return {
        ...state,
        subscription: payload,
      };
    case actionFulfilled(SET_TEAM_INVITE): {
      const { teamId, enabled } = payload;

      return {
        ...state,
        teams: {
          ...state.teams,
          list: state.teams?.list?.map((team) => {
            if (teamId === team.id)
              return { ...team, autoInviteEnabled: enabled };

            return team;
          }),
          selectedTeam: {
            ...state.teams?.selectedTeam,
            autoInviteEnabled: enabled,
          },
        },
      };
    }
    case actionFulfilled(EMAIL_VERIFY): {
      const { email, emailVerified } = payload;

      if (state.details)
        return {
          ...state,
          details: {
            ...state.details,
            emailVerified:
              state.details.email === email
                ? emailVerified
                : state.details.emailVerified,
          },
        };

      return state;
    }

    default:
      return state;
  }
};

export default userReducer;
