import React from "react";
import styled from "styled-components";

import { GOOGLE_PLAY_LINK, APP_STORE_LINK, IS_IOS } from "constants/settings";
import { GCP_BUCKET_URL_IMAGES } from "constants/env";

import { isMobileDevice, isAndroid, openTab } from "helpers/user";

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  max-width: 320px;
  margin-top: 18px;
`;

const ExternalLink = styled.a`
  width: 49%;
  line-height: 0;
  border-radius: 10px;
  overflow: hidden;

  > img {
    width: 100%;
  }
`;

const DownloadAppLinks = () => {
  const links = [
    {
      link: GOOGLE_PLAY_LINK,
      img: `${GCP_BUCKET_URL_IMAGES}google-play.png`,
      show: isAndroid(),
    },
    {
      link: APP_STORE_LINK,
      img: `${GCP_BUCKET_URL_IMAGES}app-store.png`,
      show: IS_IOS,
    },
  ];

  const openStore = (e, link) => {
    e.preventDefault();
    openTab(link);
  };

  return isMobileDevice() ? (
    <StyledWrapper>
      {links.map(({ link, img, show }) =>
        show ? (
          <ExternalLink
            href={link}
            key={link}
            onClick={(e) => openStore(e, link)}
          >
            <img src={img} alt="" />
          </ExternalLink>
        ) : null,
      )}
    </StyledWrapper>
  ) : null;
};

export default DownloadAppLinks;
