import { EVENT_STATUS } from "constants/status";

import { getCurrentEvent } from "helpers/websocket";

import { passedDiversByEventIdSelector } from "./championship";

const getCurrentEventFromState = (state) => {
  const { events = [] } = state?.leadershipScreens?.websocketNewMessage || {};
  const eventsInProgress = events.filter(
    ({ eventStatus }) => eventStatus === EVENT_STATUS.IN_PROGRESS,
  );
  const currentEvent = getCurrentEvent(
    eventsInProgress.length ? eventsInProgress : events,
  );

  return currentEvent;
};

export const wsNewMessageSelector = (state) =>
  state?.leadershipScreens?.websocketNewMessage;

export const wsCurrentRoundSelector = (state) =>
  state?.leadershipScreens?.currentRound || {};

export const currentRoundDiveOnScreenSelector = (state) =>
  state?.leadershipScreens?.currentRound?.diveOnScreen || {};

export const currentRoundCurrentTotalSelector = (state) =>
  state?.leadershipScreens?.currentRound?.currentTotal || 0;

export const currentRoundNumberSelector = (state) =>
  state?.leadershipScreens?.currentRoundNumber || 0;

export const currentDiverNumberSelector = (state) =>
  state?.leadershipScreens?.currentDiverNumber || 0;

export const wsHeaderInfoSelector = (state) =>
  state?.leadershipScreens?.headerInfo || {};

export const roundQueueSelector = (state) =>
  state?.leadershipScreens?.roundQueue || [];

export const roundDiversSelector = (state) =>
  state?.leadershipScreens?.roundDivers || [];

export const selectMeetAdmins = (state) =>
  state?.leadershipScreens?.meetAdmins || [];

export const selectIsCurrentUserMeetAdmin = (state) =>
  state?.leadershipScreens?.meetAdmins?.some(
    ({ id }) => id && id === state?.user?.details?.id,
  );

export const wsMeetIdSelector = (state) =>
  getCurrentEventFromState(state)?.meetId;

export const isOpenMeetSelector = (state) =>
  getCurrentEventFromState(state)?.isOpenMeet;

export const wsEventStatusSelector = (state) =>
  getCurrentEventFromState(state)?.eventStatus;

export const wsRoundNumberSelector = (state) =>
  getCurrentEventFromState(state)?.roundNumber || "-";

export const leadershipDiverVideosSelector = (state) =>
  Object.values(state.leadershipScreens.diveList.diveVideos)?.flat();

export const selectLeadershipDiveVideos = (diveId) => (state) =>
  state.leadershipScreens.diveList.diveVideos[diveId];

export const leaderScreenEventsSelector = (state) =>
  state.leadershipScreens.leaderScreenEvents;

export const leaderScreenEventSelector = (selectedEventId) => (state) =>
  state.leadershipScreens.leaderScreenEvents?.find(
    ({ eventId, mainBoardId, mainEventId }) =>
      mainBoardId === selectedEventId ||
      mainEventId === selectedEventId ||
      eventId === selectedEventId,
  ) || {};

export const meetPermissionsSelector = (state) =>
  state.leadershipScreens.meetPermissions;

export const leaderboardQueueSelector = (eventId, paramsEventId) => (state) => {
  const { sheets = [] } = leaderScreenEventSelector(eventId)(state);
  const passedDivers = passedDiversByEventIdSelector(paramsEventId)(state);

  return [
    ...sheets,
    ...passedDivers.filter(
      ({ userInfo, combinedUserInfo }) =>
        !sheets.some((sheet) =>
          !!combinedUserInfo
            ? sheet.combinedUserInfo?.id === combinedUserInfo?.id
            : sheet.userInfo?.id === userInfo?.id,
        ),
    ),
  ]
    .sort((a, b) => b.totalAward - a.totalAward || a.place - b.place)
    .reduce((acc, cur) => {
      const prevDiverPlace = acc.length ? acc[acc.length - 1].place : 0;

      return [
        ...acc,
        {
          ...cur,
          place:
            cur.exhibition || cur.prequalified
              ? prevDiverPlace
              : prevDiverPlace + 1,
        },
      ];
    }, []);
};
