import React, { memo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Redirect, useLocation, useRouteMatch } from "react-router-dom";

import { RENDERTRON_STATUS } from "constants/status";
import { ROUTE } from "constants/routing";

import { judgeCodesSelector, userSelector } from "store/selectors/user";
import { convertQueryParamsToObject } from "helpers/general";

import CommonRouter from "./CommonRouter";

const CommonRouterLayout = memo((props) => {
  const { t } = useTranslation();

  const user = useSelector(userSelector);
  const { pathname, search } = useLocation();
  const code = useSelector(judgeCodesSelector);

  const { isRendertronWorking } = convertQueryParamsToObject(search);

  const isLeaderboard = useRouteMatch(
    ROUTE.LEADERSHIP(":eventId", "Leaderboard"),
  )?.isExact;
  const isVideo = useRouteMatch(ROUTE.VIDEO(":userId"))?.isExact;

  const isPublicPage = isVideo || isLeaderboard;

  const isLoginPage = useRouteMatch(ROUTE.LOGIN)?.isExact;
  const isForgotPasswordPage = useRouteMatch(ROUTE.FORGOT_PASSWORD)?.isExact;
  const isResetPasswordPage = useRouteMatch(
    ROUTE.RESET_PASSWORD(":tokenId"),
  )?.isExact;
  const isRegistrationPage = useRouteMatch(ROUTE.REGISTRATION)?.isExact;
  const isLoginDetailsPage = useRouteMatch(ROUTE.LOGIN_DETAILS)?.isExact;
  const isChangePasswordPage = useRouteMatch(ROUTE.CHANGE_PASSWORD)?.isExact;

  const isNoUserPage =
    isLoginPage ||
    isForgotPasswordPage ||
    isResetPasswordPage ||
    isRegistrationPage ||
    isLoginDetailsPage ||
    isChangePasswordPage;

  useEffect(() => {
    if (
      !(
        (user && user.token) ||
        isPublicPage ||
        code ||
        isRendertronWorking === RENDERTRON_STATUS.IN_PROGRESS
      ) &&
      !isNoUserPage
    ) {
      toast.error(t("Access denied"));
    }
  }, [pathname]);

  if (
    (user && user.token) ||
    isPublicPage ||
    code ||
    isRendertronWorking === RENDERTRON_STATUS.IN_PROGRESS ||
    isLoginDetailsPage
  )
    return <CommonRouter {...props} />;

  return (
    <Redirect
      to={{
        pathname: ROUTE.LOGIN,
        search,
      }}
    />
  );
});

CommonRouterLayout.displayName = "CommonRouterLayout";

export default CommonRouterLayout;
