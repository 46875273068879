import axiosInstance from "./index";

export const setTeamInviteRequest = ({ teamId, enabled }) =>
  axiosInstance
    .post(`team/${teamId}/invite/${enabled}`)
    .then((response) => response.data);

export const cancelJoinRequest = (key) =>
  axiosInstance
    .post(`/invitation/${key}/cancel`)
    .then((response) => response.data);
