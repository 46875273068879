import {
  actionFulfilled,
  actionRejected,
  GET_VIDEO,
  SET_SHARE_MODAL_DETAILS,
  REPORT_VIDEO,
  RESTORE_VIDEO,
} from "store/actionTypes";

const defaultState = {
  currentVideo: null,
  error: null,
  shareModalDetails: null,
};

const videosReducer = (state = defaultState, { type, payload }) => {
  switch (type) {
    case actionFulfilled(GET_VIDEO):
      return {
        ...state,
        currentVideo: { ...payload },
      };
    case actionRejected(GET_VIDEO):
      const { status, data } = payload?.response || {};

      return {
        ...state,
        error: { status, message: data },
      };

    case SET_SHARE_MODAL_DETAILS:
      return {
        ...state,
        shareModalDetails: payload,
      };

    case actionFulfilled(REPORT_VIDEO):
      return {
        ...state,
        currentVideo: { ...state.currentVideo, isReported: true },
      };

    case actionFulfilled(RESTORE_VIDEO):
      return {
        ...state,
        currentVideo: { ...state.currentVideo, isReported: false },
      };

    default:
      return state;
  }
};

export default videosReducer;
