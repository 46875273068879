import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { BsArrowDownCircle, BsArrowUpCircle } from "react-icons/bs";

import { getDiveUpDownAvatarHint } from "helpers/diveUp";

import HintWarn from "Components/HintWarn";
import AvatarItem from "Components/AvatarItem";

const StyledAvatarHolder = styled.div`
  position: relative;
  box-sizing: border-box;
  height: ${({ isSmall }) => (isSmall ? "38px" : "60px")};
  width: ${({ isSmall }) => (isSmall ? "38px" : "60px")};
  border-radius: ${({ isSmall }) => (isSmall ? " 12.5px" : "20px")};
  margin-right: 14px;
  cursor: ${({ onClick }) => (onClick ? "pointer" : "default")};
  flex-shrink: 0;

  @media print {
    display: none;
  }
`;

const StyledDiveWrapper = styled.div`
  position: absolute;
  top: -5px;
  right: -5px;

  svg {
    color: ${({ theme }) => theme.modalBlueBtnColor};
    background: ${({ theme }) => theme.basicBackGroundColor};
    border-radius: 50%;
  }
`;

const AvatarWithDefaultImage = ({
  src,
  isSmall = true,
  onAvatarClick,
  diveUp = false,
  diveDown = false,
  isCanDiveUpDown = false,
  avatarType,
}) => {
  const { t } = useTranslation();

  const diveUpHintWarnDescription = getDiveUpDownAvatarHint(diveUp);

  return (
    <StyledAvatarHolder onClick={onAvatarClick} isSmall={isSmall}>
      <AvatarItem isSmall={isSmall} avatar={src} avatarType={avatarType} />
      {isCanDiveUpDown && (diveUp || diveDown) && (
        <StyledDiveWrapper>
          <HintWarn description={t(diveUpHintWarnDescription)}>
            {diveUp ? (
              <BsArrowUpCircle className="dive-up-icon" />
            ) : (
              <BsArrowDownCircle className="dive-down-icon" />
            )}
          </HintWarn>
        </StyledDiveWrapper>
      )}
    </StyledAvatarHolder>
  );
};

export default AvatarWithDefaultImage;
