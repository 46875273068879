export const PROFILE_EDIT_TABS = {
  PERSONAL_INFO: "Personal info",
  DETAILS: "Details",
  ABOUT_ME: "About me",
};

const currentYear = new Date().getFullYear();
export const GRADUATION_YEAR_MIN_VALUE = 1950;
export const GRADUATION_YEAR_MAX_VALUE = currentYear + 20;
export const MONTH_MIN_INDEX = 0;
export const MONTH_MAX_INDEX = 11;

export const ABOUT_ME_SYMBOLS_LIMIT = 1000;

export const PHONE_VALIDATION_REGEX =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
